define('client/routes/processing-section/decision', ['exports', 'client/mixins/routes/generic-workflows', 'client/libs/documents-component'], function (exports, _genericWorkflows, _documentsComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    remoteMethods: inject.service('resource-consent-remote-methods'),

    // Function to be called after save (in edit-form-action.js)
    // This updates the pageInfo, so menu actions will work
    // correctly
    postFormSaveFunction: function postFormSaveFunction() {
      var processingModel = this.modelFor('processing-section');
      get(this, 'remoteMethods').getProcessingPageInfo(processingModel.pageInfo.applicationId, true).then(function (pageInfo) {
        set(processingModel, 'pageInfo', pageInfo);
      });
    },


    workflows: [{
      key: 'application_decision',
      path: 'decision',
      title: 'Application decision'
    }, {
      component: 'documents',
      type: 'component',
      path: 'documents',
      title: 'Documents',
      getMenu: _documentsComponent.default.getMenu
    }]
  });
});