define('client/components/dynamic-form/edit/orderable-text-fields/orderable-text-field-element/sub-heading/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-orderable-field__field-container'],

    deEntitiedStateValue: computed('stateValue', function () {
      var txt = document.createElement('textarea');
      txt.innerHTML = get(this, 'stateValue');
      return txt.value;
    })

  });
});