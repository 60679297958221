define('client/components/maps/select-on-map/component', ['exports', 'client/mixins/components/common-map-mixin', 'client/libs/map-lib'], function (exports, _commonMapMixin, _mapLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get,
      set = Ember.set;


  // Default zoom level to jump to when a marker is set.
  var DEFAULT_ZOOM_LEVEL = 11;

  /**
   * Displays a map to select a point on.
   *
   * {{maps/set-location-on-map
   *   setLocation=closureFunction
   *   startPosition=startingPosition
   * }}
   *
   * Where:
   *  - closureFunction: called when a new location is set, has one argument
   *    which is an easting/northing array.
   *  - startingPosition: initial location to show on the map [easting, northing].
   */
  exports.default = Ember.Component.extend(_commonMapMixin.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initMap();
    },


    /**
     * Initialise the map.
     */
    initMap: function initMap() {
      var _this = this;

      this.createMapAndBaseLayers('.js-map-container');

      var map = get(this, 'map');

      map.on('click', function (event) {
        _this.updateMarkerPosition(event.latlng);

        // Run the callback function
        var setLocation = get(_this, 'setLocation');
        if (setLocation) {
          setLocation(_mapLib.default.latLngToNztm(event.latlng));
        }
      });

      var startPosition = get(this, 'startPosition');
      if (startPosition) {
        var _startPosition$map = startPosition.map(function (x) {
          return Number(x);
        }),
            _startPosition$map2 = _slicedToArray(_startPosition$map, 2),
            easting = _startPosition$map2[0],
            northing = _startPosition$map2[1];

        if (_mapLib.default.validateNztm(easting, northing)) {
          var latlng = _mapLib.default.nztmToLatLng([easting, northing]);
          this.updateMarkerPosition(latlng);
          map.setView(latlng, DEFAULT_ZOOM_LEVEL);
        }
      }
    },

    /**
     * Update the marker position on the map.
     * @param LatLng latlng
     */
    updateMarkerPosition: function updateMarkerPosition(latlng) {
      var map = get(this, 'map');
      var marker = get(this, 'marker');
      var genericIcon = L.icon({
        iconUrl: '/assets/markers/marker-generic.svg',
        iconSize: [35, 44],
        iconAnchor: [18, 44]
      });
      if (!marker) {
        // Just use a generic marker.
        set(this, 'marker', new L.Marker(latlng, { icon: genericIcon }).addTo(map));
      } else {
        marker.setLatLng(latlng);
      }
    }
  });
});