define('client/components/dynamic-form/edit/typeahead-cap-consent-list-with-table/component', ['exports', 'client/mixins/components/dynamic-formElement', 'ember-light-table'], function (exports, _dynamicFormElement, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',
    uniqueId: Ember.inject.service(),
    remoteMethods: inject.service('resource-consent-remote-methods'),
    consentString: null, // e.g '220641.01,220760,30961.02',
    table: null,
    hasRows: false,
    showNoRows: false,
    maxLength: 500,
    toolTipText: 'Enter consent or decision numbers separated by commas in the list below. \n                Review the records and adjust your search criterial until you have the list you wish to add.\n                <br/><br/>Then click on \'Add affected Person\' An individual affected person record will be created for \n                each person and you will be redirected to the first record. ',

    initaliseTable: function initaliseTable(self, formInstanceId, consentString) {
      get(self, 'remoteMethods').getHoldersForConsents(consentString, formInstanceId).then(function (rows) {
        if (!rows.hasOwnProperty('result') || rows.result.length === 0) {
          set(self, 'hasRows', false);
          if (get(self, 'consentString') === '') {
            set(self, 'showNoRows', false);
          } else {
            set(self, 'showNoRows', true);
          }
        } else {
          set(self, 'showNoRows', false);
          set(self, 'hasRows', true);
        }

        var tableDefinition = [{ column_label: 'Property No.',
          column_name: 'property_no' }, { column_label: 'Situation address',
          column_name: 'situation_address' }, { column_label: 'Name',
          column_name: 'name' }, { column_label: 'Postal address',
          column_name: 'postal_address' }];
        var columns = [];

        for (var i = 0; i < tableDefinition.length; i++) {
          columns.pushObject({
            label: tableDefinition[i]['column_label'],
            valuePath: tableDefinition[i]['column_name'],
            cellComponent: 'light-table-components/cell-with-title',
            classNames: 'c-table__cell c-table__cell--heading',
            cellClassNames: 'c-table__cell c-table__row--clickable',
            breakpoints: 'tabletUp'
          });
        }

        var table = new _emberLightTable.default(columns, rows.result, { enableSync: true });
        self.set('table', table);
      });
    },


    inputId: Ember.computed(function () {
      return this.uniqueId.next();
    }),

    isValidList: function isValidList(consentString) {
      consentString = consentString.toUpperCase();
      var acceptableValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', '.', ' ', '/', 'A', 'B', 'C', 'D', 'E', 'F', 'K', 'L', 'M', 'N', 'P', 'S', 'T'];
      for (var i = 0; i < consentString.length; i++) {
        if (acceptableValues.indexOf(consentString[i]) === -1) {
          console.log('invalid' + consentString[i]);
          return false;
        }
      }
      return true;
    },

    isValidKey: function isValidKey(keyCode) {
      var acceptableKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 190, 188, 13, 8, 37, 38, 39, 40, 91, 83, 84, 86, 65, 66, 67, 68, 69, 70, 76, 76, 77, 78, 80, 191, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 111];
      if (acceptableKeyCodes.indexOf(keyCode) === -1) {
        return false;
      }
      return true;
    },

    actions: {
      setValue: function setValue(value) {
        if (!get(this, 'isValidList')(value)) {
          set(this, 'consentString', '');
          return;
        }
        set(this, 'consentString', value);
        var formInstanceId = get(this, 'formInstanceId');
        get(this, 'initaliseTable')(this, formInstanceId, value);
      },
      checkInput: function checkInput(e) {
        var value = e.target.value.toString();
        var key = e.keyCode;
        if (!get(this, 'isValidKey')(key) || value.length > this.maxlength) {
          e.preventDefault();
        }
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'consentString', '');
      var formInstanceId = get(this, 'formInstanceId');
      get(this, 'initaliseTable')(this, formInstanceId, '');
    },


    /**
     * Instantiate the Ember Light Table.
     */
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
});