define('client/routes/processing-section/notification-default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      this.transitionTo('processing-section.notification', this.modelFor('processing-section'), 'notification');
    }
  });
});