define('client/services/reporting-dashboard', ['exports', 'client/services/remote-method', 'client/mixins/cache'], function (exports, _remoteMethod, _cache) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _remoteMethod.default.extend(_cache.default, {
    contentType: 'application/json; charset=utf-8',
    // Cache table requests for 1min.
    cacheLengthSec: 60,

    getSummaryByStatus: function getSummaryByStatus() {
      var cache = get(this, 'cache');
      var cacheKey = 'getSummaryByStatus';
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/reporting-dashboard/summary-by-status/');
      cache.save(cacheKey, request);
      return request;
    },
    getSummaryByProcessingOfficer: function getSummaryByProcessingOfficer() {
      var cache = get(this, 'cache');
      var cacheKey = 'getSummaryByProcessingOfficer';
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/reporting-dashboard/summary-by-processing-officer/');
      cache.save(cacheKey, request);
      return request;
    },
    getApplicationsInProgress: function getApplicationsInProgress(options) {
      var page = options ? options.page || 1 : 1;
      var pageSize = options ? options.pageSize || 5000 : 5000;
      var sortDirection = options ? options.sortDirection || 'asc' : 'asc';
      var sortField = options ? options.sortField || 'application_no' : 'application_no';

      // Build the ordering param.
      var ordering = '';
      if (sortField) {
        // A '-' in front of the field will reverse the direction (descending).
        ordering = '' + (sortDirection === 'desc' ? '-' : '') + sortField;
      }

      var requestData = { page: page, pageSize: pageSize, ordering: ordering };
      if (options) {
        if (options.trackingStageId) {
          requestData.trackingStageId = options.trackingStageId;
          requestData.noTrackingStage = false;
        } else {
          requestData.noTrackingStage = true;
        }
        if (options.processingOfficerId) {
          requestData.processingOfficerId = options.processingOfficerId;
        }
      }
      var cache = get(this, 'cache');
      var cacheKey = 'getApplicationsInProgress_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/reporting-dashboard/applications-in-progress/', { data: requestData });
      cache.save(cacheKey, request);
      return request;
    }
  });
});