define('client/mixins/authenticated-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    session: inject.service(),
    flashMessages: inject.service(),
    routing: Ember.inject.service('-routing'),
    beforeModel: function beforeModel(transition) {
      var _this = this,
          _arguments = arguments;

      return get(this, 'session.currentUser').then(function () {
        if (!get(_this, 'session.isAuthenticated')) {
          // set the URL to return to after login
          var url = transition.intent.url;
          if (url === undefined) {
            // attempt to generate the URL using the routing service
            var _transition$intent = transition.intent,
                name = _transition$intent.name,
                contexts = _transition$intent.contexts,
                queryParams = _transition$intent.queryParams;

            url = get(_this, 'routing').generateURL(name, contexts, queryParams);
          }

          transition.abort();

          // We need to figure out if we're attempting to transition away from the home section,
          // there might be a better way to infer this, but I couldn't find it.
          var inHomeSection = get(transition, 'router.state.params.home-section') !== undefined;
          if (inHomeSection) {
            // Display the login modal
            _this.controllerFor('home-section').set('returnTo', url);
            _this.controllerFor('home-section').set('loginModal', true);
          } else {
            // Redirect to the home section then display the login modal
            get(_this, 'router').transitionTo('home-section', { queryParams: { loginModal: true, returnTo: url } });
          }
        } else {
          return _this._super.apply(_this, _arguments);
        }
      });
    }
  });
});