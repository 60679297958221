define('client/services/lookups', ['exports', 'client/services/remote-method', 'client/mixins/cache'], function (exports, _remoteMethod, _cache) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = _remoteMethod.default.extend(_cache.default, {
    // Cache table requests for 1min.
    cacheLengthSec: 60,
    dynamicFormsMethods: inject.service(),
    gateKeeper: inject.service(),

    /**
      *
      * Gets the list of values for a lib_lookup_item virtual_table
      *
      * @param {string} - virtual_table_name, corresponding to the virtual_table_name
      *                   column in the lib_lookup_item table (which itself corresponds
      *                   to a record in the lib_lookup_type table
      * @return {Promise<Array>} - An array of lib_lookup_item records (promise)
      *
      */

    getLibLookupItemList: function getLibLookupItemList(virtualTableName) {
      var requestData = { virtualTableName: virtualTableName };

      var cache = get(this, 'cache');
      var cacheKey = 'getlookup_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/lookup/', { data: requestData });
      cache.save(cacheKey, request);
      return request;
    },


    /**
     *
     * Gets the list of values for a lib_lookup_fixed_key virtual_table
     *
     * @param {string} - virtual_table_name, corresponding to the virtual_table_name
     *                   column in the lib_lookup_fixed_key table (which itself corresponds
     *                   to a record in the lib_lookup_type table
     * @return {Promise<Array>} - An array of lib_lookup_fixed_key records (promise)
     *
     */

    getLibLookupFixedKeyList: function getLibLookupFixedKeyList(virtualTableName) {
      var requestData = { virtualTableName: virtualTableName };

      var cache = get(this, 'cache');
      var cacheKey = 'getlookupFixedKey_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/lookup/lookupFixedKey/', { data: requestData });
      cache.save(cacheKey, request);
      return request;
    },


    /**
     * Query for a list of lib lookup items.
     *
     * @param {string} query
     * @param {string} lookupType
     * @returns {Promise<Object[]>} Resolves to an array of results.
     */
    getLibLookupItems: function getLibLookupItems(query, lookupType) {
      var cache = get(this, 'cache');

      var requestData = { query: query, lookupType: lookupType };
      var cacheKey = 'getLibLookupItems_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/lookup/items-search/', { data: requestData }).then(function (_ref) {
        var results = _ref.results;
        return results;
      });

      cache.save(cacheKey, request);
      return request;
    },
    searchDesignation: function searchDesignation(query) {
      var requestData = { 'query': query };
      return this.request('lookup/designation-search/', { data: requestData }).then(function (results) {
        return results;
      });
    },
    getDesignation: function getDesignation(id) {
      return this.request('lookup/get-designation/' + id).then(function (result) {
        return result;
      });
    }
  });
});