define('client/components/dynamic-form/view/consents-applied-for/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    resourceConsentRemoteMethods: inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var formInstanceId = get(this, 'menu.formInstanceId');
      // We need to fetch the Consent types from the server
      get(this, 'resourceConsentRemoteMethods').getConsentTypes(formInstanceId).then(function (consentTypes) {
        return set(_this, 'consentTypes', consentTypes);
      });
    }
  });
});