define('client/libs/map-lib-base', ['exports', 'npm:proj4', 'npm:lodash/cloneDeep', 'npm:lodash/isArray', 'npm:lodash/isEmpty', 'npm:lodash/isNumber'], function (exports, _npmProj, _cloneDeep, _isArray, _isEmpty, _isNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _npmProj.default.defs('NZTM', '+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 ' + '+y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

  /**
   * Reverse points in an arbitrarily deep multi-dimensional array of co-ordinates.
   *
   * @param {array} value Input array.
   * @return {array} Copy of the input array with points reversed.
   */
  /* global L */

  var convertPoints = function convertPoints(value) {
    // Take a copy to prevent modifiying the given array.
    var list = (0, _cloneDeep.default)(value);

    if (!(0, _isArray.default)(list) || (0, _isEmpty.default)(list)) return list;

    // Determine if this is an array of coordinates.
    if (list.length === 2 && (0, _isNumber.default)(list[0]) && (0, _isNumber.default)(list[1])) {
      // Reverse the co-ordinate positions.
      return list.reverse();
    }

    return list.map(function (item) {
      return convertPoints(item);
    });
  };

  var baseIconOptions = {
    iconSize: new L.Point(15, 18)
  };

  exports.default = {
    // Takes co-ordinates in the format [easting, northing] and returns [lat, lng] (WGS84).
    nztmToLatLng: function nztmToLatLng(coordinates) {
      return (0, _npmProj.default)('NZTM', 'WGS84', coordinates).reverse();
    },
    latLngToNztm: function latLngToNztm(latlng) {
      return (0, _npmProj.default)('WGS84', 'NZTM', [latlng.lng, latlng.lat]);
    },
    convertLayerToGeoJson: function convertLayerToGeoJson(layer) {
      var geoJson = layer.toGeoJSON();
      return geoJson.geometry;
    },
    validateNztm: function validateNztm(easting, northing) {
      if (isNaN(parseFloat(easting)) || isNaN(parseFloat(northing))) return false;

      return easting >= 1000000 && easting <= 2000000 && northing >= 5000000 && northing <= 6000000;
    },


    /**
     * Handler callback for an ESRI query.
     *
     * @param {Object|undefined} error See ESRI leaflet.
     * @param {Object[]} featureCollection See ESRI leaflet.
     * @param {Function} resolve
     * @param {Function} reject
     * @param {Object} L.FeatureGroup drawnItems
     * @return {undefined}
     */
    esriQueryHandler: function esriQueryHandler(error, featureCollection, resolve, reject, drawnItems) {
      if (error) return reject(error);
      if (!featureCollection.features.length) return resolve(false);

      // We're only concerned with a single feature.
      var feature = featureCollection.features[0];

      // The points we get back from ESRI need to be reversed from lng/lat
      // to lat/lng.
      var polygons = convertPoints(feature.geometry.coordinates);
      // Cannot deal with multi-polygons, so add them separately.
      polygons.forEach(function (p) {
        return drawnItems.addLayer(L.polygon(p));
      });

      resolve(true);
    },


    /**
     * Icons
     *
     * iconUrl - path to the image
     * iconSize - size of the icon
     * iconAnchor - point of the icon which will correspond to marker's location
     * popupAnchor - point from which the popup should open relative to the iconAnchor
     *
     * Consent Type
     * The key must match the name of the consent type in lib_consent_type e.g. "Land use".
     */
    consentTypeIcons: {
      'Land use': new L.Icon(Object.assign({ iconUrl: '/assets/markers/marker-landuse.svg' }, baseIconOptions)),
      'Subdivision': new L.Icon(Object.assign({ iconUrl: '/assets/markers/marker-subdivision.svg' }, baseIconOptions)),
      'Coastal': new L.Icon(Object.assign({ iconUrl: '/assets/markers/marker-coastal.svg' }, baseIconOptions)),
      'Discharge': new L.Icon(Object.assign({ iconUrl: '/assets/markers/marker-discharge.svg' }, baseIconOptions)),
      'Water': new L.Icon(Object.assign({ iconUrl: '/assets/markers/marker-water.svg' }, baseIconOptions)),
      'Regulation': new L.Icon(Object.assign({ iconUrl: '/assets/markers/marker-generic.svg' }, baseIconOptions))
    },

    defaultIcon: new L.Icon(Object.assign({ iconUrl: '/assets/markers/marker-generic.svg' }, baseIconOptions))
  };
});