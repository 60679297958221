define('client/mixins/routes/handle-workflow-navigation', ['exports', 'client/libs/dynamic-forms-methods-lib', 'client/constants'], function (exports, _dynamicFormsMethodsLib, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;


  /**
   * Provides actions for navigating within a workflow (eg. Continue button)
   */

  // Find the path for the next workflow
  var findNextWorkflowPath = function findNextWorkflowPath(model, mode) {
    try {
      var previousWorkflowWasActive = false;
      var nextWorkflow = model.workflows.find(function (workflow) {
        if (previousWorkflowWasActive === true) {
          return true;
        } else {
          previousWorkflowWasActive = workflow.active === true;
          return false;
        }
      });
      if (nextWorkflow !== undefined) {
        return nextWorkflow.config.path || _constants.LAST;
      }
    } catch (error) {
      console.error('findNextWorkflowPath failed', { model: model, mode: mode, error: error });
    }
    return _constants.LAST;
  };

  // Find next path within the current workflow
  var findNextFormPath = function findNextFormPath(model, mode) {
    // in edit mode we jump to the next peer first, in view we drill down into the child forms
    var findNextForm = mode === 'edit' ? _dynamicFormsMethodsLib.findNextFormEdit : _dynamicFormsMethodsLib.findNextFormView;

    var forms = model.activeWorkflow.menu.forms;
    if (Array.isArray(forms) === true) {
      // workflows that haven't been started don't have forms
      var nextInstanceId = findNextForm(model.id, forms);
      if (nextInstanceId !== _constants.LAST) {
        return model.formRootPath + '/' + nextInstanceId;
      }
    }

    return _constants.LAST;
  };

  // Find next path within the current workflow, or the next workflow
  var findNextPath = function findNextPath(model, mode) {
    var hideContinueToNextWorkflow = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    // Check if the form has been marked as a submission form
    // A submission form is always considered the last form and a 'Submit' button is displayed
    if (hideContinueToNextWorkflow && model.form.formTemplate.extendedAttributes && model.form.formTemplate.extendedAttributes.isSubmissionForm) {
      return _constants.LAST;
    }

    // first try to get the next form
    var path = findNextFormPath(model, mode);

    // if this is the last form, try to get the next workflow
    if (path === _constants.LAST && hideContinueToNextWorkflow !== true) {
      path = findNextWorkflowPath(model, mode);
    }

    return path;
  };

  /**
   * Allows a route to process the form that's been retrieved in the model hook, and if is non-navigable form,
   * then redirects them to the correct child
   */
  exports.default = Ember.Mixin.create({
    resourceConsentMethods: inject.service(),
    dynamicFormsMethods: inject.service(),

    afterModel: function afterModel(model) {
      var mode = get(this, 'mode');

      // Return early if we're in print view as we may want to view a full
      // workflow (which will be isNonNavigable).
      if (mode === _constants.MODE.PRINT_VIEW) {
        return;
      }

      if ((0, _dynamicFormsMethodsLib.isNonNavigable)(model)) {
        // The user should not be able to land on this form, so navigate to the first child
        var formRootRoute = get(model, 'formRootRoute');
        var formRootPath = get(model, 'formRootPath');
        var childInstanceId = (0, _dynamicFormsMethodsLib.getChildInstanceId)(model);
        var path = formRootPath + '/' + childInstanceId;
        this.transitionTo(formRootRoute, mode, path);
      }

      if (model.form && model.form.formTemplate && model.form.formTemplate.extendedAttributes && model.form.formTemplate.extendedAttributes.isSubmissionForm) {
        set(model, 'isSubmissionForm', true);
        // Check if the submission form instance has already been submitted
        this.dynamicFormsMethods.fetchPublicSubmissionFormState(model.id).then(function (_ref) {
          var isSubmitted = _ref.isSubmitted;

          set(model, 'isSubmitted', isSubmitted);
        });
      }

      var hideContinueToNextWorkflowFunc = get(model, 'activeWorkflow.config.hideContinueToNextWorkflow') || function () {
        return Ember.RSVP.Promise.resolve(false);
      };

      return Ember.RSVP.Promise.resolve(hideContinueToNextWorkflowFunc.call(this, model)).then(function (hideContinueToNextWorkflowBoolean) {
        set(model, 'hasNext', findNextPath(model, mode, hideContinueToNextWorkflowBoolean) !== _constants.LAST);
        return model;
      });
    },


    actions: {
      // Move to the next form or workflow
      continue: function _continue() {
        var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            skipNavigationCheck = _ref2.skipNavigationCheck;

        var model = this.controller.model;
        var path = findNextPath(model, model.mode);
        if (path === _constants.LAST) {
          throw new Error('Tried to navigate beyond the last form');
        }
        this.dynamicFormsMethods.transitionToForm({ formRootPath: path, skipNavigationCheck: skipNavigationCheck });
      },
      saveAndContinue: function saveAndContinue() {
        var _this = this;

        var state = get(this, 'controller.model.form.state');
        get(this, 'save').perform(state, false, false, function () {
          _this.send('continue', { skipNavigationCheck: true });
        });
      },
      saveAndDuplicate: function saveAndDuplicate() {
        var _this2 = this;

        var state = get(this, 'controller.model.form.state');
        get(this, 'save').perform(state, false, false, function () {
          var form = _this2.controller.model.form;


          if (!form || !form.formInstance || !form.formInstance.duplicateMeta) get(_this2, 'flashMessages.danger')('There was a problem adding a another record.');
          var _form$formInstance$du = form.formInstance.duplicateMeta,
              parentFormInstanceId = _form$formInstance$du.parentFormInstanceId,
              parentName = _form$formInstance$du.parentName,
              elementName = _form$formInstance$du.elementName,
              formVersion = _form$formInstance$du.formVersion,
              formLabel = _form$formInstance$du.formLabel;

          // Logic here is similar to the logic contained within client/app/mixins/components/subform-creation.js

          get(_this2, 'dynamicFormsMethods').createChildFormInstance(formVersion, parentFormInstanceId, undefined, parentName, elementName).then(function (formInstanceId) {
            _this2.flashMessages.success('New ' + formLabel + ' created');
            _this2.dynamicFormsMethods.transitionToForm({ id: formInstanceId, skipNavigationCheck: true });
          }).catch(function () {
            get(_this2, 'flashMessages.danger')('There was a problem adding a new ' + formLabel);
          });
        });
      },
      transitionToViewMode: function transitionToViewMode() {
        this.transitionTo(get(this, 'routeName'), get(this, 'controller.model.parentId'), 'view', get(this, 'controller.model.path'));
      },


      // Save then transition to view mode - currently only used on the last page of the application form
      saveAndReturnToView: function saveAndReturnToView(state) {
        var _this3 = this;

        get(this, 'save').perform(state, false, false).then(function () {
          return _this3.dynamicFormsMethods.transitionToForm({ mode: 'view', skipNavigationCheck: true });
        });
      },


      // Finalise section 88 decision
      finaliseDecision: function finaliseDecision(state) {
        var _this4 = this;

        get(this, 'finaliseDecision').perform(state, false, false).then(function () {
          return _this4.dynamicFormsMethods.transitionToForm({ mode: 'view', skipNavigationCheck: true });
        });
      }
    }
  });
});