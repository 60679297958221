define('client/components/dynamic-form/view/id-assignment/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement'], function (exports, _dynamicFormElement, _complexFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    // Children element values
    assignNewNumberVal: computed.alias('formElementState.0.val.assignNewNumber.0.val'),
    assignedNumberVal: computed.alias('formElementState.0.val.assignedNumber.0.val'),

    // Shorthand properties for children form elements
    assignNewNumber: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'assignNewNumber';
      });
    }),
    assignedNumber: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'assignedNumber';
      });
    })
  });
});