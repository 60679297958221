define('client/components/dynamic-form/view/typeahead-debtor-number/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/components/dynamic-form/edit/typeahead-debtor-number/config'], function (exports, _dynamicFormElement, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',
    searchService: inject.service(_config.searchServiceName),
    childFormElements: _config.childFormElements,
    loading: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // Some fields aren't saved to the form (the database ignores them because they're
      // not in the form template) - we retrieve them every time using an API call.
      var debtorNumber = get(this, 'stateValue.debtorNumber.0.val');

      if (!debtorNumber) return;

      set(this, 'loading', true);
      get(this, 'searchService').debtorNumberDetails(debtorNumber).then(function (details) {
        _config.childFormElements.forEach(function (childFormElement) {
          set(_this, childFormElement.name, childFormElement.getValue(details));
        });
      }).catch(function (_ref) {
        var message = _ref.message;
        return console.log('Warning: Debtor number Lookup: ' + message);
      }).finally(function () {
        return set(_this, 'loading', false);
      });
    }
  });
});