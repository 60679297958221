define('client/router', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({}, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    // Transition handlers to ensure always at the top of the page following a click
    globalTransitionHandler: function () {
      // Return page to top on route change:

      var header = document.querySelector('.c-page-header');
      if (header !== null) {
        header.classList.remove('c-page-header__sticky');
        var contentContainer = document.querySelector('.l-content-container');
        if (contentContainer !== null) {
          contentContainer.style = null;
        }
      }

      // Note that this does NOT fire the onscroll handler
      window.scrollTo(0, 0);

      // SWC 08.12.22 RESC-1955 - Handle stickyiness of the header on form-bearing pages
      window.onscroll = function () {
        var header = document.querySelector('.c-page-header');
        if (header !== null) {
          var _contentContainer = document.querySelector('.l-content-container');
          if (window.isStickyModeEnabled === 'ON') {
            var sticky = header.offsetTop === 0 ? 72 : header.offsetTop;
            var offsetheight = header.offsetHeight;
            if (window.pageYOffset > sticky) {
              if (!header.classList.contains('c-page-header__sticky')) {
                header.classList.add('c-page-header__sticky');
                _contentContainer.style['padding-top'] = offsetheight + 'px';
              }
            } else {
              if (header.classList.contains('c-page-header__sticky')) {
                header.classList.remove('c-page-header__sticky');
                _contentContainer.style = null;
              }
            }
          } else {
            if (header.classList.contains('c-page-header__sticky')) {
              header.classList.remove('c-page-header__sticky');
            }
          }
        }
      };

      // Send route change to Raygun
      Raygun.trackEvent('pageView', { path: window.location.pathname });
    }.on('didTransition'),

    // This handler manages the sub-states, as the `didTransition` event has not fired at the point a loading/error
    // substate being shown
    globalWillTransitionHandler: function () {
      // Return page to top on route change:
      window.scrollTo(0, 0);
      // Remove mobile menu related class that sets overflow of body to hidden:
      $('body').removeClass('_hide-overflow');
    }.on('willTransition')
  });

  Router.map(function () {
    // SWC 08.12.22 RESC-1955 - Set the default for whether headers are sticky this session
    window.isStickyModeEnabled = window.localStorage.getItem('isStickyModeEnabled') === null ? 'OFF' : window.localStorage.getItem('isStickyModeEnabled');

    this.route('admin', { path: '/admin/:' });

    this.route('home-section', { path: '/' }, function () {
      this.route('index', { path: '/' });
      this.route('dashboard'); // Dashboard tab
      this.route('publicly-notified-applications'); // Publicly notified applications tab
      this.route('submitted-applications'); // Generic search - Applications
      this.route('submitted-applications-map'); // Generic search - Applications on Map
      this.route('issued-consents'); // Generic search - Issued consents
      this.route('issued-consents-map'); // Generic search - Issued consents on Map
      this.route('reporting-dashboard'); // Reporting Dashboard tab
    });

    // Because of ember "goodness", we need to use underscore case for these variables.  It looks like it uses this
    // to know that sometimes this can be an id, and sometimes a model.
    // We use that to avoid the model hook in a number of occasions
    this.route('form', function () {
      this.route('edit', { path: 'edit/:instance_id' });
      this.route('view', { path: 'view/:instance_id' });
    });

    this.route('processing-section', { path: 'processing/:parent_id/:mode' }, function () {
      this.route('index', { path: '/' });
      // Note 'application' as a route name seems to be reserved in Ember.
      this.route('submitted-application', { path: 'application/*path' });
      this.route('submitted-application-default', { path: 'application/' });
      this.route('checklist', { path: 'checklist/*path' });
      this.route('checklist-default', { path: 'checklist/' });
      this.route('circulation', { path: 'circulation/*path' });
      this.route('circulation-default', { path: 'circulation/' });
      this.route('additional-information', { path: 'additional-information/*path' });
      this.route('additional-information-default', { path: 'additional-information/' });
      this.route('notification-decision', { path: 'notification-decision/*path' });
      this.route('notification-decision-default', { path: 'notification-decision/' });
      this.route('notification', { path: 'notification/*path' });
      this.route('notification-default', { path: 'notification/' });
      this.route('hearing', { path: 'hearing/*path' });
      this.route('hearing-default', { path: 'hearing/' });
      this.route('decision', { path: 'decision/*path' });
      this.route('decision-default', { path: 'decision/' });
      this.route('additional-workflow', { path: 'additional-workflow/*path' });
      this.route('additional-workflow-default', { path: 'additional-workflow/' });
      this.route('history', { path: 'history/*path' });
      this.route('history-default', { path: 'history/' });
      this.route('fees', { path: 'fees/*path' });
      this.route('fees-default', { path: 'fees/' });
      this.route('tracking', { path: 'tracking/*path' });
      this.route('tracking-default', { path: 'tracking/' });
      this.route('correspondence', { path: 'correspondence/*path' });
      this.route('correspondence-default', { path: 'correspondence/' });
    });

    this.route('consents-section', { path: 'consents/:parent_id/:mode' }, function () {
      this.route('maintainance', { path: 'maintainance/' });
      this.route('index', { path: '/' });
      this.route('summary', { path: 'summary/*path' });
      this.route('summary-default', { path: 'summary/' });
      this.route('history', { path: 'consent-history/*path' });
      this.route('history-default', { path: 'consent-history/' });
      this.route('consent-documents', { path: 'consent-documents/*path' });
      this.route('consent-documents-default', { path: 'consent-documents/' });
    });

    this.route('style-guide', function () {
      this.route('page-layout');
      this.route('actions');
      this.route('user-feedback');
      this.route('forms');
      this.route('icons-and-images');
      this.route('navigation');
      this.route('structure');
      this.route('tables-and-lists');
      this.route('typography');
      this.route('modals');
    });

    this.route('object-not-found', { path: '/404' });

    this.route('route-not-found', { path: '/*' });
  });

  exports.default = Router;
});