define('client/components/generic-search/table/cell/actions-no-data/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    actionConfigs: Ember.computed.alias('column.config.actions'),
    actions: {
      onClick: function onClick(actionConfig) {
        var _this = this;

        var args = actionConfig.args.map(function (arg) {
          if (arg.constant !== undefined) {
            return arg.constant;
          }
          if (arg.elementKey !== undefined) {
            return _this.row.content[arg.elementKey];
          }
        });
        try {
          this.send.apply(this, [actionConfig.type].concat(_toConsumableArray(args)));
        } catch (e) {
          var _console;

          (_console = console).error.apply(_console, ['attempted to run action.' + actionConfig.type + '('].concat(_toConsumableArray(args), [')']));
          throw e;
        }
      },
      transitionAction: function transitionAction() {
        var _router;

        (_router = this.router).transitionTo.apply(_router, arguments);
      }
    }
  });
});