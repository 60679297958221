define('client/components/dynamic-form/edit/orderable-text-fields/orderable-text-field-element/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-orderable-field'],
    classNameBindings: ['number:c-orderable-field--has-number'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      set(this, 'stateValue.orderableTextFieldNumber.0.val', get(this, 'number'));
    },


    /**
     * Get the child text form element.
     */
    textFormElement: computed('formElement.formElements', function () {
      var formElements = get(this, 'formElement.formElements');
      return formElements.find(function (item) {
        return item.name === 'orderableTextFieldValue';
      });
    }),

    /**
     * Determine which component should be used.
     */
    textFieldComponentName: computed('stateValue.orderableTextFieldIsHeading.0.val', function () {
      var orderableTextFieldIsHeadingVal = get(this, 'stateValue.orderableTextFieldIsHeading.0.val');
      var isHeading = orderableTextFieldIsHeadingVal && orderableTextFieldIsHeadingVal.toString() === 'true';
      return isHeading ? 'sub-heading' : 'section';
    }),

    /**
     * Is this the top item?
     * @return {boolean}
     */
    topItem: computed('visibleIndexes', 'index', function () {
      return get(this, 'visibleIndexes.0') === get(this, 'index');
    }),

    /**
     * Is this the bottom item?
     * @return {boolean}
     */
    bottomItem: computed('visibleIndexes', 'index', function () {
      var visibleIndexes = get(this, 'visibleIndexes');
      return visibleIndexes[visibleIndexes.length - 1] === get(this, 'index');
    })
  });
});