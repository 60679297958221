define('client/helpers/is-even', ['exports', 'npm:lodash/isNumber'], function (exports, _isNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _toArray(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    if (!(0, _isNumber.default)(value)) throw new Error('Must pass number to is-even helper');
    return value % 2 === 0;
  });
});