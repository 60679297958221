define('client/components/dynamic-form/edit/question-with-assessment/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement'], function (exports, _dynamicFormElement, _complexFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;

  var PROV_NOT_RELEVANT = 'Provision not relevant';

  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    classNames: ['c-form-question-with-assessment-field'],

    // Generate a unique ID for linking the label element to the ember-power-select element
    uniqueId: Ember.inject.service(),
    yesElementId: Ember.computed(function () {
      return this.uniqueId.next();
    }),
    noElementId: Ember.computed(function () {
      return this.uniqueId.next();
    }),

    savedValue: '',
    checkYes: computed('stateValue', 'stateValueNull', function () {
      var showTextArea = get(this, 'showTextArea');
      if (showTextArea != null) return showTextArea;

      // Note: an empty string value is now converted to a null value, so `stateValueNull` doesn't uniquely identify
      // new, empty state
      return get(this, 'stateValue') !== PROV_NOT_RELEVANT && !get(this, 'stateValueNull');
    }),
    stateValueNull: computed.none('stateValue'),
    didInsertElement: function didInsertElement() {
      // Single run action to set the saved Value with the initial state
      set(this, 'savedValue', get(this, 'stateValue') || '');
    },


    actions: {
      /**
       * When the 'No' radio button is selected, then set the default 'Provision not relevant' text.
       * Else take the text from the rich-text components
       */
      selectOption: function selectOption(isProvisionRelevant) {
        set(this, 'showTextArea', isProvisionRelevant);
        var newValue = PROV_NOT_RELEVANT;
        if (isProvisionRelevant) {
          // If the page has loaded from existing state, then it's possible the saved value is 'Provision not relevant'
          // which we need to clear for the newValue
          newValue = get(this, 'savedValue') === PROV_NOT_RELEVANT ? '' : get(this, 'savedValue');
        }
        this.setValue(newValue);
      },


      /**
       * Updates the value of this element with newly entered text
       * Saves both to the state tree, but also to a local 'savedState' var which is used to remember the last entered
       * value so checking and unchecking the checkbox doesn't require the user to re-enter the same text again
       */
      updateTextArea: function updateTextArea(value) {
        set(this, 'savedValue', value);
        this.setValue(value);
      }
    }
  });
});