define('client/components/dynamic-form/view/number/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    attr: computed.alias('formElement.extendedAttributes'),

    prefix: computed('attr.prefix', function () {
      return get(this, 'attr.prefix') || '';
    }),

    // add prefix and convert to fixed decimal places (if required)
    stringValue: computed('value', 'decimalPlaces', function () {
      var value = get(this, 'stateValue');

      // don't prefix empty values
      if (value === undefined || value === null) {
        return value;
      }

      var decimalPlaces = get(this, 'attr.decimalPlaces');
      if (decimalPlaces !== undefined) {
        value = Number(value).toFixed(decimalPlaces);
      }

      return get(this, 'prefix') + value;
    })
  });
});