define('client/routes/processing-section/additional-workflow', ['exports', 'client/libs/documents-component', 'client/mixins/routes/generic-workflows'], function (exports, _documentsComponent, _genericWorkflows) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    workflows: [{
      key: 'site_visits_section',
      path: 'site-visits',
      title: 'Site visits'
    }, {
      key: 'time_extension_section',
      path: 'time-extension',
      title: 'Section 37 time extension'
    }, {
      key: 'section_91ad_section',
      path: 'section-91-suspension',
      title: 'Section 91(A/D) suspension'
    }, {
      key: 'appeals_section',
      path: 'appeals',
      title: 'Appeals'
    }, {
      component: 'documents',
      type: 'component',
      path: 'documents',
      title: 'Documents',
      getMenu: _documentsComponent.default.getMenu
    }]
  });
});