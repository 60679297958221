define('client/services/application-export', ['exports', 'client/services/remote-method'], function (exports, _remoteMethod) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _remoteMethod.default.extend({
    createApplication: function createApplication(workflowKey, parentId, path) {
      return this.post('/application-export/' + workflowKey + '/' + parentId + '/' + path);
    }
  });
});