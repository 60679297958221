define('client/components/modal-dialog/finalise-s88-check-decision/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),

    loading: null,
    hideCloseButton: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'loading', false);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'loading', false);
      this._super.apply(this, arguments);
    },


    applicationNumber: computed('pageInfo.consentNumber', function () {
      return get(this, 'pageInfo.consentNumber') || 'Not yet specified';
    }),

    isFinalisedS88CheckDecision: computed.alias('pageInfo.isFinalisedS88CheckDecision'),

    modalHeading: computed('isFinalisedS88CheckDecision', function () {
      return get(this, 'isFinalisedS88CheckDecision') ? 'Reverse finalisation of section 88 decision' : 'Finalise section 88 decision';
    }),

    actions: {
      finaliseS88CheckDecision: function finaliseS88CheckDecision() {
        var _this = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').finaliseS88CheckDecision(get(this, 'pageInfo.applicationDecisionId')).then(function (response) {
          if (response.error) {
            get(_this, 'flashMessages.danger')('Unable to finalise section 88 decision' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this, 'flashMessages.success')('Section 88 decision finalised');
            get(_this, 'remoteMethods').clearProcessingPageInfoCache(get(_this, 'pageInfo.applicationId'));
            _this.get('setShowFinaliseS88CheckDecisionModal')(false);
            _this.refreshModel();
          }
        }).finally(function () {
          set(_this, 'loading', false);
        });
      },
      unfinaliseS88CheckDecision: function unfinaliseS88CheckDecision() {
        var _this2 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').unfinaliseS88CheckDecision(get(this, 'pageInfo.applicationDecisionId')).then(function (response) {
          if (response.error) {
            get(_this2, 'flashMessages.danger')('Unable to reverse finalisation of section 88 decision' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this2, 'flashMessages.success')('Section 88 decision finalisation reversed');
            get(_this2, 'remoteMethods').clearProcessingPageInfoCache(get(_this2, 'pageInfo.applicationId'));
            _this2.get('setShowFinaliseS88CheckDecisionModal')(false);
            _this2.refreshModel();
          }
        }).finally(function () {
          set(_this2, 'loading', false);
        });
      }
    }
  });
});