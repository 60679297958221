define('client/components/pagination-controls/go-to-page-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend({
    tagName: 'form',
    classNames: ['c-pagination__goto-page-form'],
    pageNumber: null,
    submit: function submit(e) {
      e.preventDefault();
      var numToGoTo = parseInt(get(this, 'pageNumber'));
      var totalPages = parseInt(get(this, 'numberOfPages'));
      if (numToGoTo > 0 && numToGoTo <= totalPages) {
        get(this, 'setPage')(numToGoTo);
      } else {
        window.alert('Page number out of range. Please enter a number between 0 and ' + totalPages);
      }
      set(this, 'pageNumber', null);
    }
  });
});