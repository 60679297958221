define('client/routes/home-section/index', ['exports', 'client/mixins/routes/home-section-tab'], function (exports, _homeSectionTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.UnprotectedRoute.extend(_homeSectionTab.default, {
    actions: {
      viewNotifiedApplication: function viewNotifiedApplication(type, applicationId) {
        this.transitionTo('processing-section.notification-default', applicationId, 'view');
      }
    }
  });
});