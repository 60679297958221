define('client/services/address-to-location', ['exports', 'client/mixins/cache', 'client/services/remote-methods'], function (exports, _cache, _remoteMethods) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _remoteMethods.default.extend(_cache.default, {
    search: function search(query) {
      var qs = this.buildQueryString({ q: query, includePlaces: true });

      var cache = get(this, 'cache');
      var cacheKey = ['search', qs].join('_');
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('esri-address/search-physical/?' + qs);
      cache.save(cacheKey, request);
      return request.then(function (response) {
        if (response.error !== false) {
          cache.flush(cacheKey);
        }
        return response.data;
      });
    },
    getCoordinates: function getCoordinates(text, key) {
      var qs = this.buildQueryString({ text: text, key: key });

      var cache = get(this, 'cache');
      var cacheKey = ['getCoordinates', qs].join('_');
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('esri-address/get-coordinates/?' + qs);
      cache.save(cacheKey, request);
      return request.then(function (response) {
        if (response.error !== false) {
          cache.flush(cacheKey);
        }
        return response;
      });
    }
  });
});