define('client/test-fixtures/style-guide', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var formId = 9000;
  exports.default = {
    textInput: {
      'formTemplate': {
        id: formId++
      },
      'formElements': [{
        type: 'text',
        name: 'firstName',
        label: 'First name'
      }],
      state: {
        firstName: [{ id: 'new', val: 'Julia' }]
      }
    },
    numberInput: {
      'formTemplate': {
        id: formId++
      },
      'formElements': [{
        type: 'number',
        name: 'numberOfCats',
        label: 'Number of cats'
      }],
      state: {
        numberOfCats: [{ id: 'new', val: 1 }]
      }
    },
    selectInput: {
      'formTemplate': {
        id: formId++
      },
      'formElements': [{
        type: 'select',
        name: 'favouriteShow',
        label: 'Favourite Show',
        options: [{ value: '1', label: 'Arrested Development' }, { value: '2', label: 'Parks and Recreation' }, { value: '3', label: '30 Rock' }, { value: '4', label: 'Good Wife' }]
      }],
      state: {}
    },
    checkboxInput: {
      'formTemplate': {
        id: formId++
      },
      'formElements': [{
        'name': 'yesTurnThisOn',
        'label': 'Yes, turn this on',
        'type': 'checkbox'
      }],
      state: {}
    },
    checkboxGroup: {
      'formTemplate': {
        id: formId++
      },
      'formElements': [{
        'name': 'whatYouWant',
        'label': 'What do you want?',
        'type': 'checkbox-group',
        'formElements': [{
          'type': 'checkbox',
          'name': 'thisThing',
          'label': 'This'
        }, {
          'type': 'checkbox',
          'name': 'thatThing',
          'label': 'That'
        }, {
          'type': 'checkbox',
          'name': 'otherThing',
          'label': 'Other thing'
        }]
      }],
      state: {}
    },
    radioGroup: {
      'formTemplate': {
        id: formId++
      },
      'formElements': [{
        type: 'radio',
        name: 'testRadio',
        label: 'Your favourite book',
        repeatable: true,
        numAutoRepeats: 1,
        options: [{ value: '1', label: 'Ready Player One' }, { value: '2', label: 'I, Lucifer' }, { value: '3', label: 'Hey Nostradamus' }]
      }],
      state: {}
    },
    locationInput: {
      'formTemplate': {
        id: formId++
      },
      'formElements': [{
        type: 'location',
        name: 'testLocation',
        label: 'Location',
        formElements: [{
          type: 'text',
          name: 'easting',
          label: 'Easting'
        }, {
          type: 'text',
          name: 'northing',
          label: 'Northing'
        }]
      }],
      state: {
        testLocation: [{
          val: {
            easting: [{ val: '1234.45' }],
            northing: [{ val: '4321.45' }]
          }
        }]
      }
    }
  };
});