define('client/components/modal-dialog/edit-application-number/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    workflowActions: inject.service(),
    remoteMethods: inject.service('resource-consent-remote-methods'),

    applicationNumber: null,

    init: function init() {
      this._super.apply(this, arguments);

      set(this, 'loading', true);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      set(this, 'applicationNumber', get(this, 'pageInfo.consentNumber'));
      set(this, 'loading', false);
    },


    consentNumber: computed.alias('pageInfo.consentNumber'),

    actions: {
      editApplicationNumber: function editApplicationNumber() {
        var _this = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);

        var applicationId = get(this, 'pageInfo.applicationId');
        var applicationNumber = get(this, 'applicationNumber');
        get(this, 'remoteMethods').updateApplicationNo(applicationId, applicationNumber).then(function (response) {
          if (response.error === false) {
            get(_this, 'flashMessages.success')('Application number saved.');
          } else {
            throw new Error();
          }
        }).catch(function () {
          get(_this, 'flashMessages.danger')('There was a problem editing the application number.');
        }).finally(function () {
          _this.refreshModel();
          set(_this, 'showEditApplicationNumberModal', false); // remove the `?editApplicationNumber=true` from the URL and close the modal
        });
      }
    }
  });
});