define('client/components/dynamic-form/view/typeahead-submitted-application/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',
    remoteMethods: inject.service('resource-consent-remote-methods'),

    applicationNumber: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var applicationDecisionId = Number.parseInt(get(this, 'stateValue.applicationDecisionId.0.val'));
      get(this, 'remoteMethods').getApplicationNumberFromApplicationDecisionId(applicationDecisionId).then(function (applicationNumber) {
        set(_this, 'applicationNumber', applicationNumber);
      });
    }
  });
});