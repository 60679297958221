define('client/services/cache-factory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    create: function create(timeoutInSecs) {
      var store = new Map();

      var unixtime = function unixtime() {
        return Math.floor(Date.now() / 1000);
      };

      var removeExpired = function removeExpired() {
        var now = unixtime();
        store.forEach(function (value, key) {
          if (now - timeoutInSecs > value.time) {
            store.delete(key);
          }
        });
      };

      var save = function save(key, value) {
        store.set(key, { value: value, time: unixtime() });
      };

      var has = function has() {
        return store.has();
      };

      var fetch = function fetch(key) {
        removeExpired();
        return store.has(key) && store.get(key).value;
      };

      var flush = function flush(key) {
        return store.delete(key);
      };

      var flushAll = function flushAll() {
        return store.clear();
      };

      var flushMatch = function flushMatch(regexp) {
        // remove key/value pairs where the key matches the regular expression
        store.forEach(function (value, key) {
          if (typeof key === 'string' || key instanceof String) {
            if (key.match(regexp)) {
              store.delete(key);
            }
          }
        });
      };

      return {
        removeExpired: removeExpired,
        save: save,
        has: has,
        fetch: fetch,
        flush: flush,
        flushAll: flushAll,
        flushMatch: flushMatch
      };
    }
  });
});