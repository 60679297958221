define('client/components/generic-search-map/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      set = Ember.set;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('generic-search-remote-methods'),
    searchConfig: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.remoteMethods.getSearchConfig(this.searchTypeKey).then(function (_ref) {
        var searchTemplate = _ref.searchTemplate;

        set(_this, 'searchTemplate', searchTemplate);
      });
    }
  });
});