define('client/libs/documents-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;


  /*
   * functions used in the documents workflow component and route
   */

  // map from formRootRoute to the page title
  // created using the following query (then manually edited)
  // select concat('''',code,''': ''',name,' documents'',') from lib_lookup_fixed_key where virtual_table_name='lib_system_section';
  var pageTitleMap = {
    'default': 'Documents',
    'processing-section.submitted-application': 'Application documents',
    'processing-section.checklist': 'Checklist documents',
    'processing-section.circulation': 'Circulation documents',
    'processing-section.additional-information': 'Additional information documents',
    'processing-section.notification-decision': 'Notification decision documents',
    'processing-section.notification': 'Notification documents',
    'processing-section.hearing': 'Hearing documents',
    'processing-section.history': 'History',
    'processing-section.decision': 'Decision documents',
    'processing-section.fees': 'Fees documents',
    'processing-section.correspondence': 'Correspondence documents',
    'consents-section.consent-documents': 'Documents'

    // get the page title from the model
  };var getPageTitle = function getPageTitle(routeName) {
    return pageTitleMap[routeName] || pageTitleMap.default;
  };

  // get the menu JSON from the model
  var getMenu = function getMenu(_ref) {
    var path = _ref.path,
        active = _ref.active;

    var routeName = get(this, 'fullRouteName');

    // get the mode of the document page (edit, view or list) from the path
    var parts = path.split('/').filter(function (x) {
      return x;
    });
    var len = parts.length;
    var documentPageMode = parts[len - 1]; // this will return 'edit', 'view' or ''

    return {
      forms: [{
        aliasForParent: true, // required to get the label to display
        documentPageMode: documentPageMode,
        complete: false,
        label: getPageTitle(routeName),
        active: active
      }]
    };
  };

  exports.default = {
    getMenu: getMenu,
    getPageTitle: getPageTitle
  };
});