define('client/controllers/home-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['sessionExpired', 'loginModal', 'returnTo'],
    sessionExpired: null,
    loginModal: null,
    returnTo: null
  });
});