define('client/services/calculate-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Service.extend({
    displayFunctions: inject.service(),

    processNestedCondition: function processNestedCondition(state, condition, elementName) {
      if (condition.and != null) {
        return this.shouldDisplay(state, { and: condition.and }, elementName);
      }

      if (condition.or != null) {
        return this.shouldDisplay(state, { or: condition.or }, elementName);
      }

      if (condition.not != null) {
        return this.shouldDisplay(state, { not: condition.not }, elementName);
      }

      if (condition.func != null) {
        return this.shouldDisplay(state, { func: condition.func }, elementName);
      }
    },
    shouldDisplay: function shouldDisplay(state, displayConditions, elementName) {
      var _this = this,
          _get;

      if (!state) return true;

      var keys = Object.keys(state);
      var conditionKeys = displayConditions ? Object.keys(displayConditions) : [];
      if (!displayConditions || conditionKeys.length === 0) return true;
      if (conditionKeys.length > 1) {
        // No support for this, throw an error
        throw new Error('Multiple root level keys for the displayConditions for ' + elementName + ' - only one allowed');
      }

      // Need to evaluate the displayConditions - should only be one key
      var display = void 0;
      switch (conditionKeys[0]) {
        case 'and':
          display = displayConditions.and.every(function (condition) {
            if (['and', 'or', 'not', 'func'].some(function (key) {
              return condition[key] != null;
            })) {
              return _this.processNestedCondition(state, condition, elementName);
            }

            var target = keys.find(function (key) {
              return key === condition.name;
            });
            // The target condition is not yet in the state.  Because of the progress way the state is built, this is
            // legitimate, but we don't want an error thrown
            if (!target) return false;

            // Purposeful non-strict compare so we don't fall foul of "string or number" type issues
            // Also, note that we only use the first formElement.  Not sure yet how we would handle a condition with repeated formElements
            return state[target][0]['val'] == condition.value; // eslint-disable-line eqeqeq
          });
          break;

        case 'or':
          display = displayConditions.or.some(function (condition) {
            if (['and', 'or', 'not', 'func'].some(function (key) {
              return condition[key] != null;
            })) {
              return _this.processNestedCondition(state, condition, elementName);
            }

            var target = keys.find(function (key) {
              return key === condition.name;
            });
            // The target condition is not yet in the state.  Because of the progress way the state is built, this is
            // legitimate, but we don't want an error thrown
            if (!target) return false;

            // Purposeful non-strict compare so we don't fall foul of "string or number" type issues
            // Also, note that we only use the first formElement.  Not sure yet how we would handle a condition with repeated formElements
            return state[target][0]['val'] == condition.value; // eslint-disable-line eqeqeq
          });
          break;

        case 'not':
          display = !displayConditions.not.some(function (condition) {
            if (['and', 'or', 'not', 'func'].some(function (key) {
              return condition[key] != null;
            })) {
              return !_this.processNestedCondition(state, condition, elementName);
            }

            var target = keys.find(function (key) {
              return key === condition.name;
            });
            // The target condition is not yet in the state.  Because of the progress way the state is built, this is
            // legitimate, but we don't want an error thrown
            if (!target) return false;

            // Purposeful non-strict compare so we don't fall foul of "string or number" type issues
            // Also, note that we only use the first formElement.  Not sure yet how we would handle a condition with repeated formElements
            return state[target][0]['val'] == condition.value; // eslint-disable-line eqeqeq
          });
          break;

        case 'func':
          var funcObj = displayConditions.func;
          if (!('name' in funcObj)) {
            throw new Error('All "func" objects in displayConditions must have a "name" key for ' + elementName);
          }

          display = (_get = get(this, 'displayFunctions'))[funcObj.name].apply(_get, _toConsumableArray(funcObj.arguments || []));
          break;

        default:
          throw new Error('Root key for conditional element must be "and", "or" or "func" for ' + elementName);
      }

      return display;
    }
  });
});