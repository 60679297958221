define('client/components/dynamic-form/edit/numbered-text-fields/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement', 'ember-copy'], function (exports, _dynamicFormElement, _complexFormElement, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var get = Ember.get,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    dynamicFormsMethods: inject.service(),
    router: inject.service(),
    flashMessages: inject.service(), // Need to add this here to fix an issue with section-test not init'ing the service
    showConfirmationModal: false,

    // In root sections, the state value is for all instances
    // Note - something feels a bit off here
    stateValue: computed.alias('formElementState'),
    classNames: ['h-clearfix', 'l-section-wrapper'],
    // Wrapper applies margin top/bottom only when element is displayed:
    classNameBindings: ['isHidden:l-section-wrapper--empty', 'isRepeatable:l-repeatable-section-wrapper'],
    isHidden: computed('display', function () {
      return !get(this, 'display');
    }),
    isRepeatable: computed('formElement.repeatable', function () {
      return get(this, 'formElement.repeatable');
    }),

    updateDeletedHide: function updateDeletedHide(formElement, index, key) {
      var status = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      var state = (0, _emberCopy.copy)(get(this, 'state'), true);
      state[formElement.name][index] = Object.assign({}, state[formElement.name][index], _defineProperty({}, key, status));

      get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'));
    },


    actions: {

      /**
       * Deletes the indicated repeated block of all elements within this section's `formElements` array
       */
      delete: function _delete(formElement, index) {
        var postSaveCallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        this.updateDeletedHide(formElement, index, 'deleted');
      },


      /**
       * Add another block of all elements within this section's `formElements` array
       */
      add: function add() {
        var _this = this;

        var elementName = get(this, 'formElement.name');
        var existingCount = get(this, 'state.' + elementName + '.length');
        get(this, 'populateFunctions').buildState({}, get(this, 'formElement.formElements'), existingCount + 1).then(function (newState) {
          var existingState = (0, _emberCopy.copy)(get(_this, 'state'), true);
          existingState[elementName].push({ id: 'new', val: newState });
          _this.updateStateKey(elementName, existingState[elementName]);
        });
      }
    }
  });
});