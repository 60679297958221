define('client/services/consents-and-permits-lookups', ['exports', 'client/services/remote-method', 'client/mixins/cache'], function (exports, _remoteMethod, _cache) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _remoteMethod.default.extend(_cache.default, {
    suggestCAPDecision: function suggestCAPDecision(query) {
      var requestData = { query: query };
      return this.request('consents-and-permits-lookups/search-cap-decision/', { data: requestData }).then(function (results) {
        return results;
      });
    },
    getCAPDecision: function getCAPDecision(id) {
      return this.request('consents-and-permits-lookups/get-cap-decision/' + id + '/').then(function (results) {
        return results;
      });
    },
    suggestCAPConsent: function suggestCAPConsent(capDecisionId, query) {
      var applicationId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      var requestData = { capDecisionId: capDecisionId, query: query };
      if (applicationId) requestData['applicationId'] = applicationId;
      return this.request('consents-and-permits-lookups/search-cap-consent/', { data: requestData }).then(function (results) {
        return results;
      });
    },
    getCAPConsent: function getCAPConsent(id) {
      return this.request('consents-and-permits-lookups/get-cap-consent/' + id + '/').then(function (results) {
        return results;
      });
    },
    suggestCAPCondition: function suggestCAPCondition(capConsentId, query) {
      var requestData = { capConsentId: capConsentId, query: query };
      return this.request('consents-and-permits-lookups/search-cap-condition/', { data: requestData }).then(function (results) {
        return results;
      });
    },
    getCAPCondition: function getCAPCondition(id) {
      return this.request('consents-and-permits-lookups/get-cap-condition/' + id).then(function (results) {
        return results;
      });
    },
    getCAPConditionIncludingDeleted: function getCAPConditionIncludingDeleted(id) {
      return this.request('consents-and-permits-lookups/get-cap-condition-including-deleted/' + id).then(function (results) {
        return results;
      });
    }
  });
});