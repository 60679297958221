define('client/helpers/get-form-error-count', ['exports', 'npm:lodash/find', 'client/constants'], function (exports, _find, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getFormErrorCount = getFormErrorCount;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Get error count from menu.errors for the form corresponding to the given templateKey
   * If templateKey is not provided, the total error count across all the forms in the workflow is returned
   */
  function getFormErrorCount(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        formErrors = _ref2[0],
        templateKey = _ref2[1];

    var errorCount = -1;

    if (formErrors && !templateKey && 'totalErrors' in formErrors) {
      errorCount = formErrors.totalErrors;
    } else if (formErrors && templateKey && 'forms' in formErrors && Array.isArray(formErrors.forms)) {
      var formData = (0, _find.default)(formErrors.forms, { templateKey: templateKey });
      if (formData !== undefined && 'status' in formData && formData.status !== _constants.FORM_STATES.NOT_STARTED && 'errorCount' in formData) {
        errorCount = formData.errorCount;
      }
    }

    return errorCount;
  }

  exports.default = Ember.Helper.helper(getFormErrorCount);
});