define('client/components/dynamic-form/edit/sub-heading/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: 'h4',
    classNames: ['c-sub-heading']
  });
});