define('client/components/dynamic-form/menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;


  // Takes two arguments, an element and a list and returns a new list with the element inserted at the first place.
  var cons = function cons(elem) {
    var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    var newList = list.slice(0); // copy the list
    newList.unshift(elem);
    return newList;
  };

  // Traverses the menu structure, finds the active item and marks all of its parents with `activeChild: true`
  var traverseMenuMarkActiveParents = function traverseMenuMarkActiveParents(currentFormId) {
    var childForms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var parentFormStack = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

    childForms.forEach(function (form) {
      // If this menu item is active
      // For component-based workflows this is set manually, for form-based ones we check the instance id
      if (currentFormId === form.formInstanceId) {
        // mark it as active
        form.active = true;
      }

      if (form.active === true) {
        // and mark its parents as having an active child
        parentFormStack.forEach(function (form) {
          form.activeChild = true;
        });
      }

      traverseMenuMarkActiveParents(currentFormId, form.forms, cons(form, parentFormStack));
    });
  };

  exports.default = Ember.Component.extend({
    classNames: ['c-task-menu', 'h-hide-for-print'],
    ariaRole: 'navigation',

    init: function init() {
      this._super.apply(this, arguments);

      var currentFormId = Number(get(this, 'currentFormId'));

      traverseMenuMarkActiveParents(currentFormId, get(this, 'menu.forms'));
    }
  });
});