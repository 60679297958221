define('client/components/app-nav/nav-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    session: inject.service(),
    classNames: ['c-nav-menu'],
    classNameBindings: ['isOpen:c-nav-menu--open'],
    isOpen: false,
    externalLinkInfo: computed.alias('session.externalLinkInfo'),

    actions: {
      toggleNavMenu: function toggleNavMenu() {
        this.toggleProperty('isOpen');
      },
      closeNavMenu: function closeNavMenu() {
        set(this, 'isOpen', false);
      },
      gotoAdmin: function gotoAdmin() {
        get(this, 'session').gotoAdmin();
      }
    }
  });
});