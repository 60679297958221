define('client/components/dynamic-form/edit/person-name/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement'], function (exports, _dynamicFormElement, _complexFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    classNames: ['c-form-location-field'],
    honorificSelect: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'honorificSelect';
      });
    }),
    firstName: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'firstName';
      });
    }),
    surname: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'surname';
      });
    }),
    fullName: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'fullName';
      });
    }),
    hasFullName: computed.equal('formElement.extendedAttributes.hasFullName', true),
    hasHonorific: computed.equal('formElement.extendedAttributes.hasHonorific', true)
  });
});