define('client/components/dynamic-form/dialogues/navigate-away-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend({
    actions: {
      confirm: function confirm() {
        set(this, 'displayModal', false);
        get(this, 'confirmSuccessFunction')();
      }
    }
  });
});