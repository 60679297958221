define('client/components/dynamic-form/view/typeahead-cap-condition/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject,
      set = Ember.set;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    remoteMethods: inject.service('consents-and-permits-lookups'),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var value = get(this, 'value');
      if (value != null) {
        var id = value['consentDetailId'][0]['val'];
        if (id) {
          get(this, 'remoteMethods').getCAPConditionIncludingDeleted(id).then(function (result) {
            // simulate selecting the result from the drop-down list
            set(_this, 'selected', result);
          });
        }
      }
    }
  });
});