define('client/routes/processing-section/notification-decision', ['exports', 'client/mixins/routes/generic-workflows', 'client/libs/documents-component'], function (exports, _genericWorkflows, _documentsComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    remoteMethods: inject.service('resource-consent-remote-methods'),

    // Function to be called after save (in edit-form-action.js)
    postFormSaveFunction: function postFormSaveFunction() {
      // update the pageInfo, as the buttons in the actions menu are enabled/disabled based on data in forms within this workflow
      var processingModel = this.modelFor('processing-section');
      get(this, 'remoteMethods').getProcessingPageInfo(processingModel.pageInfo.applicationId, true).then(function (pageInfo) {
        set(processingModel, 'pageInfo', pageInfo);
      });
    },


    workflows: [{
      key: 'notification_decision_section',
      path: 'notification-decision',
      title: 'Notification decision'
    }, {
      component: 'documents',
      type: 'component',
      path: 'documents',
      title: 'Notification decision documents',
      getMenu: _documentsComponent.default.getMenu
    }]
  });
});