define('client/services/resource-consent-remote-methods', ['exports', 'client/mixins/cache', 'client/services/remote-methods'], function (exports, _cache, _remoteMethods) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      RSVP = Ember.RSVP;
  exports.default = _remoteMethods.default.extend(_cache.default, {

    dashboardService: inject.service('dashboard'),

    getHoldersForConsents: function getHoldersForConsents(consentsString, instanceId) {
      consentsString = consentsString.replaceAll(' ', '').toUpperCase();
      return this.request('/resource-consent-applications/get-holders-for-consents/' + consentsString + '::' + instanceId);
    },


    /**
     * Starts a new application.
     * @param {string|number} applicationTypeId
     * @return {Promise<number>} The new application ID.
     */
    newApplication: function newApplication(applicationTypeId) {
      return this.post('/resource-consent-applications/new-application/' + applicationTypeId).then(function (result) {
        return result.applicationId;
      });
    },


    /**
     * Requests a delete of an application
     * @param {string|integer} appInstanceId
     */
    deleteApplication: function deleteApplication(appInstanceId) {
      return this.delete('/resource-consent-applications/delete-application/' + appInstanceId);
    },


    /**
     * Requests that the application be submitted.
     * Note: Right now, if there are any errors remaining on the form, this will throw an error
     * @param {string|integer} appInstanceId
     */
    submitApplication: function submitApplication(appInstanceId) {
      return this.put('/resource-consent-applications/submit-application/' + appInstanceId);
    },


    /**
     * Makes a request to the server to unsubmit an application. This changes
     * the is_submitted to false and sets the is_resubmission flag to true
     * @param {string|integer} appInstanceId
     */
    unsubmitApplication: function unsubmitApplication(appInstanceId) {
      var _this = this;

      return this.put('resource-consent-applications/application/unsubmit/' + appInstanceId).then(function (result) {
        if (result.error === true) {
          return Promise.reject(new Error(result.message));
        }
        get(_this, 'dashboardService').cacheFlushAll();
        return result['new_application_id'];
      });
    },


    /**
      * Requests the status of a notification submission finalisation f
      * @deprecated
      * @param {string|integer} instanceId - instance of current subform
      */
    isSubmissionFinalised: function isSubmissionFinalised(instanceId) {
      return this.request('/resource-consent-applications/is-notification-submission-finalised/' + instanceId);
    },


    /**
     * Requests the status of letter finalisation for a form
     * @deprecated
     * @param {string|integer} instanceId - instance of current subform
     */
    isLetterFinalised: function isLetterFinalised(instanceId) {
      return this.request('/resource-consent-applications/is-letter-finalised/' + instanceId);
    },


    /**
     * Requests whether or not an application has an unsubmitted draft
     * @deprecated
     * @param {string|integer} appInstanceId
     */
    hasUnsubmittedDraft: function hasUnsubmittedDraft(instanceId) {
      return this.request('/resource-consent-applications/has-unsubmitted-draft/' + instanceId);
    },


    /**
     * Requests whether or not the application can be amended
     * @param {string|integer} appInstanceId
     */
    getAmendApplicationConditions: function getAmendApplicationConditions(instanceId) {
      return this.request('/resource-consent-applications/amend-application-conditions/' + instanceId).then(function (result) {
        return result;
      });
    },


    /**
     * Requests whether or not a form instance has been submitted
     * @param {string|integer} appInstanceId
     */
    isSubmitted: function isSubmitted(instanceId) {
      return this.request('/resource-consent-applications/is-submitted/' + instanceId).then(function (result) {
        return result['is_submitted'];
      });
    },


    /**
     * Requests whether or not a draft is a resubmission
     * @param {string|integer} appInstanceId
     */
    isResubmission: function isResubmission(instanceId) {
      return this.request('/resource-consent-applications/is-resubmission/' + instanceId).then(function (result) {
        return result['is_resubmission'];
      });
    },


    /**
     * Requests a list of consent types for an application
     * @param {string|integer} appInstanceId
     */

    getConsentTypes: function getConsentTypes(applicationInstanceId) {
      var cache = get(this, 'cache');
      var cacheKey = 'getConsentTypes' + applicationInstanceId;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;
      var request = this.request('/resource-consent-applications/fetch-consent-types/' + applicationInstanceId);

      cache.save(cacheKey, request);
      return request;
    },


    /**
     * Makes a request to the server to fetch the consent number
     * @deprecated
     * @param {string|integer} appInstanceId
     */
    getConsentNumber: function getConsentNumber(appInstanceId) {
      appInstanceId = appInstanceId.split('-')[0];
      return this.request('/resource-consent-applications/consent-number/' + appInstanceId).then(function (rc) {
        return rc.consentNumber;
      });
    },


    /**
     * Makes a request to the server to save the consent number.
     * @deprecated
     * @param {string|integer} appInstanceId
     * @param {string} consentNumber
     */
    setConsentNumber: function setConsentNumber(appInstanceId, consentNumber) {
      appInstanceId = appInstanceId.split('-')[0];
      return this.put('/resource-consent-applications/consent-number/' + appInstanceId + '/' + consentNumber);
    },
    addressDetailsPostal: function addressDetailsPostal(dpid) {
      var cache = get(this, 'cache');
      var cacheKey = 'addressDetailsPostal_' + dpid;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('postal-address/details/?q=' + encodeURIComponent(dpid));
      cache.save(cacheKey, request);
      return request;
    },
    suggestAddressPostal: function suggestAddressPostal(query) {
      var addressType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'All';

      var cache = get(this, 'cache');
      var cacheKey = 'suggestAddressPostal_' + addressType + '_' + query;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('postal-address/suggest/?q=' + encodeURIComponent(query) + '&type=' + encodeURIComponent(addressType));
      cache.save(cacheKey, request);
      return request;
    },
    suggestAddressEsri: function suggestAddressEsri(query) {
      var addressType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'All';

      var cache = get(this, 'cache');
      var cacheKey = 'suggestAddressEsri_' + addressType + '_' + query;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('esri-address/suggest/?q=' + encodeURIComponent(query) + '&type=' + encodeURIComponent(addressType));
      cache.save(cacheKey, request);
      return request;
    },


    /**
     * Makes a request to the server to get a list of documents
     */
    documentList: function documentList(parentId, parentIdType, tabLocationCode, query) {
      var params = { 'tab_location_code': tabLocationCode };
      if (query !== undefined && query !== '') {
        params.q = query;
      }
      params[parentIdType] = parentId;
      return this.fetch('documents/list', params);
    },


    /**
    * Makes a request to the server to get am individual History record
    */
    getHistoryRecord: function getHistoryRecord(recordId) {
      return this.fetch('forms/history-record/' + recordId);
    },


    /**
     * Makes a request to the server to get a list of tabs with document workflows
     */
    documentTabLocations: function documentTabLocations(tabLocationCode) {
      var query = { 'tab_location_code': tabLocationCode };
      return this.fetch('documents/tab-location-options', query);
    },


    /**
     * Updates circulation_document metadata.
     * @param {Object} state Dynamic forms state data containing the document id and metadata
     * @returns Promise that resolves to an object containing the tab_location_code of the document
     */
    documentUpdate: function documentUpdate(state) {
      return this.post('documents/update', { data: state });
    },


    /**
      * Makes a request to the server to copy the decsion from processing.application_decision to
      * consents_and_permits.cap_decision. Note empty payload. May need extra info later if
      * confirmation dialog is added
      */
    copyDecisionToConsentsAndPermits: function copyDecisionToConsentsAndPermits(applicationId) {
      return this.post('resource-consent-applications/copy-decision-to-consents-and-permits/' + applicationId, {});
    },


    /**
     * Update consent conditions of the consents and permits record
     * @param {String|Number} capDecisionId
     * @return {Promise<Object[]>}
     */
    updateConsentsAndPermits: function updateConsentsAndPermits(capDecisionId, reasonForChangeId, applicationDecisionId, comment) {
      var data = {
        reasonForChangeId: reasonForChangeId,
        comment: comment
      };
      return this.put('resource-consent-applications/update-consents-permits/' + applicationDecisionId + '/' + capDecisionId, { data: data });
    },


    /**
     * Get data to be displayed for 'Update record' dialog to update consents and permists record
     */
    updateConsentsPermitsDialogInfo: function updateConsentsPermitsDialogInfo(applicationId) {
      var applicationTypeCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var endpoint = 'resource-consent-applications/update-consents-permits-dialog-info/' + applicationId;
      if (applicationTypeCode) endpoint += '?code=' + applicationTypeCode;
      return this.request(endpoint, {});
    },


    /**
     * Makes a request to the server that the processing information associated with an application
     * be made available to Iwi.
     */
    grantIwiDecisionAccess: function grantIwiDecisionAccess(applicationId) {
      return this.post('resource-consent-applications/grant-iwi-decision-access/' + applicationId, {});
    },


    /**
     * Makes a request to the server that the processing information associated with an application
     * be made unavilable to Iwi.
     */
    revokeIwiDecisionAccess: function revokeIwiDecisionAccess(applicationId) {
      return this.post('resource-consent-applications/revoke-iwi-decision-access/' + applicationId, {});
    },


    /**
     * Makes a request to the server that the processing information associated with an application
     * be made available to the public.
     */
    grantPublicDecisionAccess: function grantPublicDecisionAccess(applicationId) {
      return this.post('resource-consent-applications/grant-public-decision-access/' + applicationId, {});
    },


    /**
     * Makes a request to the server that the processing information associated with an application
     * be made unavilable to the public.
     */
    revokePublicDecisionAccess: function revokePublicDecisionAccess(applicationId) {
      return this.post('resource-consent-applications/revoke-public-decision-access/' + applicationId, {});
    },


    /**
     * Makes a request to the server that the application be notified.
     */
    notifyApplication: function notifyApplication(applicationId) {
      return this.post('resource-consent-applications/notify-application/' + applicationId, {});
    },


    /**
     * Makes a request to the server that the application be removed from notification.
     */
    denotifyApplication: function denotifyApplication(applicationId) {
      return this.post('resource-consent-applications/denotify-application/' + applicationId, {});
    },


    /**
     * Makes a request to the server that the letter be unfinalised.
     */
    unfinaliseLetter: function unfinaliseLetter(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-letter/' + formInstanceId);
    },


    /**
    * Makes a request to the server that the letter be finalised.
    */
    finaliseLetter: function finaliseLetter(formInstanceId) {
      var onFinaliseSendLetterToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var onFinaliseSendEmailToApplicant = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var data = {
        onFinaliseSendLetterToCM: onFinaliseSendLetterToCM,
        onFinaliseSendEmailToApplicant: onFinaliseSendEmailToApplicant
      };
      return this.post('resource-consent-applications/finalise-letter/' + formInstanceId, { data: data });
    },


    /**
     * Makes a request to the server that the notification submission be unfinalised.
     */
    unfinaliseSubmission: function unfinaliseSubmission(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-notification-submission/' + formInstanceId);
    },


    /**
     * Makes a request to the server that the submission be finalised.
     */
    finaliseSubmission: function finaliseSubmission(formInstanceId) {
      var onFinaliseSendSubmissionToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var data = {
        onFinaliseSendSubmissionToCM: onFinaliseSendSubmissionToCM
      };
      return this.post('resource-consent-applications/finalise-notification-submission/' + formInstanceId, { data: data });
    },


    /**
    * Makes a request to the server that the letter be unfinalised.
    */
    unfinaliseS37Decision: function unfinaliseS37Decision(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-s37-decision/' + formInstanceId);
    },


    /**
    * Makes a request to the server that the letter be finalised.
    */
    finaliseS37Decision: function finaliseS37Decision(formInstanceId) {
      var onFinaliseSendDecisionToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var data = {
        onFinaliseSendDecisionToCM: onFinaliseSendDecisionToCM
      };
      return this.post('resource-consent-applications/finalise-s37-decision/' + formInstanceId, { data: data });
    },


    /**
      * Makes a request to the server that the letter be unfinalised.
      */
    unfinaliseS37Letter: function unfinaliseS37Letter(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-s37-letter/' + formInstanceId);
    },


    /**
    * Makes a request to the server that the letter be finalised.
    */
    finaliseS37Letter: function finaliseS37Letter(formInstanceId) {
      var onFinaliseSendApplicantLetterToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var onFinaliseSendSubmitterLetterToCM = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var onFinaliseSendEmailToApplicant = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var onFinaliseSendEmailToSubmitters = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

      var data = {
        onFinaliseSendApplicantLetterToCM: onFinaliseSendApplicantLetterToCM,
        onFinaliseSendSubmitterLetterToCM: onFinaliseSendSubmitterLetterToCM,
        onFinaliseSendEmailToApplicant: onFinaliseSendEmailToApplicant,
        onFinaliseSendEmailToSubmitters: onFinaliseSendEmailToSubmitters
      };
      return this.post('resource-consent-applications/finalise-s37-letter/' + formInstanceId, { data: data });
    },


    /**
     * Makes a request to the server that the letter be unfinalised.
     */
    unfinaliseS91adDecision: function unfinaliseS91adDecision(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-s91ad-decision/' + formInstanceId);
    },


    /**
    * Makes a request to the server that the letter be finalised.
    */
    finaliseS91adDecision: function finaliseS91adDecision(formInstanceId) {
      var onFinaliseSendDecisionToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var onFinaliseCreateTrackingStage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      var data = {
        onFinaliseSendDecisionToCM: onFinaliseSendDecisionToCM,
        onFinaliseCreateTrackingStage: onFinaliseCreateTrackingStage
      };
      return this.post('resource-consent-applications/finalise-s91ad-decision/' + formInstanceId, { data: data });
    },


    /**
      * Makes a request to the server that the letter be unfinalised.
      */
    unfinaliseS91adLetter: function unfinaliseS91adLetter(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-s91ad-letter/' + formInstanceId);
    },


    /**
    * Makes a request to the server that the letter be finalised.
    */
    finaliseS91adLetter: function finaliseS91adLetter(formInstanceId) {
      var onFinaliseSendApplicantLetterToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var onFinaliseSendSubmitterLetterToCM = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var onFinaliseSendEmailToApplicant = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var onFinaliseSendEmailToSubmitters = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

      var data = {
        onFinaliseSendApplicantLetterToCM: onFinaliseSendApplicantLetterToCM,
        onFinaliseSendSubmitterLetterToCM: onFinaliseSendSubmitterLetterToCM,
        onFinaliseSendEmailToApplicant: onFinaliseSendEmailToApplicant,
        onFinaliseSendEmailToSubmitters: onFinaliseSendEmailToSubmitters
      };
      return this.post('resource-consent-applications/finalise-s91ad-letter/' + formInstanceId, { data: data });
    },


    /**
         * Makes a request to the server that the letter be unfinalised.
         */
    unfinaliseS92_2Decision: function unfinaliseS92_2Decision(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-s92-2-decision/' + formInstanceId);
    },


    /**
     * Makes a request to the server that the letter be finalised.
     */
    finaliseS92_2Decision: function finaliseS92_2Decision(formInstanceId) {
      var onFinaliseSendDecisionToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var data = {
        onFinaliseSendDecisionToCM: onFinaliseSendDecisionToCM
      };
      return this.post('resource-consent-applications/finalise-s92-2-decision/' + formInstanceId, { data: data });
    },


    /**
     * Makes a request to the server that the letter be unfinalised.
     */
    unfinaliseS92_2Letter: function unfinaliseS92_2Letter(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-s92-2-letter/' + formInstanceId);
    },


    /**
     * Makes a request to the server that the letter be finalised.
     */
    finaliseS92_2Letter: function finaliseS92_2Letter(formInstanceId) {
      var onFinaliseSendApplicantLetterToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var onFinaliseSendEmailToApplicant = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var data = {
        onFinaliseSendApplicantLetterToCM: onFinaliseSendApplicantLetterToCM,
        onFinaliseSendEmailToApplicant: onFinaliseSendEmailToApplicant
      };
      return this.post('resource-consent-applications/finalise-s92-2-letter/' + formInstanceId, { data: data });
    },


    /**
       * Makes a request to the server that the letter be unfinalised.
       */
    unfinaliseS91Decision: function unfinaliseS91Decision(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-s91-decision/' + formInstanceId);
    },


    /**
     * Makes a request to the server that the letter be finalised.
     */
    finaliseS91Decision: function finaliseS91Decision(formInstanceId) {
      var onFinaliseSendDecisionToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var data = {
        onFinaliseSendDecisionToCM: onFinaliseSendDecisionToCM
      };
      return this.post('resource-consent-applications/finalise-s91-decision/' + formInstanceId, { data: data });
    },


    /**
     * Makes a request to the server that the letter be unfinalised.
     */
    unfinaliseS91Letter: function unfinaliseS91Letter(formInstanceId) {
      return this.request('resource-consent-applications/unfinalise-s91-letter/' + formInstanceId);
    },


    /**
     * Makes a request to the server that the letter be finalised.
     */
    finaliseS91Letter: function finaliseS91Letter(formInstanceId) {
      var onFinaliseSendApplicantLetterToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var onFinaliseSendEmailToApplicant = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var data = {
        onFinaliseSendApplicantLetterToCM: onFinaliseSendApplicantLetterToCM,
        onFinaliseSendEmailToApplicant: onFinaliseSendEmailToApplicant
      };
      return this.post('resource-consent-applications/finalise-s91-letter/' + formInstanceId, { data: data });
    },


    /**
     * Makes a request to the server that the decision be finalised.
     */
    finaliseDecision: function finaliseDecision(applicationId) {
      var onFinaliseSendDocumentToCM = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var onFinaliseSendEmailToApplicant = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var data = {
        onFinaliseSendDocumentToCM: onFinaliseSendDocumentToCM,
        onFinaliseSendEmailToApplicant: onFinaliseSendEmailToApplicant
      };
      return this.post('resource-consent-applications/finalise-decision/' + applicationId, { data: data });
    },


    /**
     * Makes a request to the server that the decision be un-finalised.
     */
    unfinaliseDecision: function unfinaliseDecision(applicationId) {
      return this.post('resource-consent-applications/unfinalise-decision/' + applicationId, {});
    },


    /**
     * Makes a request to the server that the notification decision be finalised.
     */
    finaliseNotificationDecision: function finaliseNotificationDecision(applicationDecisionId) {
      return this.post('resource-consent-applications/finalise-notification-decision/' + applicationDecisionId, {});
    },


    /**
     * Makes a request to the server that the notification decision be un-finalised.
     */
    unfinaliseNotificationDecision: function unfinaliseNotificationDecision(applicationDecisionId) {
      return this.post('resource-consent-applications/unfinalise-notification-decision/' + applicationDecisionId, {});
    },


    /**
     * Get the consent applicants for an application decision.
     * @param applicationDecisionId
     * @return {Promise<String>}
     */
    getApplicationDecisionApplicants: function getApplicationDecisionApplicants(applicationDecisionId) {
      if (!applicationDecisionId) return RSVP.resolve('');
      return this.request('resource-consent-applications/application-decision-applicants/' + applicationDecisionId).then(function (result) {
        return result.names;
      });
    },


    /**
    * Get the consent applicants for an application.
    * @param applicationId
    * @return {Promise<String>}
    */
    getApplicationApplicants: function getApplicationApplicants(applicationId) {
      if (!applicationId) return RSVP.resolve('');
      return this.request('resource-consent-applications/application-applicants/' + applicationId).then(function (result) {
        return result.names;
      });
    },


    /**
     * Get the holders for a consents_and_permits.consent record
     * @param capConsentId
     * @return {Promise<String>}
     */
    getCapConsentHolders: function getCapConsentHolders(capConsentId) {
      if (!capConsentId) return RSVP.resolve('');
      return this.request('resource-consent-applications/cap-consent-holders/' + capConsentId).then(function (result) {
        return result.names;
      });
    },


    /**
     * Get the holders for a consents_and_permits.cap_decision record
     * @param capConsentId
     * @return {Promise<String>}
     */
    getCapDecisionHolders: function getCapDecisionHolders(capDecisionId) {
      if (!capDecisionId) return RSVP.resolve('');
      return this.request('resource-consent-applications/cap-decision-holders/' + capDecisionId).then(function (result) {
        return result.names;
      });
    },
    suggestApplicationNumber: function suggestApplicationNumber(query) {
      return this.request('resource-consent-applications/suggest-application-number/' + query).then(function (_ref) {
        var results = _ref.results;
        return results;
      });
    },
    getApplicationNumberFromApplicationDecisionId: function getApplicationNumberFromApplicationDecisionId(applicationDecisionId) {
      return this.request('resource-consent-applications/get-application-number/application-decision/' + applicationDecisionId).then(function (_ref2) {
        var applicationNumber = _ref2.applicationNumber;
        return applicationNumber;
      });
    },


    /**
       * Returns a textual description of any maintenance windows that are forthcoming
       * @return {Promise<String>}
       */
    getMaintenanceWindows: function getMaintenanceWindows() {
      return this.request('resource-consent-applications/get-maintenance-windows').then(function (_ref3) {
        var results = _ref3.results;
        return results;
      });
    },


    /**
     * Retrieve information about this application, for use in the page header in the application decision section
     * @param {String|Number} applicationId
     * @param {boolean} refresh Set this to true to ignore the cached version and retrieve the latest data from the server
     * @return {Promise<Object[]>}
     */
    getProcessingPageInfo: function getProcessingPageInfo(applicationId) {
      var refresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var cacheKey = 'getProcessingPageInfo_' + applicationId;

      var cache = get(this, 'cache');
      if (refresh === false) {
        var cachedResult = cache.fetch(cacheKey);
        if (cachedResult) return cachedResult;
      }

      var request = this.request('resource-consent-applications/processing-page-info/' + applicationId);
      cache.save(cacheKey, request);
      return request;
    },
    getTabConfig: function getTabConfig(sectionKey, parentId) {
      var cacheKey = 'getTabConfig_' + sectionKey + '_' + parentId;

      var cachedResult = this.cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('forms/get-tabs/' + sectionKey + '/' + parentId).then(function (_ref4) {
        var tabs = _ref4.tabs;
        return tabs;
      });
      this.cache.save(cacheKey, request);
      return request;
    },


    /**
       * Clear pageInfo Cache
       * @param {String|Number} applicationId
       */
    clearProcessingPageInfoCache: function clearProcessingPageInfoCache(applicationId) {
      var cacheKey = 'getProcessingPageInfo_' + applicationId;
      var cache = get(this, 'cache');
      cache.flush(cacheKey);
    },


    /**
     * Retrieve information about this consent, for use in the page header in the consents and permits section
     * @param {String|Number} capDecisionId
     * @param {boolean} refresh Set this to true to ignore the cached version and retrieve the latest data from the server
     * @return {Promise<Object[]>}
     */
    getConsentsPageInfo: function getConsentsPageInfo(capDecisionId) {
      var refresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var cacheKey = 'getConsentsPageInfo_' + capDecisionId;

      var cache = get(this, 'cache');
      if (refresh === false) {
        var cachedResult = cache.fetch(cacheKey);
        if (cachedResult) return cachedResult;
      }

      var request = this.request('resource-consent-applications/consents-page-info/' + capDecisionId);
      cache.save(cacheKey, request);
      return request;
    },


    /**
     * Update an application number
     * @param {String|Number} applicationId
     * @param {String|Number} applicationNo
     * @return {Promise<Object[]>}
     */
    updateApplicationNo: function updateApplicationNo(applicationId, applicationNo) {
      var _this2 = this;

      if (applicationNo === undefined || applicationNo === null) {
        applicationNo = '';
      }
      return this.put('resource-consent-applications/update-application-number/' + applicationId + '/' + applicationNo).then(function (response) {
        // these have changed so we need to refresh our cached versions
        var cache = get(_this2, 'cache');

        var processingPageInfoKey = 'getProcessingPageInfo_' + applicationId;
        var getProcessingPageInfo = cache.fetch(processingPageInfoKey);
        cache.flush(processingPageInfoKey);

        if (getProcessingPageInfo === false) {
          // we don't know what the capDecisionId is so refresh them all
          cache.flushMatch(/^getConsentsPageInfo_/);
        } else {
          getProcessingPageInfo.then(function (_ref5) {
            var capDecisionId = _ref5.capDecisionId;

            cache.flush('getConsentsPageInfo_' + capDecisionId);
          });
        }

        // return the response before the above promises are resolved
        return response;
      });
    },


    /**
     * Gets a the processing.v_status_tracking_item_table view from the server,
     * returns dynamic forms JSON (formElements and state) to pass to the manage-form-elements component
     * @param {String|Number} applicationDecisionId
     * @return {Promise<Object[]>}
     */
    getStatusTrackingItemTotalDaysTable: function getStatusTrackingItemTotalDaysTable(applicationDecisionId) {
      return this.request('resource-consent-applications/get-status-tracking-item-summary-table/' + applicationDecisionId).then(function (_ref6) {
        var results = _ref6.results;

        var totalDaysSection = results.map(function (_ref7) {
          var trackingType = _ref7.trackingType,
              totalDays = _ref7.totalDays;
          return {
            'val': {
              'trackingType': [{ 'val': trackingType }],
              'totalDays': [{ 'val': totalDays }]
            }
          };
        });
        return {
          'state': {
            'totalDaysTable': [{
              'val': { totalDaysSection: totalDaysSection }
            }]
          },
          formElements: [{
            'name': 'totalDaysTable',
            'type': 'table-view',
            'extendedAttributes': {
              'tableViewInEdit': true
            },
            'formElements': [{
              'name': 'totalDaysSection',
              'type': 'section',
              'formElements': [{
                'name': 'trackingType',
                'type': 'text',
                'label': 'Tracking type'
              }, {
                'name': 'totalDays',
                'type': 'text',
                'label': 'Total days'
              }]
            }]
          }]
        };
      });
    },


    /**
     * Unublishes a consent
     * @param {String|Number} capDecisionId
     * @return {Promise<Object[]>}
     */
    unpublishConsent: function unpublishConsent(capDecisionId, reasonForChangeId, variationApplicationId, comment) {
      var data = {
        reasonForChangeId: reasonForChangeId,
        variationApplicationId: variationApplicationId,
        comment: comment
      };
      return this.post('resource-consent-applications/unpublish-consent/' + capDecisionId, { data: data }).then(function (_ref8) {
        var results = _ref8.results;
        return results;
      });
    },


    /**
     * Publishes a consent
     * @param {String|Number} capDecisionId
     * @return {Promise<Object[]>}
     */
    publishConsent: function publishConsent(capDecisionId, reasonForChangeId, variationApplicationId, comment) {
      var data = {
        reasonForChangeId: reasonForChangeId,
        variationApplicationId: variationApplicationId,
        comment: comment
      };
      return this.post('resource-consent-applications/publish-consent/' + capDecisionId, { data: data });
    },


    /**
     * Get information for the publish consent modal
     * @param {String|Number} capDecisionId
     * @return {Promise<Object[]>}
     */
    publishConsentDialogInfo: function publishConsentDialogInfo(capDecisionId) {
      return this.request('resource-consent-applications/publish-consent-dialog-info/' + capDecisionId);
    },


    /**
     * Returns a promise that resolves to true if a cap_decision has been published
     * @param {String|Number} capDecisionId
     * @return {Promise<boolean>}
     */
    isConsentDecisionPublished: function isConsentDecisionPublished(capDecisionId) {
      return this.request('resource-consent-applications/is-consent-decision-published/' + capDecisionId).then(function (_ref9) {
        var isPublished = _ref9.isPublished;
        return isPublished;
      });
    },


    /**
     * Makes a request to the server to send a notification to the assigned processing officer
     */
    sendAssignedProcessingOfficerNotification: function sendAssignedProcessingOfficerNotification(applicationId) {
      return this.put('resource-consent-applications/send-assigned-processing-officer-notification/' + applicationId);
    },


    /**
      * Makes a request to the server to skip the S88 check for this application
      */
    createS88Demo: function createS88Demo(applicationType) {
      return this.put('resource-consent-applications/create-s88-demo/' + applicationType);
    },


    /**
     * Makes a request to the server to skip the S88 check for this application
     */
    skipS88Check: function skipS88Check(applicationId) {
      return this.put('resource-consent-applications/skip-s88-check/' + applicationId);
    },


    /**
     * Makes a request to the server to assign an application number to this application
     */
    assignApplicationNumber: function assignApplicationNumber(applicationId) {
      return this.put('resource-consent-applications/assign-application-number/' + applicationId);
    },


    /**
     * Makes a request to the server to get a list of application types that can be used for creating new application forms
     */
    getNewApplicationTypes: function getNewApplicationTypes() {
      return this.request('resource-consent-applications/get-new-application-types').then(function (_ref10) {
        var results = _ref10.results;
        return results;
      });
    },
    finaliseS88CheckDecision: function finaliseS88CheckDecision(applicationDecisionId) {
      return this.post('resource-consent-applications/finalise-s88-check-decision/' + applicationDecisionId, {});
    },
    unfinaliseS88CheckDecision: function unfinaliseS88CheckDecision(applicationId) {
      return this.post('resource-consent-applications/unfinalise-s88-check-decision/' + applicationId, {});
    },
    suggestSubmittedApplication: function suggestSubmittedApplication(query) {
      return this.request('resource-consent-applications/suggest-submitted-application/' + query).then(function (_ref11) {
        var results = _ref11.results;
        return results;
      });
    },
    getSubmittedApplication: function getSubmittedApplication(id) {
      return this.request('resource-consent-applications/get-submitted-application/' + id).then(function (_ref12) {
        var result = _ref12.result;
        return result;
      });
    },


    /**
     * Sets the required flags in the backend to mark the public submission as submitted
     * @param {string|integer} formInstanceId
    */
    confirmPublicSubmission: function confirmPublicSubmission(formInstanceId) {
      return this.post('resource-consent-applications/confirm-public-submission/' + formInstanceId, {});
    },


    /**
     * Requests a delete of a public submission application
     * @param {string|integer} formInstanceId
    */
    deletePublicSubmission: function deletePublicSubmission(formInstanceId) {
      return this.delete('resource-consent-applications/delete-public-submission/' + formInstanceId);
    }
  });
});