define('client/components/home-section/reporting-dashboard/applications-table/columns-in-progress', ['exports', 'moment', 'client/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Formatting functions for the table cells.
  var formatText = function formatText(str) {
    return str || '-';
  };
  var formatNumber = function formatNumber(str) {
    return str || '0';
  };
  var formatDate = function formatDate(str) {
    var m = (0, _moment.default)(str);
    return m.isValid() ? m.format(_environment.default.dateFormat) : '-';
  };

  exports.default = [{
    sortable: false,
    width: '30px',
    cellComponent: 'light-table-components/row-toggle',
    classNames: 'c-table__cell--heading',
    cellClassNames: 'c-table__cell c-table__row--clickable c-table__row--toggle-column',
    breakpoints: ['upToTablet']
  }, {
    label: 'Application number',
    width: '100px',
    valuePath: 'applicationNo',
    cellComponent: 'light-table-components/cell-with-title',
    format: formatText,
    classNames: 'c-table__cell--heading',
    cellClassNames: 'c-table__cell c-table__row--clickable'
  }, {
    label: 'Applicants',
    valuePath: 'applicants',
    cellComponent: 'light-table-components/cell-with-title',
    format: formatText,
    classNames: 'c-table__cell--heading',
    cellClassNames: 'c-table__cell c-table__row--clickable',
    breakpoints: []
  }, {
    label: 'Date this stage commenced',
    width: '120px',
    valuePath: 'applicationStatusStartDate',
    format: formatDate,
    align: 'right',
    classNames: 'c-table__cell--heading',
    cellClassNames: 'c-table__cell c-table__row--clickable',
    breakpoints: []
  }, {
    label: 'Working days in current stage',
    width: '100px',
    valuePath: 'workingDays',
    format: formatNumber,
    align: 'right',
    classNames: 'c-table__cell--heading',
    cellClassNames: 'c-table__cell c-table__row--clickable',
    breakpoints: ['tabletUp']
  }, {
    label: 'Processing days in current stage',
    width: '100px',
    valuePath: 'processingDays',
    format: formatNumber,
    align: 'right',
    classNames: 'c-table__cell--heading',
    cellClassNames: 'c-table__cell c-table__row--clickable',
    breakpoints: ['tabletUp']
  }, {
    label: 'Total processing days',
    width: '100px',
    valuePath: 'totalProcessingDays',
    format: formatNumber,
    align: 'right',
    classNames: 'c-table__cell--heading',
    cellClassNames: 'c-table__cell c-table__row--clickable',
    breakpoints: ['smallDeviceUp']
  }];
});