define('client/components/dynamic-form/edit/restricted-rich-text/ckeditor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    ckeditorKey: computed('elementId', function () {
      return 'ckeditor-' + get(this, 'elementId');
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var editor = CKEDITOR.replace(get(this, 'ckeditorKey'), {
        // White list of HTML tags
        // https://docs.ckeditor.com/ckeditor4/docs/#!/api/CKEDITOR.config-cfg-allowedContent
        // If updating this whitelist, ensure it's also updated on the server-side
        // see server/dynamic_forms/utils/dynamic_forms.py
        allowedContent: 'b br em h1 h2 h3 i li ol p strong u ul',
        toolbarGroups: [{ name: 'basicstyles', groups: ['basicstyles'] }, { name: 'paragraph', groups: ['list', 'indent'] }, { name: 'clipboard', groups: ['clipboard', 'undo'] }, { name: 'tools', groups: ['tools'] }, { name: 'styles', groups: ['styles'] }],
        removeButtons: 'Subscript,Superscript',
        disableNativeSpellChecker: false
      });
      editor.on('change', function (evt) {
        _this.setValue(evt.editor.getData());
      });

      // run the didInsertEditor action that (optionally) gets passed down to this component, allowing the parent component access to the ckeditor instance
      if (this.didInsertEditor) {
        this.didInsertEditor(editor);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (CKEDITOR.instances[get(this, 'ckeditorKey')]) {
        CKEDITOR.instances[get(this, 'ckeditorKey')].destroy();
      }
    }
  });
});