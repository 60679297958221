define('client/routes/consents-section/summary', ['exports', 'client/mixins/routes/generic-workflows'], function (exports, _genericWorkflows) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    remoteMethods: inject.service('resource-consent-remote-methods'),

    // Function to be called after save (in edit-form-action.js)
    // This updates the pageInfo, to ensure the correct holder names are being displayed in the page header
    postFormSaveFunction: function postFormSaveFunction() {
      var model = this.modelFor('consents-section');
      get(this, 'remoteMethods').getConsentsPageInfo(model.pageInfo.capDecisionId, true).then(function (pageInfo) {
        set(model, 'pageInfo', pageInfo);
      });
    },


    workflows: [{
      key: 'consent_decision_summary_section',
      path: 'decision',
      title: 'Decision summary'
    }]
  });
});