define('client/test-fixtures/menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'menu': {
      'label': 'application',
      'formTemplateVersionId': 10016,
      'formInstanceId': 10041,
      'renderAs': 'section',
      'ordinalPosition': 10,
      'templateKey': 'application',
      'forms': [{
        'label': 'Applicant details',
        'formTemplateVersionId': 10002,
        'formInstanceId': 10036,
        'childFormAlias': 'App Details',
        'renderAs': 'form-link',
        'ordinalPosition': 10,
        'templateKey': 'applicant_details'
      }, {
        'label': 'Property details',
        'formTemplateVersionId': 10003,
        'formInstanceId': 10040,
        'childFormAlias': 'Property details',
        'renderAs': 'form-link',
        'ordinalPosition': 20,
        'templateKey': 'property_details'
      }, {
        'label': 'Proposed activity',
        'formTemplateVersionId': 10004,
        'formInstanceId': 10032,
        'childFormAlias': 'Proposed activity',
        'renderAs': 'form-link',
        'ordinalPosition': 30,
        'templateKey': 'proposed_activity'
      }, {
        'label': 'Consent Summary',
        'formTemplateVersionId': 10010,
        'formInstanceId': 10039,
        'childFormAlias': 'Consent summary',
        'renderAs': 'form-link',
        'ordinalPosition': 40,
        'templateKey': 'consent_summary',
        'forms': [{
          'label': 'Consent',
          'formTemplateVersionId': 10009,
          'formInstanceId': 10046,
          'childFormAlias': 'Consent 1',
          'renderAs': 'form-link',
          'ordinalPosition': 10,
          'nonNavigable': true,
          'aliasFormLocation': 'consent_information',
          'templateKey': 'consentForm',
          'forms': [{
            'label': 'Consent information',
            'formTemplateVersionId': 10005,
            'formInstanceId': 10043,
            'childFormAlias': 'Consent information',
            'renderAs': 'form-link',
            'ordinalPosition': 10,
            'aliasForParent': true,
            'templateKey': 'consent_information'
          }, {
            'label': 'Map details',
            'formTemplateVersionId': 10006,
            'formInstanceId': 10045,
            'childFormAlias': 'Map information',
            'renderAs': 'form-link',
            'ordinalPosition': 20,
            'templateKey': 'map_information'
          }, {
            'label': 'Assessment of effects',
            'formTemplateVersionId': 10007,
            'formInstanceId': 10044,
            'childFormAlias': 'Assessment of Effects on the Environment (AEE)',
            'renderAs': 'form-link',
            'ordinalPosition': 30,
            'templateKey': 'assessment_of_effects'
          }, {
            'label': 'Supplementary details',
            'formTemplateVersionId': 10008,
            'formInstanceId': 10042,
            'childFormAlias': 'Supplementary details',
            'renderAs': 'form-link',
            'ordinalPosition': 40,
            'templateKey': 'supplementary_details'
          }]
        }, {
          'label': 'Consent',
          'formTemplateVersionId': 10009,
          'formInstanceId': 10146,
          'childFormAlias': 'Consent 2',
          'renderAs': 'form-link',
          'ordinalPosition': 10,
          'nonNavigable': true,
          'aliasFormLocation': 'consent_information',
          'templateKey': 'consentForm',
          'forms': [{
            'label': 'Consent information',
            'formTemplateVersionId': 10005,
            'formInstanceId': 10143,
            'childFormAlias': 'Consent information',
            'renderAs': 'form-link',
            'ordinalPosition': 10,
            'aliasForParent': true,
            'templateKey': 'consent_information'
          }, {
            'label': 'Map details',
            'formTemplateVersionId': 10006,
            'formInstanceId': 10145,
            'childFormAlias': 'Map information',
            'renderAs': 'form-link',
            'ordinalPosition': 20,
            'templateKey': 'map_information'
          }, {
            'label': 'Assessment of effects',
            'formTemplateVersionId': 10007,
            'formInstanceId': 10144,
            'childFormAlias': 'Assessment of Effects on the Environment (AEE)',
            'renderAs': 'form-link',
            'ordinalPosition': 30,
            'templateKey': 'assessment_of_effects'
          }, {
            'label': 'Supplementary details',
            'formTemplateVersionId': 10008,
            'formInstanceId': 10142,
            'childFormAlias': 'Supplementary details',
            'renderAs': 'form-link',
            'ordinalPosition': 40,
            'templateKey': 'supplementary_details'
          }]
        }]
      }, {
        'label': 'Statutory Planning',
        'formTemplateVersionId': 10011,
        'formInstanceId': 10035,
        'childFormAlias': 'Statutory planning',
        'renderAs': 'form-link',
        'ordinalPosition': 50,
        'templateKey': 'statutory_planning'
      }, {
        'label': 'Additional information',
        'formTemplateVersionId': 10012,
        'formInstanceId': 10037,
        'childFormAlias': 'Additional Information',
        'renderAs': 'form-link',
        'ordinalPosition': 60,
        'templateKey': 'additional_information',
        'forms': [{
          'label': 'Fake Form',
          'formTemplateVersionId': 10100,
          'formInstanceId': 10101,
          'childFormAlias': 'Fake Form',
          'renderAs': 'form-link'
        }]
      }, {
        'label': 'Affected person(s) approvals obtained',
        'formTemplateVersionId': 10013,
        'formInstanceId': 10034,
        'childFormAlias': 'Affected persons approval',
        'renderAs': 'form-link',
        'ordinalPosition': 70,
        'templateKey': 'affected_persons_approvals'
      }, {
        'label': 'Fees',
        'formTemplateVersionId': 10014,
        'formInstanceId': 10038,
        'childFormAlias': 'Fees',
        'renderAs': 'form-link',
        'ordinalPosition': 80,
        'templateKey': 'fees'
      }, {
        'label': 'Declaration',
        'formTemplateVersionId': 10015,
        'formInstanceId': 10033,
        'childFormAlias': 'Declaration',
        'renderAs': 'form-link',
        'ordinalPosition': 90,
        'templateKey': 'declaration'
      }]
    }
  };
});