define('client/components/modal-dialog/finalise-decision/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),

    loading: null,
    hideCloseButton: null,
    onFinaliseSendDocumentToCM: false,
    onFinaliseSendEmailToApplicant: false,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'loading', false);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'loading', false);
      this._super.apply(this, arguments);
    },


    applicationNumber: computed('pageInfo.consentNumber', function () {
      return get(this, 'pageInfo.consentNumber') || 'Not yet specified';
    }),

    isDecisionFinalised: computed.alias('pageInfo.isDecisionFinalised'),

    modalHeading: computed('isDecisionFinalised', function () {
      return get(this, 'isDecisionFinalised') ? 'Reverse finalisation of decision' : 'Finalise decision';
    }),

    actions: {
      finaliseDecisionAction: function finaliseDecisionAction() {
        var _this = this;

        var onFinaliseSendDocumentToCM = get(this, 'onFinaliseSendDocumentToCM');
        var onFinaliseSendEmailToApplicant = get(this, 'onFinaliseSendEmailToApplicant');

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').finaliseDecision(get(this, 'pageInfo.applicationId'), onFinaliseSendDocumentToCM, onFinaliseSendEmailToApplicant).then(function (response) {
          if (response.error) {
            get(_this, 'flashMessages.danger')('Unable to finalise decision' + (response.message ? ' - ' + response.message : ''));
            // These need resetting or otherwise the checkboxes will reset, but won't be synched with the back end values, because.. ember.
            set(_this, 'onFinaliseSendDocumentToCM', false);
            set(_this, 'onFinaliseSendEmailToApplicant', false);
          } else {
            get(_this, 'flashMessages.success')('Decision finalised');
            get(_this, 'remoteMethods').clearProcessingPageInfoCache(get(_this, 'pageInfo.applicationId'));
            _this.get('setShowFinaliseDecisionModal')(false);
            _this.refreshModel();
          }
        }).finally(function () {
          set(_this, 'loading', false);
        });
      },
      unfinaliseDecisionAction: function unfinaliseDecisionAction() {
        var _this2 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').unfinaliseDecision(get(this, 'pageInfo.applicationId')).then(function (response) {
          if (response.error) {
            get(_this2, 'flashMessages.danger')('Unable to reverse finalisation of decision' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this2, 'flashMessages.success')('Decision finalisation reversed');
            get(_this2, 'remoteMethods').clearProcessingPageInfoCache(get(_this2, 'pageInfo.applicationId'));
            _this2.get('setShowFinaliseDecisionModal')(false);
            _this2.refreshModel();
          }
        }).finally(function () {
          set(_this2, 'loading', false);
        });
      }
    }
  });
});