define('client/components/generic-search/filter/pill/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isEnabled: Ember.computed('query.[]', function () {
      return this.query.length > 0;
    })
  });
});