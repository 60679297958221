define('client/components/dynamic-form/edit/consent-number/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement', 'npm:lodash/isString', 'ember-copy'], function (exports, _dynamicFormElement, _complexFormElement, _isString, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    // The child form element for the application number
    applicationNumber: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'applicationNumber';
      });
    }),

    // The actual value of the application number
    applicationNumberValue: computed('stateValue.applicationNumber.0.val', function () {
      var applicationNumber = get(this, 'stateValue.applicationNumber.0.val');
      // If there is no application number set (which shouldn't happen in practice), we use a "0" as a placeholder.
      return applicationNumber === null ? '0' : applicationNumber;
    }),

    // The child form element for the full consent number (including suffix)
    consentNumberWithSuffix: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'consentNumberWithSuffix';
      });
    }),

    // The value of the suffix on the consent number, assumes a format like 123456.01
    consentSuffixValue: computed('state', 'formElement', function () {
      var formElementName = get(this, 'formElement.name');
      var fullValue = get(this, 'state.' + formElementName + '.0.val.consentNumberWithSuffix.0.val');
      // The prefix and/or suffix associated with the application type are added by the system
      // and should be excluded from the user entered consent suffix value
      var excludePrefix = get(this, 'pageInfo.applicationTypePrefix');
      var excludeSuffix = get(this, 'pageInfo.applicationTypeSuffix');
      if ((0, _isString.default)(fullValue) && (excludePrefix || excludeSuffix)) {
        // Replace the first occurences of the prefix and suffix with trailing or leading '.' respectively
        if (excludePrefix) fullValue = fullValue.replace(excludePrefix + '.', '');
        if (excludeSuffix) fullValue = fullValue.replace('.' + excludeSuffix, '');
      }
      return (0, _isString.default)(fullValue) ? fullValue.split('.').slice(1).join('.') : '';
    }),

    actions: {
      /**
       * A wrapper function around updateStateKey that adds the application number to the suffix.
       * @param {String} value The suffix
       */
      setConsentSuffix: function setConsentSuffix(value) {
        var formElementName = get(this, 'formElement.name');
        var applicationNumber = get(this, 'applicationNumberValue');

        var state = (0, _emberCopy.copy)(get(this, 'state'), true);
        var newValue = applicationNumber + '.' + value;
        state[formElementName][0]['val']['consentNumberWithSuffix'][0]['val'] = newValue;
        return get(this, 'updateStateKey')(formElementName, state[formElementName]);
      }
    }
  });
});