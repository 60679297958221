define('client/components/dynamic-form/edit/typeahead-debtor-number/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Note: If you wish to save these to the form/back-end tables, they must exist
  //        in the dynamic forms config as child formElements of this formElement
  var childFormElements = exports.childFormElements = [{
    type: 'text',
    name: 'debtorNumber',
    label: 'Debtor number',
    getValue: function getValue(details) {
      return details.debtorNumber;
    }
  }, {
    name: 'entities',
    getValue: function getValue(details) {
      return details.entities;
    }
  }];

  // the ember service to use for the search
  var searchServiceName = exports.searchServiceName = 'council-services';

  // The method used perform the search
  // Should take a query string and return a promise that resolves to an array of objects
  var searchMethodName = exports.searchMethodName = 'debtorNumberSearch';
});