define('client/components/consents-section/component', ['exports', 'npm:lodash/find', 'client/constants', 'client/mixins/components/workflow-section'], function (exports, _find, _constants, _workflowSection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_workflowSection.default, {
    router: inject.service(),

    /**
     * Items for the breadcrumb trail.
     *
     * Watches the route path from the router service, and uses it to build up
     * an array of crumb items.
     */
    crumbs: computed('parentId', 'currentTabCrumb', function () {
      return [_constants.TOP_LEVEL_BREADCRUMB, (0, _find.default)(_constants.HOME_TABS, { path: 'home-section.issued-consents' }), {
        name: 'Consent/permit',
        path: 'consents-section.index',
        mode: _constants.MODE.VIEW,
        parentId: get(this, 'parentId')
      }, get(this, 'currentTabCrumb')].filter(function (o) {
        return o !== undefined;
      });
    })
  });
});