define('client/components/app-nav/component', ['exports', 'client/config/environment', 'client/constants'], function (exports, _environment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      getWithDefault = Ember.getWithDefault,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    session: inject.service(),
    router: inject.service(),
    gateKeeper: inject.service(),
    councilInfo: Ember.computed(function () {
      return getWithDefault(_environment.default, 'ENV_VARS.COUNCIL_INFO', {});
    }),
    hideNav: computed('router.currentRouteName', function () {
      var _this = this;

      return _constants.HIDE_NAV_ROUTES.some(function (v) {
        return _this.router.currentRouteName === v;
      });
    }),
    showQuickSearch: computed('session.currentUser.allowedActions', function () {
      return this.gateKeeper.can('global.use_quick_search');
    })
  });
});