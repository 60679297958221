define('client/components/generic-search/filter/pill-collapsed/component', ['exports', 'ember-data', 'moment', 'client/config/environment', 'client/libs/generic-search'], function (exports, _emberData, _moment, _environment, _genericSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: '',
    remoteMethods: Ember.inject.service('generic-search-remote-methods'),

    // Converts a single value to a string. Can optionally return a promise.
    valueToString: function valueToString(value) {
      if (this.template.type === 'date-range' || this.template.type === 'date-specific') {
        return (0, _moment.default)(value).format(_environment.default.dateFormat);
      }
      if (this.template.type === 'typeahead-selector' || this.template.type === 'select') {
        return this.remoteMethods.getLookupDisplayValue({
          searchTypeKey: this.searchTypeKey,
          optionsKey: this.template.optionsKey,
          internalValue: value
        }).then(function (_ref) {
          var lookupDisplayValueResult = _ref.lookupDisplayValueResult;
          return lookupDisplayValueResult.displayValue;
        }).catch(function () {
          return value;
        });
      }
      return value;
    },
    filterToString: function filterToString() {
      var _this = this;

      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (filter.filterSet) {
        var operator = {
          'OR': 'or',
          'AND': 'and'
        }[filter.filterSet.booleanOperator] || filter.filterSet.booleanOperator;

        // special case for date ranges
        if (this.template.type === 'date-range') {
          operator = '';
        }

        return Ember.RSVP.Promise.all(filter.filterSet.filters.map(function (filter) {
          return _this.filterToString(filter);
        })).then(function (s) {
          return s.reduce(function (prev, curr) {
            if (prev.length > 0) {
              prev.push({ operator: operator });
            }
            return prev.concat.apply(prev, _toConsumableArray(curr));
          }, []);
        });
      } else if (filter.filterKey) {
        var _operator = {
          '>': 'greater than',
          '<': 'less than',
          '>=': 'greater than or equal to',
          '<=': 'less than or equal to',
          '=': 'is',
          '@@': 'for',
          'ilike': 'like',
          'ilike-bar': 'is',
          'in': 'is'
        }[filter.filterOperator];
        if (_operator === undefined) {
          _operator = filter.filterOperator;
        }

        // special case for date ranges
        if (this.template.type === 'date-range') {
          var filterConfig = _genericSearch.dateRangeFilterOperatorList.find(function (f) {
            return f.value === filter.filterOperator;
          });
          if (filterConfig) {
            return Ember.RSVP.Promise.resolve(filterConfig.getPillText(filterConfig));
          } else {
            // custom range
            _operator = {
              '>=': 'from',
              '<=': 'to',
              '>': 'after',
              '<': 'before'
            }[filter.filterOperator] || _operator;
          }
        }

        return Ember.RSVP.Promise.resolve(this.valueToString(filter.filterValue)).then(function (value) {
          return [{ operator: _operator }, { value: value }];
        });
      }
    },


    pills: Ember.computed('query', function () {
      var _this2 = this;

      var promise = Ember.RSVP.Promise.all((this.query || []).map(function (q) {
        return _this2.filterToString((0, _genericSearch.removeRedundantFilters)(q, true, true));
      })).then(function (pills) {
        return pills.filter(function (q) {
          return q !== undefined && q.length > 0;
        });
      });
      return _emberData.default.PromiseArray.create({ promise: promise });
    })
  });
});