define('client/mixins/components/dynamic-formElement', ['exports', 'npm:lodash/isArray', 'ember-copy', 'client/libs/dynamic-forms-methods-lib'], function (exports, _isArray, _emberCopy, _dynamicFormsMethodsLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      set = Ember.set,
      inject = Ember.inject,
      run = Ember.run;
  exports.default = Ember.Mixin.create({
    // Some useful values
    values: [],
    index: 0,
    calculateDisplay: inject.service(),
    formElementState: computed('state', 'formElement', function () {
      var formElementName = get(this, 'formElement.name');
      return get(this, 'state.' + formElementName);
    }),
    stateToDisplay: computed.filter('formElementState', function (item) {
      return !get(item, 'deleted') && !get(item, 'hidden');
    }),
    totalNumberDisplayed: computed.alias('stateToDisplay.length'),

    hiddenState: undefined,

    itemState: computed('formElementState', 'index', function () {
      return (0, _isArray.default)(get(this, 'formElementState')) && get(this, 'formElementState').length ? get(this, 'formElementState').objectAt(get(this, 'index')) : null;
    }),

    /**
     * stateValue represents the 'val' of this element.  Only really relevant where the type contains other formElements
     * that need to have a new root state passed down
     */
    stateValue: computed('itemState', function () {
      var state = get(this, 'itemState');
      return state ? get(state, 'val') : null;
    }),

    isDeletedOrHidden: computed('formElementState', 'index', function () {
      var index = get(this, 'index');
      if (index == null) return false;
      if (!(0, _isArray.default)(get(this, 'formElementState'))) return false;

      var state = get(this, 'formElementState').objectAt(get(this, 'index'));
      return state && (state.hidden || state.deleted);
    }),

    hiddenByPrintView: Ember.computed('printView', 'formElement.extendedAttributes.hideInPrintView', function () {
      return get(this, 'formElement.extendedAttributes.hideInPrintView') === true && get(this, 'printView') === true;
    }),

    display: computed('state', 'formElement.{name,displayConditions,extendedAttributes}', function () {
      var displayConditions = get(this, 'formElement.displayConditions');
      var formElementName = get(this, 'formElement.name');
      var state = get(this, 'state');

      var display = void 0;

      if (get(this, 'formElement.extendedAttributes.suppressInPrint') && get(this, 'printView')) {
        display = false;
      } else {
        // Calculate whether this particular element passes the displayConditions to display
        display = get(this, 'calculateDisplay').shouldDisplay(state, displayConditions, formElementName);
      }

      if ((0, _isArray.default)(get(this, 'formElementState'))) {
        // Set each element of the array to be hidden (normally only one item unless a section)
        get(this, 'formElementState').forEach(function (element) {
          set(element, 'hidden', !display);
        });
      }

      return display;
    }),

    displayObserver: observer('display', function () {
      // If the extendedAttribute clearOnHide is set, and the element is hidden, change the value of the element to null
      // This is to do with how values should update when sections are conditionally hidden.

      if (get(this, 'formElement.extendedAttributes.clearOnHide') && !get(this, 'display') && !!get(this, 'value')) {
        var clearValue = get(this, 'clearValue').bind(this);
        run.next(function () {
          clearValue();
        });
      }
    }),

    // SWC 08.07.23
    // formElementState.0.hidden continuously shows as having changed even when the value actually
    // hasn't changed. This provides a value (hiddenState) that can be observed and will only fire
    // when there is an actual change

    hiddenStateObserver: observer('formElementState.0.hidden', function () {
      if (get(this, 'formElement.extendedAttributes.defaultOnShow')) {
        var hiddenState = get(this, 'hiddenState');
        var currentHiddenState = get(this, 'formElementState.0.hidden');
        if (hiddenState !== currentHiddenState) {
          set(this, 'hiddenState', currentHiddenState);
        }
      }
    }),

    defaultOnShowObserver: observer('hiddenState', function () {
      // SWC 08.07.23
      // If the extendedAttribute defaultOnShow is set, and the element is made visible, a value is taken
      // from the "source" element and written to the target element. This will keep happening unless a value
      // (any value) is set in the target element

      if (get(this, 'formElement.extendedAttributes.defaultOnShow') && !get(this, 'formElementState.0.hidden') && !get(this, 'value')) {
        var result = (0, _dynamicFormsMethodsLib.treeSearch)(get(this, 'form.state'), get(this, 'formElement.extendedAttributes.defaultOnShow.source'));
        if (result !== undefined) {
          result = result[0].val;
        }
        var setValue = get(this, 'setValue').bind(this);
        run.next(this, function () {
          setValue(result);
          if (this.editor !== undefined) {
            this.editor.setData(result);
          }
        });
      }
    }),

    // Text formElements need the value to be updated as you type in order to update
    valuesObserver: observer('state', 'formElement.name', function () {
      this.conditionalSetValues();
    }),

    init: function init() {
      this._super();
      this.conditionalSetValues();
    },
    conditionalSetValues: function conditionalSetValues() {
      if (get(this, 'formElementState.length') !== get(this, 'values.length')) {
        set(this, 'values', get(this, 'formElementState'));
      }
    },
    forceSetValues: function forceSetValues() {
      run.next(this, function () {
        set(this, 'values', get(this, 'formElementState'));
      });
    },


    value: computed.alias('stateValue'),

    /**
     * Set the value of the form element.
     * @param value
     */
    setValue: function setValue(value) {
      var state = (0, _emberCopy.copy)(get(this, 'state'), true);
      var formElement = get(this, 'formElement');

      // Cope with empty state issues
      if (!state) state = {};
      if (!state[formElement.name]) state[formElement.name] = [];
      var valToSave = String(value).trim() ? String(value) : null;

      state[formElement.name][0] = Object.assign({ id: 'new' }, state[formElement.name][0], { val: valToSave });
      get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'));
    },


    /**
     * Clear the current value from the state.
     */
    clearValue: function clearValue() {
      // let state = copy(get(this, 'state'), true)
      // const formElement = get(this, 'formElement')

      // Cope with empty state issues
      // if (!state) state = {}
      // if (!state[formElement.name]) state[formElement.name] = []

      // state[formElement.name][0] = Object.assign({}, state[formElement.name][0], { val: undefined, selectedOption: undefined })
      // get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'))

      var name = this.formElement.name;

      var oldState = (0, _emberCopy.copy)(getWithDefault(this, 'state.' + name + '.0', {}), true);
      var newState = Object.assign(oldState, { val: undefined, selectedOption: undefined });
      this.updateStateKey(name, [newState], this.index);
    },


    actions: {
      /**
       * Set the value of the form element.
       * @param value
       */
      setValue: function setValue(value) {
        this.setValue(value);
      },


      /**
       * Clear the current value from the state.
       */
      clearValue: function clearValue() {
        this.clearValue();
      }
    }
  });
});