define('client/components/page-header/component', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getWithDefault = Ember.getWithDefault,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['c-page-header', 'h-hide-for-print'],
    ariaRole: 'banner',
    councilInfo: computed(function () {
      return getWithDefault(_environment.default, 'ENV_VARS.COUNCIL_INFO', {});
    })
  });
});