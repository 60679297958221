define('client/components/home-section/information/component', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Component.extend({
    router: inject.service(),
    session: inject.service(),

    councilInfo: Ember.computed(function () {
      return getWithDefault(_environment.default, 'ENV_VARS.COUNCIL_INFO', {});
    }),

    maintenanceWindows: Ember.computed('maintenanceWindowsPromise', function () {
      return get(this, 'maintenanceWindowsPromise');
    }),

    actions: {
      apply: function apply() {
        // redirect to the login page
        get(this, 'router').transitionTo('home-section', { queryParams: { loginModal: true } });
      }
    }
  });
});