define('client/mixins/csrf-token', ['exports', 'npm:lodash/merge'], function (exports, _merge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    cookies: inject.service(),
    csrfToken: undefined,
    init: function init() {
      this._super.apply(this, arguments);

      // Read the CSRF Token out from the Cookie.
      var csrfToken = get(this, 'cookies').read('csrftoken');
      if (!csrfToken) {
        throw new Error('No CSRF Token defined, "csrftoken" cookie probably not getting set');
      }
      set(this, 'csrfToken', csrfToken);

      // Set the 'headers' property, will merge with an parents headers if defined
      // Django normalises this to HTTP_X_CSRFTOKEN as requested by the docs. If we try and use that directly as the
      // header name, it breaks.  https://docs.djangoproject.com/en/1.11/ref/csrf/#setting-the-token-on-the-ajax-request
      set(this, 'headers', (0, _merge.default)({}, getWithDefault(this, 'headers', {}), {
        'X-CSRFToken': csrfToken
      }));
    }
  });
});