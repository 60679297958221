define('client/components/dynamic-form/view/restricted-rich-text/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',

    hideIfEmpty: computed('formElement.extendedAttributes.hideIfEmpty,stateValue', function () {
      var hideIfEmpty = get(this, 'formElement.extendedAttributes.hideIfEmpty');
      if (hideIfEmpty === undefined) {
        return false;
      }
      var value = get(this, 'stateValue');
      if (value == null || value === '') {
        return true;
      } else {
        if (hideIfEmpty) {
          return false;
        }
      }
    })
  });
});