define('client/components/component-workflows/documents/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    // parse the URL to determine the component to load and the document number
    path: computed('model', 'model.path', function () {
      var parts = get(this, 'model.path').split('/').filter(function (x) {
        return x;
      });
      var len = parts.length;
      var edit = parts[len - 1] === 'edit';
      var view = parts[len - 1] === 'view';
      var documentId = edit || view ? parts[len - 2] : null;
      return {
        edit: edit,
        view: view,
        documentId: documentId
      };
    }),

    subComponent: computed('path', function () {
      var path = get(this, 'path');
      return path.edit ? 'edit' : path.view ? 'view' : 'list';
    })
  });
});