define('client/mixins/routes/generic-workflows', ['exports', 'client/mixins/routes/edit-form-actions', 'client/mixins/routes/handle-workflow-navigation', 'client/mixins/routes/export-application', 'client/constants', 'ember-ajax/errors', 'client/libs/workflows'], function (exports, _editFormActions, _handleWorkflowNavigation, _exportApplication, _constants, _errors, _workflows) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Ember.Mixin.create(_editFormActions.default, _handleWorkflowNavigation.default, _exportApplication.default, {
    serialize: function serialize(_ref) {
      var path = _ref.path;

      return {
        path: path
      };
    },


    dynamicFormsMethods: inject.service(),
    workflowActions: inject.service(),
    resourceConsentRemoteMethods: inject.service(),
    resourceConsentLookups: inject.service(),

    sectionRoute: computed('routeName', function () {
      return get(this, 'routeName').split('.').shift();
    }),

    // Instead of setting the templateName directly, we override the renderTemplate
    // method to allow us to render different templates
    dynamicTemplateName: null,
    renderTemplate: function renderTemplate() {
      var template = getWithDefault(this, 'dynamicTemplateName', 'workflow/error');

      // If the mode is print-view, use a different template (append "-print" to the template).
      if (get(this, 'mode') === _constants.MODE.PRINT_VIEW) {
        template += '-print';
      }
      this.render(template);
    },


    mode: null, // View or Edit - set in the model
    workflowType: 'form',

    /**
     * This builds a list of all the workflows, needed to drive the sidebar menu
     * for multiple workflows.
     * @param workflows The workflows configuration object from the route.
     * @param path The tidied path from the URL.
     * @param parentId
     */
    getWorkflowMenus: function getWorkflowMenus(workflows, path, parentId, sectionRouteModel) {
      var _this = this;

      // Loop over all the workflows and build an array of objects like:
      // {
      //   active: true,
      //   type: 'form',
      //   parentFormInstanceId: 12345, (null for component workflows),
      //   menu: <menuObject>,
      //   title: 'Application details',
      //   config: <workflowConfigObject> (from the route)
      // }
      return Ember.RSVP.all(workflows.filter(function (wf) {
        return wf.visible !== false;
      }) // Don't attempt to get the menu for workflows that aren't visible
      .map(function (wf) {
        // Compare against the first segment of the path.
        var active = path.split('/')[0] === wf.path;

        // the default workflowType is form
        var workflowType = _workflows.workflowTypes[wf.type] || _workflows.workflowTypes['form'];

        var parentFormInstance = void 0,
            menu = void 0;

        var getHideWorkflow = function getHideWorkflow() {
          if (wf.visible !== undefined) {
            return Ember.RSVP.resolve(!wf.visible);
          }
          if (wf.hideWorkflow !== undefined) {
            return Ember.RSVP.resolve(wf.hideWorkflow.call(_this, { wf: wf, parentId: parentId, sectionRouteModel: sectionRouteModel }));
          } else {
            return Ember.RSVP.resolve(false);
          }
        };

        var permissionDenied = false;
        var notFound = false;

        return Ember.RSVP.resolve().then(function () {
          return workflowType.getFormInstance.call(_this, { wf: wf, parentId: parentId }).then(function (x) {
            // store the result in the `parentFormInstance` variable
            parentFormInstance = x;

            if (parentFormInstance.status === 'NOT_FOUND') {
              notFound = true;
            }

            // if the workflow hasn't been started and the user can't start it, flag it as permission denied
            if (parentFormInstance.status === 'PERMISSION_DENIED') {
              // console.log(`The ${wf.title} workflow (${wf.key}) is being hidden because you do not have permission to start it`)
              permissionDenied = true;
            }
          });
        }).then(function () {
          // if the workflow has been flagged as permission denied, we know that getMenu() will result in an error
          if (permissionDenied !== true) {
            return workflowType.getMenu.call(_this, { wf: wf, parentFormInstance: parentFormInstance, path: path, active: active }).then(function (x) {
              menu = x;
            }).catch(function (e) {
              // if getMenu() results in an error anyway, flag it as permission denied
              if ((0, _errors.isForbiddenError)(e)) {
                // console.log(`The ${wf.title} workflow (${wf.key}) is being hidden because you do not have permission to view it`)
                permissionDenied = true;
              }
            });
          }
        }).then(function () {
          return Ember.RSVP.hash({
            parentFormInstance: parentFormInstance,
            parentFormInstanceId: parentFormInstance.instanceId,
            menu: menu,
            active: active,
            workflowType: workflowType,
            title: wf.title,
            config: wf,
            hideWorkflow: getHideWorkflow(),
            permissionDenied: permissionDenied,
            notFound: notFound
          });
        });
      })).then(function (workflows) {
        // hide workflows if permission was denied, or if they're explicitly hidden
        return workflows.filter(function (_ref2) {
          var hideWorkflow = _ref2.hideWorkflow,
              permissionDenied = _ref2.permissionDenied,
              notFound = _ref2.notFound;
          return hideWorkflow !== true && permissionDenied !== true && notFound !== true;
        });
      });
    },
    model: function model(_ref3, transition) {
      var _this2 = this;

      var path = _ref3.path;

      this._super.apply(this, arguments);
      var sectionRoute = get(this, 'sectionRoute');
      var sectionRouteModel = this.modelFor(sectionRoute);
      var parentId = sectionRouteModel.parentId,
          mode = sectionRouteModel.mode,
          pageInfo = sectionRouteModel.pageInfo,
          tabConfig = sectionRouteModel.tabConfig;

      // Tidy up the path
      // e.g. trailing slashes or double slashes will be trimmed.

      path = path.split('/').filter(function (v) {
        return !!v;
      }).join('/');

      var currentTabKey = this.routeName.split('.').pop();
      var currentTabConfig = tabConfig.find(function (x) {
        return x.key === currentTabKey;
      });

      // Combine workflows sent from the server (using the get-tabs/ endpoint), with workflows defined in the route
      //  (these are typically defined in app/routes/[processing|consents]-section/*.js)
      // Workflows that haven't been defined on the server are ignored
      // Properties defined in the route override ones from the server
      //
      // Should be an array of objects with the following attributes:
      // key = the form template key
      // path = the URL path to match against
      // title = text to show in the page
      var workflows = currentTabConfig.workflows.map(function (wf) {
        return Object.assign({}, wf, _this2.workflows.find(function (w) {
          return w.path === wf.path;
        }) || {});
      });

      if (!workflows) {
        get(this, 'flashMessages.danger')('Unable to load workflow');
        console.error('Workflows not defined');
        this.transitionTo(sectionRoute, parentId);
      }

      var validModes = [_constants.MODE.VIEW, _constants.MODE.EDIT, _constants.MODE.PRINT_VIEW];
      if (validModes.indexOf(mode) < 0) {
        throw new Error('Unrecognised mode, valid modes are "' + validModes.join('", "') + '"');
      }

      // Edit form actions mixin uses this
      set(this, 'mode', mode);

      var queryparams = this.paramsFor(get(this, 'routeName'));

      // Pull data through for use in auto-save functionality
      var isDraft = get(queryparams, 'draft') === 'true' && mode === _constants.MODE.EDIT;
      var draftObsolete = isDraft && get(queryparams, 'obsolete') === 'true';

      var formRootRoute = get(transition, 'targetName');

      var workflowMenus = void 0,
          activeWorkflow = void 0,
          workflowModel = void 0;

      var isWorking = false;

      return Ember.RSVP.resolve().then(function () {
        // Get all the workflow menu information first.
        return _this2.getWorkflowMenus(workflows, path, parentId, sectionRouteModel).then(function (x) {
          workflowMenus = x;
        });
      }).then(function () {
        // First check that there is a workflow visible somewhere in the menu
        if (workflowMenus.length <= 0) {
          get(_this2, 'flashMessages.danger')('Unable to load tab');
          console.error('Could not find any visible workflows on the ' + sectionRoute + ' ' + path + ' tab');
          _this2.transitionTo(sectionRoute);
        }

        // Find the active workflow
        activeWorkflow = workflowMenus.find(function (wfm) {
          return wfm.active;
        });

        // If there is no active workflow, transition to the first visible one
        if (!activeWorkflow) {
          _this2.transitionTo(get(_this2, 'routeName'), parentId, 'view', workflowMenus[0].config.path);
          return;
        }

        // There is an active workflow, so return its model
        return Ember.RSVP.Promise.resolve().then(function () {
          // Then get the workflow-specific model
          var getWorkflowModel = activeWorkflow.workflowType.getModel;

          if (getWorkflowModel === undefined) {
            get(_this2, 'flashMessages.danger')('Unable to load workflow');
            console.error('Invalid workflow model');
            _this2.transitionTo(sectionRoute, parentId);
            return;
          }

          return getWorkflowModel.call(_this2, { activeWorkflow: activeWorkflow, parentId: parentId, path: path, transition: transition, pageInfo: pageInfo }).then(function (x) {
            workflowModel = x;
          });
        }).then(function () {
          // the templateName is set by the model, set dynamicTemplateName on the model
          // so that renderTemplate() can determine the template to render
          set(_this2, 'dynamicTemplateName', workflowModel.templateName);

          // extend the workflowModel with a some extra properties then pass it to the template
          var formModel = Object.assign(workflowModel, {
            mode: mode,
            path: path,
            workflows: workflowMenus,
            parentId: parentId,
            formRootRoute: formRootRoute,
            sectionRoute: sectionRoute,
            activeWorkflow: activeWorkflow,
            sectionRouteModel: sectionRouteModel,
            pageInfo: pageInfo,
            tabConfig: tabConfig,
            isWorking: isWorking
          });

          if (isDraft) {
            // If a draft is available for this form, retrieve it
            // Once retrieved, check draft is valid for this page
            // If not valid, return standard model
            // If valid, add draft state params to the model
            return get(_this2, 'dynamicFormsMethods').retrieveDraft().then(function (_ref4) {
              var instance = _ref4.instance;

              if (!instance) {
                return formModel;
              }

              var state = instance.state_json,
                  returnUrl = instance.return_url;

              var currentPath = window.location.pathname;

              if (returnUrl !== currentPath || !state) {
                return formModel;
              }

              return Object.assign(formModel, {
                isDraft: isDraft,
                draftObsolete: draftObsolete,
                draftState: state
              });
            });
          } else {
            return formModel;
          }
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);

      // call the afterModel hook defined in the workflow type above
      var workflowAfterModel = get(model, 'activeWorkflow.workflowType.afterModel');
      if (workflowAfterModel) {
        workflowAfterModel.call(this, model, transition);
      }
    },


    actions: {
      startWorkflow: function startWorkflow(model) {
        var _this3 = this;

        var parentId = model.parentId,
            activeWorkflow = model.activeWorkflow;

        var dynamicFormsMethods = get(this, 'dynamicFormsMethods');
        set(model, 'isWorking', true);
        return dynamicFormsMethods.retrieveFormInstance({
          parentId: parentId,
          formKey: activeWorkflow.config.key,
          startWorkflow: true
        }).then(function (formInstance) {
          // after starting a workflow, go straight into editing the newly created form
          get(_this3, 'dynamicFormsMethods').transitionToForm({ id: formInstance.formInstanceId, mode: 'edit' });
        });
      },


      /**
       * Runs a method in the workflow-actions.js service.
       *
       * Used for the actions in the actions menu.
       */
      workflowAction: function workflowAction(method, model) {
        var workflowActions = get(this, 'workflowActions');
        if (workflowActions[method] === undefined) {
          console.error('The workflowActions Ember service has no method ' + method);
          return;
        }
        workflowActions[method](model);
      },


      /**
       * Rerun the model hook.
       *
       * Useful in cases were you want to force a refresh. An example and the
       * only current use is to reload the menu after an item is deleted from
       * a form-link table (like Consents).
       */
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});