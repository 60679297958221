define('client/components/component-workflows/history/view/component', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),
    router: inject.service(),

    row_id: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var parts = get(this, 'model.formRootPath').split('/').filter(function (x) {
        return x;
      });
      set(this, 'row_id', parts[parts.length - 2]);
    },


    record: computed('row_id', function () {
      var promise = get(this, 'remoteMethods').getHistoryRecord(get(this, 'row_id')).then(function (result) {
        return result.data[0];
      });
      return _emberData.default.PromiseObject.create({ promise: promise });
    })
  });
});