define('client/components/dynamic-form/edit/read-only-remote-method/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    resourceConsentRemoteMethods: inject.service(),
    tagName: '',
    result: '',
    lastValue: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      // If the value hasn't changed, don't call the endpoint again.
      if (get(this, 'value') === get(this, 'lastValue')) {
        return;
      }
      set(this, 'lastValue', get(this, 'value'));

      // Calls a method on resourceConsentRemoteMethods with the value and displays the response.
      var resourceConsentRemoteMethods = get(this, 'resourceConsentRemoteMethods');
      var methodName = get(this, 'formElement.extendedAttributes.methodName');
      if (methodName && typeof resourceConsentRemoteMethods[methodName] === 'function') {
        resourceConsentRemoteMethods[methodName](get(this, 'value')).then(function (v) {
          return set(_this, 'result', v);
        });
      }
    }
  });
});