define('client/test-fixtures/demo-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'menu': {
      'id': 288,
      'problemDomainId': 1,
      'formInstanceId': 122,
      'name': 'application'
    },
    'forms': [{
      'id': 1,
      'formInstanceId': 1,
      'label': 'Demo Page 1',
      'isComplete': false
    }, {
      'id': 2,
      'formInstanceId': 2,
      'label': 'Demo Page 2',
      'isComplete': false
    }]
  };
});