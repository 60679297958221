define('client/components/modal-dialog/notify-application/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),

    loading: null,
    hideCloseButton: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'loading', false);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'loading', false);
      this._super.apply(this, arguments);
    },


    applicationNumber: computed('pageInfo.consentNumber', function () {
      return get(this, 'pageInfo.consentNumber') || 'Not yet specified';
    }),

    isReleasedForNotification: computed.alias('pageInfo.isReleasedForNotification'),

    modalHeading: computed('isReleasedForNotification', function () {
      return get(this, 'isReleasedForNotification') ? 'Stop notification of application' : 'Notify application';
    }),

    actions: {
      notifyApplication: function notifyApplication() {
        var _this = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').notifyApplication(get(this, 'pageInfo.applicationId')).then(function (response) {
          if (response.error) {
            get(_this, 'flashMessages.danger')('Unable to notify application' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this, 'flashMessages.success')('Application notified');
            get(_this, 'remoteMethods').clearProcessingPageInfoCache(get(_this, 'pageInfo.applicationId'));
            _this.get('setShowNotifyApplicationModal')(false);
            _this.refreshModel();
          }
        }).finally(function () {
          set(_this, 'loading', false);
        });
      },
      denotifyApplication: function denotifyApplication() {
        var _this2 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').denotifyApplication(get(this, 'pageInfo.applicationId')).then(function (response) {
          if (response.error) {
            get(_this2, 'flashMessages.danger')('Unable to stop notification of application' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this2, 'flashMessages.success')('Notification of application stopped');
            get(_this2, 'remoteMethods').clearProcessingPageInfoCache(get(_this2, 'pageInfo.applicationId'));
            _this2.get('setShowNotifyApplicationModal')(false);
            _this2.refreshModel();
          }
        }).finally(function () {
          set(_this2, 'loading', false);
        });
      }
    }
  });
});