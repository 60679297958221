define('client/components/tabs-group/tab-nav/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['c-tab-nav', 'h-clearfix'],
    classNameBindings: ['isCompact:c-tab-nav--compact']
  });
});