define('client/services/dynamic-forms-methods', ['exports', 'client/services/remote-methods', 'client/mixins/cache', 'client/libs/dynamic-forms-methods-lib', 'ember-ajax/errors'], function (exports, _remoteMethods, _cache, _dynamicFormsMethodsLib, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = _remoteMethods.default.extend(_cache.default, {
    contentType: 'application/json; charset=utf-8',
    populateFunctions: inject.service(),
    flashMessages: inject.service(),
    router: inject.service(),

    fetchInstance: function fetchInstance(instanceId) {
      var _this = this;

      var includeSubForms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      return this.request('/forms/fetch-instance/' + instanceId + '/' + String(includeSubForms)).then(function (form) {
        return Ember.RSVP.all([get(_this, 'populateFunctions').buildState(form.state, form.formElements), form]);
      }).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            state = _ref2[0],
            form = _ref2[1];

        set(form, 'state', state);
        return form;
      }).catch(function (error) {
        // Handle the user accessing a form instance they don't have access to.
        // This block would possibly be more appropriate within the routes
        // calling this method, but has been put here instead as it can be
        // defined in a single location and there's not currently any case
        // where we don't want to redirect in this scenario.
        if ((0, _errors.isForbiddenError)(error)) {
          var message = 'Either the application does not exist or you do not have permission to view it';
          get(_this, 'flashMessages.danger')(message);
          get(_this, 'router').transitionTo('home-section');
          return;
        }
        throw error;
      });
    },
    save: function save(data, formElements) {
      var _this2 = this;

      data.state = (0, _dynamicFormsMethodsLib.tidyData)(data.state);
      return this.put('/forms/save-instance', { data: JSON.stringify(data) }).then(function (form) {
        if (!formElements) return form.state;
        return get(_this2, 'populateFunctions').buildState(form.state, formElements);
      });
    },
    saveDraft: function saveDraft(data) {
      data.state = (0, _dynamicFormsMethodsLib.tidyData)(data.state);
      return this.put('/forms/save-draft-instance', { data: JSON.stringify(data) }).catch(function (e) {
        throw new Error('Unable to save draft');
      });
    },
    retrieveDraft: function retrieveDraft() {
      return this.request('/forms/get-draft-instance').catch(function (e) {
        throw new Error('Unable to retrieve draft instance for user');
      });
    },
    getFormDetails: function getFormDetails(formKey) {
      return this.request('/forms/get-forms/' + formKey);
    },


    /**
     * Retrieve the table row data for the children form-links of this form instance.
     * @param {String|Number} formInstanceId
     * @return {Promise<Object[]>}
     */
    getFormLinkTableData: function getFormLinkTableData(formInstanceId, formElementName) {
      var formInstanceFieldValueId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var formInstanceContainerId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      if (formInstanceFieldValueId == null && formInstanceContainerId == null) {
        return this.request('forms/form-link-table/' + formInstanceId + '/' + formElementName);
      } else {
        if (formInstanceContainerId == null) {
          return this.request('forms/form-link-table/' + formInstanceId + '/' + formElementName + '/F/' + formInstanceFieldValueId);
        } else {
          return this.request('forms/form-link-table/' + formInstanceId + '/' + formElementName + '/C/' + formInstanceContainerId);
        }
      }
    },


    /**
     * This fetches the instance_id of a sub-form attached to a dynamic form table
     * If the subform has not been inflated, then it will be reinflated first
     */
    getTableChildFormInstanceId: function getTableChildFormInstanceId(formElementName, formInstanceId, tableRowId) {
      return this.request('/forms/form-link-inflate-instance/' + formInstanceId + '/' + formElementName + '/' + tableRowId).then(function (result) {
        if (result.error) {
          throw new Error('Unable to get table child form instance');
        }
        return result.new_instance_id;
      });
    },


    /**
      * Deletes a row from a dynamic form table. If the row has not been
      * inflated, then it will be reinflated and then deleted (in order
      * to keep the data consistent)
      */
    deleteTableRow: function deleteTableRow(formElementName, formInstanceId, childFormInstanceId, tableRowId) {
      var _this3 = this;

      if (childFormInstanceId == null) {
        childFormInstanceId = '';
      }
      return this.post('/forms/form-link-table-delete-instance/' + formInstanceId + '/' + childFormInstanceId + '/' + formElementName + '/' + tableRowId).then(function (result) {
        if (result.error) {
          throw new Error('Unable to delete row');
        }
        var message = 'Record deleted';
        get(_this3, 'flashMessages.success')(message);
        return true;
      });
    },
    retrieveFormInstance: function retrieveFormInstance(_ref3) {
      var formKey = _ref3.formKey,
          parentId = _ref3.parentId,
          startWorkflow = _ref3.startWorkflow;

      return this.request('/forms/retrieve-form-instance-id/' + formKey + '/' + parentId + '/' + (startWorkflow === true ? 'true' : 'false'));
    },


    /**
     * This fetches a new form instance for a given versionId
     * If the `createChildInstances = true` arg is passed, then the server will generate instances for each immediate
     * child form.  E.g. create an application instance, and it also generates Applicant Details, Property Details, etc.
     * instances.
     * This is required, because the menu json only returns child instances.  If we just created the Application with no
     * children, there could be no menu.
     * @param {number} versionId
     * @param {boolean} createChildInstances
     * @returns {*|Promise|{title}}
     */
    createNewFormInstance: function createNewFormInstance(versionId) {
      var createChildInstances = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      return this.post('/forms/create-new-instance/' + versionId + '/' + String(createChildInstances));
    },
    createChildFormInstance: function createChildFormInstance(childVersionId, formInstanceId, parentsId, parentName, childFormName) {
      var flags = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;

      parentsId = parentsId || 'root';
      if (flags == null) {
        flags = '';
      }
      return this.post('/forms/create-child-instance/' + formInstanceId + '/' + childVersionId + '/' + parentsId + '/' + parentName + '/' + childFormName + '/' + flags).then(function (result) {
        return result.new_id;
      });
    },
    deleteInstance: function deleteInstance(id) {
      return this.delete('/forms/delete-instance/' + id);
    },
    fetchMenu: function fetchMenu(instanceId) {
      return this.request('/forms/fetch-menu/' + instanceId);
    },


    /**
     * Requests filtered data for components that pull their options back from the server
     * Caches the values locally so changes to state don't cause a whole bunch of requests
     * Cache gets cleared when the page is refreshed or after 15 mins.
     * If you open another application, the cache isn't cleared but it will generate a different cache key
     */
    fetchFilteredList: function fetchFilteredList(instanceId, filterKeyName, filterStateValue, query) {
      var cache = get(this, 'cache');
      var cacheKey = 'fetchFilteredList_' + [].concat(Array.prototype.slice.call(arguments)).join('_');
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/forms/fetch-filtered-list/' + instanceId + '/' + filterKeyName + '/' + filterStateValue + (query != null ? '?query=' + encodeURIComponent(query) : ''));
      cache.save(cacheKey, request);
      return request;
    },


    /**
     * Requests summarised error details for a form and any children
     * 29.06.20: Removed @deprecated tag and caching of the response
     * We always want the latest error summary for a given form instance
     */
    fetchErrorSummary: function fetchErrorSummary(instanceId) {
      return this.request('/forms/fetch-error-summary/' + instanceId);
    },


    /**
     * Transitions to a form.
     * If any of the following are not supplied, looks up the values for the current route.
     * @param {integer} id - A form instance id
     * @param {string} route - Ember route name, eg. 'consents-section.summary'
     * @param {integer} parentId - The parent id for the workflow - eg. an application_decision id
     * @param {string} mode - 'view', 'edit' .etc
     * @param {string} formRootPath - the workflow name, ie. the `path` variable defined in the router
     * @param {string} skipNavigationCheck - Set to true to skip the check for unsaved changes to the form state
     */
    transitionToForm: function transitionToForm() {
      var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          id = _ref4.id,
          route = _ref4.route,
          parentId = _ref4.parentId,
          mode = _ref4.mode,
          formRootPath = _ref4.formRootPath,
          skipNavigationCheck = _ref4.skipNavigationCheck;

      var currentRoute = Ember.getOwner(this).lookup('controller:application').currentPath;
      if (route === undefined) {
        route = currentRoute;
      }
      var controllerName = 'controller:' + currentRoute;
      var controller = Ember.getOwner(this).lookup(controllerName);
      var model = controller.model;
      var router = this.router;

      if (parentId === undefined) {
        parentId = model.parentId;
      }

      if (mode === undefined) {
        mode = model.mode;
      }
      if (formRootPath === undefined) {
        formRootPath = model.formRootPath;
      }
      if (skipNavigationCheck === true) {
        set(controller, 'skipNavigationCheck', true);
      }

      router.transitionTo(route, parentId, mode, [formRootPath, id].filter(function (x) {
        return x !== undefined;
      }).join('/'));
    },


    /**
     * Makes a request to the server to get a list of history records
     */
    getHistoryTable: function getHistoryTable(parentId, parentIdType, targetIdType) {
      var sortField = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'created_timestamp';
      var sortDirection = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'DESC';

      var query = {
        'parent_row_id': parentId,
        'parent_row_type': parentIdType,
        'target_row_type': targetIdType,
        'sort_field': sortField,
        'sort_direction': sortDirection
      };
      return this.fetch('forms/history-table', query);
    },
    getPublicSubmissionData: function getPublicSubmissionData(applicationId) {
      return this.request('/forms/public-submission-data/' + applicationId);
    },
    setAmendmentEnableState: function setAmendmentEnableState(parentId, flag) {
      return this.put('/forms/update-amendment-enable-state/' + parentId + '/' + flag);
    },


    /**
     * Fetch the public submission form state
     * Currently the only state information we need is the submitted state
     */
    fetchPublicSubmissionFormState: function fetchPublicSubmissionFormState(instanceId) {
      return this.request('/forms/public-submission-form-state/' + instanceId);
    },


    /**
     * Get the current MODE for the given route
    */
    getCurrentMode: function getCurrentMode(route) {
      var currentRoute = Ember.getOwner(this).lookup('controller:application').currentPath;
      if (!route) {
        route = currentRoute;
      }
      var controllerName = 'controller:' + currentRoute;
      var controller = Ember.getOwner(this).lookup(controllerName);
      var model = controller.model;
      return model.mode;
    }
  });
});