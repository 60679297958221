define('client/components/dynamic-form/dialogues/handle-draft-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    dynamicFormsMethods: inject.service(),

    closeModal: function closeModal() {
      set(this, 'displayModal', false);
    },


    actions: {
      discard: function discard(callback) {
        set(this, 'displayModal', false);

        callback && callback();
      },
      save: function save(callback) {
        // Copy state to form state
        var state = get(this, 'formState');
        set(this, 'form.state', state);

        // Trigger save
        get(this, 'save')(state);
        set(this, 'displayModal', false);

        callback && callback();
      }
    }
  });
});