define('client/components/component-workflows/documents/edit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      set = Ember.set,
      computed = Ember.computed;


  var noop = function noop() {};

  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),
    router: inject.service(),

    document: {},
    state: {},

    downloadLink: computed('documentId', function () {
      return '<p><a href="/api/documents/download?id=' + get(this, 'documentId') + '">Download this document</p>';
    }),

    formElements: computed('tabLocationOptions', 'downloadLink', function () {
      // This is essentially dynamic forms configuration JSON. It gets passed to the
      // dynamic-forms/[view|edit]/manage-form-elements component in order to render the form.
      return [{ name: 'page_heading', markup: 'Document', type: 'page-heading', readOnly: true }, { name: 'downloadLink', type: 'inline-help', markup: get(this, 'downloadLink'), extendedAttributes: { showInView: true } }, { name: 'document_title', label: 'Title', type: 'text', readOnly: true }, { name: 'author', label: 'Author', type: 'text', readOnly: true }, { name: 'creation_date', label: 'Creation date', type: 'date', readOnly: true }, { name: 'file_type', label: 'Type', type: 'text', readOnly: true }, { name: 'trim_reference', label: 'Content Manager document number', type: 'text', readOnly: true }, { name: 'tab_location_id', label: 'Location this document is stored against', type: 'select', options: get(this, 'tabLocationOptions') }];
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var documentId = get(this, 'documentId');
      var parentId = get(this, 'model.parentId');
      // the tab location code is either set explicitly in the config for this workflow or we can just use the formRootRoute
      var tabLocationCode = get(this, 'model.activeWorkflow.config.tabLocationCode') || get(this, 'model.formRootRoute');
      // consents and permits section uses cap_decision_id, everything else uses application_id so default to that
      var parentIdType = getWithDefault(this, 'model.activeWorkflow.config.parentIdType', 'application_id');

      // Create the initial state to pass to the manage-form-elements component.
      // All the documents for this tab should be cached, so grab the details from there
      // we also need to get the list of tab locations for the tab location drop-down
      Ember.RSVP.hash({
        documentList: get(this, 'remoteMethods').documentList(parentId, parentIdType, tabLocationCode),
        documentTabLocations: get(this, 'remoteMethods').documentTabLocations(tabLocationCode)
      }).then(function (_ref) {
        var _ref$documentList = _ref.documentList,
            rows = _ref$documentList.rows,
            canEdit = _ref$documentList.canEdit,
            options = _ref.documentTabLocations.options;

        set(_this, 'tabLocationOptions', options);

        var documents = rows.filter(function (row) {
          return String(row.id) === String(documentId);
        });
        var document = documents.length === 1 ? documents[0] : {};
        set(_this, 'document', document);

        if (documents.length !== 1) {
          get(_this, 'flashMessages.danger')('Document not found.');
        }

        var state = {};
        Object.keys(document).forEach(function (property) {
          state[property] = [{ val: document[property] }];
        });
        set(_this, 'state', state);
      });
    },


    actions: {
      save: function save(state) {
        var _this2 = this;

        // pass the state generated by the dynamic forms elements to a custom REST endpoint
        get(this, 'remoteMethods').documentUpdate(state).then(function (response) {
          if (response.error !== false) {
            return Promise.reject(new Error('error'));
          }
          get(_this2, 'router').transitionTo(response['tab_location_code'], 'documents/' + get(_this2, 'documentId') + '/view');
          get(_this2, 'flashMessages.success')('Document saved.');
        }).catch(function () {
          get(_this2, 'flashMessages.danger')('There was a problem saving this document.');
        });
      },

      delete: noop,
      updateRootState: noop,
      refreshModel: noop,
      displayUnsavedChanges: noop,
      updateStateKey: function updateStateKey(key, childState) {
        set(this, 'state.' + key, childState);
      }
    }
  });
});