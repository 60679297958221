define('client/components/home-section/dialogues/create-new-application/component', ['exports', 'client/constants', 'client/config/environment'], function (exports, _constants, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    downloadableApplications: _constants.DOWNLOADABLE_APPLICATIONS,
    creatingApplication: false,
    councilInfo: Ember.computed(function () {
      return getWithDefault(_environment.default, 'ENV_VARS.COUNCIL_INFO', {});
    }),
    showAllApplicationTypes: false,
    displayApplicationTypes: computed('showAllApplicationTypes', function () {
      var applicationTypes = get(this, 'applicationTypes');
      if (this.showAllApplicationTypes) return applicationTypes;
      return applicationTypes.slice(0, 8);
    }),
    applicationsComingSoon: [{ description: 'Fast Track Resource Consent application', section: 'Section 88' }, { description: 'Objection against certain decisions or requirements', section: 'Section 357A' }, { description: 'Objection to imposition of additional charges or recovery of costs', section: 'Section 357B' }, { description: 'Certificate of Compliance', section: 'Section 139' }, { description: 'Certificate of Existing Use', section: 'Section 139A' }, { description: 'Designation application', section: 'Section 168/168A' }, { description: 'Designation alteration or removal', section: 'Section 168/168A' }, { description: 'Outline Plan waiver', section: 'Section 176a' }, { description: 'Change or cancel consent notice condition', section: 'Section 221' }, { description: 'Transfer of Abstraction Site', section: 'Section 136' }],
    actions: {
      /**
       * Closes the modal.
       * Needs to be done locally rather than using a mut helper like elsewhere so we can allow propogation of the click
       * using `preventDefault=false` in the template, in order to get the link to actually open
       */
      closeModal: function closeModal() {
        set(this, 'displayModal', false);
      },
      newApplication: function newApplication(applicationTypeId) {
        // Creating a new form can take a second or so, and we don't want the user to think nothing is happening
        // Set a flag so we can initiate some UI notification
        set(this, 'creatingApplication', true);
        get(this, 'newApplication')(applicationTypeId);
      },
      toggleDisplayApplicationList: function toggleDisplayApplicationList() {
        set(this, 'showAllApplicationTypes', !this.showAllApplicationTypes);
      }
    }
  });
});