define('client/components/home-section/reporting-dashboard/open-applications/filter/pill-collapsed/component', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var OPERATOR_LABELS = {
    '>': 'greater than',
    '<': 'less than',
    '>=': 'greater than or equal to',
    '<=': 'less than or equal to',
    '=': 'is',
    '@@': 'for',
    'ilike': 'like',
    'ilike-bar': 'is',
    'in': 'is'
  };

  exports.default = Ember.Component.extend({
    remoteMethods: Ember.inject.service('generic-search-remote-methods'),

    // Converts a single value to a string. Can optionally return a promise.
    valueToString: function valueToString(value) {
      var filterKey = this.filter.filterKey;

      var _ref = this.filterTemplate.find(function (filter) {
        return filter.filterKey === filterKey;
      }) || {},
          _ref$optionsKey = _ref.optionsKey,
          optionsKey = _ref$optionsKey === undefined ? '' : _ref$optionsKey;

      if (optionsKey) {
        return this.remoteMethods.getLookupDisplayValue({
          searchTypeKey: this.searchTypeKey,
          optionsKey: optionsKey,
          internalValue: value
        }).then(function (_ref2) {
          var lookupDisplayValueResult = _ref2.lookupDisplayValueResult;
          return lookupDisplayValueResult.displayValue || value;
        }).catch(function () {
          return value;
        });
      } else {
        return Ember.RSVP.Promise.resolve(value);
      }
    },


    displayFilter: computed('filter', 'filterTemplate', function () {
      var _this = this;

      var _get = this.get('filter'),
          filterKey = _get.filterKey,
          filterValue = _get.filterValue;

      var _get$find = this.get('filterTemplate').find(function (filter) {
        return filter.filterKey === filterKey;
      }),
          filterOperator = _get$find.filterOperator,
          label = _get$find.label;

      var operator = OPERATOR_LABELS[filterOperator] || filterOperator;
      var promise = this.valueToString(filterValue).then(function (value) {
        return Object.assign({}, _this.filter, { operator: operator, label: label, value: value });
      });
      return _emberData.default.PromiseObject.create({ promise: promise });
    })
  });
});