define('client/test-fixtures/demo1', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'formTemplate': {
      id: 1
    },
    'formElements': [{
      type: 'page-heading',
      markup: 'Demo Page 1'
    }, {
      type: 'section-heading',
      markup: 'Section 1'
    }, {
      type: 'sub-heading',
      markup: 'Sub Heading 1'
    }, {
      type: 'section',
      name: 'personDetails',
      repeatable: true,
      numAutoRepeats: 1,
      formElements: [{
        type: 'text',
        name: 'personName',
        label: 'Person\'s name'
      }, {
        type: 'number',
        name: 'personAge',
        label: 'Person\'s age'
      }]
    }, {
      type: 'select',
      name: 'favouriteShow',
      label: 'Favourite Show',
      options: [{ value: '1', label: 'The Simpsons' }, { value: '2', label: 'Family Guy' }, { value: '3', label: 'Futurama' }, { value: '4', label: 'American Dad' }]
    }, {
      type: 'radio',
      name: 'testRadio',
      label: 'Your favourite book:',
      repeatable: true,
      numAutoRepeats: 1,
      options: [{ value: '1', label: 'Ready Player One' }, { value: '2', label: 'I, Lucifer' }, { value: '3', label: 'Hey Nostradamus' }]
    }, {
      'name': 'enterLocale',
      'label': 'I want to enter the locale of the site',
      'type': 'checkbox'
    }, {
      'name': 'someOptions',
      'label': 'Some options',
      'type': 'checkbox-group',
      'formElements': [{
        'type': 'checkbox',
        'name': 'someName',
        'label': 'Some label'
      }, {
        'type': 'checkbox',
        'name': 'someName2',
        'label': 'Some label 2'
      }]
    }],
    state: {
      personDetails: [{
        val: {
          personName: [{ id: 'new', val: 'Peter Griffin' }],
          personAge: [{ id: 'new', val: '42' }]
        }
      }, {
        val: {
          personName: [{ id: 'new', val: 'Stewie Griffin' }],
          personAge: [{ id: 'new', val: '1' }]
        }
      }],
      favouriteShow: [{ val: '2' }],
      testLocation: [{
        val: {
          easting: [{ val: '1234.45' }],
          northing: [{ val: '4321.45' }]
        }
      }],
      testRadio: [{ val: '2' }],
      enterLocale: [{ val: true }],
      someOptions: [{
        val: {
          someName2: [{ val: true }]
        }
      }]
    }
  };
});