define('client/components/home-section/dashboard/applications-table/edit-columns/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['h-hide-for-print'],
    displayColumnEditor: false,
    columnsForEdit: [],
    dashboardService: inject.service('dashboard'),
    modalSubHeading: null,

    columnsLength: computed('columnsForEdit', function () {
      return get(this, 'columnsForEdit').length;
    }),

    columnsLengthIndex: computed('columnsForEdit', function () {
      return get(this, 'columnsForEdit').length - 1;
    }),

    dashboardTableName: computed('columnsForEdit', function () {
      return get(this, 'columnsForEdit')[0]['dashboard_table_name'];
    }),

    modalSubheadingObserver: observer('displayColumnEditor', function () {
      var tableName = get(this, 'columnsForEdit')[0];
      if (tableName === undefined) {
        return '';
      }
      tableName = tableName['dashboard_table_name'];

      if (tableName === 'lib_dashboard_profile_column:assigned_to_me') {
        set(this, 'modalSubHeading', 'Applications assigned to me');
      } else if (tableName === 'lib_dashboard_profile_column:unassigned') {
        set(this, 'modalSubHeading', 'Unassigned applications');
      } else if (tableName === 'lib_dashboard_profile_column:submitted') {
        set(this, 'modalSubHeading', 'Submitted applications');
      } else if (tableName === 'lib_dashboard_profile_column:draft') {
        set(this, 'modalSubHeading', 'Applications in draft');
      } else {
        set(this, 'modalSubHeading', 'Not sent to issued consents');
      }
    }),

    actions: {
      setVisible: function setVisible(index) {
        var row = get(this, 'columnsForEdit').objectAt(index);
        set(row, 'visible', true);
      },
      setInvisible: function setInvisible(index) {
        var row = get(this, 'columnsForEdit').objectAt(index);
        set(row, 'visible', false);
      },
      moveUp: function moveUp(index) {
        var columnsForEdit = get(this, 'columnsForEdit');
        if (index >= 0) {
          var el = columnsForEdit[index];
          columnsForEdit[index] = columnsForEdit[index - 1];
          columnsForEdit[index - 1] = el;
        }
        set(this, 'columnsForEdit', null); // Required for ember refresh
        set(this, 'columnsForEdit', columnsForEdit);
      },
      moveDown: function moveDown(index) {
        var columnsForEdit = get(this, 'columnsForEdit');
        if (index !== -1 && index < columnsForEdit.length - 1) {
          var el = columnsForEdit[index];
          columnsForEdit[index] = columnsForEdit[index + 1];
          columnsForEdit[index + 1] = el;
        }
        set(this, 'columnsForEdit', null); // Required for ember refresh
        set(this, 'columnsForEdit', columnsForEdit);
      },
      resetChanges: function resetChanges() {
        var _this = this;

        get(this, 'dashboardService').resetDashboardTable(get(this, 'dashboardTableName').replace('lib_dashboard_profile_column:', '')).then(function (response) {
          if (response.error === false) {
            set(_this, 'refreshTrigger', Math.random());
            get(_this, 'flashMessages.success')('Profile reset.');
          } else {
            get(_this, 'flashMessages.danger')('Unable to reset profile.');
          }
        });
      },
      saveChanges: function saveChanges() {
        var _this2 = this;

        var columns = get(this, 'columnsForEdit');

        for (var index = 0; index < columns.length; index++) {
          set(get(this, 'columnsForEdit').objectAt(index), 'assigned_sequence', index + 1);
        }

        var json = JSON.stringify(columns);

        get(this, 'dashboardService').setDashboardTable(json).then(function (response) {
          if (response.error === false) {
            set(_this2, 'refreshTrigger', Math.random());
            get(_this2, 'flashMessages.success')('Profile saved.');
          } else {
            get(_this2, 'flashMessages.danger')('Unable to save profile.');
          }
        });
      },
      returnToView: function returnToView() {
        set(this, 'displayColumnEditor', false);
      }
    }
  });
});