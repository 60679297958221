define('client/services/display-functions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Service.extend({
    gateKeeper: inject.service(),

    /**
     * Proxies the gatekeeper `can` method
     * @param {string} permission
     * @returns boolean
     */
    can: function can(permission) {
      return get(this, 'gateKeeper').can(permission);
    }
  });
});