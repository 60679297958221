define('client/services/workflow-actions', ['exports', 'client/services/remote-method', 'client/mixins/cache'], function (exports, _remoteMethod, _cache) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = _remoteMethod.default.extend(_cache.default, {
    flashMessages: inject.service(),
    resourceConsentRemoteMethods: inject.service(),
    dynamicFormsMethods: inject.service(),
    councilServices: inject.service(),

    sendAssignedProcessingOfficerNotification: function sendAssignedProcessingOfficerNotification(_ref) {
      var _this = this;

      var parentId = _ref.parentId;

      // assume this is called from the processing section so parentId should be an application.application id
      get(this, 'resourceConsentRemoteMethods').sendAssignedProcessingOfficerNotification(parentId).then(function (_ref2) {
        var emailAddress = _ref2.emailAddress,
            error = _ref2.error,
            message = _ref2.message;

        if (error === false) {
          get(_this, 'flashMessages.success')('Sending notification to ' + emailAddress);
        } else {
          get(_this, 'flashMessages.danger')(message !== undefined ? message : 'There was a problem sending the notification.');
        }
      }).catch(function () {
        get(_this, 'flashMessages.danger')('There was a problem sending the notification.');
      });
    },


    /**
     * Send a request to create TRIM Document folders for this application.
     *
     * @param parentId Should be an application.application ID
     * @return {Promise} Resolves once the server responses.
     */
    createTrimFolders: function createTrimFolders(_ref3) {
      var _this2 = this;

      var parentId = _ref3.parentId;

      return get(this, 'councilServices').createTrimFolders(parentId).then(function (_ref4) {
        var error = _ref4.error,
            message = _ref4.message;

        if (error === false) {
          get(_this2, 'flashMessages.success')('Creation request sent');
        } else {
          get(_this2, 'flashMessages.danger')(message !== undefined ? message : 'There was a problem creating the folders');
        }
      }).catch(function () {
        get(_this2, 'flashMessages.danger')('There was a problem creating the folders');
      });
    },


    /**
     * Delete public submission.
     *
     * @param id Form instance id of the public submission
     * @return {Promise} Resolves once the server responses.
     */
    deletePublicSubmission: function deletePublicSubmission(_ref5) {
      var _this3 = this;

      var id = _ref5.id;

      return get(this, 'resourceConsentRemoteMethods').deletePublicSubmission(id).then(function (response) {
        if (response.error) {
          get(_this3, 'flashMessages.danger')(response.message ? response.message : 'There was a problem deleting the submission');
        } else {
          get(_this3, 'flashMessages.success')('Submission deleted');
          get(_this3, 'dynamicFormsMethods').transitionToForm({
            route: 'processing-section.notification',
            formRootPath: 'notification-submissions',
            mode: 'view',
            skipNavigationCheck: true
          });
        }
      }).catch(function () {
        get(_this3, 'flashMessages.danger')('There was a problem deleting the submission');
      });
    }
  });
});