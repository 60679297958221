define('client/mixins/components/workflow-section', ['exports', 'client/constants', 'npm:lodash/find'], function (exports, _constants, _find) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Mixin.create({
    router: inject.service(),

    /**
     * default values for page information
     */
    pageInfo: {
      applicationType: 'Application',
      consentTypes: []
    },

    /**
     * The crumb for the currently selected tab.
     */
    currentTabCrumb: computed('router.currentRouteName', 'mode', 'tabConfig', function () {
      var currentRouteName = get(this, 'router.currentRouteName');
      var mode = get(this, 'mode');

      var tabConfig = get(this, 'tabConfig');
      if (tabConfig === undefined) {
        throw new Error('tabConfig property not found on workflow-section component.');
      }

      // find the config for this tab (defined in constants.js)
      var key = currentRouteName.split('.').pop();
      var tab = (0, _find.default)(tabConfig, { key: key });

      if (!tab) {
        return;
      }

      // prepend "View" or "Edit" to the tab title
      var namePrefix = _constants.BREADCRUMB_PREFIX[mode];
      var name = namePrefix === undefined ? tab.title : namePrefix + (tab.title || '').toLowerCase();

      return {
        name: name,
        id: key
      };
    }),

    /**
     * Returns currently active route (used for styling active menu item).
     */
    currentRoute: computed('router.currentRouteName', function () {
      return get(this, 'router.currentRouteName');
    })
  });
});