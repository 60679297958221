define('client/mixins/components/form-link-table-fetch-data-sorting', ['exports', 'npm:lodash/sortBy', 'moment', 'client/config/environment'], function (exports, _sortBy, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      setProperties = Ember.setProperties;
  exports.default = Ember.Mixin.create({

    sortedRows: computed('tableData.data', 'tableData.metadata', 'dir', 'sort', function () {
      var data = get(this, 'tableData.data');
      var metadata = get(this, 'tableData.metadata');
      var columnKey = get(this, 'sort');
      var dir = get(this, 'dir');

      if (!columnKey || !dir) return data;

      var type = metadata.find(function (item) {
        return item.elementKey === columnKey;
      })['type'];
      var sortedRows = void 0;
      if (type === 'date') {
        // Convert to unix time for sorting, for reliable parsing specify the date format.
        sortedRows = (0, _sortBy.default)(data, function (item) {
          return (0, _moment.default)(item[columnKey], _environment.default.dateFormat).unix();
        });
      } else {
        // Default to plain string sort.
        sortedRows = (0, _sortBy.default)(data, function (item) {
          return item[columnKey];
        });
      }

      if (dir === 'desc') {
        sortedRows.reverse();
      }

      return sortedRows;
    }).readOnly(),

    sortDisabled: computed.alias('formElement.extendedAttributes.disableSorting'),

    actions: {
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          setProperties(this, {
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath')
          });

          get(this, 'setRows').perform();
        }
      }
    }
  });
});