define('client/services/session', ['exports', 'ember-data', 'client/services/remote-method'], function (exports, _emberData, _remoteMethod) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed,
      NAME_KEY = Ember.NAME_KEY,
      get = Ember.get,
      set = Ember.set;


  var CurrentUserProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  CurrentUserProxy[NAME_KEY] = 'current-user';

  var POLLING_INTERVAL = 30; // Check for session expiry every n seconds.

  /**
   * Sets the user for Raygun.
   *
   * @param userData As returned from the current-user endpoint.
   */
  var setRaygunUser = function setRaygunUser(userData) {
    var fullName = '';
    if (userData.firstName && userData.lastName) {
      fullName = userData.firstName + ' ' + userData.lastName;
    }
    Raygun.setUser(userData.username, // id
    false, // is anonymous
    userData.email, // email
    fullName, // full name
    userData.firstName // first/preferred name
    );
  };

  /**
   * Manages the user session for the client app.
   */
  exports.default = _remoteMethod.default.extend({
    store: inject.service(),
    username: computed.alias('currentUser.username').readOnly(),
    allowedActions: computed.alias('currentUser.allowedActions').readOnly(),
    roleDisplayName: computed.alias('currentUser.roleDisplayName').readOnly(),
    isAuthenticated: computed.bool('username'),
    currentAuth: computed.alias('currentUser.currentAuth').readOnly(),
    isStaffAuth: computed.alias('currentUser.isStaffAuth').readOnly(),
    returnTo: '',

    /**
     * externalLinkInfo - Basic cached list of links used in the
     * menu.
     */

    externalLinkInfo: computed(function () {
      var promise = this.request('/resource-consent-applications/external-link-info').then(function (linkData) {
        return linkData.data;
      }).catch(function (err) {
        return err;
      });
      return _emberData.default.PromiseObject.create({ promise: promise });
    }).readOnly(),

    /**
     *  councilInfo - Basic cached information that identifies the council that
     *  this system is running on behalf of. Uses same attribute names
     *  as the substitution tag names used in the forms, e.g.
     *  shortName, longName, region, etc.
     */

    councilInfo: computed(function () {
      var promise = this.request('/resource-consent-applications/council-info').then(function (councilData) {
        return councilData.data;
      }).catch(function (err) {
        return err;
      });
      return _emberData.default.PromiseObject.create({ promise: promise });
    }).readOnly(),

    /**
     * A promise which resolves once the session user data is loaded.
     */
    loaded: computed('session.currentUser.promise', function () {
      return get(this, 'session.currentUser.promise').then(function () {
        return true;
      });
    }),

    /**
     * Retrieves a User object from our API and makes it available to the client app.
     */
    currentUser: computed(function () {
      return CurrentUserProxy.create({
        promise: this.request('/current-user').then(function (userData) {
          if (userData) {
            setRaygunUser(userData);
          }
          return userData;
        }).catch(function (err) {
          return err;
        })
      });
    }).readOnly(),

    /**
     * Check how long the client has until their session expires on the server
     */
    init: function init() {
      // Start polling
      this._super.apply(this, arguments);
      this.tick();
    },

    /**
     * Stores the current timeout.
     */
    currentSessionPollTimeout: null,

    /**
     * Clear the set timeout in case of error during session expiry check
     * The session expiry endpoint should ideally only send an error response
     * if the API itself is no longer available
     */
    cancelTick: function cancelTick() {
      var currentSessionPollTimeout = get(this, 'currentSessionPollTimeout');
      if (currentSessionPollTimeout) {
        clearTimeout(currentSessionPollTimeout);
      }
    },


    /**
     * Poll the `session-expiry` endpoint every `POLLING_INTERVAL` seconds.
     *
     * Call `sessionExpired()` if the session has expired
     *
     * Clear timeout if `session-expiry` gives an error response
     */
    tick: function tick() {
      var self = this;
      var timeout = setTimeout(function () {
        self.request('/session-expiry/session-expiry').then(function (sessionExpiry) {
          var expiresIn = sessionExpiry['expires_in'];
          if (expiresIn < 0 && get(self, 'isAuthenticated')) {
            self.sessionExpired();
          }
        }).then(function () {
          return self.tick();
        }).catch(function () {
          return self.cancelTick();
        });
      }, POLLING_INTERVAL * 1000);
      set(this, 'currentSessionPollTimeout', timeout);
    },


    /**
     * Handle Session Expiry.
     *
     * Go back to the landing page.
     * `returnTo` is set to return the user to the page they were on before their session expired.
     * `sessionExpired` is set to display a notification to the user on the landing page.
     *
     * Will also clear an outstanding poll timeout to prevent errors.
     */
    sessionExpired: function sessionExpired() {
      this.cancelTick();
      window.location.assign('/?sessionExpired=true&returnTo=' + encodeURIComponent(window.location.pathname));
    },


    /**
     * Redirect to the server API's login handler.
     *
     * If there's a returnTo location set add that as a query param.
     */
    login: function login(providerName) {
      var returnTo = get(this, 'returnTo');
      var queryString = returnTo ? '?returnTo=' + encodeURIComponent(returnTo) : '';
      if (providerName === undefined) {
        window.location.assign('/auth/login' + queryString);
      } else {
        window.location.assign('/' + providerName + '-auth/login' + queryString);
      }
    },


    /**
     * Redirect to the server API's staff login handler.
     *
     * If there's a returnTo location set add that as a query param.
     */
    staff_login: function staff_login() {
      var returnTo = get(this, 'returnTo');
      var queryString = returnTo ? '?returnTo=' + encodeURIComponent(returnTo) : '';
      window.location.assign('/staff/auth/login' + queryString);
    },


    /**
     * Redirect to the server API's logout handler.
     */
    logout: function logout() {
      var isStaffAuth = get(this, 'isStaffAuth');
      if (isStaffAuth) {
        window.location.assign('/staff/auth/logout');
      } else {
        window.location.assign('/' + get(this, 'currentUser.currentAuth') + '/logout');
      }
    },


    /**
     * Redirect to the server API's change password handler.
     * Not supported for staff logins
     */
    changePassword: function changePassword() {
      window.location.assign('/' + get(this, 'currentUser.currentAuth') + '/change-password');
    },


    /**
     * Go to the admin page.
     */
    gotoAdmin: function gotoAdmin() {
      window.location.assign('/admin/');
    }
  });
});