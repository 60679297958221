define('client/components/actions-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Component.extend({
    classNames: ['c-actions-menu', 'h-hide-for-print'],
    classNameBindings: ['isOpen:c-actions-menu--open'],
    isOpen: false,
    actions: {
      toggleActionsMenu: function toggleActionsMenu() {
        this.toggleProperty('isOpen');
      },
      closeActionsMenu: function closeActionsMenu() {
        set(this, 'isOpen', false);
      }
    }
  });
});