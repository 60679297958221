define('client/mixins/controllers/generic-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    remoteMethods: inject.service('generic-search-remote-methods'),
    genericSearch: inject.service('generic-search'),
    searchTemplate: null,
    q: null,
    queryParamEnabled: false,

    queryParams: {
      q: {
        as: 'q',
        scope: 'router',
        replace: true
      }
    },

    refinflateQuery: null,

    preventReinflation: false,
    reinflateQueryObserver: Ember.observer('q', function () {
      if (!this.preventReinflation && this.q !== null) {
        var searchQuery = {
          filterSet: {
            booleanOperator: 'AND',
            filters: this.q ? [{
              filterSet: {
                booleanOperator: 'OR',
                filters: [{
                  filterKey: 'omniSearch',
                  filterOperator: '@@',
                  filterValue: this.q
                }]
              }
            }] : []
          }
        };
        this.genericSearch.saveFilterSet(this.searchTypeKey); // clear any existing filter set saved to the generic search service (RMA-244)
        set(this, 'reinflateQuery', searchQuery);
      }
      set(this, 'preventReinflation', false);
    }),

    onSearchQueryChange: function onSearchQueryChange(searchQuery) {
      set(this, 'q', null);
    }
  });
});