define('client/components/home-section/component', ['exports', 'npm:lodash/find', 'npm:lodash/throttle', 'client/constants'], function (exports, _find, _throttle, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({

    router: inject.service(),
    gateKeeper: inject.service(),
    session: inject.service(),
    messages: inject.service(),

    tabs: computed.alias('model.tabs'),

    maintenanceWindowsPromise: computed.alias('model.maintenanceWindowsPromise'),

    hideNav: computed('router.currentRouteName', function () {
      var _this = this;

      return _constants.HIDE_NAV_ROUTES.some(function (v) {
        return _this.router.currentRouteName === v;
      });
    }),

    currentPath: computed.alias('router.currentRouteName'),

    currentTab: computed('tabs', 'currentPath', function () {
      return (0, _find.default)(get(this, 'tabs'), { path: get(this, 'currentPath') });
    }),

    isAuthenticated: computed.alias('model.isAuthenticated'),

    crumbs: computed('currentTab', function () {
      return [_constants.TOP_LEVEL_BREADCRUMB, { name: get(this, 'currentTab.title') }];
    }),

    // 21/11/2018 Throttling this function to stop it from constantly hitting the messages endpoint. Will probably need to change this to poll the messages endpoint once full alert functionality is built out.
    didRender: (0, _throttle.default)(function () {
      var _this2 = this;

      // Show the Django messages as flashMessages
      get(this, 'messages').getMessages().then(function (data) {
        if (data.messages !== undefined && data.messages.length > 0) {
          // This supports only one message
          var message = data.messages[0];
          var level = message.level;

          // Adhoc solution: The template doesn't support .error CSS class
          // but, it supports .info and .warning CSS classes
          if (message.level === 'error') {
            level = 'danger';
          }

          // Keep the message for 5 seconds
          get(_this2, 'flashMessages.' + level)(message.message, { timeout: 5000 });
        }
      });
    }, 5000)
  });
});