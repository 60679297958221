define('client/libs/history-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;


  /*
   * functions used in the history workflow component and route
   */

  var pageTitleMap = {
    'default': 'Processing history',
    'processing-section.history': 'Processing history',
    'consents-section.history': 'Consents history'

    // get the page title from the model
  };var getPageTitle = function getPageTitle(routeName) {
    if (routeName.activeWorkflow !== undefined) {
      return routeName.activeWorkflow.title;
    }
    return pageTitleMap[routeName] || pageTitleMap.default;
  };

  // get the menu JSON from the model
  var getMenu = function getMenu(_ref) {
    var path = _ref.path,
        active = _ref.active;

    var routeName = get(this, 'fullRouteName');
    return {
      forms: [{
        aliasForParent: true, // required to get the label to display
        complete: false,
        label: getPageTitle(routeName),
        active: active
      }]
    };
  };

  exports.default = {
    getMenu: getMenu,
    getPageTitle: getPageTitle
  };
});