define('client/services/resource-consent-lookups', ['exports', 'client/services/remote-method', 'client/mixins/cache'], function (exports, _remoteMethod, _cache) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = _remoteMethod.default.extend(_cache.default, {

    cacheLengthSec: 60,
    dynamicFormsMethods: inject.service(),
    gateKeeper: inject.service(),

    /**
       * @deprecated
       *
       * Gets the list of consent types for populating a drop-down list
       *
       * @return {Promise<Array>} - An array of consent type records (promise)
       *
       */

    getConsentTypeList: function getConsentTypeList() {
      var cache = get(this, 'cache');
      var cacheKey = 'getConsentTypeList';
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;
      var request = this.request('/dashboard/consentTypeLookup/').then(function (_ref) {
        var results = _ref.results;
        return results;
      });
      cache.save(cacheKey, request);
      return request;
    },


    /**
      * @deprecated
      *
      * Gets the list of consent types for populating a drop-down list
      *
      * @return {Promise<Array>} - An array of consent type records (promise)
      *
      */
    getApplicationTypeList: function getApplicationTypeList() {
      var cache = get(this, 'cache');
      var cacheKey = 'getApplicationTypeList_';
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;
      var request = this.request('/resource-consent-applications/get-application-types').then(function (_ref2) {
        var results = _ref2.results;
        return results;
      });
      cache.save(cacheKey, request);
      return request;
    },


    /**
      *
      * Gets the list of Application Versions for populating a drop-down list
      *
      * @param {string} - instanceId A form_instance_id representing a top level form or the application id
      * @param {isFormInstance} - isFormInstance Set as true if the instanceId is a form instance
      * @return {Promise<Array>} - An array of snapshot identifier records (promise)
      *
      */
    getApplicationVersionsList: function getApplicationVersionsList() {
      var instanceId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var isFormInstance = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      return this.request('/dashboard/applicationVersionsLookup/' + instanceId + '/' + isFormInstance).then(function (result) {
        return result['results'];
      });
    },


    /**
      *
      * Gets the application version details for selected application version
      *
      * @param {string} - application_id
      * @return {Promise<>} - Application version details
      *
      */
    getApplicationVersionDetails: function getApplicationVersionDetails(applicationId) {
      return this.request('/dashboard/applicationVersionDetails/' + applicationId).then(function (_ref3) {
        var result = _ref3.result;
        return result;
      });
    },


    /**
      * @deprecated
      *
      * Gets the list of Application Versions for populating a drop-down list
      *
      * @param {string} - topFormInstanceId A form_instance_id representing a top level form
      * @return {Promise<Array>} - An array of snapshot identifier records (promise)
      *
      */
    getStatusLookupList: function getStatusLookupList() {
      var cache = get(this, 'cache');

      // This list changes depending on whether the user has this permission so we add it to
      //  the cache key to ensure that we get the correct list after the user logs in/out.
      var cacheKey = 'getStatusLookupList_' + this.get('gateKeeper').can('global.view_all_search_fields');

      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/dashboard/statusLookup/').then(function (_ref4) {
        var results = _ref4.results;
        return results;
      });

      cache.save(cacheKey, request);
      return request;
    },


    /**
     * Query for a list of existing wells.
     *
     * @param {string} query
     * @returns {Promise<Object[]>} Resolves to an array of results.
     */
    getWellNos: function getWellNos(query) {
      var cache = get(this, 'cache');

      var requestData = { query: query };
      var cacheKey = 'getWellNos_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/resource-consent-applications/well-search', { data: requestData }).then(function (_ref5) {
        var results = _ref5.results;
        return results;
      });

      cache.save(cacheKey, request);
      return request;
    },


    /**
     * Query for a list of mooring numbers.
     *
     * @param {string} query
     * @returns {Promise<Object[]>} Resolves to an array of results.
     */
    getMooringNos: function getMooringNos(query) {
      var cache = get(this, 'cache');

      var requestData = { query: query };
      var cacheKey = 'getMooringNos_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/resource-consent-applications/mooring-no-search', { data: requestData }).then(function (_ref6) {
        var results = _ref6.results;
        return results;
      });

      cache.save(cacheKey, request);
      return request;
    },


    /**
     * Query for a list of meter numbers.
     *
     * @param {string} query
     * @returns {Promise<Object[]>} Resolves to an array of results.
     */
    getMeterNos: function getMeterNos(query) {
      var cache = get(this, 'cache');

      var requestData = { query: query };
      var cacheKey = 'getMeterNos_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/resource-consent-applications/meter-no-search', { data: requestData }).then(function (_ref7) {
        var results = _ref7.results;
        return results;
      });

      cache.save(cacheKey, request);
      return request;
    }
  });
});