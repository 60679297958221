define('client/components/light-table-components/cell-with-tick/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    tagName: '',

    textValue: computed('value', function () {
      var val = get(this, 'value');
      if (val != null && typeof val === 'string') {
        return val.toString().replace('<<tick>>', '');
      } else {
        return val;
      }
    }),

    hasTick: computed('value', function () {
      var val = get(this, 'rawValue');
      if (val !== null && typeof val === 'string' && val.indexOf('<<tick>>') > -1) {
        return true;
      } else {
        return false;
      }
    })

  });
});