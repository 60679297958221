define('client/components/dynamic-form/view/typeahead-cap-consent/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/libs/validation-error-lib'], function (exports, _dynamicFormElement, _validationErrorLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    remoteMethods: inject.service('consents-and-permits-lookups'),

    valueErrors: computed('stateValue.capConsentId.0.errors', function () {
      return (0, _validationErrorLib.deDupErrors)(get(this, 'stateValue.capConsentId.0.errors') || []);
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var value = get(this, 'value');
      if (value != null) {
        var id = value['capConsentId'][0]['val'];
        if (id) {
          get(this, 'remoteMethods').getCAPConsent(id).then(function (result) {
            // simulate selecting the result from the drop-down list
            set(_this, 'selected', result);
          });
        }
      }
    }
  });
});