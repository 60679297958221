define('client/components/home-section/publicly-notified-applications/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Component.extend({
    dashboardService: inject.service('dashboard')
  });
});