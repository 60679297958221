define('client/components/maps/search-map/component', ['exports', 'client/mixins/components/common-map-mixin', 'client/libs/map-lib', 'npm:lodash/escape'], function (exports, _commonMapMixin, _mapLib, _escape) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      observer = Ember.observer,
      set = Ember.set;


  /**
   * Build HTML content for the popup content.
   * @param data
   * @returns {string}
   */
  var buildPopupContent = function buildPopupContent(data) {
    var number = data.n,
        type = data.t,
        subtype = data.st;

    var subline = [type, subtype].filter(function (v) {
      return v;
    }).join(' / ');
    var content = '<h3>' + (number ? (0, _escape.default)(number) : 'Number unset') + '</h3>';
    if (subline) {
      content += '' + (0, _escape.default)(subline);
    }
    return content;
  };

  /**
   * Displays a map to select a point on.
   *
   * {{maps/search-map
   *   searchResults=searchResults
   * }}
   *
   * Where:
   * - searchResults: An array of search results, with a structure like:
   * {
   *   id: 22, // ID (passed to viewAction on marker click)
   *   l: [easting, northing],
   *   t: 'Consent type',
   *   st: 'Consent sub-type',
   *   n: 1234 // Reference no.
   * }
   */
  exports.default = Ember.Component.extend(_commonMapMixin.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initMap();
    },


    /**
     * Initialise the map.
     */
    initMap: function initMap() {
      this.createMapAndBaseLayers('.js-map-container');
      var map = get(this, 'map');
      this.addInitialLayersAndMarkers(map);
      var markerClusterGroup = new L.markerClusterGroup({ // eslint-disable-line new-cap
        iconCreateFunction: function iconCreateFunction(cluster) {
          return L.divIcon({
            className: 'c-cluster-icon',
            html: '<span class="c-cluster-icon__count">' + cluster.getChildCount() + '</span>',
            iconSize: new L.Point(38, 38)
          });
        }
      });
      map.addLayer(markerClusterGroup);
      set(this, 'markerClusterGroup', markerClusterGroup);
    },
    resetBounds: function resetBounds() {
      var map = get(this, 'map');
      var markerClusterGroup = get(this, 'markerClusterGroup');
      map.fitBounds(markerClusterGroup.getBounds());
    },


    searchResultsObserver: observer('searchResults', function () {
      var markerClusterGroup = get(this, 'markerClusterGroup');
      markerClusterGroup.clearLayers();
      var map = get(this, 'map');

      // offset - moves the popup above the marker icon
      // closeButton - hide the X in the top corner
      var popup = L.popup({
        offset: new L.Point(0, 0),
        closeButton: false
      });
      var viewAction = get(this, 'viewAction');

      var searchResults = get(this, 'searchResults');
      if (searchResults) {
        var markers = [];
        searchResults.forEach(function (searchResult) {
          if (!_mapLib.default.validateNztm(searchResult.l[0], searchResult.l[1])) {
            return;
          }

          var marker = new L.Marker(_mapLib.default.nztmToLatLng(searchResult.l), {
            icon: searchResult.t ? _mapLib.default.consentTypeIcons[searchResult.t] : _mapLib.default.defaultIcon,
            data: searchResult // put on data so the popup can get access to other info
          });
          marker.on('mouseover', function (e) {
            popup.setContent(buildPopupContent(this.options.data));
            popup.setLatLng(this.getLatLng());
            map.openPopup(popup);
          });
          marker.on('mouseout', function (e) {
            map.closePopup(popup);
          });
          marker.on('click', function (e) {
            viewAction(this.options.data.id);
          });
          markers.push(marker);
        });

        // Would like to chunk loading as per the markercluster docs, however
        // couldn't get the fit bounds to work.
        markerClusterGroup.addLayers(markers);
        this.resetBounds();
      }
    })
  });
});