define('client/components/dynamic-form/view/typeahead-ci-name/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/components/dynamic-form/edit/typeahead-ci-name/config'], function (exports, _dynamicFormElement, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',
    searchService: inject.service(_config.searchServiceName),
    childFormElements: _config.childFormElements,
    loading: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // set the initial state of the child elements
      var ratepayerIdentifier = get(this, 'stateValue.nameNumber.0.val');

      if (!ratepayerIdentifier) {
        return;
      }

      set(this, 'loading', true);
      get(this, 'searchService').ratepayerIdentifierDetails(ratepayerIdentifier).then(function (details) {
        _config.childFormElements.forEach(function (childFormElement) {
          set(_this, childFormElement.name, childFormElement.getValue(details));
        });
        set(_this, 'loading', false);
      }).catch(function (_ref) {
        var message = _ref.message;

        console.log('Warning: CI Name Number Lookup: ' + message);
        set(_this, 'loading', false);
      });
    }
  });
});