define('client/components/dynamic-form/edit/file-upload/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/constants'], function (exports, _dynamicFormElement, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject,
      set = Ember.set,
      getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    filesService: inject.service('files'),

    // Name for the ember-file-upload file queue, make it unique by appending
    // the elementId.
    queueName: computed('formElement.name', 'elementId', function () {
      return [get(this, 'formElement.name'), get(this, 'elementId')].join('_');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      // Init values
      set(this, 'file', false); // File metadata
      set(this, 'loading', false); // Loading file metadata
      set(this, 'uploading', false); // Uploading a file to the server
      set(this, 'fileInfoError', false); // An error retrieving file metadata
      set(this, 'uploadError', false); // An error uploading a file
      set(this, 'avScanStatus', null); // Current antivirus scan status

      // Load file metadata if set
      var fileId = get(this, 'stateValue');
      if (fileId) {
        set(this, 'loading', true);
        this.loadFileMetadata();
      }
    },


    scanning: Ember.computed('file.status', 'loading', 'uploading', function () {
      return !this.loading && !this.uploading && this.file && !this.file.status;
    }),

    // Load file metadata
    loadFileMetadata: function loadFileMetadata() {
      var _this = this;

      var fileId = get(this, 'stateValue');

      if (this.isDestroyed === true || fileId === undefined) {
        return;
      }

      return get(this, 'filesService').getInfo(fileId).then(function (file) {
        if (!get(_this, 'state.' + _this.formElement.name + '.0.val')) return;
        _this.set('file', file);
        set(_this, 'loading', false);
        // Reset any previous file error as we now allow uploading a new file incase of file error
        set(_this, 'fileInfoError', false);

        // keep re-fetching the metadata until the file has been scanned
        if (!file.status) {
          return new Ember.RSVP.Promise(function (resolve) {
            return window.setTimeout(resolve, 1000);
          }).then(function () {
            return _this.loadFileMetadata();
          });
        }

        if (file.status === 'INFECTED' && getWithDefault(_this, 'state.' + _this.formElement.name + '.0', undefined) !== undefined) {
          // note: the state object might no longer exist after the file info is returned, so need to check for it above
          // (this can happen when the component is cleared by the user clicking the delete button)
          set(_this, 'state.' + _this.formElement.name + '.0.errors', [{
            description: _constants.VIRUS_DETECTED_MESSAGE,
            type: 'INFECTED'
          }]);
          // Hack to get the validation message to update after setting the above value, which is deep down in the state tree
          set(_this, 'state', Ember.copy(_this.state));
        }

        if (file.status === 'CLEAN' && getWithDefault(_this, 'state.' + _this.formElement.name + '.0', undefined) !== undefined && getWithDefault(_this, 'state.' + _this.formElement.name + '.0.errors', undefined) !== undefined) {
          // Clear any validation error, if exists, once file status is CLEAN
          set(_this, 'state.' + _this.formElement.name + '.0.errors', []);
          // Hack to get the validation message to update after setting the above value, which is deep down in the state tree
          set(_this, 'state', Ember.copy(_this.state));
        }
      }).catch(function (err) {
        console.error(err);
        set(_this, 'state.' + _this.formElement.name + '.0.errors', []);
        // Hack to get the validation message to update after setting the above value, which is deep down in the state tree
        set(_this, 'state', Ember.copy(_this.state));
        set(_this, 'fileInfoError', 'Failed to load file information. Please try uploading the file again.');
        set(_this, 'loading', false);
      });
    },


    actions: {
      /**
       * Upload a file as returned from ember-file-upload
       * @param {Object} file
       */
      upload: function upload(file) {
        var _this2 = this;

        set(this, 'uploading', true);
        set(this, 'uploadError', false);
        get(this, 'filesService').upload(file, 'form_instance', get(this, 'formInstanceId')).then(function (uploadedFile) {
          set(_this2, 'file', uploadedFile);
          _this2.setValue(uploadedFile.id);
          new Ember.RSVP.Promise(function (resolve) {
            return window.setTimeout(resolve, 1000);
          }).then(function () {
            return _this2.loadFileMetadata();
          });
        }).catch(function (response) {
          set(_this2, 'uploadError', response.body.error);
        }).finally(function () {
          set(_this2, 'uploading', false);
        });
      },


      /**
       * Download the file
       * @param {Number} id
       */
      download: function download(id) {
        get(this, 'filesService').download(id);
      },


      /**
       * Unset the current file.
       * @param {Number} id
       */
      clear: function clear(id) {
        set(this, 'file', false);
        set(this, 'avScanStatus', null);
        this.clearValue();
      },


      /**
       * Delete the file
       * @param {Number} id
       */
      delete: function _delete(id) {
        var _this3 = this;

        get(this, 'filesService').deleteFile(id).then(function (response) {
          if (response.success) {
            set(_this3, 'file', false);
            set(_this3, 'avScanStatus', null);
            _this3.clearValue();
          }
        }).catch(function (error) {
          set(_this3, 'deleteError', error.message);
        });
      }
    }
  });
});