define('client/components/app-nav/search-menu/result-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    onKeyDown: null,
    onSelect: null,

    actions: {
      onClick: function onClick() {
        this.router.transitionTo(this.linkToRouteName, this.linkToId, 'view');

        // tell the higher-level component that something has been selected
        // (used to close the menu)
        if (this.onSelect !== null) {
          this.onSelect(this);
        }
      },
      onKeyDown: function onKeyDown(e) {
        if (e.keyCode === 13) {
          this.send('onClick');
        }

        // tell the higher-level component that a key has been pressed
        // (used to tab to the next/previous option)
        if (this.onKeyDown !== null) {
          this.onKeyDown(e);
        }
      }
    }
  });
});