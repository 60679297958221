define('client/components/dynamic-form/view/application-version-selector/component', ['exports', 'client/mixins/components/dynamic-formElement', 'ember-data'], function (exports, _dynamicFormElement, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed,
      inject = Ember.inject,
      getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    remoteMethods: inject.service('resource-consent-lookups'),

    displayValue: computed('stateValue', function () {
      var selected = getWithDefault(this, 'values.0.val.applicationVersionId.0.val', null);
      var displayValue = '-';
      if (selected) {
        displayValue = get(this, 'remoteMethods').getApplicationVersionDetails(selected).then(function (value) {
          return value['submission_information'];
        });
      }
      return _emberData.default.PromiseObject.create({ promise: Ember.RSVP.resolve(displayValue) });
    })
  });
});