define('client/components/loading-spinner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c-loading-spinner'],
    classNameBindings: ['centerInParent:c-loading-spinner--center-in-parent', 'centerInViewport:c-loading-spinner--center-in-viewport', 'isSmall:c-loading-spinner--small', 'isTiny:c-loading-spinner--tiny', 'displayInline:c-loading-spinner--display-inline']
  });
});