define('client/routes/resource_app', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'dynamic-form-edit',
    model: function model() {
      // no-op right now
    }
  });
});