define('client/components/dynamic-form/edit/location/set-location-on-map-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'displayModal', false);
    },

    actions: {
      confirm: function confirm() {
        var setLocation = get(this, 'setLocation');
        if (setLocation) {
          setLocation(get(this, 'location'));
        }
        set(this, 'displayModal', false);
      },

      /**
       * Update the location when it changes on the map.
       * @param {Number[]} location Easting and Northing
       */
      onLocationChange: function onLocationChange(location) {
        set(this, 'location', location);
      }
    }
  });
});