define('client/components/home-section/new-demo-application-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend({
    classNames: ['c-cta', 'h-clearfix'],
    session: inject.service(),
    remoteMethods: inject.service('resource-consent-remote-methods'),
    isDisabled: false,
    selectedApplicationType: 'S88',

    actions: {
      setValue: function setValue(value) {
        set(this, 'selectedApplicationType', value);
      },
      createS88Demo: function createS88Demo() {
        var _this = this;

        set(this, 'isDisabled', true);
        get(this, 'remoteMethods').createS88Demo(get(this, 'selectedApplicationType')).then(function (response) {
          if (response.error !== false) {
            return Promise.reject(new Error('error'));
          }
          get(_this, 'flashMessages.success')('A new demo application has been added to your drafts.');
          set(_this, 'isDisabled', false);
        }).catch(function () {
          get(_this, 'flashMessages.danger')('Unable to create demo application.');
          set(_this, 'isDisabled', false);
        });
      }
    }
  });
});