define('client/components/dynamic-form/edit/typeahead-property/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;


  // Note: If you wish to save these to the form/back-end tables, they must exist
  //        in the dynamic forms config as child formElements of this formElement
  var childFormElements = exports.childFormElements = [{
    type: 'text',
    name: 'propertyNumber',
    getValue: function getValue(details, extendedAttribute) {
      return details.propertyNumber;
    }
  }, {
    type: 'text',
    name: 'owners',
    getValue: function getValue(details, extendedAttribute) {
      return details.owners;
    }
  }, {
    type: 'text',
    name: 'address',
    getValue: function getValue(details, extendedAttribute) {
      if (extendedAttribute == null) {
        return details.address;
      } else {
        return details[get(extendedAttribute, 'APIAddressProperty') ? get(extendedAttribute, 'APIAddressProperty') : 'address'];
      }
    }
  }, {
    type: 'text',
    name: 'postalAddressFromAPI',
    getValue: function getValue(details, extendedAttribute) {
      return details.postalAddress;
    }
  }, {
    name: 'entities',
    getValue: function getValue(details, extendedAttribute) {
      return details.entities;
    }
  }];

  // the ember service to use for the search
  var searchServiceName = exports.searchServiceName = 'council-services';

  // The method used perform the search
  // Should take a query string and return a promise that resolves to an array of objects
  var searchMethodName = exports.searchMethodName = 'propertyNumberSearch';
});