define('client/libs/map-lib-mdc', ['exports', 'client/libs/map-lib-base', 'npm:lodash/without', 'npm:lodash/map', 'npm:lodash/sortBy', 'npm:lodash/omit', 'npm:lodash/isArray', 'npm:lodash/uniq'], function (exports, _mapLibBase, _without, _map, _sortBy, _omit, _isArray, _uniq) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var mapsBaseUrl = '//gis.marlborough.govt.nz/server/rest/services';

  /**
   * See https://gis.marlborough.govt.nz/server/rest/services/DataPublic/LegalParcelAndProperty/MapServer for the full list.
   * Exceptions:
   * AERIAL_PHOTO_DATES layer is being served from https://gis.marlborough.govt.nz/server/rest/services/DataPublic/AerialPhotoDates/MapServer/26
   * LIDAR_COVERAGE is being served from https://gis.marlborough.govt.nz/server/rest/services/DataPublic/AerialPhotoDates/MapServer/27
   */
  var MDC_LAYERS = {
    AERIAL_PHOTO_DATES: 0,
    HYDRO_BOUNDARY: 1,
    LAND_PARCEL: 2,
    ROAD_PARCEL: 3,
    PROPERTY: 5,
    PROPERTY_LINE: 6,
    RAIL_CENTRELINE: 7,
    RIVERS: 13,
    LIDAR_COVERAGE: 14

    // See https://gis.marlborough.govt.nz/server/rest/services/DataPublic/Labels/MapServer for the full list.
  };var MDC_LABELS = {
    ADDRESS_NUMBER: 0,
    BAY_NAMES: 1,
    LEGAL_DESCRIPTION: 2,
    POPULATED_PLACE_NAME: 2,
    PROPERTY_NUMBER: 4,
    RIVER_NAME: 5,
    ROAD_NAME: 6,
    VALUATION_NUMBER: 7,
    PARCEL_AREA: 8,
    TITLE_REFERENCE: 9,
    PLACE_NAME: 10
  };

  var layers = {
    landParcel: {
      mdcLayerIds: [MDC_LAYERS.LAND_PARCEL],
      mdcLabelIds: [MDC_LABELS.LEGAL_DESCRIPTION],
      order: 1,
      name: 'Land parcel boundaries'
    },
    property: {
      mdcLayerIds: [MDC_LAYERS.PROPERTY],
      mdcLabelIds: [MDC_LABELS.PROPERTY_NUMBER],
      order: 2,
      name: 'Property boundaries'
    },
    roadNames: {
      mdcLabelIds: [MDC_LABELS.ROAD_NAME],
      order: 3,
      name: 'Roads',
      defaultOn: true
    },
    rivers: {
      mdcLayerIds: [MDC_LAYERS.RIVERS],
      order: 4,
      name: 'Rivers'
    }
  };

  var getExtraLayers = function getExtraLayers() {
    var layers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var minZoom = arguments[1];
    var maxZoom = arguments[2];

    return L.esri.dynamicMapLayer({
      url: mapsBaseUrl + '/DataPublic/LegalParcelAndProperty/MapServer',
      f: 'image',
      format: 'png32',
      layers: layers,
      minZoom: minZoom,
      maxZoom: maxZoom
    });
  };

  var getExtraLabels = function getExtraLabels() {
    var layers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var minZoom = arguments[1];
    var maxZoom = arguments[2];

    return L.esri.dynamicMapLayer({
      url: mapsBaseUrl + '/DataPublic/Labels/MapServer',
      f: 'image',
      format: 'png32',
      layers: layers,
      minZoom: minZoom,
      maxZoom: maxZoom
    });
  };

  var extraLayers = void 0,
      extraLabels = void 0;

  exports.default = Object.assign({}, _mapLibBase.default, {
    getBaseLayers: function getBaseLayers() {
      var baseLayer = L.tileLayer(mapsBaseUrl + '/Cache/Basemap/MapServer/tile/{z}/{y}/{x}', {
        'maxZoom': 11,
        'attribution': '',
        'minZoom': 0,
        'continuousWorld': true
      });
      var lowResLayer = L.tileLayer(mapsBaseUrl + '/Cache/LowResolutionAerialPhotos/MapServer/tile/{z}/{y}/{x}', {
        'maxZoom': 11,
        'attribution': '',
        'minZoom': 7,
        'continuousWorld': true
      });
      var highResLayer = L.tileLayer(mapsBaseUrl + '/Cache/HighResolutionAerialPhotos/MapServer/tile/{z}/{y}/{x}', {
        'maxZoom': 11,
        'attribution': 'Marlborough District Council',
        'minZoom': 7,
        'continuousWorld': true
      });

      return L.layerGroup([baseLayer, lowResLayer, highResLayer]);
    },
    getLayers: function getLayers() {
      // Convert to array with the keys name and order.
      // Order the array and then remove the order key.
      var converted = (0, _map.default)(layers, function (v, k) {
        return {
          id: k,
          name: v.name,
          defaultOn: v.defaultOn,
          order: v.order
        };
      });
      var ordered = (0, _sortBy.default)(converted, 'order');
      return (0, _map.default)(ordered, function (v) {
        return (0, _omit.default)(v, 'order');
      });
    },
    showLayer: function showLayer(id, map) {
      if (layers[id].mdcLayerIds && layers[id].mdcLayerIds.length) {
        var newMdcIds = _without.default.apply(undefined, [extraLayers.getLayers()].concat(_toConsumableArray(layers[id].mdcLayerIds)));
        newMdcIds.push.apply(newMdcIds, _toConsumableArray(layers[id].mdcLayerIds));
        extraLayers.setLayers(newMdcIds);
      }
      if (layers[id].mdcLabelIds && layers[id].mdcLabelIds.length) {
        var _newMdcIds = _without.default.apply(undefined, [extraLabels.getLayers()].concat(_toConsumableArray(layers[id].mdcLabelIds)));
        _newMdcIds.push.apply(_newMdcIds, _toConsumableArray(layers[id].mdcLabelIds));
        extraLabels.setLayers(_newMdcIds);
      }
    },
    hideLayer: function hideLayer(id, map) {
      if (layers[id].mdcLayerIds && layers[id].mdcLayerIds.length) {
        extraLayers.setLayers(_without.default.apply(undefined, [extraLayers.getLayers()].concat(_toConsumableArray(layers[id].mdcLayerIds))));
      }
      if (layers[id].mdcLabelIds && layers[id].mdcLabelIds.length) {
        extraLabels.setLayers(_without.default.apply(undefined, [extraLabels.getLayers()].concat(_toConsumableArray(layers[id].mdcLabelIds))));
      }
    },
    addInitialLayersAndMarkers: function addInitialLayersAndMarkers(map) {
      var defaultOnLayers = [];
      var defaultOnLabels = [];

      Object.keys(layers).forEach(function (key) {
        var layer = layers[key];
        if (layer.defaultOn) {
          if ((0, _isArray.default)(layer.mdcLayerIds)) {
            defaultOnLayers.push.apply(defaultOnLayers, _toConsumableArray(layer.mdcLayerIds));
          }
          if ((0, _isArray.default)(layer.mdcLabelIds)) {
            defaultOnLabels.push.apply(defaultOnLabels, _toConsumableArray(layer.mdcLabelIds));
          }
        }
      });

      extraLayers = getExtraLayers((0, _uniq.default)(defaultOnLayers));
      extraLayers.addTo(map);
      extraLabels = getExtraLabels((0, _uniq.default)(defaultOnLabels));
      extraLabels.addTo(map);
    },


    /**
     * Default Center to use.
     *
     * This points to Blenheim.
     */
    defaultCenter: L.latLng(-41.5134, 173.9612),

    mapDefaultZoom: 6,
    mapMinZoom: undefined,
    mapMaxZoom: 11,

    maxClusteringZoomLevel: 8,

    // NZTM CRS
    crs: new L.Proj.CRS('EPSG:2193', // http://epsg.io/2193
    '+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 +y_0=10000000 ' + '+ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs', {
      origin: [-4020900, 19998100],
      resolutions: [396.87579375158754, 264.5838625010584, 132.2919312505292, 66.1459656252646, 33.0729828126323, 26.458386250105836, 13.229193125052918, 6.614596562526459, 2.6458386250105836, 1.3229193125052918, 0.6614596562526459, 0.26458386250105836, 0.13229193125052918, 0.06614596562526459, 0.033072982812632296]
    })

  });
});