define('client/components/modal-dialog/finalise-notification-submission/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),

    loading: false,
    hideCloseButton: false,
    onFinaliseSendSubmissionToCM: true,
    pageInfo: null,
    formInstanceId: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'loading', false);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      set(this, 'loading', false);
      this._super.apply(this, arguments);
      var formInstanceId = get(this, 'formInstanceId');
      var showFinaliseSubmissionModal = get(this, 'showFinaliseSubmissionModal');
      if (formInstanceId && showFinaliseSubmissionModal) {
        set(this, 'onFinaliseSendSubmissionToCM', true);
        get(this, 'remoteMethods').isSubmissionFinalised(formInstanceId).then(function (finalisationInfo) {
          set(_this, 'isSubmissionFinalised', finalisationInfo.is_submission_finalised);
          set(_this, 'canFinaliseSubmission', finalisationInfo.can_finalise_submission);
          set(_this, 'canUnfinaliseSubmission', finalisationInfo.can_unfinalise_submission);
        });
      }
    },


    applicationNumber: computed('pageInfo.consentNumber', function () {
      return get(this, 'pageInfo.consentNumber') || 'Not yet specified';
    }),

    modalHeading: computed('isNotificationSubmissionFinalised', function () {
      return get(this, 'isNotificationSubmissionFinalised') ? 'Reverse finalisation ofsubmission' : 'Finalise submission';
    }),

    actions: {
      finaliseSubmissionAction: function finaliseSubmissionAction() {
        var _this2 = this;

        console.log(get(this, 'formInstanceId'));
        var onFinaliseSendSubmissionToCM = get(this, 'onFinaliseSendSubmissionToCM');

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').finaliseSubmission(get(this, 'formInstanceId'), onFinaliseSendSubmissionToCM).then(function (response) {
          if (response.error) {
            get(_this2, 'flashMessages.danger')('Unable to finalise submission ' + (response.message ? ' - ' + response.message : ''));
            // These need resetting or otherwise the checkboxes will reset, but won't be synched with the back end values, because.. ember.
            set(_this2, 'onFinaliseSendSubmissionToCM', false);
          } else {
            get(_this2, 'flashMessages.success')('Submission finalised');
            _this2.get('setShowFinaliseSubmissionModal')(false);
            _this2.refreshModel();
          }
        }).finally(function () {
          set(_this2, 'loading', false);
        });
      },
      unfinaliseSubmissionAction: function unfinaliseSubmissionAction() {
        var _this3 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').unfinaliseSubmission(get(this, 'formInstanceId')).then(function (response) {
          if (response.error) {
            get(_this3, 'flashMessages.danger')('Unable to reverse finalisation of submission ' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this3, 'flashMessages.success')('Submission finalisation reversed');
            get(_this3, 'remoteMethods').clearProcessingPageInfoCache(get(_this3, 'pageInfo.applicationId'));
            _this3.get('setShowFinaliseSubmissionModal')(false);
            _this3.refreshModel();
          }
        }).finally(function () {
          set(_this3, 'loading', false);
        });
      }
    }
  });
});