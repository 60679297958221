define('client/components/modal-dialog/finalise-s91ad-letter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),

    loading: false,
    hideCloseButton: false,
    onFinaliseSendApplicantLetterToCM: false,
    onFinaliseSendEmailToApplicant: false,
    onFinaliseSendSubmitterLetterToCM: false,
    onFinaliseSendEmailToSubmitters: false,
    pageInfo: null,
    isLetterFinalised: null,
    submitterCount: 0,
    formInstanceId: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'loading', false);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      set(this, 'loading', false);
      this._super.apply(this, arguments);
      var showFinaliseS91adLetterModal = get(this, 'showFinaliseS91adLetterModal');
      var formInstanceId = get(this, 'formInstanceId');
      if (formInstanceId && showFinaliseS91adLetterModal) {
        get(this, 'remoteMethods').isLetterFinalised(formInstanceId).then(function (finalisationInfo) {
          set(_this, 'isLetterFinalised', finalisationInfo.is_letter_finalised);
          set(_this, 'submitterCount', finalisationInfo.submitter_count);
        });
      }
    },


    applicationNumber: computed('pageInfo.consentNumber', function () {
      return get(this, 'pageInfo.consentNumber') || 'Not yet specified';
    }),

    modalHeading: computed('isLetterFinalised', function () {
      return get(this, 'isLetterFinalised') ? 'Reverse finalisation of letter' : 'Finalise letter';
    }),

    actions: {
      finaliseLetterAction: function finaliseLetterAction() {
        var _this2 = this;

        var onFinaliseSendApplicantLetterToCM = get(this, 'onFinaliseSendApplicantLetterToCM');
        var onFinaliseSendSubmitterLetterToCM = get(this, 'onFinaliseSendSubmitterLetterToCM');
        var onFinaliseSendEmailToApplicant = get(this, 'onFinaliseSendEmailToApplicant');
        var onFinaliseSendEmailToSubmitters = get(this, 'onFinaliseSendEmailToSubmitters');

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').finaliseS91adLetter(get(this, 'formInstanceId'), onFinaliseSendApplicantLetterToCM, onFinaliseSendSubmitterLetterToCM, onFinaliseSendEmailToApplicant, onFinaliseSendEmailToSubmitters).then(function (response) {
          if (response.error) {
            get(_this2, 'flashMessages.danger')('Unable to finalise letter' + (response.message ? ' - ' + response.message : ''));
            // These need resetting or otherwise the checkboxes will reset, but won't be synched with the back end values, because.. ember.
            set(_this2, 'onFinaliseSendApplicantLetterToCM', false);
            set(_this2, 'onFinaliseSendEmailToApplicant', false);
            set(_this2, 'onFinaliseSendSubmitterLetterToCM', false);
            set(_this2, 'onFinaliseSendEmailToSubmitters', false);
          } else {
            get(_this2, 'flashMessages.success')('Letter finalised');
            _this2.get('setShowFinaliseS91adLetterModal')(false);
            _this2.refreshModel();
          }
        }).finally(function () {
          set(_this2, 'loading', false);
        });
      },
      unfinaliseLetterAction: function unfinaliseLetterAction() {
        var _this3 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').unfinaliseLetter(get(this, 'formInstanceId')).then(function (response) {
          if (response.error) {
            get(_this3, 'flashMessages.danger')('Unable to reverse finalisation of letter' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this3, 'flashMessages.success')('Letter finalisation reversed');
            get(_this3, 'remoteMethods').clearProcessingPageInfoCache(get(_this3, 'pageInfo.applicationId'));
            _this3.get('setShowFinaliseS91adLetterModal')(false);
            _this3.refreshModel();
          }
        }).finally(function () {
          set(_this3, 'loading', false);
        });
      }
    }
  });
});