define('client/routes/home-section', ['exports', 'client/constants', 'client/config/environment', 'ember-data'], function (exports, _constants, _environment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get;
  exports.default = Ember.UnprotectedRoute.extend({
    gateKeeper: inject.service(),
    remoteMethods: inject.service('resource-consent-remote-methods'),

    model: function model() {
      var _this = this;

      // we need to wait for the session to load before we can check whether the user is authenticated.
      return get(this, 'gateKeeper.loaded').then(function () {
        var isAuthenticated = get(_this, 'gateKeeper.isAuthenticated');

        var promise = get(_this, 'remoteMethods').getMaintenanceWindows().then(function (result) {
          return result[0]['getMaintenanceWindows'];
        });
        var maintenanceWindowsPromise = _emberData.default.PromiseObject.create({ promise: promise });

        var tabs = _constants.HOME_TABS.filter(function (_ref) {
          var permissionsRequired = _ref.permissionsRequired,
              authenticatedOnly = _ref.authenticatedOnly,
              unauthenticatedOnly = _ref.unauthenticatedOnly;

          var gateKeeper = get(_this, 'gateKeeper');

          // remove tabs if the user doesn't have the correct permissions
          var show = true;

          if (unauthenticatedOnly === true && get(gateKeeper, 'isAuthenticated')) {
            show = false;
          }

          if (authenticatedOnly === true && !get(gateKeeper, 'isAuthenticated')) {
            show = false;
          }

          if (permissionsRequired !== undefined) {
            permissionsRequired.forEach(function (permission) {
              // hack to show these on the front page in the demo environment
              if (_environment.default.ENV_VARS.DEMO_ENVIRONMENT === true) {
                if (permission === 'global.access_phase_2' && !get(gateKeeper, 'isAuthenticated')) {
                  return;
                }
              }

              if (!gateKeeper.can(permission)) {
                show = false;
              }
            });
          }

          return show;
        }).map(function (_ref2) {
          var name = _ref2.name,
              path = _ref2.path;
          return { title: name, path: path };
        });

        return {
          isAuthenticated: isAuthenticated,
          tabs: tabs,
          maintenanceWindowsPromise: maintenanceWindowsPromise
        };
      });
    }
  });
});