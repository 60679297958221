define('client/components/dynamic-form/view/orderable-text-fields/orderable-text-field-element/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    number: computed('stateValue.orderableTextFieldNumber.0.val', function () {
      var val = get(this, 'stateValue.orderableTextFieldNumber.0.val');
      // Very occasionally migrated data has a trailing '.', strip it so there
      // aren't two when displaying.
      return val ? val.replace(/\.+$/, '') : '';
    }),

    /**
     * Get the child text form element.
     */
    textFormElement: computed('formElement.formElements', function () {
      var formElements = get(this, 'formElement.formElements');
      return formElements.find(function (item) {
        return item.name === 'orderableTextFieldValue';
      });
    }),

    /**
     * Determine which component should be used.
     */
    textFieldComponentName: computed('stateValue.orderableTextFieldIsHeading.0.val', function () {
      var orderableTextFieldIsHeadingVal = get(this, 'stateValue.orderableTextFieldIsHeading.0.val');
      var isHeading = orderableTextFieldIsHeadingVal && orderableTextFieldIsHeadingVal.toString() === 'true';
      return 'dynamic-form/view/' + (isHeading ? 'orderable-text-fields/orderable-text-field-element/sub-heading' : 'restricted-rich-text');
    })
  });
});