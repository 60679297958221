define('client/components/dynamic-form/menu/menu-item/component', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject,
      getProperties = Ember.getProperties;
  exports.default = Ember.Component.extend({
    tagName: 'li',
    resourceConsentMethods: inject.service(),
    classNameBindings: ['isCompactView:c-task-menu__compact-task:c-task-menu__task', 'isActive:c-task-menu__task--active', 'form.nonNavigable:c-task-menu__sub-menu', 'isSubMenuOpen:c-task-menu__sub-menu--open'],

    isActive: computed.alias('form.active'),

    taskNumber: computed('index', function () {
      return get(this, 'index') + 1;
    }),

    formLabel: computed('form.{childFormAlias,aliasFormLocation,aliasForParent,label,hideInMenuIfInactive}', function () {
      var label = get(this, 'resourceConsentMethods').getFormLabel(get(this, 'form'));

      // We only show the "Viewing"/"Editing" prefix if we're hiding
      // non-active sibling items from the menu.
      var prefix = '';
      if (get(this, 'form.hideInMenuIfInactive')) {
        label = label.toLowerCase();
        if (['a', 'e', 'i', 'o', 'u'].includes(label.charAt(0))) {
          // If the label begins with a vowel, we need the prefix "Viewing an" or "Editing an"
          // this isn't an ideal way to handle this, but a proper fix would require dynamic forms engine changes.
          prefix = _constants.SIDE_MENU_PREFIX_A[get(this, 'mode')] || '';
        } else {
          prefix = _constants.SIDE_MENU_PREFIX[get(this, 'mode')] || '';
        }
      }

      return prefix + label;
    }),

    taskLabel: computed('taskNumber', 'form.label', function () {
      var taskNumber = '<span class="c-task-menu__task-number">' + get(this, 'taskNumber') + '. </span>';
      var taskLabel = '<span class="c-task-menu__task-label" data-test-task-menu-label>' + get(this, 'formLabel') + '</span>';
      return taskNumber + taskLabel;
    }),

    /**
     * Sub menu items that should be shown in the sidebar. Removes non-active
     * ones, if that is flagged in the config by the extendedAttribute
     * "hideInMenuIfInactive".
     */
    menuSubItems: computed('form.forms', 'activeSubItems', 'showSubItems', function () {
      var subItems = get(this, 'form.forms');

      // If menu item has sub items, check if any of them are active
      if (!subItems) return [];

      return subItems.filter(function (si) {
        return si.active || si.activeChild || !si.hideInMenuIfInactive;
      });
    }),

    /**
     * Open if any of this menu item's sub-items are active.
     */
    isSubMenuOpen: computed.alias('form.activeChild'),

    /**
     * Note: this function will always return `true` for snapshots because the formInstanceId !== errorForm.formInstanceId
     * This is okay as we don't want to display errors on the `submission-view`, and this behaviour gives that result
     * We can't actually get summary error info for the application from the snapshot due to how the summary error function
     * works, and the fact that the snapshots are just json blobs
     */
    isComplete: computed('form.complete', 'form', 'errors', function () {
      // component-based workflows manually set complete:true/false
      if (get(this, 'form.complete') !== undefined) {
        return get(this, 'form.complete');
      }

      var _getProperties = getProperties(this, ['form', 'errors']),
          form = _getProperties.form,
          errors = _getProperties.errors;

      // If no errors object returned at all, assume form completed
      // (More likely is in test run)


      if (!errors) return true;

      return errors.forms.some(function (errorForm) {
        // will purposefully never match on submission-view
        return String(form.formInstanceId) === String(errorForm.formInstanceId) && errorForm.status === _constants.FORM_STATES.COMPLETE;
      });
    }),

    actions: {
      toggleSubMenu: function toggleSubMenu() {
        // TODO: Julia will remove this hack/make this work properly :D
        this.$().find('.c-task-menu__task-link').eq(0).trigger('click');
      }
    },

    click: function click() {
      get(this, 'closeMenu')();
    }
  });
});