define('client/services/remote-method', ['exports', 'ember-ajax/services/ajax', 'client/config/environment', 'client/mixins/csrf-token', 'ember-ajax/errors'], function (exports, _ajax, _environment, _csrfToken, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;

  var DRF_LOGIN_NEEDED_ERROR = 'Authentication credentials were not provided.';

  /**
   * Extends the Ajax service, with settings common for calls to our API.
   *
   * Adds a CSRF Token to the headers property using the CSRFToken mixin, and
   * sets the base path. All calls to our API outside of Ember Data should use
   * this Service.
   */
  exports.default = _ajax.default.extend(_csrfToken.default, {
    host: _environment.default.apiNamespace,
    session: inject.service(),
    flashMessages: inject.service(),
    router: inject.service(),

    /**
     * Builds a properly escaped query string.
     * @param {Object} query Key/Value object.
     * @return {string}
     */
    buildQueryString: function buildQueryString(query) {
      return Object.keys(query === undefined ? {} : query).map(function (key) {
        return { key: key, value: query[key] };
      }).filter(function (_ref) {
        var value = _ref.value;
        return value !== undefined;
      }).map(function (_ref2) {
        var key = _ref2.key,
            value = _ref2.value;
        return key + '=' + encodeURIComponent(value);
      }).join('&');
    },
    request: function request() {
      var _this = this;

      return this._super.apply(this, arguments).catch(function (error) {
        // Detect a user not logged in error and redirect them to the login page
        if ((0, _errors.isForbiddenError)(error) && get(error, 'errors.0.detail.detail') === DRF_LOGIN_NEEDED_ERROR) {
          get(_this, 'flashMessages.warning')('You must be logged in to access that');
          return get(_this, 'router').transitionTo('home-section', { queryParams: { loginModal: true, returnTo: window.location.pathname } }).then(function () {
            return null;
          });
        } else if ((0, _errors.isForbiddenError)(error)) {
          var message = 'Either the resource does not exist or you do not have permission to view it';
          get(_this, 'flashMessages.danger')(message);
          return get(_this, 'router').transitionTo('home-section');
        }
        throw error;
      });
    }
  });
});