define('client/services/wordpress-posts', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = Ember.Service.extend({
    getPosts: function getPosts(host, apiPath, queryString) {
      if (_environment.default.ENV_VARS.DISABLE_BEST_PRACTICE_GUIDES_LOOKUP) {
        return RSVP.hash({ posts: [] });
      }

      return RSVP.hash({
        posts: Ember.$.ajax({
          url: host + apiPath + queryString,
          dataType: 'json',
          type: 'GET'
        })
      });
    }
  });
});