define('client/components/dynamic-form/view/checkbox-group/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-view-text'],
    checkedValues: computed('stateValue', 'formElement', function () {
      var stateValue = get(this, 'stateValue');
      if (!stateValue) return '';

      var values = get(this, 'formElement.formElements').filter(function (item) {
        return stateValue[item.name] && stateValue[item.name][0].val && stateValue[item.name][0].val !== 'false';
      }).map(function (item) {
        return item.label;
      });

      if (get(this, 'formElement.extendedAttributes.renderAsList')) {
        return values.join('<br>');
      } else {
        return values.join(', ');
      }
    })
  });
});