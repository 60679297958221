define('client/components/modal-dialog/finalise-s92-2-decision/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),

    loading: false,
    hideCloseButton: false,
    onFinaliseSendDecisionToCM: true,
    onFinaliseCreateTrackingStage: true,
    pageInfo: null,
    formInstanceId: null,
    isS92_2DecisionFinalised: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'loading', false);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      set(this, 'loading', false);
      this._super.apply(this, arguments);
      var showFinaliseS922DecisionModal = get(this, 'showFinaliseS922DecisionModal');
      var formInstanceId = get(this, 'formInstanceId');
      if (formInstanceId && showFinaliseS922DecisionModal) {
        set(this, 'onFinaliseSendDecisionToCM', true);
        get(this, 'remoteMethods').isLetterFinalised(formInstanceId).then(function (finalisationInfo) {
          set(_this, 'isS92_2DecisionFinalised', finalisationInfo.is_decision_finalised);
          set(_this, 'canFinaliseDecision', finalisationInfo.can_finalise_decision);
          set(_this, 'canUnfinaliseDecision', finalisationInfo.can_unfinalise_decision);
        });
      }
    },


    applicationNumber: computed('pageInfo.consentNumber', function () {
      return get(this, 'pageInfo.consentNumber') || 'Not yet specified';
    }),

    modalHeading: computed('isS92_2DecisionFinalised', function () {
      return get(this, 'isS92_2DecisionFinalised') ? 'Reverse finalisation of S92(2) commission reports request decision' : 'Finalise S92(2) commission reports request decision';
    }),

    actions: {
      finaliseS92_2DecisionAction: function finaliseS92_2DecisionAction() {
        var _this2 = this;

        var onFinaliseSendDecisionToCM = get(this, 'onFinaliseSendDecisionToCM');
        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').finaliseS92_2Decision(get(this, 'formInstanceId'), onFinaliseSendDecisionToCM).then(function (response) {
          if (response.error) {
            get(_this2, 'flashMessages.danger')('Unable to finalise Section 92(2) decision' + (response.message ? ' - ' + response.message : ''));
            // These need resetting or otherwise the checkboxes will reset, but won't be synched with the back end values, because.. ember.
            set(_this2, 'onFinaliseSendDecisionToCM', false);
          } else {
            get(_this2, 'flashMessages.success')('Section 92(2) decision finalised');
            _this2.get('setShowFinaliseS922DecisionModal')(false);
            _this2.refreshModel();
          }
        }).finally(function () {
          set(_this2, 'loading', false);
        });
      },
      unfinaliseS92_2DecisionAction: function unfinaliseS92_2DecisionAction() {
        var _this3 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').unfinaliseS92_2Decision(get(this, 'formInstanceId')).then(function (response) {
          if (response.error) {
            get(_this3, 'flashMessages.danger')('Unable to reverse finalisation of decision' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this3, 'flashMessages.success')('Section 92(2) decision finalisation reversed');
            get(_this3, 'remoteMethods').clearProcessingPageInfoCache(get(_this3, 'pageInfo.applicationId'));
            _this3.get('setShowFinaliseS922DecisionModal')(false);
            _this3.refreshModel();
          }
        }).finally(function () {
          set(_this3, 'loading', false);
        });
      }
    }
  });
});