define('client/components/modal-dialog/update-consent/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),
    dynamicFormsMethods: inject.service(),
    reasonsForChange: null,
    reasonForChangeId: null,
    comment: null,
    loading: null,
    hideCloseButton: null,
    isError: false,
    errorMessage: null,

    init: function init() {
      this._super.apply(this, arguments);

      set(this, 'reasonsForChange', []);
      set(this, 'comment', '');
      set(this, 'loading', true);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      // Wait until the pageInfo has been loaded then populate the modal with information from the database
      // To avoid slowing down the rest of the page, we only do this once the modal is displayed
      if (get(this, 'showUpdateConsentsAndPermitsModal') === true && get(this, 'pageInfo.existingCapDecisionId') !== undefined) {
        set(this, 'loading', true);
        get(this, 'remoteMethods').updateConsentsPermitsDialogInfo(get(this, 'pageInfo.existingCapDecisionId'), get(this, 'pageInfo.applicationTypeCode')).then(function (_ref) {
          var snapshotMetadata = _ref.snapshotMetadata,
              reasonsForChange = _ref.reasonsForChange;

          set(_this, 'reasonForChangeId', snapshotMetadata.reasonForChangeId);
          set(_this, 'comment', snapshotMetadata.comment);
          set(_this, 'reasonsForChange', reasonsForChange);
          set(_this, 'loading', false);
        }).catch(function (error) {
          console.error(error);
          set(_this, 'errorMessage', 'Failed to load dialog.');
          set(_this, 'isError', true);
          set(_this, 'loading', false);
        });
      }
    },


    consentNumber: computed.alias('pageInfo.consentNumber'),
    isPublished: computed.alias('pageInfo.isPublished'),
    modalHeading: 'Update record',

    actions: {
      updateConsentsAndPermits: function updateConsentsAndPermits() {
        var _this2 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        var capDecisionId = get(this, 'pageInfo.existingCapDecisionId');
        var applicationDecisionId = get(this, 'pageInfo.applicationDecisionId');
        var reasonForChangeId = get(this, 'reasonForChangeId');
        var comment = get(this, 'comment');
        get(this, 'remoteMethods').updateConsentsAndPermits(capDecisionId, reasonForChangeId, applicationDecisionId, comment).then(function (results) {
          set(_this2, 'loading', false);
          if (results.error !== false) {
            get(_this2, 'flashMessages.danger')(results.error);
          } else {
            _this2.get('setShowUpdateConsentsAndPermitsModal')(false);
            // take the user to the Consents & Permits record
            get(_this2, 'dynamicFormsMethods').transitionToForm({
              route: 'consents-section.summary',
              formRootPath: 'decision',
              parentId: capDecisionId,
              mode: 'view'
            });
          }
        }).catch(function (error) {
          var errors = error.errors;
          var errorMessage = 'Failed to update record.';
          if (errors && errors.length && errors[0].detail && errors[0].detail.message) {
            errorMessage = errors[0].detail.message;
          }
          get(_this2, 'flashMessages.danger')(errorMessage);
          set(_this2, 'errorMessage', errorMessage);
          set(_this2, 'isError', true);
          set(_this2, 'loading', false);
        });
      }
    }
  });
});