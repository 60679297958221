define('client/components/dynamic-form/dialogues/summary-errors/component', ['exports', 'npm:lodash/find'], function (exports, _find) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    /**
     * This computed property orders the error summary data returned from the server by using the menu structure as
     * a guide.  Therefore the modal listing the forms and their status will list the forms in the same order they
     * are shown in the menu.
     */
    orderedForms: computed('errors.forms', 'menu.forms', function () {
      var menuForms = get(this, 'menu.forms');
      var errorForms = get(this, 'errors.forms');
      var orderedForms = [];

      /**
       * This function can be used recursively to match each menuForm with its counterpart in the summary errors
       * array to build up a flat array of forms/statuses
       */
      var matchMenu = function matchMenu(menuForms) {
        menuForms.forEach(function (menuForm) {
          var matchedForm = (0, _find.default)(errorForms, function (errorForm) {
            return menuForm.formInstanceId === errorForm.formInstanceId;
          });

          // TODO: Instead of checking against the 'consent' templateKey,
          // check if a different form key can be added/used to flag that the matchedForm should not be pushed to orderedForms
          if (matchedForm && matchedForm.templateKey !== 'consent' && matchedForm.status !== 'COMPLETE') {
            orderedForms.push(matchedForm);
          }

          if (menuForm.forms) {
            matchMenu(menuForm.forms);
          }
        });
      };

      // Kick off with the top level
      matchMenu(menuForms);
      return orderedForms;
    })
  });
});