define('client/components/generic-search/load/component', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    remoteMethods: Ember.inject.service('generic-search-remote-methods'),
    focusNextRender: null,
    isSavedSearchNotificationEnabled: _environment.default.ENV_VARS.ENABLE_SAVED_SEARCH_NOTIFICATION,

    actions: {
      deleteQuery: function deleteQuery(row) {
        var _this = this;

        Ember.set(row, 'deleteLoading', true);
        this.remoteMethods.deleteSavedQuery(row.queryId).then(function () {
          _this.savedSearchList.content.rows.removeObject(row);
        });
      },
      enableRenameQuery: function enableRenameQuery(row) {
        Ember.set(row, 'newQueryName', row.queryName);
        this.savedSearchList.content.rows.setEach('renameEnabled', false);
        Ember.set(this, 'focusNextRender', 'generic-search-rename-query-' + row.queryId);
        Ember.set(row, 'renameEnabled', true);
      },
      updateSubscription: function updateSubscription(row) {
        this.remoteMethods.updateSubscription(row.queryId).then(function (response) {
          Ember.set(row, 'isSubscribed', response.isSubscribed);
        });
      },
      cancelRenameQuery: function cancelRenameQuery(row) {
        Ember.set(row, 'renameEnabled', false);
        Ember.set(row, 'newQueryName', row.queryName);
      },
      renameQuery: function renameQuery(row) {
        Ember.set(row, 'renameLoading', true);
        this.remoteMethods.renameQuery(row.queryId, row.newQueryName).then(function () {
          Ember.set(row, 'queryName', row.newQueryName);
          Ember.set(row, 'renameEnabled', false);
          Ember.set(row, 'renameLoading', false);
        });
      },
      renameQueryKeyDown: function renameQueryKeyDown(row, e) {
        // Save when the enter key is pressed
        if (e.keyCode === 13 && row.newQueryName.length) {
          this.actions.renameQuery.call(this, row);
        }
      }
    },

    didRender: function didRender() {
      if (this.focusNextRender !== null) {
        document.getElementById(this.focusNextRender).focus();
      }
      Ember.set(this, 'focusNextRender', null);
    }
  });
});