define('client/components/dynamic-form/view/postal-address/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/libs/validation-error-lib'], function (exports, _dynamicFormElement, _validationErrorLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;


  var errorKeys = [];
  for (var i = 1; i <= 5; i++) {
    errorKeys.push('stateValue.address' + i + '.0.errors');
  }

  var addressLineKeys = [];
  for (var _i = 1; _i <= 5; _i++) {
    addressLineKeys.push('stateValue.address' + _i + '.0.val');
  }

  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    addressLines: computed.apply(undefined, addressLineKeys.concat(['stateValue.manualEntry.0.val', 'stateValue.careOf.0.val', function () {
      var _this = this;

      var lines = [];
      if (get(this, 'stateValue.careOf.0.val')) {
        lines.push(get(this, 'stateValue.careOf.0.val'));
      }
      addressLineKeys.forEach(function (key) {
        var line = get(_this, key);
        if (line) lines.push(line);
      });
      return lines;
    }])),

    errors: computed.apply(undefined, errorKeys.concat([function () {
      // merges any errors from the parent, or firstName/surname child elements, into one array
      var errors = [];
      for (var _i2 = 1; _i2 <= 5; _i2++) {
        // note: address field names are 1-indexed
        errors = errors.concat(get(this, 'stateValue.address' + _i2 + '.0.errors') || []);
      }
      errors = errors.concat(get(this, 'stateValue.postcode.0.errors') || []);
      return (0, _validationErrorLib.deDupErrors)(errors);
    }]))

  });
});