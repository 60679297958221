define('client/components/component-workflows/documents/list/component', ['exports', 'ember-light-table', 'client/libs/documents-component', 'client/components/component-workflows/documents/list/columns', 'client/config/environment', 'ember-copy'], function (exports, _emberLightTable, _documentsComponent, _columns, _environment, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),
    router: inject.service(),

    page: 1,
    pageSize: _environment.default.embeddedTablePageSize,
    total: 0,
    columns: [],
    rows: [],
    canEdit: false,
    loading: true,
    reloadQuery: null,
    lastQuery: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var parentId = get(this, 'model.parentId');
      // the tab location code is either set explicitly in the config for this workflow or we can just use the formRootRoute
      var tabLocationCode = get(this, 'model.activeWorkflow.config.tabLocationCode') || get(this, 'model.formRootRoute');
      // consents and permits section uses cap_decision_id, everything else uses application_id so default to that
      var parentIdType = getWithDefault(this, 'model.activeWorkflow.config.parentIdType', 'application_id');

      get(this, 'remoteMethods').documentList(parentId, parentIdType, tabLocationCode).then(function (_ref) {
        var rows = _ref.rows,
            canEdit = _ref.canEdit;

        set(_this, 'rows', rows);
        set(_this, 'canEdit', canEdit);
        set(_this, 'loading', false);
      });
    },


    pageTitle: computed('model.formRootRoute', function () {
      return _documentsComponent.default.getPageTitle(get(this, 'model'));
    }),

    menu: computed('pageTitle', function () {
      return {
        forms: [{
          aliasForParent: true, // required to get the label to display
          label: get(this, 'pageTitle'),
          active: true,
          complete: false
        }]
      };
    }),

    table: computed('rows', 'page', 'canEdit', function () {
      var model = get(this, 'model');

      var rows = (0, _emberCopy.copy)(get(this, 'rows'), true).map(function (row) {
        row.actions = {
          id: row.id,
          view: { 'label': 'view', 'path': 'documents/' + row.id + '/view' },
          route: model.formRootRoute
        };
        return row;
      });

      set(this, 'total', rows.length);
      var page = get(this, 'page');
      var pageSize = get(this, 'pageSize');
      var paginatedRows = rows.slice((page - 1) * pageSize, page * pageSize);
      return new _emberLightTable.default(_columns.default, paginatedRows);
    }),
    actions: {
      setPage: function setPage(page) {
        set(this, 'page', page);
      },
      onAfterResponsiveChange: function onAfterResponsiveChange(matches) {
        if (matches.indexOf('largeDesktop') > -1) {
          this.get('table.expandedRows').setEach('expanded', false);
        }
      },
      rowClicked: function rowClicked(row, event) {
        // ignore clicks on links within the row
        if (getWithDefault(event, 'target.tagName', undefined) === 'A') {
          return;
        }
        var actions = get(row, 'actions');
        get(this, 'router').transitionTo(actions.route, actions.view.path);
      },
      search: function search(query) {
        var _this2 = this;

        if (query === this.lastQuery) {
          return;
        }

        if (this.loading === true) {
          set(this, 'reloadQuery', query);
          return;
        }

        set(this, 'loading', true);

        var parentId = get(this, 'model.parentId');
        // the tab location code is either set explicitly in the config for this workflow or we can just use the formRootRoute
        var tabLocationCode = get(this, 'model.activeWorkflow.config.tabLocationCode') || get(this, 'model.formRootRoute');
        // consents and permits section uses cap_decision_id, everything else uses application_id so default to that
        var parentIdType = getWithDefault(this, 'model.activeWorkflow.config.parentIdType', 'application_id');

        get(this, 'remoteMethods').documentList(parentId, parentIdType, tabLocationCode, query).then(function (_ref2) {
          var rows = _ref2.rows,
              canEdit = _ref2.canEdit;

          set(_this2, 'lastQuery', query);
          set(_this2, 'rows', rows);
          set(_this2, 'canEdit', canEdit);
          set(_this2, 'loading', false);
          if (_this2.reloadQuery !== null) {
            var q = _this2.reloadQuery;
            set(_this2, 'reloadQuery', null);
            _this2.send('search', q);
          }
        });
      }
    }
  });
});