define('client/constants', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  // config.ENV_VARS should be injected into index.htm by the server,
  // but we also need to ensure its there when running tests
  if (_environment.default.ENV_VARS === undefined) {
    _environment.default.ENV_VARS = { COUNCIL_INFO: {} };
  }

  exports.MIN_REPEATS = 1;

  exports.APPLICATION_STATUSES = {
    DRAFT: 'Draft',
    SUBMITTED: 'Submitted'

    // After time period defined, state JSON for a form will be sent to the server and persisted in case the user's session times out.
  };exports.AUTO_SAVE_DEBOUNCE_MS = 3000;

  exports.TOOL_TIP_MAX_CHAR_LENGTH = 4000;

  exports.LAST = 'last';

  exports.FORM_STATES = {
    'NOT_STARTED': 'NOT_STARTED',
    'IN_PROGRESS': 'IN_PROGRESS',
    'COMPLETE': 'COMPLETE'
  };

  exports.FORM_STATES_DISPLAY = {
    'NOT_STARTED': 'Not started',
    'IN_PROGRESS': 'In progress',
    'COMPLETE': 'Complete'
  };

  exports.DOWNLOADABLE_APPLICATIONS = {
    'Duty planner enquiry': 'https://www.marlborough.govt.nz/services/resource-consents/duty-planner-service-information/?ed-step=1'
  };

  exports.DELETED_ITEM_DEFAULT_SUFFIX = ' (deactivated)';

  exports.MODE = {
    EDIT: 'edit',
    VIEW: 'view',
    PRINT_VIEW: 'print-view'
  };

  exports.SIDE_MENU_PREFIX = {};
  exports.SIDE_MENU_PREFIX[exports.MODE.VIEW] = 'Viewing a ';
  exports.SIDE_MENU_PREFIX[exports.MODE.EDIT] = 'Editing a ';

  exports.SIDE_MENU_PREFIX_A = {};
  exports.SIDE_MENU_PREFIX_A[exports.MODE.VIEW] = 'Viewing an ';
  exports.SIDE_MENU_PREFIX_A[exports.MODE.EDIT] = 'Editing an ';

  exports.BREADCRUMB_PREFIX = {};
  exports.BREADCRUMB_PREFIX[exports.MODE.VIEW] = 'View ';
  exports.BREADCRUMB_PREFIX[exports.MODE.EDIT] = 'Edit ';

  exports.TOP_LEVEL_BREADCRUMB = { path: 'home-section', name: 'Home' };

  exports.HOME_TABS = [_environment.default.ENV_VARS.COUNCIL_INFO.enablePubliclyNotifiedApplications === 'true' && _environment.default.ENV_VARS.MVP2020_INITIAL_ROLLOUT !== true && { path: 'home-section.index', name: 'Publicly notified applications', unauthenticatedOnly: true }, { path: 'home-section.dashboard', name: 'Dashboard', authenticatedOnly: true }, { path: 'home-section.submitted-applications', name: 'Applications', authenticatedOnly: _environment.default.ENV_VARS.COUNCIL_INFO.enablePublicSearch !== 'true', permissionsRequired: ['global.view_applications_tab'] }, /* Temporary Perm SWC 21.07.20 */
  _environment.default.ENV_VARS.COUNCIL_INFO.enableConsentsAndPermits === 'true' && { path: 'home-section.issued-consents', name: 'Issued consents', permissionsRequired: ['global.view_consents_and_permits_tab'] }, /* Temporary Perm SWC 21.07.20 */
  _environment.default.ENV_VARS.COUNCIL_INFO.enablePubliclyNotifiedApplications === 'true' && { path: 'home-section.publicly-notified-applications', name: 'Publicly notified applications', authenticatedOnly: true, permissionsRequired: ['global.view_notified_applications_tab'] }, /* Temporary Perm SWC 21.07.20 */
  _environment.default.ENV_VARS.COUNCIL_INFO.enableReportingDashboard === 'true' && { path: 'home-section.reporting-dashboard', name: 'Insights', authenticatedOnly: true, permissionsRequired: ['global.view_reporting_dashboard'] }].filter(function (x) {
    return x !== false;
  });

  /*
    Takes pageInfo object and returns true
    1. If the application hasn't been submitted or
    2. If the application has been submitted but the council is an agent
  */
  var notSubmittedOrAgent = function notSubmittedOrAgent(_ref) {
    var isSubmitted = _ref.isSubmitted;

    return !isSubmitted || isAgent;
  };

  /**
   * Returns true if:
   * 1. s88 checklist is finalised
   * 2. Application is migrated (no s88 checklist step)
   * @param {Object} pageInfo
   */
  var isS88FinalisedOrMigrated = function isS88FinalisedOrMigrated(_ref2) {
    var isFinalisedS88CheckDecision = _ref2.isFinalisedS88CheckDecision,
        isMigrated = _ref2.isMigrated;

    return isFinalisedS88CheckDecision || isMigrated;
  };

  var isAgent = !!_environment.default.ENV_VARS && !!_environment.default.ENV_VARS.COUNCIL_INFO && _environment.default.ENV_VARS.COUNCIL_INFO.isAgent === 'true';

  exports.PROCESSING_TABS = [{
    key: 'submitted-application',
    title: 'Application'
  }, {
    key: 'checklist',
    title: 'Checklist',
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }, {
    key: 'circulation',
    title: 'Circulation',
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }, {
    key: 'additional-information',
    title: 'Additional information',
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }, {
    key: 'notification-decision',
    title: 'Notification decision',
    hideIf: function hideIf(pageInfo) {
      return notSubmittedOrAgent(pageInfo) || !isS88FinalisedOrMigrated(pageInfo);
    },
    permission: 'global.access_phase_2'
  }, {
    key: 'notification',
    title: 'Notification',
    hideIf: function hideIf(pageInfo) {
      return notSubmittedOrAgent(pageInfo) || !isS88FinalisedOrMigrated(pageInfo);
    },
    permission: 'global.access_phase_2'
  }, {
    key: 'hearing',
    title: 'Hearing',
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }, {
    key: 'decision',
    title: 'Decision',
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }, {
    key: 'additional-workflow',
    title: 'Additional workflow',
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }, {
    separator: true
  }, {
    key: 'history',
    title: 'History',
    permission: 'global.view_history_tab',
    isEmphasisedLink: true,
    hideIf: notSubmittedOrAgent
  }, {
    key: 'fees',
    title: 'Fees',
    isEmphasisedLink: true,
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }, {
    key: 'tracking',
    title: 'Tracking',
    isEmphasisedLink: true,
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }, {
    key: 'correspondence',
    title: 'Correspondence',
    isEmphasisedLink: true,
    hideIf: notSubmittedOrAgent,
    permission: 'global.access_phase_2'
  }];

  exports.CONSENTS_TABS = [{
    key: 'summary',
    title: 'Summary'
  }, {
    key: 'history',
    title: 'History',
    permission: 'global.view_history_tab',
    isEmphasisedLink: true
  }, {
    key: 'consent-documents',
    title: 'Documents'
  }];

  exports.TYPEAHEAD_COMPONENT = {
    DEBOUNCE_MS: 500, // minimum time (in milliseconds) between API lookups
    MIN_CHARS: 3 // minimum characters that must be typed before a lookup is performed using the API
  };

  exports.BEST_PRACTICE_BLOG_ENABLED = _environment.default.ENV_VARS.COUNCIL_INFO.websiteBestPracticeGuides !== undefined;
  exports.BEST_PRACTICE_BLOG_URL = {
    HOST: _environment.default.ENV_VARS.COUNCIL_INFO.websiteBestPracticeGuides,
    API_PATH: 'wp-json/wp/v2/posts/',
    LIMIT_POSTS_QUERY_STRING: 'per_page='
  };

  exports.FULL_WORKFLOW_TEXT = 'full';

  exports.SEARCH_TYPES = {
    LIST: 'list',
    MAP: 'map'

    // String used as a property number in property typeahead when not related to a property.
  };exports.NPRC = 'NPRC-01';
  exports.NPRC_DESCRIPTION = 'Application is not related to a property number';

  // Note: Restricted to our domain
  exports.MAPBOX_API_KEY = 'pk.eyJ1IjoibWVkaWFzdWl0ZSIsImEiOiJjanY3ZDN3MGowODNvM3puN3ppdnBwcTdpIn0.oQTSopfOvXdll_3CJaMLRQ';

  exports.HIDE_NAV_ROUTES = ['home-section.issued-consents-map', 'home-section.submitted-applications-map'];
  // See https://gis.marlborough.govt.nz/server/rest/services/DataPublic/Labels/MapServer for the full list.
  exports.MDC_MAP_EXTRA_LABELS = {
    ADDRESS_NUMBER: 0,
    LEGAL_DESCRIPTION: 2,
    PROPERTY_NUMBER: 4,
    ROAD_NAME: 6,
    PARCEL_AREA: 8,
    PLACE_NAME: 10
  };

  exports.VIRUS_DETECTED_MESSAGE = 'Antivirus scan has determined that this file is potentially infected with a virus.';

  // While virus scanning a file, the client polls the server for status updates this often (interval in ms)
  exports.VIRUS_SCAN_CLIENT_REFRESH_RATE = 1000;

  exports.VIEW_PORT = {
    MOBILE: 'mobile'
  };
});