define('client/components/dynamic-form/view/orderable-text-fields/orderable-text-field-element/sub-heading/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',

    deEntitiedStateValue: computed(function () {
      var txt = document.createElement('textarea');
      txt.innerHTML = get(this, 'stateValue');
      return txt.value;
    })
  });
});