define('client/components/dynamic-form/edit/section/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement', 'client/mixins/components/subform-creation', 'ember-copy'], function (exports, _dynamicFormElement, _complexFormElement, _subformCreation, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, _subformCreation.default, {
    dynamicFormsMethods: inject.service(),
    router: inject.service(),
    flashMessages: inject.service(), // Need to add this here to fix an issue with section-test not init'ing the service
    showConfirmationModal: false,

    // In root sections, the state value is for all instances
    // Note - something feels a bit off here
    stateValue: computed.alias('formElementState'),
    classNames: ['h-clearfix', 'l-section-wrapper'],
    // Wrapper applies margin top/bottom only when element is displayed:
    classNameBindings: ['isHidden:l-section-wrapper--empty', 'isRepeatable:l-repeatable-section-wrapper'],
    isHidden: computed('display', function () {
      return !get(this, 'display');
    }),
    isRepeatable: computed('formElement.repeatable', function () {
      return get(this, 'formElement.repeatable');
    }),

    updateDeletedHide: function updateDeletedHide(formElement, index, key) {
      var status = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      var state = (0, _emberCopy.copy)(get(this, 'state'), true);
      state[formElement.name][index] = Object.assign({}, state[formElement.name][index], _defineProperty({}, key, status));

      get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'));
    },


    actions: {

      /**
       * Deletes the indicated repeated block of all elements within this section's `formElements` array
       */
      delete: function _delete(formElement, index) {
        var _this = this;

        var postSaveCallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        // If this section is wrapping a form-link type, we need to do a number of things differently:
        // 1. Show a confirmation dialogue to the user to ensure they really do want to delete all the consent
        // And on confirmation:
        // 2. Mark the state for the container iteration that holds the form as deleted
        // 3. Once that has finished flushing through the state tree (computeds, observers, etc) then save state to the server
        // 4. As part of this save, refresh the menu
        if (get(this, 'isFormContainer')) {
          set(this, 'confirmSuccessFunction', function () {
            _this.updateDeletedHide(formElement, index, 'deleted');
            set(_this, 'showConfirmationModal', false);
            Ember.run.later(function () {
              var updateMenu = true;
              var hideSuccessBanner = true;
              return get(_this, 'save')(updateMenu, hideSuccessBanner, postSaveCallback);
            }, 0);
          });
          set(this, 'showConfirmationModal', true);
        } else {
          // We only need to do item (2) from above, mark the state as deleted
          this.updateDeletedHide(formElement, index, 'deleted');
        }
      },


      /**
       * Add another block of all elements within this section's `formElements` array
       */
      add: function add() {
        var _this2 = this;

        // If this section is wrapping a form-link type, we need to do something different
        if (get(this, 'isFormContainer')) {
          var childElements = get(this, 'formElement.formElements');
          var childVersionId = get(childElements, '0.childFormTemplateVersionId');

          // Check if displayUnsavedChanges modal is passed down
          var displayChanges = get(this, 'displayUnsavedChanges');

          if (displayChanges) {
            // If displayUnsavedChanges modal trigger is passed down
            // Fire that with the form create as a callback.
            // If there are no changes, will fire the callback automatically
            displayChanges(null, function () {
              get(_this2, 'addNewChildForm').perform(childVersionId, get(childElements, '0.name'), get(childElements, '0.label'));
            });
          } else {
            get(this, 'addNewChildForm').perform(childVersionId, get(childElements, '0.name'), get(childElements, '0.label'));
          }

          return;
        }

        // This section does not wrap a form-link type, so we want to simply repeat the enclosing elements
        var elementName = get(this, 'formElement.name');
        var existingCount = get(this, 'state.' + elementName + '.length');
        get(this, 'populateFunctions').buildState({}, get(this, 'formElement.formElements'), existingCount + 1).then(function (newState) {
          var existingState = (0, _emberCopy.copy)(get(_this2, 'state'), true);
          existingState[elementName].push({ id: 'new', val: newState });
          _this2.updateStateKey(elementName, existingState[elementName]);
        });
      }
    }
  });
});