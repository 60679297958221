define('client/components/component-workflows/history/component', ['exports', 'client/libs/history-component'], function (exports, _historyComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({

    // parse the URL to determine the component to load
    path: computed('model', 'model.path', function () {
      var parts = get(this, 'model.path').split('/').filter(function (x) {
        return x;
      });
      var len = parts.length;
      var view = parts[len - 1] === 'view';
      return {
        view: view
      };
    }),

    subComponent: computed('path', function () {
      var path = get(this, 'path');
      return path.view ? 'view' : 'list';
    }),

    pageTitle: computed('model.formRootRoute', function () {
      return _historyComponent.default.getPageTitle(get(this, 'model'));
    })

  });
});