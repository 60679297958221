define('client/components/dynamic-form/view/section/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement'], function (exports, _dynamicFormElement, _complexFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    classNameBindings: ['formElement.repeatable:c-view-section', 'formElementName'],

    isTopLevelForm: computed('form.formInstance.id,form.formInstance.topLevelFormInstanceId', function () {
      if (get(this, 'form.formInstance.id') !== undefined) {
        return get(this, 'form.formInstance.id') === get(this, 'form.formInstance.topLevelFormInstanceId');
      }
      return false;
    }),

    showTableForPrint: computed('formElement.extendedAttributes.displayTableInPrintView,isFormContainer,isTopLevelForm', function () {
      return !get(this, 'isTopLevelForm') && get(this, 'formElement.formElements.0.extendedAttributes.displayTableInPrintView') && get(this, 'isFormContainer');
    }),

    showChildFormsForPrint: computed('formElement.extendedAttributes.displayTableInPrintView,isFormContainer,isTopLevelForm', function () {
      return !get(this, 'isTopLevelForm') && get(this, 'formElement.formElements.0.extendedAttributes.displayChildFormsInPrintView') && get(this, 'isFormContainer');
    }),

    // Insert the name of this formElement as a css class for hacky styling
    formElementName: Ember.computed('formElement.name', function () {
      return 'c-view-section--' + Ember.String.dasherize(Ember.get(this, 'formElement.name'));
    })
  });
});