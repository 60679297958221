define('client/components/generic-search/table/cell/text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    tagName: '',
    displayValue: Ember.computed(function () {
      if (this.value !== null && this.value !== undefined) {
        var valueType = _typeof(this.value);
        var displayValue = this.value;
        if (valueType === 'string') {
          displayValue = this.value.length > 0 ? this.value : '-';
        }
        return displayValue;
      }
      return '-';
    })
  });
});