define('client/routes/consents-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Route.extend({
    gateKeeper: inject.service(),
    remoteMethods: inject.service('resource-consent-remote-methods'),

    // This is to delay processing of the route until the session is loaded
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return get(this, 'gateKeeper').isSessionLoaded();
    },
    model: function model(_ref) {
      var _this = this;

      var parent_id = _ref.parent_id,
          mode = _ref.mode;

      var tabConfigPromise = get(this, 'remoteMethods').getTabConfig('consents', parent_id);

      var pageInfoPromise = get(this, 'remoteMethods').getConsentsPageInfo(parent_id).then(function (pageInfo) {
        // if the cap_decision is unpublished, we need to check whether the user can view unpublished cap_decisions.
        // if they can't then transition to the maintainance page.
        if (pageInfo.isPublished !== true) {
          return get(_this, 'gateKeeper').canEnsureLoaded('global.view_unpublished_cap_record').then(function (canViewUnpublished) {
            if (canViewUnpublished !== true) {
              _this.transitionTo('consents-section.maintainance');
            }
            return pageInfo;
          });
        }

        return pageInfo;
      });
      return Ember.RSVP.hash({
        parentId: parent_id,
        mode: mode,
        isPublished: pageInfoPromise.then(function (_ref2) {
          var isPublished = _ref2.isPublished;
          return isPublished;
        }),
        pageInfo: pageInfoPromise,
        tabConfig: tabConfigPromise
      });
    },

    pageInfo: {},
    actions: {
      showPublishConsentModal: function showPublishConsentModal() {
        var bool = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        set(this.controller, 'publishConsent', bool);
      },
      setPageInfo: function setPageInfo(pageInfo) {
        set(this, 'pageInfo', pageInfo);
      },
      refreshConsentsSectionRoute: function refreshConsentsSectionRoute() {
        get(this, 'remoteMethods').cache.flushAll();
        return this.refresh();
      }
    },

    afterModel: function afterModel(model, transition) {
      this._super.apply(this, arguments);
      if (model.pageInfo.notFound === true) {
        get(this, 'flashMessages.danger')('Resource consent application could not be found');
        this.replaceWith('object-not-found');
      }
    }
  });
});