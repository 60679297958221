define('client/components/generic-search/save/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    remoteMethods: Ember.inject.service('generic-search-remote-methods'),

    newQueryName: null,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'newQueryName', '');
    },
    didRender: function didRender() {
      var elem = document.getElementById('saveSearchNameInput');
      if (elem !== null) {
        elem.focus();
      }
    },


    actions: {
      saveQuery: function saveQuery() {
        var _this = this;

        // Save a new query
        var saveQuery = Object.assign({}, this.currentSearchQuery);
        this.remoteMethods.saveQuery(saveQuery, this.newQueryName).then(function (queryId) {
          if (_this.onSave) {
            _this.onSave({
              queryId: queryId,
              query: _this.currentSearchQuery,
              name: _this.newQueryName
            });
          }
        });
      },
      keyDown: function keyDown(e) {
        // Save when the enter key is pressed
        if (e.keyCode === 13 && this.newQueryName.length) {
          this.send('saveQuery');
        }
      }
    }
  });
});