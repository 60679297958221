define('client/components/home-section/reporting-dashboard/open-applications/page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var set = Ember.set;
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'searchQuery', {
        searchTypeKey: this.searchTypeKey,
        requestedPage: 1,
        requestedPageLength: 10,
        filterSet: {
          booleanOperator: 'AND',
          filters: []
        }
      });
    },


    actions: {
      updateFilters: function updateFilters(_ref) {
        var filterKey = _ref.filterKey,
            filterOperator = _ref.filterOperator,
            filterValue = _ref.filterValue;

        var _get = this.get('searchQuery.filterSet'),
            filters = _get.filters;

        var appliedFilters = [].concat(_toConsumableArray(filters));
        var filterIndex = filters.findIndex(function (filter) {
          return filter.filterKey === filterKey;
        });
        var isSameFilterApplied = false;

        // Remove the existing filter if the same filter key already exists
        if (filterIndex !== -1) {
          var currentValue = filters[filterIndex].filterValue;

          appliedFilters = [].concat(_toConsumableArray(appliedFilters.slice(0, filterIndex)), _toConsumableArray(appliedFilters.slice(filterIndex + 1)));
          isSameFilterApplied = currentValue === filterValue;
        }

        // Append the new filter if its not the same filter key and value being applied again
        if (!isSameFilterApplied) {
          appliedFilters = [].concat(_toConsumableArray(appliedFilters), [{ filterKey: filterKey, filterOperator: filterOperator, filterValue: filterValue }]);
        }

        set(this, 'searchQuery.filterSet.filters', appliedFilters);
      },
      removeFilter: function removeFilter(filterKey) {
        var _get2 = this.get('searchQuery.filterSet'),
            filters = _get2.filters;

        var updatedFilters = filters.filter(function (filter) {
          return filter.filterKey !== filterKey;
        });
        set(this, 'searchQuery.filterSet.filters', [].concat(_toConsumableArray(updatedFilters)));
      },
      resetFilterSet: function resetFilterSet() {
        this.searchQuery.filterSet.filters = [];
      }
    }
  });
});