define('client/app', ['exports', 'client/resolver', 'ember-load-initializers', 'client/config/environment', 'client/mixins/authenticated-route'], function (exports, _resolver, _emberLoadInitializers, _environment, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = void 0;

  Ember.MODEL_FACTORY_INJECTIONS = true;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  // Promise polyfill for IE11
  if (window.Promise === undefined) {
    window.Promise = Ember.RSVP.Promise;
  }

  // Add any environment variables passed from the server into the config.
  // See client/app/index.html and server/ember/views.py for how these are
  // injected. Removes it off the global object once it's in config so that
  // nowhere in the app relies on it.
  _environment.default.ENV_VARS = window.ENV_VARS;
  delete window.ENV_VARS;

  // Raygun configuration, see ember-cli-raygun for details.
  if (_environment.default.ENV_VARS.RAYGUN_API_KEY) {
    _environment.default.raygun.apiKey = _environment.default.ENV_VARS.RAYGUN_API_KEY;
    _environment.default.raygun.enablePulse = true;
  } else {
    _environment.default.raygun.enabled = false;
    _environment.default.raygun.beQuiet = true; // prevents annoying console message in dev
  }

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  // Replace the default Ember route with one containing the
  // "AuthenticatedRouteMixin" so that a logged in user is required by default.
  // Adds a new "UnprotectedRoute" that can be used for the other routes that
  // don't require a logged in user e.g. Landing page.
  Ember.UnprotectedRoute = Ember.Route;
  Ember.Route = Ember.Route.extend(_authenticatedRoute.default);

  exports.default = App;
});