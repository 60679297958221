define('client/routes/processing-section/tracking', ['exports', 'client/mixins/routes/generic-workflows'], function (exports, _genericWorkflows) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    remoteMethods: inject.service('resource-consent-remote-methods'),

    // Function to be called after save (in edit-form-action.js)
    // This updates the pageInfo, as the status pill can be changed from within this workflow
    postFormSaveFunction: function postFormSaveFunction() {
      var processingModel = this.modelFor('processing-section');
      get(this, 'remoteMethods').getProcessingPageInfo(processingModel.pageInfo.applicationId, true).then(function (pageInfo) {
        set(processingModel, 'pageInfo', pageInfo);
      });
    },


    workflows: [{
      key: 'processing_breakdown_section',
      path: 'tracking',
      title: 'Tracking'
    }]
  });
});