define('client/components/dynamic-form/view/typeahead-property/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/components/dynamic-form/edit/typeahead-property/config', 'client/constants'], function (exports, _dynamicFormElement, _config, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',
    searchService: inject.service(_config.searchServiceName),
    childFormElements: _config.childFormElements,
    isNPRC: false,
    loading: true,
    NPRC_DESCRIPTION: _constants.NPRC_DESCRIPTION,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // set the initial state of the child elements
      var propertyNumber = get(this, 'stateValue.propertyNumber.0.val');

      // special case if NPRC is set don't bother doing the lookup.
      if (propertyNumber === _constants.NPRC) {
        set(this, 'isNPRC', true);
        set(this, 'loading', false);
        return;
      }

      // if we have a valid propertyNumber and we can find it using the API,
      // save the details - both to the state and as properties on the ember component
      set(this, 'loading', true);
      get(this, 'searchService').getProperty(propertyNumber).then(function (details) {
        _config.childFormElements.forEach(function (childFormElement) {
          set(_this, childFormElement.name, childFormElement.getValue(details, get(_this, 'formElement.extendedAttributes')));
        });
      }).catch(function (_ref) {
        var message = _ref.message;
        return console.log('Warning: Property Lookup: ' + message);
      }).finally(function () {
        return set(_this, 'loading', false);
      });
    }
  });
});