define('client/components/dynamic-form/edit/form-link-table-button/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/subform-creation'], function (exports, _dynamicFormElement, _subformCreation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var get = Ember.get,
      computed = Ember.computed,
      getWithDefault = Ember.getWithDefault,
      set = Ember.set;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _subformCreation.default, {
    elementPath: computed.alias('formElement.extendedAttributes.tablePath'),
    pseudoElement: {},

    showUserMustSave: false,

    // Need the parent id, so we know where the button is located
    componentStateId: computed('formElementState', function () {
      return get(this, 'formElementState')[0]['id'];
    }),

    sendButtonPositionId: computed.alias('formElement.extendedAttributes.sendButtonPositionId'),

    setElementConfig: function setElementConfig() {
      if (get(this, 'locatedFormElement')) return true;

      var elementPath = getWithDefault(this, 'elementPath', []);

      // Get elements from the form
      var rootElements = get(this, 'form.formElements');

      // Find the target form element by navigating the form hierarchy
      var formElement = elementPath.reduce(function (element, segment) {
        if (element != null && get(element, 'formElements') != null) {
          return getWithDefault(element, 'formElements', []).find(function (element) {
            return get(element, 'name') === segment;
          });
        }
      }, { formElements: rootElements });

      if (formElement == null) {
        return false;
      }

      set(this, 'locatedFormElement', formElement);

      var childElements = get(formElement, 'formElements');

      if (childElements == null) {
        set(this, 'locatedFormElement', null);
        return false;
      }

      set(this, 'pseudoElement.childFormVersionId', get(childElements, '0.childFormTemplateVersionId'));
      set(this, 'pseudoElement.childElementName', get(childElements, '0.name'));
      set(this, 'pseudoElement.formName', get(childElements, '0.label'));
      set(this, 'pseudoElement.elementName', get(formElement, 'name'));
      set(this, 'pseudoElement.formInstanceId', get(this, 'formInstanceId'));
      set(this, 'pseudoElement.label', getWithDefault(formElement, 'label', 'object'));

      // Logic assumes that this element is only nested inside other sections
      // Fairly safe assumption, as currently only sections and complex elements can contain other elements
      // and complex elements (other than sections) do not contain form link tables
      if (elementPath.length < 2) {
        // If one or no parent elements, the parent section is the "root" (form container)
        // In this case, pass undefined.
        set(this, 'pseudoElement.parentSectionId', undefined);
      } else {
        var rootState = getWithDefault(this, 'form.state', []);

        var _elementPath$slice$re = elementPath.slice(0, elementPath.length - 1).reduce(function (stateInfo, segment) {
          // If the passed state is not undefined, continue
          if (stateInfo != null) {
            var childState = stateInfo.childState;
            // Check if the state is an object, and if the object contains the correct key

            if ((typeof childState === 'undefined' ? 'undefined' : _typeof(childState)) === 'object' && Object.keys(childState).includes(segment)) {
              var stateObject = childState[segment][0];

              return { currentId: stateObject.id, childState: stateObject.val };
            }
          }
        }, { currentId: undefined, childState: rootState }),
            parentElementId = _elementPath$slice$re.currentId;

        set(this, 'pseudoElement.parentSectionId', parentElementId);
      }

      return true;
    },
    createChildForm: function createChildForm() {
      // Pull in values that would normally be pulled from the section
      var overriddenContext = Object.assign({}, get(this, 'pseudoElement'));

      if (get(this, 'sendButtonPositionId')) {
        overriddenContext.flags = '{BUTTON_STATE_ID:' + get(this, 'componentStateId') + '}';
      }

      get(this, 'addNewChildForm').perform(get(this, 'pseudoElement.childFormVersionId'), get(this, 'pseudoElement.childElementName'), get(this, 'pseudoElement.formName'), overriddenContext);
    },


    actions: {
      add: function add() {
        var _this = this;

        if (this.setElementConfig()) {
          if (get(this, 'sendButtonPositionId') && get(this, 'componentStateId') === 'new') {
            set(this, 'showUserMustSave', true);
            return;
          }

          // Check if displayUnsavedChanges modal is passed down
          var displayChanges = get(this, 'displayUnsavedChanges');

          if (displayChanges) {
            // If displayUnsavedChanges modal trigger is passed down
            // Fire that with the form create as a callback.
            // If there are no changes, will fire the callback automatically
            displayChanges(null, function () {
              _this.createChildForm();
            });
          } else {
            this.createChildForm();
          }
        }
      }
    }
  });
});