define('client/components/dynamic-form/view/toggle-buttons/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-form-radio-group'],
    displayValue: computed('stateValue', 'formElement.options.@each.label', function () {
      var stateValue = get(this, 'stateValue');

      var option = get(this, 'formElement.options').find(function (option) {
        return get(option, 'value') === stateValue;
      });
      return option && option.label || '-';
    })
  });
});