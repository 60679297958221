define('client/services/populate-functions', ['exports', 'npm:async/reduce', 'npm:async/eachOf', 'npm:lodash/merge', 'npm:lodash/isObject', 'ember-copy'], function (exports, _reduce, _eachOf, _merge, _isObject, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var inject = Ember.inject,
      get = Ember.get;
  exports.default = Ember.Service.extend({
    defaultFunctions: inject.service(),

    /**
     * Loops through all the "formElements" and builds a default state them if one does not already exist
     * An initial state is passed in that may contain partial state for a formElement, or no state at all
     *
     * @param {Object} initialState - the hierarchical section of the state object related to the array of formElements
     * @param {Object[]} formElements - the formElements on the same hierarchical level
     * @param formElementCount - how many loops of these formElements should there be (required for repeating sections)
     * @returns {RSVP.Promise}
     */
    buildState: function buildState(initialState, formElements) {
      var _this = this;

      var formElementCount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

      initialState = (0, _emberCopy.copy)(initialState) || {};

      return new Ember.RSVP.Promise(function (resolve) {
        // Loop through each formElement and make sure we have state
        (0, _reduce.default)(formElements, {}, function (tempState, formElement, callback) {
          if (!formElement.formElements) {
            // Populate by a population function if it exists, and the state is not already set
            if (get(formElement, 'default.func') && (!initialState[formElement.name] || initialState[formElement.name][0].val == null)) {
              _this.getStateFromFunction(initialState, formElement, formElementCount, tempState, callback);
            } else {
              tempState[formElement.name] = _this.getStaticState(initialState, formElement);
              callback(null, tempState);
            }
          } else {
            if (formElement.repeatable && !formElement.numAutoRepeats) {
              // todo: this only makes a distinction between no numAutoRepeats, and some numAutoRepeats.  If we ever
              // have numAutoRepeats > 1, then we'll need to do some work here
              tempState[formElement.name] = initialState[formElement.name] || [];
              callback(null, tempState);
            } else {
              _this.processSection(initialState, formElement, formElementCount, tempState, callback);
            }
          }
        }, function (err, result) {
          if (err) {/* swallow */}
          resolve(Object.assign({}, initialState, result));
        });
      });
    },


    /**
     * Get the initial state of the formElement from either a static value or default to null
     * @param {Object} initialState - complete initial state for this level of the hierarchy
     * @param {Object} formElement - definition of a single for element
     * @returns {Array} The values for this formElement
     */
    getStaticState: function getStaticState(initialState, formElement) {
      if (!initialState[formElement.name]) {
        return [{ id: 'new', val: get(formElement, 'default.val') || null }];
      } else if (initialState[formElement.name][0].val == null && initialState[formElement.name].length === 1) {
        return (0, _merge.default)(initialState[formElement.name], [{ val: get(formElement, 'default.val') || null }]);
      }
      return initialState[formElement.name];
    },


    /**
     * This function attempts to populate the initial value of a formElement from a function, and not a fixed value
     * There are rules around when a function might be expected to run, in case it isn't, it'll fall back to getting
     * static state.
     * @param {Object} initialState - complete initial state for this level of the hierarchy
     * @param {Object} formElement - definition of a single for element
     * @param {number} formElementCount - the number of these formElements
     * @param {Object} tempState - the current accumulater for the state used by the async reduce
     * @param {Function} callback
     */
    getStateFromFunction: function getStateFromFunction(initialState, formElement, formElementCount, tempState, callback) {
      var _this2 = this;

      if (!('name' in get(formElement, 'default.func'))) {
        throw new Error('Default functions must have the "name" key, for ' + get(formElement, 'name'));
      }

      // Need to determine if this is the correct time to call the default function
      var runOnFirstOnlyAndIsFirst = formElement.default.execute === 'first-only' && formElementCount === 1;
      var runAlways = formElement.default.execute == null || formElement.default.execute === 'all';

      if (runOnFirstOnlyAndIsFirst || runAlways) {
        var _get;

        var funcName = get(formElement, 'default.func.name');
        var args = formElement.default.func.arguments || [];
        var result = (_get = get(this, 'defaultFunctions'))[funcName].apply(_get, _toConsumableArray(args));
        // Check if the function result is a promise or not.  Duck typing check - if it has a `then` function on the
        // returned object, then it's good enough for us
        if ((0, _isObject.default)(result) && 'then' in result && typeof result.then === 'function') {
          result.then(function (value) {
            _this2.addValueToState(value, formElement, initialState, tempState, callback);
          });
        } else {
          this.addValueToState(result, formElement, initialState, tempState, callback);
        }
      } else {
        // todo: consider tidying this code as is duplicated above
        tempState[formElement.name] = this.getStaticState(initialState, formElement);
        callback(null, tempState);
      }
    },
    processSection: function processSection(initialState, formElement, formElementCount, tempState, callback) {
      var _this3 = this;

      // Holy Recursing, Batman!
      // This is complicated.  The initialState[formElement] returns an array of objects, or nothing
      // [{val: dfdf, errors:..dsfsd, hidden:sdfsdf}, {val:dfsdfs}]
      // Therefore, need to loop this properly as well to process each 'state' from the array
      // todo: Remove when heavily tested.  Note: might fail if defaults on sections/complex components, but this is not currently something that we do
      if (!initialState[formElement.name]) {
        // There is no initial state, there just build it from null
        return this.buildState(null, formElement.formElements, formElementCount).then(function (state) {
          tempState[formElement.name] = [{ id: 'new', val: state }];
          callback(null, tempState);
        });
      }
      // Else, we have existing state, that we want to append to, potentially
      tempState[formElement.name] = [];
      (0, _eachOf.default)(initialState[formElement.name], function (subState, index, next) {
        _this3.buildState(subState.val, formElement.formElements, formElementCount).then(function (state) {
          tempState[formElement.name][index] = (0, _merge.default)({ id: 'new' }, subState, { val: state });
          next();
        });
      }, function () {
        callback(null, tempState);
      });
    },


    /**
     * Adds a value returned by a default function to the tempState object.
     * Abstracted to a separate function so can be called by both async and sync default functions
     * @param {any} value
     * @param {object} formElement
     * @param {object} initialState
     * @param {object} tempState
     * @param {function} callback
     */
    addValueToState: function addValueToState(value, formElement, initialState, tempState, callback) {
      var eleState = initialState[formElement.name];
      // the initial state could be populated with an id and a null value, so need to check for that
      if (eleState && eleState[0] && eleState[0].val == null) {
        tempState[formElement.name] = (0, _merge.default)(initialState[formElement.name], [{ val: value }]);
      } else {
        // Else just create the new state
        tempState[formElement.name] = [{
          id: 'new',
          val: value
        }];
      }
      callback(null, tempState);
    }
  });
});