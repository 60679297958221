define('client/initializers/key-manager-config', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var keyManagerConfig = Ember.get(_environment.default, 'keyManagerConfig') || {};
    application.register('main:key-manager-config', keyManagerConfig, {
      instantiate: false
    });
  }

  exports.default = {
    name: 'key-manager-config',
    initialize: initialize
  };
});