define('client/components/mediasuite-radio-buttons-boolean', ['exports', 'mediasuite-formelements/components/mediasuite-radio-buttons-boolean'], function (exports, _mediasuiteRadioButtonsBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mediasuiteRadioButtonsBoolean.default;
    }
  });
});