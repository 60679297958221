define('client/components/dynamic-form/edit/text-to-page-heading/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-form-text-field'],
    uniqueId: Ember.inject.service(),
    inputId: Ember.computed(function () {
      return this.uniqueId.next();
    })
  });
});