define('client/components/dynamic-form/workflow-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['c-workflow-menu'],
    ariaRole: 'navigation',
    activeMenuItem: computed('menuItems', 'activeRoute', function () {
      var _this = this;

      return get(this, 'menuItems').find(function (item) {
        return get(_this, 'activeRoute').indexOf(item.path) === 0;
      });
    })
  });
});