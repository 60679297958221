define('client/components/maps/map-control-view/component', ['exports', 'client/constants', 'client/helpers/get-view-port'], function (exports, _constants, _getViewPort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      getWithDefault = Ember.getWithDefault,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    gateKeeper: inject.service(),

    displaySavedSearchList: false,
    saveCurrentSearch: false,
    showGotoControl: true,
    showSearchControl: true,

    /*
     * Which controls panel is showing.
     * Shows the search pane by default and
     * false if none are showing.
    */
    showPanel: 'search',

    // Used to pre-select the address lookup with the last entered value.
    lastSelectedAddress: null,

    showLayersControl: computed('layers', function () {
      return getWithDefault(this, 'layers', []).length > 0;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // Retain the checked state of layers, if present.
      var checkedLayers = this.checked && Object.keys(this.checked).length ? Object.assign({}, this.checked) : {};
      var layers = getWithDefault(this, 'layers', []);

      if (!Object.keys(checkedLayers).length) {
        layers.forEach(function (l) {
          checkedLayers[l.id] = l.defaultOn || false;
        });
      }

      set(this, 'checked', checkedLayers);
    },
    closePanelForMobile: function closePanelForMobile() {
      if ((0, _getViewPort.getViewPort)() === _constants.VIEW_PORT.MOBILE) {
        set(this, 'showPanel', '');
      }
    },


    actions: {
      noop: function noop() {},
      panelClick: function panelClick(panelName) {
        if (get(this, 'showPanel') === panelName) {
          set(this, 'showPanel', false);
        } else {
          set(this, 'showPanel', panelName);
        }
      },
      clickLayer: function clickLayer(layerId, e) {
        var isChecked = e.target.checked;
        if (isChecked) {
          this.showLayer(layerId);
        } else {
          this.hideLayer(layerId);
        }
        set(this, 'checked.' + layerId, isChecked);
      },
      queryModified: function queryModified() {
        if (get(this, 'queryModified')) {
          this.queryModified.apply(this, arguments);
        }
      },
      onSearch: function onSearch(searchQuery) {
        if (get(this, 'onSearch')) {
          this.closePanelForMobile();
          this.onSearch.apply(this, arguments);
        }
      },
      onAddressChange: function onAddressChange(addressItem) {
        set(this, 'lastSelectedAddress', addressItem);
      },
      loadQuery: function loadQuery() {
        set(this, 'filtersCurrentlyCollapsed', true);
        set(this, 'displaySavedSearchList', false);
        this.loadQuery.apply(this, arguments);
        set(this, 'showPanel', 'search');
      },
      saveSearch: function saveSearch() {
        set(this, 'saveCurrentSearch', false);
        this.sendAction('fetchSavedSearchList');
        set(this, 'displaySavedSearchList', true);
      }
    }
  });
});