define('client/mixins/components/form-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    resourceConsentMethods: inject.service(),
    formLabel: computed('stateValue', 'menu', 'formElement.label', function () {
      // We need to walk the menu to find the alias
      var instanceId = get(this, 'stateValue');
      var form = get(this, 'resourceConsentMethods').findForm(get(this, 'menu'), instanceId);
      if (!form) return get(this, 'formElement.label');

      return get(this, 'resourceConsentMethods').getFormLabel(form);
    })
  });
});