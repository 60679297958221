define('client/components/page-header/landing-panel/component', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Component.extend({
    classNames: ['c-page-header pad-bottom'],

    councilInfo: Ember.computed(function () {
      return getWithDefault(_environment.default, 'ENV_VARS.COUNCIL_INFO', {});
    })
  });
});