define('client/components/dynamic-form/view/summary-map/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/libs/map-lib', 'npm:lodash/escape'], function (exports, _dynamicFormElement, _mapLib, _escape) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;


  var buildPopupContent = function buildPopupContent(consentNumber, consentType) {
    var content = '<h3>' + (consentNumber ? (0, _escape.default)(consentNumber) : 'Number unset') + '</h3>';
    if (consentType) {
      content += '' + (0, _escape.default)(consentType);
    }
    return content;
  };

  /**
   * This form element is display only.
   *
   * It takes values from children elements to display on a map.
   * See decision_summary for an example, but the currently supported children are:
   * - applicationMarkers
   * - consentMarkers
   *
   * Each of those children needs to provide an easting and northing formElement.
   *
   * applicationMarkers also supports an optional "applicationNumber" child
   * which will be used to populate the popup on hover.
   *
   * consentMarkers also supports an optional "consentNumber" and "pinTypeConsent"
   * which are used to populate the popup on hover and set the icon colour.
   *
   * This component could be extended to support other pin types if needed in future.
   */
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-view-location'],
    applicationMarkersVals: computed('stateValue', function () {
      return get(this, 'stateValue.applicationMarkers');
    }),
    consentMarkers: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'consentMarkers';
      });
    }),
    consentMarkersVals: computed('stateValue', function () {
      return get(this, 'stateValue.consentMarkers');
    }),
    markers: computed('eastingVal', 'northingVal', function () {
      var markers = [];

      // Applications
      var applicationMarkersVals = get(this, 'applicationMarkersVals');
      if (applicationMarkersVals) {
        applicationMarkersVals.forEach(function (applicationMarkersVal) {
          // Pull the needed values out of the state.
          var easting = get(applicationMarkersVal, 'val.easting.0.val');
          var northing = get(applicationMarkersVal, 'val.northing.0.val');
          var applicationNumber = get(applicationMarkersVal, 'val.applicationNumber.0.val');

          // Check there's some geospatial data.
          if (!_mapLib.default.validateNztm(easting, northing)) return;

          markers.push({
            position: [easting, northing],
            icon: _mapLib.default.defaultIcon,
            popupContent: buildPopupContent(applicationNumber)
          });
        });
      }

      // Consents
      var consentMarkers = get(this, 'consentMarkers');
      var consentMarkersVals = get(this, 'consentMarkersVals');
      var pinTypeConsentConfig = get(consentMarkers, 'formElements').find(function (obj) {
        return obj.name === 'pinTypeConsent';
      });

      if (consentMarkersVals) {
        consentMarkersVals.forEach(function (consentMarkersVal) {
          // Pull the needed values out of the state.
          var easting = get(consentMarkersVal, 'val.easting.0.val');
          var northing = get(consentMarkersVal, 'val.northing.0.val');
          var consentNumber = get(consentMarkersVal, 'val.consentNumber.0.val');
          var pinTypeConsent = get(consentMarkersVal, 'val.pinTypeConsent.0.val');

          // Check there's some geospatial data.
          if (!_mapLib.default.validateNztm(easting, northing)) return;

          // Defaults
          var icon = _mapLib.default.defaultIcon;
          var consentType = null;

          // If we can determine the consent type, set the label and icon.
          if (pinTypeConsent && pinTypeConsentConfig && pinTypeConsentConfig.options) {
            var consentTypeOption = pinTypeConsentConfig.options.find(function (o) {
              return o.value === pinTypeConsent;
            });
            if (consentTypeOption) {
              icon = _mapLib.default.consentTypeIcons[consentTypeOption.label];
              consentType = consentTypeOption.label;
            }
          }

          var popupContent = buildPopupContent(consentNumber, consentType);

          markers.push({
            position: [easting, northing],
            icon: icon,
            popupContent: popupContent
          });
        });
      }

      return markers;
    })
  });
});