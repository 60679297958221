define('client/breakpoints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    smallDeviceUp: '(min-width: 450px)',
    tabletUp: '(min-width: 768px)',
    upToTablet: '(max-width: 767px)',
    desktopUp: '(min-width: 900px)',
    upToDesktop: '(max-width: 899px)',
    largeDesktopUp: '(min-width: 1260px)',
    upToLargeDesktop: '(max-width: 1259px)'
  };
});