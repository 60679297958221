define('client/components/generic-search/filter/input/select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    remoteMethods: Ember.inject.service('generic-search-remote-methods'),
    filterValue: Ember.computed.alias('filter.filterValue'),

    options: null,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'options', []);
    },


    // initialise the list of options - either from the search template json, or by requesting from the server
    // returns a promise that resolves when the options have been loaded
    initOptions: function initOptions() {
      var _this = this;

      if (this.template.options === undefined && this.template.optionsKey !== undefined) {
        return this.remoteMethods.getLookupResult({
          searchTypeKey: this.searchTypeKey,
          optionsKey: this.template.optionsKey,
          filter: ''
        }).then(function (_ref) {
          var lookupResult = _ref.lookupResult;

          Ember.set(_this, 'options', lookupResult.rows.map(function (row) {
            return {
              value: '' + row.id,
              label: row.display_value
            };
          }));
        });
      } else {
        Ember.set(this, 'options', this.template.options);
        return Ember.RSVP.Promise.resolve();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.initOptions();
    },


    actions: {
      setFilterValue: function setFilterValue(filterValue) {
        if (filterValue === '') {
          this.setValue({});
        } else {
          this.setValue({
            'filterKey': this.template.filterKey,
            'filterOperator': this.template.filterOperator || '=',
            'filterValue': '' + filterValue
          });
        }
      }
    }
  });
});