define('client/components/dynamic-form/dialogues/submission-help/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      get = Ember.get;
  var inject = Ember.inject;
  exports.default = Ember.Component.extend({
    dynamicFormsMethods: inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var forms = get(this, 'forms');
      try {
        if (forms != null) {
          var declarationInstanceId = forms.filter(function (form) {
            if (form.templateKey.endsWith('declaration')) {
              return true;
            }
          })[0].formInstanceId;

          if (declarationInstanceId != null) {
            this.dynamicFormsMethods.transitionToForm({ id: declarationInstanceId, skipNavigationCheck: true });
          }
        }
      } catch (e) {
        console.log('Unable to resolve declaration form');
      }
      set(this, 'displayModal', false);
    }
  });
});