define('client/components/dynamic-form/view/page-heading/component', ['exports', 'npm:lodash/isObject', 'client/mixins/components/dynamic-formElement', 'client/libs/validation-error-lib'], function (exports, _isObject, _dynamicFormElement, _validationErrorLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',
    isNew: computed('state', function () {
      var state = get(this, 'state');
      if (!(0, _isObject.default)(state)) return true;

      var keys = Object.keys(state);
      if (!keys.length) return true;

      return get(state, keys[0] + '.0.id') === 'new';
    }),
    errorCount: computed('state', function () {
      // Use computed property rather than handlebars helper so we only need to calculate once per component
      return (0, _validationErrorLib.countErrors)(get(this, 'state'));
    })
  });
});