define('client/components/home-section/reporting-dashboard/open-applications/table/component', ['exports', 'ember-light-table', 'ember-data'], function (exports, _emberLightTable, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('generic-search-remote-methods'),
    currentView: null,
    query: null,
    totalRows: null,
    currentPage: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var currentView = this.searchTemplate.views.find(function (view) {
        return view.viewKey === _this.viewKey;
      });
      set(this, 'currentView', currentView);
      set(this, 'query', Object.assign({}, this.searchQuery, { viewKey: this.viewKey }));
    },


    isLoading: computed('rows.content', function () {
      var _ref = this.rows || {},
          _ref$content = _ref.content,
          content = _ref$content === undefined ? [] : _ref$content;

      return !content.length;
    }),

    rows: computed('query.ordering', 'query.requestedPage', 'searchQuery.filterSet.filters', function () {
      var _this2 = this;

      var promise = this.remoteMethods.getSearchResult(this.query).then(function (_ref2) {
        var searchResult = _ref2.searchResult;
        var rows = searchResult.rows,
            totalRows = searchResult.totalRows,
            page = searchResult.page;

        set(_this2, 'totalRows', totalRows);
        set(_this2, 'currentPage', page);
        return rows;
      }).catch(function () {
        return [];
      });
      return _emberData.default.PromiseArray.create({ promise: promise });
    }),

    columns: computed('currentView.table.columns.[]', 'sortColumn', function () {
      var _this3 = this;

      var columns = this.currentView.table.columns.filter(function (c) {
        return c.type !== 'hidden';
      }).map(function (c) {
        return {
          label: c.columnHeading,
          sortable: c.sortable,
          cellComponent: 'generic-search/table/cell/' + c.type,
          valuePath: c.elementKey,
          classNames: 'c-table__cell c-table__cell--heading',
          cellClassNames: 'c-table__cell c-table__row--clickable',
          config: c,
          breakpoints: c.breakpoints,
          sorted: c.elementKey === _this3.sortColumn.elementKey,
          ascending: c.elementKey === _this3.sortColumn.elementKey && _this3.sortColumn.direction === 'asc',
          width: c.width
        };
      });

      // add column on the left with chevrons to control the expandable row
      columns.unshift({
        sortable: false,
        width: '22px',
        cellComponent: 'light-table-components/row-toggle',
        classNames: 'c-table__cell c-table__cell--heading',
        cellClassNames: 'c-table__cell c-table__row--clickable c-table__row--toggle-column',
        breakpoints: ['upToLargeDesktop']
      });
      return columns;
    }),

    table: computed('rows.content', 'columns', 'isLoading', function () {
      return new _emberLightTable.default(this.columns, this.rows.content);
    }),

    sortColumn: computed('query.ordering', function () {
      if (this.query.ordering !== undefined && this.query.ordering.length > 0) {
        return this.query.ordering[0];
      } else {
        return {};
      }
    }),

    actions: {
      onColumnHeaderClick: function onColumnHeaderClick(column) {
        if (column.sortable) {
          set(this, 'query.ordering', [{
            elementKey: column.get('valuePath'),
            direction: column.ascending ? 'asc' : 'desc'
          }]);
        }
      },
      onRowClick: function onRowClick(row) {
        var actionConfig = this.currentView.table.onRowClick;
        if (actionConfig && actionConfig.filterKey) {
          var filterKey = actionConfig.filterKey;

          var _ref3 = this.searchTemplate.filters.find(function (filter) {
            return filter.filterKey === filterKey;
          }) || {},
              _ref3$filterOperator = _ref3.filterOperator,
              filterOperator = _ref3$filterOperator === undefined ? '' : _ref3$filterOperator;

          var args = {
            filterKey: filterKey,
            filterOperator: filterOperator,
            filterValue: row.content[filterKey]
          };
          this.updateFilters(args);
        }
      },
      setCurrentPage: function setCurrentPage(page) {
        set(this, 'query.requestedPage', page);
      },
      onAfterResponsiveChange: function onAfterResponsiveChange(matches) {
        if (matches.indexOf('upToLargeDesktop') === -1) {
          this.get('table.expandedRows').setEach('expanded', false);
        }
      }
    }
  });
});