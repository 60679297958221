define('client/components/dynamic-form/view/table-view/file-field/component', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    filesService: inject.service('files'),
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      // Init values
      set(this, 'file', false);
      set(this, 'loading', false);
      set(this, 'error', false);

      // Load file metadata if set
      var fileId = get(this, 'value');

      if (fileId) {
        set(this, 'loading', true);
        this.loadFileMetadata();
      }
    },


    // Load file metadata
    loadFileMetadata: function loadFileMetadata() {
      var _this = this;

      if (this.isDestroyed === true) {
        return;
      }
      var fileId = get(this, 'value');
      return get(this, 'filesService').getInfo(fileId).then(function (file) {
        _this.set('file', file);
        set(_this, 'loading', false);

        // keep re-fetching the metadata until the file has been scanned
        if (!file.status) {
          return new Ember.RSVP.Promise(function (resolve) {
            return window.setTimeout(resolve, _constants.VIRUS_SCAN_CLIENT_REFRESH_RATE);
          }).then(function () {
            return _this.loadFileMetadata();
          });
        }

        if (file.status === 'INFECTED') {
          set(_this, 'state.' + _this.formElement.name + '.0.errors', [{
            description: _constants.VIRUS_DETECTED_MESSAGE,
            type: 'INFECTED'
          }]);
          // Hack to get the validation message to update after setting the above value, which is deep down in the state tree
          set(_this, 'state', Ember.copy(_this.state));
        }
      }).catch(function (err) {
        console.error(err);
        set(_this, 'error', 'Failed to load file information');
        set(_this, 'loading', false);
      });
    },


    actions: {
      /**
       * Download the file
       * @param {Number} id
       */
      download: function download(id) {
        get(this, 'filesService').download(id);
      }
    }
  });
});