define('client/routes/resource-consent-application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      RSVP = Ember.RSVP;
  exports.default = Ember.Route.extend({
    dynamicFormsMethods: inject.service(),
    model: function model(_ref) {
      var parent_id = _ref.parent_id;

      return RSVP.hash({
        application: get(this, 'dynamicFormsMethods').fetchInstance(parent_id),
        application_instance_id: parent_id
      });
    }
  });
});