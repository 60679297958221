define('client/components/svg-icon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['c-icon'],
    height: '16px',
    width: '16px',
    fill: 'currentColor',
    styleAttr: computed('width', 'height', 'fill', function () {
      // eslint-disable-next-line new-cap
      return new Ember.String.htmlSafe('height:' + get(this, 'height') + '; ' + 'width: ' + get(this, 'width') + '; ' + 'fill: ' + get(this, 'fill'));
    })
  });
});