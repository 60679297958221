define('client/components/generic-search/table/component', ['exports', 'ember-light-table'], function (exports, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),

    table: Ember.computed('rows', 'columns', 'isLoading', function () {
      return new _emberLightTable.default(this.columns, this.isLoading ? [] : this.rows);
    }),

    columns: Ember.computed('currentView.table.columns.[]', 'sortColumn', function () {
      var _this = this;

      var columns = this.currentView.table.columns.filter(function (c) {
        return c.type !== 'hidden';
      }).map(function (c) {
        return {
          label: c.columnHeading,
          sortable: c.sortable,
          cellComponent: 'generic-search/table/cell/' + c.type,
          valuePath: c.elementKey,
          classNames: 'c-table__cell c-table__cell--heading',
          cellClassNames: 'c-table__cell c-table__row--clickable',
          config: c,
          sorted: c.elementKey === _this.sortColumn.elementKey,
          ascending: c.elementKey === _this.sortColumn.elementKey && _this.sortColumn.direction === 'asc',
          breakpoints: c.breakpoints,
          width: c.width
        };
      });

      // add column on the left with chevrons to control the expandable row
      columns.unshift({
        sortable: false,
        width: '22px',
        cellComponent: 'light-table-components/row-toggle',
        classNames: 'c-table__cell c-table__cell--heading',
        cellClassNames: 'c-table__cell c-table__row--clickable c-table__row--toggle-column',
        breakpoints: ['upToLargeDesktop']
      });
      return columns;
    }),

    actions: {
      onColumnHeaderClick: function onColumnHeaderClick(column) {
        if (column.sortable) {
          this.setSortColumn(column.get('valuePath'), column.ascending ? 'asc' : 'desc');
        }
      },
      onAfterResponsiveChange: function onAfterResponsiveChange(matches) {
        // if the screen is a large desktop or bigger, the expand/contract column gets hidden,
        // so we need to automatically expand all the rows.
        if (matches.indexOf('upToLargeDesktop') === -1) {
          this.get('table.expandedRows').setEach('expanded', false);
        }
      },
      onRowClick: function onRowClick(row) {
        var actionConfig = this.currentView.table.onRowClick;
        if (actionConfig === undefined || actionConfig === null) {
          // no action configured
          return;
        }
        var args = actionConfig.args.map(function (arg) {
          if (arg.constant !== undefined) {
            return arg.constant;
          }
          if (arg.elementKey !== undefined) {
            return row.content[arg.elementKey];
          }
        });
        try {
          this.send.apply(this, [actionConfig.type].concat(_toConsumableArray(args)));
        } catch (e) {
          var _console;

          // eslint-disable-next-line no-console
          (_console = console).error.apply(_console, ['attempted to run action.' + actionConfig.type + '('].concat(_toConsumableArray(args), [')']));
          throw e;
        }
      },
      transitionAction: function transitionAction() {
        var _router;

        (_router = this.router).transitionTo.apply(_router, arguments);
      }
    }
  });
});