define('client/routes/consents-section/history', ['exports', 'client/libs/history-component', 'client/mixins/routes/generic-workflows'], function (exports, _historyComponent, _genericWorkflows) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    workflows: [{
      component: 'history',
      type: 'component',
      path: 'history',
      title: 'Consents history',
      parentIdType: 'consents_and_permits.cap_decision',
      targetIdType: 'consents_and_permits.cap_decision',
      getMenu: _historyComponent.default.getMenu
    }]
  });
});