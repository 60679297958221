define('client/components/component-workflows/history/list/columns', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    'sortable': false,
    'width': '30px',
    'cellComponent': 'light-table-components/row-toggle',
    'classNames': 'c-table__cell c-table__cell--heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__row--clickable c-table__row--toggle-column',
    'breakpoints': ['upToDesktop']
  }, {
    'label': 'Date',
    'valuePath': 'created_timestamp',
    'width': '100px',
    'cellComponent': 'dynamic-form/view/table-view/date-field',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap',
    'breakpoints': ['smallDeviceUp']
  }, {
    'label': 'Form',
    'valuePath': 'form_description',
    'cellComponent': 'light-table-components/cell-with-title',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap'
  }, {
    'label': 'Field changed',
    'width': '200px',
    'valuePath': 'object_description',
    'cellComponent': 'light-table-components/cell-with-title',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap',
    'breakpoints': ['tabletUp']
  }, {
    'label': 'From',
    'valuePath': 'old_value',
    'cellComponent': 'light-table-components/cell-with-title',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap',
    'breakpoints': ['largeDesktopUp']
  }, {
    'label': 'To',
    'valuePath': 'new_value',
    'cellComponent': 'light-table-components/cell-with-title',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap',
    'breakpoints': ['largeDesktopUp']
  }, {
    'label': 'User',
    'valuePath': 'created_by_name',
    'width': '130px',
    'cellComponent': 'light-table-components/cell-with-title',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap',
    'breakpoints': ['largeDesktopUp']
  }, {
    'sortable': false,
    'label': '',
    'valuePath': 'actions',
    'width': '50px',
    'align': 'initial', // reset default alignment so actions align right
    'cellComponent': 'component-workflows/history/list/actions',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--actions'
  }];
});