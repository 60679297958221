define('client/routes/home-section/submitted-applications', ['exports', 'client/mixins/routes/home-section-tab'], function (exports, _homeSectionTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.UnprotectedRoute.extend(_homeSectionTab.default, {
    actions: {
      onSearchQueryChange: function onSearchQueryChange() {
        var _controller;

        (_controller = this.controller).onSearchQueryChange.apply(_controller, arguments);
      }
    }
  });
});