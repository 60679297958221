define('client/services/messages', ['exports', 'client/services/remote-method'], function (exports, _remoteMethod) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _remoteMethod.default.extend({
    /**
     * Retrieves Django messages from our API and makes it available to the client app.
     */
    getMessages: function getMessages() {
      var request = this.request('/messages/');
      return request;
    }
  });
});