define('client/test-fixtures/property-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'formTemplate': {
      // Ignore for now
    },
    'formElements': [{
      'name': 'siteWrapper',
      'type': 'section',
      'formElements': [{
        'name': 'streetAddress',
        'label': 'Street Address',
        'type': 'text'
      }, {
        'name': 'legalDescription',
        'label': 'Legal Description',
        'type': 'text'
      }]
    }, {
      'name': 'enterLocale',
      'label': 'I want to enter the locale of the site',
      'type': 'checkbox'
    }, {
      'name': 'locale',
      'type': 'section',
      'displayConditions': { and: [{ name: 'enterLocale', value: 'true' }] },
      'formElements': [{
        'name': 'bayName',
        'label': 'Bay name',
        'type': 'text'
      }, {
        'name': 'riverName',
        'label': 'River name',
        'type': 'text'
      }, {
        'name': 'roadName',
        'label': 'Road name',
        'type': 'text'
      }]
    }, {
      'name': 'ownersAndOccupiers',
      'type': 'section',
      'formElements': [{
        'name': 'soleOccupier',
        'label': 'Applicant is the only owner and occupier',
        'type': 'radio',
        'options': [{ 'value': 'true', 'label': 'Yes' }, { 'value': 'false', 'label': 'No' }]
      }, {
        'name': 'additionalOwnersOccupier',
        'type': 'section',
        'repeatable': true,
        'numAutoRepeats': 1,
        'displayConditions': { and: [{ name: 'soleOccupier', value: 'false' }] },
        'formElements': [{
          'name': 'ownerOrOccupier',
          'label': 'Are the below:',
          'type': 'radio',
          'default': { val: 1 },
          'options': [{ 'value': 'owner', 'label': 'Owner' }, { 'value': 'occupier', 'label': 'Occupier' }]
        }, {
          'name': 'ownerOccupierType',
          'label': 'Type',
          'type': 'radio',
          'default': { val: 1 },
          'options': [{ 'value': '1', 'label': 'Individual' }, { 'value': '2', 'label': 'Company' }, { 'value': '3', 'label': 'Trust' }]
        }, {
          'name': 'individual',
          'type': 'section',
          'displayConditions': { and: [{ 'name': 'ownerOccupierType', 'value': '1' }] },
          'formElements': [{
            'name': 'firstName',
            'label': 'First name(s)',
            'help': 'Some help text',
            'type': 'text'
          }, {
            'name': 'lastName',
            'label': 'Last Name',
            'type': 'text'
          }]
        }, {
          'name': 'company',
          'type': 'section',
          'displayConditions': { and: [{ 'name': 'ownerOccupierType', 'value': '2' }] },
          'formElements': [{
            'name': 'companyName',
            'label': 'Company Name',
            'help': 'Some help text',
            'type': 'text'
          }, {
            'name': 'companyNumber',
            'label': 'Registered Company Number',
            'type': 'text'
          }]
        }, {
          'name': 'trust',
          'type': 'section',
          'displayConditions': { and: [{ 'name': 'ownerOccupierType', 'value': '3' }] },
          'formElements': [{
            'name': 'trustName',
            'label': 'Trust Name',
            'help': 'Some help text',
            'type': 'text'
          }, {
            'name': 'trustNumber',
            'label': 'Registered Trust Number',
            'type': 'text'
          }]
        }, {
          'name': 'contactAddress',
          'label': 'Contact Address (postal):',
          'type': 'text'
        }]
      }]
    }],

    'state': {}
  };
});