define('client/components/modal-dialog/publish-consent/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),

    reasonsForChange: null,
    reasonForChangeId: null,
    variationApplications: null,
    variationApplicationId: null,
    comment: null,
    loading: null,
    hideCloseButton: null,

    init: function init() {
      this._super.apply(this, arguments);

      set(this, 'reasonsForChange', []);
      set(this, 'variationApplications', []);
      set(this, 'comment', '');
      set(this, 'loading', true);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      // Wait until the pageInfo has been loaded then populate the modal with information from the database
      // To avoid slowing down the rest of the page, we only do this once the modal is displayed
      if (get(this, 'showPublishConsentModal') === true && get(this, 'pageInfo.capDecisionId') !== undefined) {
        set(this, 'loading', true);

        get(this, 'remoteMethods').publishConsentDialogInfo(get(this, 'pageInfo.capDecisionId')).then(function (_ref) {
          var snapshotMetadata = _ref.snapshotMetadata,
              reasonsForChange = _ref.reasonsForChange;

          set(_this, 'reasonForChangeId', snapshotMetadata.reasonForChangeId);
          set(_this, 'variationApplicationId', snapshotMetadata.variationApplicationId);
          set(_this, 'comment', snapshotMetadata.comment);
          set(_this, 'reasonsForChange', reasonsForChange);

          set(_this, 'loading', false);
        });
      }
    },


    consentNumber: computed.alias('pageInfo.consentNumber'),
    isPublished: computed.alias('pageInfo.isPublished'),
    modalHeading: computed('isPublished', function () {
      return get(this, 'isPublished') ? 'Unpublish and amend decision' : 'Publish decision';
    }),

    actions: {
      publishConsent: function publishConsent() {
        var _this2 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);

        var capDecisionId = get(this, 'pageInfo.capDecisionId');
        var reasonForChangeId = get(this, 'reasonForChangeId');
        var variationApplicationId = get(this, 'variationApplicationId');
        var comment = get(this, 'comment');
        get(this, 'remoteMethods').publishConsent(capDecisionId, reasonForChangeId, variationApplicationId, comment).then(function (results) {
          set(_this2, 'loading', false);
          if (results.error !== false) {
            get(_this2, 'flashMessages.danger')(results.error);
          } else {
            _this2.get('setShowPublishConsentModal')(false); // remove the `?publishConsent=true` from the URL
            _this2.refreshModel();
          }
        });
      },
      unpublishConsent: function unpublishConsent() {
        var _this3 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);

        var capDecisionId = get(this, 'pageInfo.capDecisionId');
        var reasonForChangeId = get(this, 'reasonForChangeId');
        var variationApplicationId = get(this, 'variationApplicationId');
        var comment = get(this, 'comment');
        get(this, 'remoteMethods').unpublishConsent(capDecisionId, reasonForChangeId, variationApplicationId, comment).then(function () {
          _this3.get('setShowPublishConsentModal')(false); // remove the `?publishConsent=true` from the URL
          _this3.refreshModel();
        });
      }
    }
  });
});