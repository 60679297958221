define('client/components/power-select/before-options', ['exports', 'ember-power-select/components/power-select/before-options'], function (exports, _beforeOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _beforeOptions.default.extend({
    focusInput: function focusInput() {
      this.input = document.querySelector('.ember-power-select-search-input[aria-controls="' + this.listboxId + '"]');
      if (this.input) {
        Ember.run.next(this.input, 'focus');
      }
    }
  });
});