define('client/components/dynamic-form/edit/component', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var set = Ember.set,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    showConfirmationModal: false,
    updateDeletedHide: function updateDeletedHide(formElement, index, key) {
      var status = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      // We take a copy here so that the state reference changes on set and forces observers throughout the app fire
      var state = (0, _emberCopy.copy)(get(this, 'form.state'), true);
      state[formElement.name][index] = Object.assign({}, state[formElement.name][index], _defineProperty({}, key, status));
      set(this, 'form.state', state);
    },
    didRender: function didRender() {
      var _this = this;

      // Capture a snapshot after a render
      // We need this, because the  render adds in hidden state details that become very difficult to strip
      // out later when we want to compare if anything has changed on the page
      if (get(this, 'takeSnapshot')) {
        Ember.run.later(function () {
          // Need the if condition, as tests don't include this function and will fail
          if (get(_this, 'setCheckpointState')) get(_this, 'setCheckpointState')(get(_this, 'form.state'));
        });
      }
    },


    actions: {
      updateStateKey: function updateStateKey(key, childState) {
        var state = (0, _emberCopy.copy)(get(this, 'form.state'), true);
        state[key] = childState;
        set(this, 'form.state', state);

        if (get(this, 'saveDraft')) {
          get(this, 'saveDraft')(state);
        }
      },
      delete: function _delete(formElement, index) {
        this.updateDeletedHide(formElement, index, 'deleted');
      },


      /**
       * Wrap the updateRootState closure function so that if the route's template does not pass this function, it doesn't
       * cause a crash
       */
      updateRootState: function updateRootState(state) {
        var updateMenu = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        state = (0, _emberCopy.copy)(state, true);
        if (get(this, 'updateRootState')) get(this, 'updateRootState')(state, updateMenu);else {
          Ember.Logger.info('Attempted to call "updateRootState" when no closure function was passed to the dynamic forms');
        }
      },


      /**
       * Wrap the save closure function so that if the route's template does not pass this function, it doesn't
       * cause a crash
       */
      save: function save() {
        var updateMenu = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var hideSuccessBanner = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        var postSaveCallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        if (get(this, 'save')) get(this, 'save')(updateMenu, hideSuccessBanner, postSaveCallback);else {
          Ember.Logger.info('Attempted to call "save" when no closure function was passed to the dynamic forms');
        }
      },


      /**
       * Wrap the refreshModel closure function so that if the route's template does not pass this function, it doesn't
       * cause a crash
       */
      refreshModel: function refreshModel() {
        if (get(this, 'refreshModel')) get(this, 'refreshModel')();else {
          Ember.Logger.info('Attempted to call "refreshModel" when no closure function was passed to the dynamic forms');
        }
      },


      /**
       * Wrap the displayUnsavedChanges closure function so that if the route's template does not pass this function, it doesn't
       * cause a crash
       */
      displayUnsavedChanges: function displayUnsavedChanges(transition, continueAction) {
        if (get(this, 'displayUnsavedChanges')) get(this, 'displayUnsavedChanges')(transition, continueAction);else {
          Ember.Logger.info('Attempted to call "displayUnsavedChanges" when no closure function was passed to the dynamic forms');
        }
      }
    }
  });
});