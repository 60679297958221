define('client/services/generic-search-remote-methods', ['exports', 'client/mixins/cache', 'client/services/remote-methods'], function (exports, _cache, _remoteMethods) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _remoteMethods.default.extend(_cache.default, {
    /**
     * Makes a request to the server to fetch the configuration for a given search_type_key
     */
    getSearchConfig: function getSearchConfig(searchTypeKey) {
      var cacheKey = 'getSearchConfig_' + searchTypeKey;
      var cachedResult = this.cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('generic-search/fetch-search-config/' + searchTypeKey);
      this.cache.save(cacheKey, request);
      return request;
    },


    /**
     * Sends the search query to the server and fetches the results
     */
    getSearchResult: function getSearchResult(searchQuery) {
      return this.post('generic-search/fetch-search-results', {
        data: { query: JSON.stringify({ searchQuery: searchQuery }) }
      });
    },


    /**
     * Sends the search query to the server and fetches the results
     */
    getLookupResult: function getLookupResult(lookupQuery) {
      return this.post('generic-search/fetch-lookup-results', {
        data: { query: JSON.stringify({ lookupQuery: lookupQuery }) }
      });
    },


    /**
     * Sends the search query to the server and fetches the results
     */
    getLookupDisplayValue: function getLookupDisplayValue(lookupDisplayValueQuery) {
      return this.post('generic-search/fetch-lookup-display-value', {
        data: { query: JSON.stringify({ lookupDisplayValueQuery: lookupDisplayValueQuery }) }
      });
    },


    /**
     * Saves a query with the given name
     */
    saveQuery: function saveQuery(searchQuery, name) {
      return this.post('generic-search/add-saved-query', {
        data: { query: JSON.stringify({ searchQuery: searchQuery }), name: name }
      });
    },


    /**
     * Deletes a saved query
     */
    deleteSavedQuery: function deleteSavedQuery(queryId) {
      return this.del('generic-search/delete-saved-query/' + queryId);
    },


    /**
     * Retreives a saved query
     */
    fetchSavedQuery: function fetchSavedQuery(queryId) {
      return this.fetch('generic-search/fetch-saved-query/' + queryId);
    },


    /**
     * Retreives a list of the queries saved by the user
     */
    fetchSavedQueryList: function fetchSavedQueryList(searchTypeKey) {
      return this.fetch('generic-search/fetch-saved-query-list/' + searchTypeKey);
    },


    /**
     * Renames a saved query
     */
    renameQuery: function renameQuery(queryId, name) {
      return this.put('generic-search/update-saved-query/' + queryId, {
        data: { name: name }
      });
    },
    fetchDetailedMapResult: function fetchDetailedMapResult(mapDetailQuery, id) {
      return this.post('generic-search/fetch-detailed-map-result', {
        data: { query: JSON.stringify({ mapDetailQuery: mapDetailQuery }) }
      });
    },
    updateSubscription: function updateSubscription(queryId) {
      return this.put('generic-search/update-saved-query-subscription/' + queryId);
    }
  });
});