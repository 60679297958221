define('client/routes/processing-section/circulation', ['exports', 'client/libs/documents-component', 'client/mixins/routes/generic-workflows'], function (exports, _documentsComponent, _genericWorkflows) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    workflows: [{
      component: 'documents',
      type: 'component',
      path: 'documents',
      title: 'Documents',
      getMenu: _documentsComponent.default.getMenu
    }]
  });
});