define('client/services/remote-methods', ['exports', 'client/mixins/cache', 'client/services/remote-method'], function (exports, _cache, _remoteMethod) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _remoteMethod.default.extend(_cache.default, {
    // Cache for a minute
    cacheLengthSec: 60,

    /**
     * Makes a GET request to the server
     *
     * @param {Object} args Optional arguments to pass to the server as url parameters.
     *                      eg. {'application_id': 50}
     * @param {string} baseUrl url to fetch from
     * @return {Promise<Object>}
     */
    fetch: function fetch(baseUrl, query) {
      var queryString = this.buildQueryString(query);

      var url = [baseUrl, queryString].filter(function (x) {
        return x !== '';
      }).join('?');

      var cache = get(this, 'cache');
      var cachedResult = cache.fetch('url_', url);
      if (cachedResult) return cachedResult;

      var result = this.request(url);
      cache.save('url_' + url, result);
      return result;
    }
  });
});