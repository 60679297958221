define('client/helpers/get-view-port', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getViewPort = getViewPort;
  function getViewPort() {
    if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= 599) {
      return _constants.VIEW_PORT.MOBILE;
    }
  }

  exports.default = Ember.Helper.helper(getViewPort);
});