define('client/components/bread-crumbs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['c-bread-crumbs'],
    classNameBindings: ['crumbs:c-bread-crumbs--has-crumbs']
  });
});