define('client/components/dynamic-form/view/person-name/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/libs/validation-error-lib'], function (exports, _dynamicFormElement, _validationErrorLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    fullName: computed('value.firstName.0.val', 'value.surname.0.val', 'value.fullName.0.val', function () {
      var honorificSelect = get(this, 'value.honorificSelect.0.val');
      var otherHonorific = honorificSelect === 'Other' || !honorificSelect;
      var honorific = otherHonorific ? '' : honorificSelect;
      var firstName = get(this, 'value.firstName.0.val') || '';
      var surname = get(this, 'value.surname.0.val') || '';
      var fullName = get(this, 'value.fullName.0.val') || '';
      return (honorific + ' ' + firstName + ' ' + surname + ' ' + fullName).trim();
    }),

    errors: computed('value.firstName.0.errors', 'value.surname.0.errors', function () {
      // merges any errors from the parent, or firstName/surname child elements, into one array
      var firstNameErrors = get(this, 'value.firstName.0.errors') || [];
      var surnameErrors = get(this, 'value.surname.0.errors') || [];
      var parentErrors = get(this, 'itemState.errors') || [];
      var errors = firstNameErrors.concat(surnameErrors).concat(parentErrors);

      return (0, _validationErrorLib.deDupErrors)(errors);
    })
  });
});