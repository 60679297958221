define('client/components/app-nav/alerts-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Component.extend({
    classNames: ['c-nav-dropdown'],
    classNameBindings: ['isOpen:c-nav-dropdown--open'],
    isOpen: false,
    actions: {
      toggleAlertsMenu: function toggleAlertsMenu() {
        this.toggleProperty('isOpen');
      },
      closeAlertsMenu: function closeAlertsMenu() {
        set(this, 'isOpen', false);
      }
    }
  });
});