define('client/components/dynamic-form/edit/date/component', ['exports', 'client/mixins/components/dynamic-formElement', 'moment', 'client/config/environment'], function (exports, _dynamicFormElement, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: 'div',

    // The format used to display the currently set date
    dateFormat: _environment.default.dateFormat,
    minDate: computed.alias('formElement.extendedAttributes.clientValidationMinDate'),
    maxDate: computed.alias('formElement.extendedAttributes.clientValidationMaxDate'),

    // Use a custom function to parse dates entered by the user as text
    parseDate: function parseDate(dateString, format) {
      var dateFormats = [format, // The same format as used for display
      'D/M/YYYY', // Standard NZ date formatting
      'YYYY-M-D' // ISO standard date formatting
      ];
      return (0, _moment.default)(dateString, dateFormats).toDate();
    },


    dateValue: Ember.computed('stateValue', function () {
      /**
       * If the date picker is configured to display the current date by default,
       * `getCurrentDate` in `default-functions.js` returns the date parsed and formatted in local time.
       *
       * If a date is selected by the user, `setDate` parses and stores the date in local time.
       *
       * If date value is received from the server, the date is parsed and formatted in local time
       *
       */
      return this.stateValue ? (0, _moment.default)(this.stateValue).format(this.dateFormat) : null;
    }),

    actions: {
      setDate: function setDate(date) {
        /**
         * pikaday has been configured to return date with the UTC timezone.
         * We use moment to parse the date to local time using the UTC timezone information and
         * then convert the parsed date to an ISO 8601 string which the database will accept.
        */
        if (date === null) {
          this.clearValue();
        } else {
          var checkdate = date === null ? undefined : (0, _moment.default)(date);

          var maxDate = get(this, 'maxDate');
          var minDate = get(this, 'minDate');

          if (checkdate !== undefined && maxDate !== undefined && checkdate.isAfter(maxDate)) {
            this.clearValue();
            return;
          }

          if (checkdate !== undefined && minDate !== undefined && checkdate.isBefore(minDate)) {
            this.clearValue();
            return;
          }

          this.setValue(date === null ? undefined : (0, _moment.default)(date).toISOString());
        }
      }
    }
  });
});