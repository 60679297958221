define('client/components/generic-search/filter/input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    queryLastIndex: Ember.computed('query.length', function () {
      return this.query.length - 1;
    })
  });
});