define('client/mixins/components/inline-form-table-view-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    router: inject.service(),
    dynamicFormsMethods: inject.service(),

    // Handle the click event on the row
    click: function click() {
      this.transitionToConsent();
    },


    // Navigate to the clicked on form
    transitionToConsent: function transitionToConsent() {
      var name = get(this, 'formElement.formElements.0.name');
      var id = get(this, 'value.' + name + '.0.val');
      this.dynamicFormsMethods.transitionToForm({ id: id });
    },


    actions: {
      // Handle an event from the button click
      view: function view(event) {
        // don't sent the click to the row click as well - it might confuse Ember
        event.stopPropagation();
        this.transitionToConsent();
      },
      delete: function _delete(formElement, index, event) {
        // don't sent the click to the row click as well
        event.stopPropagation();
        get(this, 'delete')(formElement, index);
      }
    }
  });
});