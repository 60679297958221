define('client/services/unique-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var nextNumber = 1729; // a boring number

  exports.default = Ember.Service.extend({
    next: function next() {
      return 'id-' + nextNumber++;
    }
  });
});