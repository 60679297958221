define('client/routes/processing-section/history', ['exports', 'client/libs/history-component', 'client/mixins/routes/generic-workflows'], function (exports, _historyComponent, _genericWorkflows) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    workflows: [{
      component: 'history',
      type: 'component',
      path: 'history',
      title: 'Processing history',
      targetIdType: 'processing.application_decision',
      getMenu: _historyComponent.default.getMenu
    }]
  });
});