define('client/mixins/routes/export-application', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject,
      set = Ember.set;


  /**
   * Extract the relevant part of the path from the URL.
   *
   * Example:
   *   https://<host>/processing/40070/view/application/application/23443
   * Would return:
   *   application/application/23443 (if fullWorkflow = false)
   *   application/application/full (if fullWorkflow = true)
   *
   * A better way to extract the path would be from the router service using the
   * urlFor method (https://www.emberjs.com/api/ember/2.18/classes/RouterService/methods/urlFor?anchor=urlFor)
   * However, this isn't avaiable in Ember 2.12, so until then we'll take it
   * from the window.location.
   *
   * @param {boolean} fullWorkflow Is the full workflow path required?
   * @returns {string}
   */
  var getPath = function getPath(fullWorkflow) {
    var pathParts = window.location.pathname.split('/') // break into parts
    .filter(function (v) {
      return v;
    }) // remove any empty items so that leading, trailing, or double slashes don't matter
    .slice(3); // drop the first three parts e.g. the /processing/12345/view/ part of the URL

    // If a full workflow has been requested ensure that '/full' is appended to the path,
    // it needs to replace the form instance ID if one exists at the end.
    if (fullWorkflow) {
      var lastPathPart = pathParts[pathParts.length - 1];
      var hasFormInstanceId = /^\d+$/.test(lastPathPart);
      if (hasFormInstanceId) {
        // Replace form instance ID if exists
        pathParts[pathParts.length - 1] = _constants.FULL_WORKFLOW_TEXT;
      } else {
        // otherwise append
        pathParts.push(_constants.FULL_WORKFLOW_TEXT);
      }
    }

    return pathParts.join('/');
  };

  /**
   * Adds exportApplication action to submission-view and view. Sends application to the server for conversion to PDF and emailing to MDC staff.
   */
  exports.default = Ember.Mixin.create({
    applicationExportService: inject.service('applicationExport'),
    disableExportApplication: false,

    actions: {
      exportApplication: function exportApplication(workflowKey, parentId) {
        var _this = this;

        var fullWorkflow = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var title = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        var path = getPath(fullWorkflow);

        // Hack for notification package because this code is defective by design. SWC 12.10.23
        if (workflowKey === 'notification_package' && fullWorkflow) {
          path = 'notification/notification-package/full';
        } // end hack

        set(this, 'disableExportApplication', true);
        get(this, 'applicationExportService').createApplication(workflowKey, parentId, path).then(function () {
          var email = get(_this, 'session.username');
          get(_this, 'flashMessages.success')('Exporting' + (title && title.toLowerCase ? ' ' + title.toLowerCase() : '') + '. A link will be emailed to ' + email + ' once complete.');
        }).catch(function () {
          get(_this, 'flashMessages.danger')('There was a problem exporting');
        }).finally(function () {
          set(_this, 'disableExportApplication', false);
        });
      }
    }
  });
});