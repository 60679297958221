define('client/components/generic-search/filter/input/text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    searchOnNextChange: false,

    textValue: Ember.computed('filter', function () {
      return this.filter.filterValue;
    }),

    actions: {
      setText: function setText(text) {
        if (text && text.length > 0) {
          this.setValue({
            'filterKey': this.template.filterKey,
            'filterOperator': this.template.filterOperator || 'ilike',
            'filterValue': '' + text
          });
        } else {
          this.setValue(undefined);
        }
        if (this.searchOnNextChange === true) {
          Ember.set(this, 'searchOnNextChange', false);
          this.performSearch();
        }
      },
      keyDown: function keyDown(e) {
        // Perform search when the enter key is pressed
        if (e.keyCode === 13) {
          Ember.set(this, 'searchOnNextChange', true);
        }
      }
    }
  });
});