define('client/components/dynamic-form/edit/toggle-buttons/individual-toggle-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Component.extend({
    classNames: ['c-form-toggle-group__toggle-button'],
    classNameBindings: ['isChecked:c-form-toggle-group__toggle-button--active', 'isFocused:c-form-toggle-group__toggle-button--focused'],
    actions: {
      toggleFocus: function toggleFocus(isFocused) {
        set(this, 'isFocused', isFocused);
      }
    }
  });
});