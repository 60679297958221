define('client/helpers/count-errors', ['exports', 'client/libs/validation-error-lib'], function (exports, _validationErrorLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _toArray(_ref),
        state = _ref2[0],
        rest = _ref2.slice(1);

    return (0, _validationErrorLib.countErrors)(state);
  });
});