define('client/libs/map-lib-mapbox', ['exports', 'client/libs/map-lib-base', 'client/config/environment', 'client/constants'], function (exports, _mapLibBase, _environment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var COUNCIL_INFO = _environment.default.ENV_VARS.COUNCIL_INFO;
  exports.default = Object.assign({}, _mapLibBase.default, {
    getBaseLayers: function getBaseLayers() {
      var streetsSatellite = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=' + _constants.MAPBOX_API_KEY, {
        attribution: '© <a href="https://www.mapbox.com/about/maps/" target="_blank">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
      });

      return L.layerGroup([streetsSatellite]);
    },
    addInitialLayersAndMarkers: function addInitialLayersAndMarkers(map) {
      Ember.$.getJSON('/assets/boundary_geojson/' + COUNCIL_INFO.tla + '.json').done(function (data) {
        L.GeoJSON.geometryToLayer(data).addTo(map).setStyle({
          color: '#008650',
          opacity: 1,
          fillOpacity: 0
        });
      });
    },


    /**
     * Default Center to use.
     */
    defaultCenter: L.latLng(COUNCIL_INFO.mapDefaultCenterLat, COUNCIL_INFO.mapDefaultCenterLong),

    mapDefaultZoom: COUNCIL_INFO.mapDefaultZoom,
    mapMinZoom: undefined,
    mapMaxZoom: 18,

    maxClusteringZoomLevel: 13,

    // WGS 84 / Pseudo-Mercator -- Spherical Mercator, Google Maps, OpenStreetMap, Bing, ArcGIS, ESRI
    crs: L.CRS.EPSG3857
  });
});