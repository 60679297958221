define('client/components/content-footer/component', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Component.extend({
    session: inject.service(),
    classNames: ['c-content-footer', 'h-clearfix'],
    ariaRole: 'contentinfo',

    councilInfo: Ember.computed(function () {
      return getWithDefault(_environment.default, 'ENV_VARS.COUNCIL_INFO', {});
    })
  });
});