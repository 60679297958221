define('client/components/dynamic-form/view/component', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var set = Ember.set,
      get = Ember.get,
      inject = Ember.inject,
      run = Ember.run;
  exports.default = Ember.Component.extend({
    populateFunctions: inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // This is used as a signal that the application has finished displaying on the page.
      // It is used by the PDF generation, see node-job-queue/handlers/generate-application-pdf.js
      document.RESC_APPLICATION_DONE = false;
      run.scheduleOnce('afterRender', this, 'signalComplete');
    },
    updateDeletedHide: function updateDeletedHide(formElement, index, key) {
      var status = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      // We take a copy here so that the state reference changes on set and forces observers throughout the app fire
      var state = (0, _emberCopy.copy)(get(this, 'form.state'), true);
      state[formElement.name][index] = Object.assign({}, state[formElement.name][index], _defineProperty({}, key, status));
      set(this, 'form.state', state);
    },
    signalComplete: function signalComplete() {
      // Signal that the page is done rendering.
      document.RESC_APPLICATION_DONE = true;
    }
  });
});