define('client/components/status-pill/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c-status-pill'],
    classNameBindings: ['isDraftPill:c-status-pill--draft', 'isAlertPill:c-status-pill--alert', 'isWarningPill:c-status-pill--warning', 'isErrorPill:c-status-pill--error', 'isSuccessPill:c-status-pill--success', 'isInfoPill:c-status-pill--info', 'isLargePill:c-status-pill--large']
  });
});