define('client/components/page-header/processing-status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Component.extend({
    classNames: ['c-page-header__status'],

    isStickyModeEnabled: window.isStickyModeEnabled,

    actions: {
      togglePin: function togglePin() {
        var newMode = window.isStickyModeEnabled === 'OFF' ? 'ON' : 'OFF';
        window.isStickyModeEnabled = newMode;
        set(this, 'isStickyModeEnabled', newMode);
        window.localStorage.setItem('isStickyModeEnabled', newMode);
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      set(this, 'isStickyModeEnabled', window.isStickyModeEnabled);
    }
  });
});