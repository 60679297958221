define('client/services/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    getUserDetails: function getUserDetails() {
      return {
        firstName: 'Brian',
        surname: 'Clough',
        mobPhone: '02897358943759',
        bio: 'Brian won two European Cups with Nottingham Forest in the 78/79 and 79/80 seasons.'
      };
    }
  });
});