define('client/components/component-workflows/history/list/component', ['exports', 'ember-light-table', 'client/components/component-workflows/history/list/columns', 'client/config/environment', 'ember-copy'], function (exports, _emberLightTable, _columns, _environment, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    dynamicFormsMethods: inject.service('dynamic-forms-methods'),

    page: 1,
    pageSize: _environment.default.embeddedTablePageSize,
    total: 0,
    columns: [],
    rows: [],
    canEdit: false,

    // Sorting.
    sortField: 'created_timestamp',
    sortDirection: 'DESC',

    didReceiveAttrs: function didReceiveAttrs() {
      this.send('fetchRecords');
    },


    menu: computed('pageTitle', function () {
      return {
        forms: [{
          aliasForParent: true, // required to get the label to display
          label: get(this, 'pageTitle'),
          active: true,
          complete: false
        }]
      };
    }),

    table: computed('rows', 'page', 'canEdit', function () {
      var model = get(this, 'model');

      var rows = (0, _emberCopy.copy)(get(this, 'rows'), true).map(function (row) {
        Object.keys(row).forEach(function (key, index) {
          row[key] = row[key] === '' ? '-' : row[key];
        });
        row.actions = {
          id: row.id,
          view: { 'label': 'view', 'path': 'history/' + row.id + '/view' },
          route: model.formRootRoute
        };
        return row;
      });

      set(this, 'total', rows.length);
      var page = get(this, 'page');
      var pageSize = get(this, 'pageSize');
      var paginatedRows = rows.slice((page - 1) * pageSize, page * pageSize);
      var table = new _emberLightTable.default(_columns.default, paginatedRows);

      // Setup initial sort column
      var sortColumn = table.get('allColumns').findBy('valuePath', get(this, 'sortField'));
      if (sortColumn) {
        sortColumn.set('sorted', true);
        var sortDirection = get(this, 'sortDirection');
        sortColumn.set('ascending', sortDirection === 'ASC');
      }

      return table;
    }),
    actions: {
      fetchRecords: function fetchRecords() {
        var _this = this;

        var parentId = get(this, 'model.parentId');
        var sortField = get(this, 'sortField');
        var sortDirection = get(this, 'sortDirection');
        // This is the type of the outer container for the tabs
        var parentIdType = getWithDefault(this, 'model.activeWorkflow.config.parentIdType', 'application_id');
        // This is the type of the history which we need to get. The Server-side will map the parentId to the target id
        var targetIdType = getWithDefault(this, 'model.activeWorkflow.config.targetIdType', 'processing.application_decision');
        get(this, 'dynamicFormsMethods').getHistoryTable(parentId, parentIdType, targetIdType, sortField, sortDirection).then(function (rows) {
          set(_this, 'rows', rows.data);
        });
      },
      setPage: function setPage(page) {
        set(this, 'page', page);
      },
      onAfterResponsiveChange: function onAfterResponsiveChange(matches) {
        if (matches.indexOf('largeDesktop') > -1) {
          this.get('table.expandedRows').setEach('expanded', false);
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            sortDirection: column.ascending ? 'ASC' : 'DESC',
            sortField: column.get('valuePath')
          });
          this.send('fetchRecords');
        }
      }
    }
  });
});