define('client/components/dynamic-form/compact-menu/menu-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['c-compact-menu__task'],
    resourceConsentMethods: inject.service(),
    isActive: computed('form.active', 'form.formInstanceId', 'currentFormId', function () {
      // component-based workflows manually set active:true/false
      if (get(this, 'form.active') !== undefined) {
        return get(this, 'form.active');
      }
      // with form-based workflows it gets set automatically
      return String(get(this, 'form.formInstanceId')) === String(get(this, 'currentFormId'));
    })
  });
});