define('client/services/resource-consent-methods', ['exports', 'client/libs/dynamic-forms-methods-lib', 'client/constants', 'npm:lodash/isString', 'npm:lodash/isNumber', 'npm:lodash/values'], function (exports, _dynamicFormsMethodsLib, _constants, _isString, _isNumber, _values) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Service.extend({
    router: inject.service(),
    resourceConsentRemoteMethods: inject.service(),

    /**
     * Test if the given mode is valid.
     * @param applicationId
     * @returns {bool}
     */
    validMode: function validMode(mode) {
      return (0, _values.default)(_constants.MODE).indexOf(mode) >= 0;
    },


    /**
     * Test if the given application ID is valid.
     * @param applicationId
     * @returns {bool}
     */
    validApplicationId: function validApplicationId(applicationId) {
      return ((0, _isString.default)(applicationId) || (0, _isNumber.default)(applicationId)) && String(applicationId).match(/\d+/);
    },


    /**
     * Navigate to the application form.
     *
     * @param {number} applicationId
     * @param {string} mode See MODE constant for valid values.
     */
    navigateToApplication: function navigateToApplication(applicationId) {
      var mode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _constants.MODE.VIEW;

      if (!this.validMode(mode)) {
        throw new Error('Invalid mode passed to navigateToApplication: "' + mode + '"');
      }
      if (!this.validApplicationId(applicationId)) {
        throw new Error('Invalid application ID passed to navigateToApplication: "' + applicationId + '"');
      }
      return get(this, 'router').transitionTo('processing-section.submitted-application-default', applicationId, mode);
    },


    /**
     * Creates a new Application instance based off a versionId.
     * Also creates the first level child form instances.
     * @param {number} applicationTypeId
     */
    createNewApplicationInstance: function createNewApplicationInstance(applicationTypeId) {
      var _this = this;

      get(this, 'resourceConsentRemoteMethods').newApplication(applicationTypeId).then(function (applicationId) {
        return _this.navigateToApplication(applicationId, _constants.MODE.EDIT);
      });
    },


    /**
     * Navigates the user to the next form when they click 'Save and continue' in Edit mode or
     * 'Continue' in View mode
     */
    navigateToNextForm: function navigateToNextForm(menu, currentInstanceId, mode) {
      var nextInstanceId = void 0;
      if (mode === _constants.MODE.EDIT) {
        nextInstanceId = (0, _dynamicFormsMethodsLib.findNextFormEdit)(currentInstanceId, menu.forms);
        if (!nextInstanceId || nextInstanceId === _constants.LAST) {
          // This was the last item in the form, so take the user to the View page
          // Don't need to specify the applicationInstanceId as it will be assumed to not have changed since we remain
          // on the same route hierarchy
          get(this, 'router').transitionTo('resource-consent-application.view', menu.forms[menu.forms.length - 1].formInstanceId);
          return;
        }
      } else {
        nextInstanceId = (0, _dynamicFormsMethodsLib.findNextFormView)(currentInstanceId, menu.forms);
        if (!nextInstanceId || nextInstanceId === _constants.LAST) {
          // Should not be able to move on from the last form.  Getting here is a logic error
          throw new Error('Tried to navigate beyond the last form in view mode');
        }
      }
      // Move to the next item in forms array
      get(this, 'router').transitionTo('resource-consent-application.' + mode, nextInstanceId);
    },


    /**
     * Finds a specific form from the menu json based on an instanceId
     */
    findForm: function findForm(menu, instanceId) {
      if (!menu || !menu.forms) return false;
      return (0, _dynamicFormsMethodsLib.findFormViaInstance)(menu.forms, instanceId);
    },


    /**
     * Gets the text to display in the menu for a given form.  Handles the situation when an alias is set, both in the
     * current form or in a child form
     */
    getFormLabel: function getFormLabel(form) {
      var aliasFormLocation = get(form, 'aliasFormLocation');
      if (aliasFormLocation) {
        // Need to get the alias from a child form
        var childForm = get(form, 'forms').find(function (childForm) {
          return get(childForm, 'templateKey') === aliasFormLocation;
        });

        // ============================================ WARNING =========================================================
        // Warning: There's no reason label == childFormAlias (default form `name`) other than convention
        // This is BRITTLE as hell, but the actual form `name` is not available in the form json and this is the only way
        // to determine if we do want to grab the childFormAlias from the child form or use the current form default
        // ==============================================================================================================

        if (childForm && get(childForm, 'childFormAlias').trim() !== '' && get(childForm, 'childFormAlias') !== get(childForm, 'label')) return get(childForm, 'childFormAlias');
      }

      if (get(form, 'aliasForParent')) return get(form, 'label');

      return get(form, 'childFormAlias');
    }
  });
});