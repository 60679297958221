define('client/services/gate-keeper', ['exports', 'npm:lodash/isArray', 'npm:lodash/includes'], function (exports, _isArray, _includes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Service.extend({
    session: inject.service(),

    loaded: computed.alias('session.loaded'),

    isAuthenticated: computed.alias('session.isAuthenticated'),

    /**
     * Usage: Inject the 'gateKeeper' service into your route or component
     * `get(this, 'gateKeeper').can('global.test_permission_1')`
     *
     * @param {string} action Permission name.
     * @return {boolean}
     */
    can: function can(action) {
      var allowedActions = get(this, 'session.currentUser.allowedActions');
      return (0, _isArray.default)(allowedActions) && (0, _includes.default)(allowedActions, action);
    },


    /**
     * Similar to "can" but ensures that the session is loaded before
     * performing the test. Note: Asynchronous.
     *
     * @param {string} action Permission name.
     * @return {Promise<boolean>}
     */
    canEnsureLoaded: function canEnsureLoaded(action) {
      var _this = this;

      return get(this, 'session.loaded').then(function () {
        return _this.can(action);
      });
    },


    /**
     * Checks that the session has been loaded
      * @return {Promise<boolean>}
     */
    isSessionLoaded: function isSessionLoaded() {
      return get(this, 'session.loaded');
    }
  });
});