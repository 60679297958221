define('client/mixins/routes/home-section-tab', ['exports', 'npm:lodash/find'], function (exports, _find) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create({
    redirect: function redirect() {
      // If the tab specified in the routeName does not exist in `tabs`
      // (for example when the user doesn't have permission to access it)
      // redirect to the first tab

      var tabs = get(this.modelFor('home-section'), 'tabs');
      var currentTab = (0, _find.default)(tabs, { path: get(this, 'routeName') });
      if (currentTab === undefined) {
        this.transitionTo(get(tabs, '0.path'));
      }
    }
  });
});