define('client/controllers/consents-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // when ?publishConsent=true, we display the publish or unpublish consent dialog
    queryParams: ['publishConsent'],
    publishConsent: false,
    actions: {
      refreshConsentsSectionModel: function refreshConsentsSectionModel() {
        this.send('refreshConsentsSectionRoute');
      }
    }
  });
});