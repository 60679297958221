define('client/libs/map-lib-esri', ['exports', 'client/libs/map-lib-base'], function (exports, _mapLibBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LINZ_API_KEY = '527366268de6447fb458a2899688ed36'; /* global L */

  // Extends map-lib-base to provide LINZ base maps

  exports.default = Object.assign({}, _mapLibBase.default, {
    getBaseLayers: function getBaseLayers() {
      var worldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      });

      var landParcels = L.tileLayer('http://tiles-{s}.data-cdn.linz.govt.nz/services;key=' + LINZ_API_KEY + '/tiles/v4/set=4769/EPSG:3857/{z}/{x}/{y}.png', {
        'subdomains': 'abcd', // This will allow Leaflet to get tiles from all subdomains
        'attribution': 'Land parcels sourced from <a href=“http://data.linz.govt.nz”>LINZ</a> CC BY 4.0',
        'continuousWorld': true,
        'maxZoom': 16,
        'minZoom': 6
      });

      return L.layerGroup([worldImagery, landParcels]);
    },
    addInitialLayersAndMarkers: function addInitialLayersAndMarkers(map) {
      // add extra layers
      // add extra labels
    },


    /**
     * Default Center to use.
     */
    defaultCenter: L.latLng(-40.852931, 172.762057), // TODO: set default map center based on the council

    mapDefaultZoom: 6,
    mapMinZoom: undefined,
    mapMaxZoom: 18,

    maxClusteringZoomLevel: 13,

    // WGS 84 / Pseudo-Mercator -- Spherical Mercator, Google Maps, OpenStreetMap, Bing, ArcGIS, ESRI
    crs: L.CRS.EPSG3857
  });
});