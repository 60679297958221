define('client/services/council-services', ['exports', 'client/mixins/cache', 'client/services/remote-method'], function (exports, _cache, _remoteMethod) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get;
  exports.default = _remoteMethod.default.extend(_cache.default, {
    // Cache for a minute
    cacheLengthSec: 60,

    /**
     * Makes a request to the server to fetch name numbers.
     *
     * @param {string} debtorNumber
     * @return {object} e.g. {"error": false, "results": [12666]}
     */
    getNameNumbersFromDebtorNumber: function getNameNumbersFromDebtorNumber(debtorNumber) {
      var cache = get(this, 'cache');
      var cacheKey = 'getNameNumbersFromDebtorNumber_' + debtorNumber;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var result = this.request('council-services/get-ratepayer-identifiers?debtorNumber=' + encodeURIComponent(debtorNumber));
      cache.save(cacheKey, result);
      return result;
    },


    /**
     * Makes a request to the server to fetch debtor numbers.
     *
     * @param {number} ratepayerIdentifier
     * @return {object} e.g. {"error": false, "results": ["556404"]}
     */
    getDebtorNumbersFromNameNumber: function getDebtorNumbersFromNameNumber(ratepayerIdentifier) {
      var cache = get(this, 'cache');
      var cacheKey = 'getDebtorNumbersFormNameNumber_' + ratepayerIdentifier;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var result = this.request('council-services/get-debtor-numbers?ratepayerIdentifier=' + encodeURIComponent(ratepayerIdentifier));
      cache.save(cacheKey, result);
      return result;
    },


    /**
     * Makes a request to the server to search for CI records.
     *
     * @param {string} query A name number can be given or a string that searches
     *                       through other fields such as name.
     * @return {object} e.g. {"error": false, "results": [{name_Ctr...}]}
     */
    debtorNumberSearch: function debtorNumberSearch(query) {
      var cache = get(this, 'cache');
      var cacheKey = 'debtorNumberSearch_' + query;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var result = this.request('council-services/debtor-number-search?query=' + encodeURIComponent(query));
      cache.save(cacheKey, result);

      return result;
    },


    /**
     * Makes a request to the server to search for a single debtor record.
     * Returns a promise that rejects if a single matching record couldn't be found.
     *
     * @param {number} debtorNumber A debtor number
     * @return {Promise<Object>} Promise that returns an object containing the details
     *                       of the CI record
     */
    debtorNumberDetails: function debtorNumberDetails(debtorNumber) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.debtorNumberSearch(debtorNumber).then(function () {
          var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          var _response$results = response.results,
              results = _response$results === undefined ? [] : _response$results;

          if (results.length !== 1) {
            return reject(new Error('Returned ' + results.length + ' results (expecting 1)'));
          }

          var _results = _slicedToArray(results, 1),
              _results$ = _results[0],
              result = _results$ === undefined ? {} : _results$;

          if (String(result.debtorNumber) !== String(debtorNumber)) {
            return reject(new Error('Returned result with ' + JSON.stringify({ 'debtorNumber': result.debtorNumber }) + ' (expecting ' + JSON.stringify({ 'debtorNumber': debtorNumber }) + ')'));
          }
          resolve(result);
        });
      });
    },


    /**
     * Makes a request to the server to search for CI records.
     *
     * @param {string} query A name number can be given or a string that searches
     *                       through other fields such as name.
     * @return {object} e.g. {"error": false, "results": [{name_Ctr...}]}
     */
    ratepayerIdentifierSearch: function ratepayerIdentifierSearch(query) {
      var cache = get(this, 'cache');
      var cacheKey = 'ratepayerIdentifierSearch_' + query;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var result = this.request('council-services/ratepayer-identifier-search?query=' + encodeURIComponent(query));
      cache.save(cacheKey, result);
      return result;
    },


    /**
     * Makes a request to the server to search for a single CI record.
     * Returns a promise that rejects if a single matching record couldn't be found.
     *
     * @param {string} ratepayerIdentifier A name number can be given (recommended)
     *                       or a string that searches similar to ratepayerIdentifierSearch (not recommended)
     * @return {Promise<Object>} Promise that returns an object containing the details
     *                       of the CI record e.g. {name_Ctr...}
     */
    ratepayerIdentifierDetails: function ratepayerIdentifierDetails(ratepayerIdentifier) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        _this2.ratepayerIdentifierSearch(ratepayerIdentifier).then(function () {
          var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          var _response$results2 = response.results,
              results = _response$results2 === undefined ? [] : _response$results2;

          if (results.length !== 1) {
            return reject(new Error('Returned ' + results.length + ' results (expecting 1)'));
          }

          var _results2 = _slicedToArray(results, 1),
              _results2$ = _results2[0],
              result = _results2$ === undefined ? {} : _results2$;

          if ('' + result.ratepayerIdentifier !== '' + ratepayerIdentifier) {
            return reject(new Error('Returned result with ' + JSON.stringify({ 'ratepayerIdentifier': '' + result.ratepayerIdentifier }) + ' (expecting ' + JSON.stringify({ 'ratepayerIdentifier': '' + ratepayerIdentifier }) + ')'));
          }
          resolve(result);
        });
      });
    },


    /**
     * Property lookup.
     * @param {String} query
     * @returns {Promise<Object|null>}
     */
    propertyNumberSearch: function propertyNumberSearch(query) {
      var cache = get(this, 'cache');
      var cacheKey = 'propertyNumberSearch_' + query;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var result = this.request('council-services/property-search?query=' + encodeURIComponent(query)).then(function (response) {
        if (response.error) {
          // If there's an error remove it from the cache.
          cache.flush(cacheKey);
          throw new Error('Error from Property API - unable to retrieve property search');
        } else {
          return response.results;
        }
      });
      cache.save(cacheKey, result);
      return result;
    },


    /**
     * Get a single property.
     * @param {String} propertyNumber
     * @returns {Promise<Object|null>}
     */
    getProperty: function getProperty(propertyNumber) {
      var cache = get(this, 'cache');
      var cacheKey = 'getProperty_' + propertyNumber;
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var result = this.request('council-services/get-property?propertyNumber=' + encodeURIComponent(propertyNumber)).then(function (response) {
        if (response.error) {
          // If there's an error remove it from the cache.
          cache.flush(cacheKey);
          throw new Error('Error from Property API - unable to retrieve property');
        } else {
          if (response.result === null) {
            throw new Error('Property not found');
          }
          return response.result;
        }
      });
      cache.save(cacheKey, result);
      return result;
    },


    /**
     * Makes a request to the server to create TRIM folders for this application.
     * @param {String} applicationId
     * @returns {Promise<Object|null>}
     */
    createTrimFolders: function createTrimFolders(applicationId) {
      return this.post('documents/create-containers/' + applicationId);
    }
  });
});