define('client/mixins/components/show-delete-button', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Mixin.create({
    showDeleteButton: computed('totalNumberDisplayed', 'formElement.{repeatable,minRepeats}', function () {
      if (!get(this, 'formElement.repeatable')) return false;
      var minRepeats = getWithDefault(this, 'formElement.minRepeats', _constants.MIN_REPEATS);
      return get(this, 'totalNumberDisplayed') > minRepeats;
    })
  });
});