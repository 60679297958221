define('client/components/content-container/component', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    router: inject.service(),
    gateKeeper: inject.service(),
    tagName: '',
    isFullWidth: true,
    sidePanelClass: computed('isSidePanelOpen', function () {
      return get(this, 'isSidePanelOpen') ? 'l-side-panel-container--show' : '';
    }),

    section: computed('formRootRoute', function () {
      return get(this, 'formRootRoute').split('.')[0];
    }),

    /**
     * Create the processing and consents menu items.
     */
    topMenuItems: computed('section', 'pageInfo', 'tabConfig', 'gateKeeper', function () {
      var _this = this;

      var section = get(this, 'section');
      var pageInfo = get(this, 'pageInfo') || {};
      var tabConfig = get(this, 'tabConfig');

      if (section === 'processing-section') {
        return _constants.PROCESSING_TABS.filter(function (_ref) {
          var hideIf = _ref.hideIf;
          return hideIf === undefined || !hideIf(pageInfo);
        }).filter(function (_ref2) {
          var permission = _ref2.permission;
          return permission === undefined || get(_this, 'gateKeeper').can(permission);
        }).filter(function (_ref3) {
          var key = _ref3.key;

          if (!Array.isArray(tabConfig)) {
            return true;
          }
          var thisTabConfig = tabConfig.find(function (x) {
            return x.key === key;
          });
          return thisTabConfig === undefined || thisTabConfig.visible !== false;
        }).map(function (tab) {
          return {
            path: 'processing-section.' + tab.key,
            key: tab.key,
            title: tab.title,
            isEmphasisedLink: !!tab.isEmphasisedLink,
            disabled: tab.disabled,
            separator: tab.separator
          };
        });
      }

      if (section === 'consents-section') {
        return _constants.CONSENTS_TABS.filter(function (_ref4) {
          var permission = _ref4.permission;
          return permission === undefined || get(_this, 'gateKeeper').can(permission);
        }).filter(function (_ref5) {
          var key = _ref5.key;

          if (!Array.isArray(tabConfig)) {
            return true;
          }
          var thisTabConfig = tabConfig.find(function (x) {
            return x.key === key;
          });
          return thisTabConfig === undefined || thisTabConfig.visible !== false;
        }).map(function (tab) {
          return {
            path: 'consents-section.' + tab.key,
            key: tab.key,
            title: tab.title,
            isEmphasisedLink: !!tab.isEmphasisedLink,
            disabled: tab.disabled
          };
        });
      }
    }),

    /*
     * Returns currently active route (used for styling active menu item).
     */
    currentRoute: computed('router.currentRouteName', function () {
      return get(this, 'router.currentRouteName');
    }),

    actions: {
      closeMenu: function closeMenu() {
        set(this, 'isSidePanelOpen', false);
        $('body').removeClass('_hide-overflow');
      },
      toggleSidePanel: function toggleSidePanel() {
        this.toggleProperty('isSidePanelOpen');
        /*
          Hack to stop page scrolling behind open menu panel...
          There aren't any better alternatives to this problem
          as far as I'm aware so will probably stick around:
        */
        $('body').toggleClass('_hide-overflow');
      }
    }
  });
});