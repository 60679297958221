define('client/initializers/leaflet', ['exports', 'npm:proj4leaflet', 'npm:esri-leaflet', 'npm:leaflet.markercluster'], function (exports, _npmProj4leaflet, _npmEsriLeaflet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* container, application */{
    L.esri = _npmEsriLeaflet.default;
    L.Proj = _npmProj4leaflet.default;
    // Fix the browser touch flag so that we can get consistent point sizes.
    L.Browser.touch = false;
  }
  // import {} from 'npm:leaflet-draw'
  /* global L */

  // Need to import this to get access to L on the global name space.
  exports.default = {
    name: 'leaflet-initializer',
    initialize: initialize
  };
});