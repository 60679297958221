define('client/mixins/cache', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject,
      $ = Ember.$;
  exports.default = Ember.Mixin.create({
    cacheFactory: inject.service(),

    // The cache object, provides 'save(key, value)' and 'fetch(key)' functions.
    // See cache factory for details.
    cache: null,

    // Default to 15min, can set this in the class this is mixed into to change.
    cacheLengthSec: 15 * 60,

    /**
     * Initialise the cache object.
     */
    init: function init() {
      this._super.apply(this, arguments);
      var cache = get(this, 'cacheFactory').create(get(this, 'cacheLengthSec'));
      set(this, 'cache', cache);
    },


    /**
     * Builds a string based on query parameters, useful for using as a cache key.
     *
     * @param {Object} queryData As passed to an Ember Ajax request.
     * @returns {String}
     */
    cacheKeyFromRequestData: function cacheKeyFromRequestData(queryData) {
      return $.param(queryData);
    },


    /**
     * Flush the entire cache.
     */
    cacheFlushAll: function cacheFlushAll() {
      get(this, 'cache').flushAll();
    }
  });
});