define('client/components/modal-dialog/grant-iwi-access/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),

    loading: null,
    hideCloseButton: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'loading', false);
      set(this, 'hideCloseButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      set(this, 'loading', false);
    },


    applicationNumber: computed('pageInfo.consentNumber', function () {
      return get(this, 'pageInfo.consentNumber') || 'Not yet specified';
    }),

    isReleasedToIwi: computed.alias('pageInfo.isReleasedToIwi'),

    modalHeading: computed('isReleasedToIwi', function () {
      return get(this, 'isReleasedToIwi') ? 'Remove access from Iwi' : 'Make available to Iwi';
    }),

    actions: {
      grantAccess: function grantAccess() {
        var _this = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').grantIwiDecisionAccess(get(this, 'pageInfo.applicationId')).then(function (response) {
          if (response.error) {
            get(_this, 'flashMessages.danger')('Unable to make available to Iwi' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this, 'flashMessages.success')('Made available to Iwi');
            get(_this, 'remoteMethods').clearProcessingPageInfoCache(get(_this, 'pageInfo.applicationId'));
            _this.get('setShowGrantIwiDecisionAccessModal')(false); // remove the `?grantIwiDecisionAccess=true` from the URL
            _this.refreshModel();
          }
        }).finally(function () {
          set(_this, 'loading', false);
        });
      },
      revokeAccess: function revokeAccess() {
        var _this2 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').revokeIwiDecisionAccess(get(this, 'pageInfo.applicationId')).then(function (response) {
          if (response.error) {
            get(_this2, 'flashMessages.danger')('Unable to remove access from Iwi' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this2, 'flashMessages.success')('Access removed from Iwi');
            get(_this2, 'remoteMethods').clearProcessingPageInfoCache(get(_this2, 'pageInfo.applicationId'));
            _this2.get('setShowGrantIwiDecisionAccessModal')(false); // remove the `?grantIwiDecisionAccess=true` from the URL
            _this2.refreshModel();
          }
        }).finally(function () {
          set(_this2, 'loading', false);
        });
      }
    }
  });
});