define('client/components/login-or-register/component', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    session: inject.service(),

    googleAuthEnable: _environment.default.ENV_VARS.GOOGLE_AUTH_ENABLE,
    fakeAuthEnable: _environment.default.ENV_VARS.FAKE_AUTH_ENABLE,
    datacomAuthEnable: _environment.default.ENV_VARS.DATACOM_AUTH_ENABLE,

    actions: {
      login: function login(providerName) {
        set(this, 'session.returnTo', get(this, 'returnTo'));
        get(this, 'session').login(providerName);
      }
    }
  });
});