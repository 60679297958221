define('client/controllers/processing-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['editApplicationNumber', 'grantIwiDecisionAccess', 'grantPublicDecisionAccess', 'notifyApplication', 'finaliseDecision', 'updateConsentsAndPermits', 'finaliseS88CheckDecision', 'finaliseLetter', 'finaliseS37Letter', 'finaliseS91Letter', 'finaliseS91adLetter', 'finaliseS92_2Letter', 'finaliseS88Letter', 'finaliseS37Decision', 'finaliseSubmission', 'finaliseS91Decision', 'finaliseS91adDecision', 'finaliseS92_2Decision', 'amendApplication', 'confirmPublicSubmission'],
    grantIwiDecisionAccess: false,
    grantPublicDecisionAccess: false,
    editApplicationNumber: false,
    notifyApplication: false,
    finaliseDecision: false,
    updateConsentsAndPermits: false,
    finaliseS88CheckDecision: false,
    finaliseLetter: false,
    finaliseS37Letter: false,
    finaliseS91Letter: false,
    finaliseS91adLetter: false,
    finaliseS92_2Letter: false,
    finaliseS88Letter: false,
    finaliseS37Decision: false,
    finaliseSubmission: false,
    finaliseS91Decision: false,
    finaliseS91adDecision: false,
    finaliseS92_2Decision: false,
    amendApplication: false,
    confirmPublicSubmission: false,
    formInstanceId: null,
    actions: {
      refreshProcessingSectionModel: function refreshProcessingSectionModel() {
        return this.send('refreshProcessingSectionRoute');
      }
    }
  });
});