define('client/components/dynamic-form/view/select/component', ['exports', 'ember-data', 'client/mixins/components/dynamic-formElement'], function (exports, _emberData, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject,
      getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    dynamicFormsMethods: inject.service(),

    displayValue: computed('stateValue', function () {
      var selected = getWithDefault(this, 'values.0.selectedOption.label', '-');
      return _emberData.default.PromiseObject.create({ promise: Ember.RSVP.resolve(selected) });
    }),

    statusPill: computed.alias('formElement.extendedAttributes.statusPill'),
    statusPillType: computed('statusPill', 'displayValue', function () {
      var _this = this;

      // Type of the status pill - error, success, info .etc
      // Configured using the extendedAttributes object in the form JSON
      // Can be set conditionally based on the stateValue, eg:
      // "extendedAttributes": {
      //   "statusPill": {
      //     "type": "info",  // used if none of the values below match
      //     "conditionalTypes": [
      //       { "val": 10766, "#": "Granted", "type": "success" },
      //       { "val": 10769, "#": "Refused", "type": "error" }
      //     ]
      //   }
      // }
      if (this.statusPill === undefined || !this.stateValue) {
        // Note: this returns undefined if the stateValue null, to avoid displaying "-" in a pill.
        return;
      }

      if (Array.isArray(this.statusPill.conditionalTypes)) {
        var conditionalType = this.statusPill.conditionalTypes.find(function (_ref) {
          var val = _ref.val;

          if (Array.isArray(val)) {
            return val.includes(_this.stateValue);
          }
          return val === _this.stateValue;
        });
        if (conditionalType !== undefined) {
          return conditionalType.type;
        }
      }
      return this.statusPill.type;
    })
  });
});