define('client/components/modal-content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend({
    classNames: ['c-modal-content'],
    actions: {
      overlayClicked: function overlayClicked() {
        // clicking on the overlay should do nothing if the close button is hidden
        if (get(this, 'hideCloseButton') !== true) {
          set(this, 'displayModal', false);
        }
      }
    }
  });
});