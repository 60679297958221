define('client/helpers/format-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params, namedParams) {
    var value = params[0];
    var isRenderAsHtml = !!(namedParams && namedParams.isRenderAsHtml);
    if (typeof value === 'string') {
      value = value.trim();
      if (isRenderAsHtml) {
        // The string should be rendered as HTML
        // Check if the string passed in already starts with HTML like tag
        var pattern = new RegExp(/^<[a-z][^>]*>/);
        if (!pattern.test(value)) {
          // Wrap string with <p> tag for proper styling of the text
          value = '<p>' + value + '</p>';
        }
      }
    }
    if (value === 0) value = '0';
    return value || '-';
  });
});