define('client/components/dynamic-form/edit/typeahead-ci-name/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Note: If you wish to save these to the form/back-end tables, they must exist
  //        in the dynamic forms config as child formElements of this formElement
  var childFormElements = exports.childFormElements = [{
    type: 'text',
    name: 'nameNumber',
    label: 'Name number',
    getValue: function getValue(details) {
      return details.ratepayerIdentifier;
    }
  }, {
    type: 'text',
    name: 'fullName',
    label: 'Full name',
    getValue: function getValue(details) {
      return details.fullName;
    }
  }, {
    type: 'text',
    name: 'address',
    label: 'Address',
    getValue: function getValue(details) {
      return details.address;
    }
  }];

  // the ember service to use for the search
  var searchServiceName = exports.searchServiceName = 'council-services';

  // The method used perform the search
  // Should take a query string and return a promise that resolves to an array of objects
  var searchMethodName = exports.searchMethodName = 'ratepayerIdentifierSearch';
});