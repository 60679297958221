define('client/components/component-workflows/documents/list/columns', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    'sortable': false,
    'width': '30px',
    'cellComponent': 'light-table-components/row-toggle',
    'classNames': 'c-table__cell c-table__cell--heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__row--clickable c-table__row--toggle-column',
    'breakpoints': ['upToDesktop']
  }, {
    'label': 'Title',
    'valuePath': 'document_title',
    'cellComponent': 'light-table-components/cell-with-title',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap'
  }, {
    'label': 'Creation date',
    'valuePath': 'creation_date',
    'width': '150px',
    'cellComponent': 'dynamic-form/view/table-view/date-field',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap'
  }, {
    'label': 'Type',
    'valuePath': 'file_type',
    'width': '60px',
    'cellComponent': 'light-table-components/cell-with-title',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap',
    'breakpoints': ['desktopUp']
  }, {
    'label': 'Number',
    'width': '100px',
    'valuePath': 'trim_reference',
    'cellComponent': 'light-table-components/cell-with-title',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--wrap',
    'breakpoints': ['desktopUp']
  }, {
    'label': '',
    'valuePath': 'actions',
    'width': '100px',
    'align': 'initial', // reset default alignment so actions align right
    'cellComponent': 'component-workflows/documents/list/actions',
    'classNames': 'c-table__cell c-table__cell--heading c-table__cell--view-heading',
    'cellClassNames': 'c-table__cell c-table__cell--view c-table__cell--actions'
  }];
});