define('client/components/generic-search/filter/component', ['exports', 'client/libs/generic-search'], function (exports, _genericSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function findFilterKey() {
    var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (filter.filterSet) {
      return filter.filterSet.filters.map(findFilterKey).filter(function (x) {
        return x !== undefined;
      }).pop();
    } else if (filter.filterKey) {
      return filter.filterKey;
    }
  }

  exports.default = Ember.Component.extend({
    tagName: '',
    query: null,
    viewKey: null,
    isCollapsed: false,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'query', {});
      Ember.get(this, 'reinflateQuery'); // get this to ensure the observer works correctly
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.set(this, 'viewKey', this.searchTemplate.views[0].viewKey);
      this.searchTemplate.filters.forEach(function (_ref) {
        var filterKey = _ref.filterKey;

        Ember.set(_this.query, filterKey, Ember.A());
      });
      this.queryChanged(false);
      this.reinflateQueryObserver();
    },


    // the `reinflateQuery` parameter gets set in order to 'reinflate' a query from JSON
    // we use an observer here to allow it to be changed without completely re-initialising
    // the component
    reinflateQueryObserver: Ember.observer('reinflateQuery', function () {
      var _this2 = this;

      if (!this.reinflateQuery) return;

      // make sure the existing query is cleared
      Object.keys(this.query).forEach(function (filterKey) {
        _this2.query[filterKey].clear();
      });

      // reinflate the query
      this.reinflateQuery.filterSet.filters.forEach(function (filter) {
        var filterKey = findFilterKey(filter);
        _this2.query[filterKey].pushObjects(filter.filterSet.filters);
      });
      this.queryChanged();
      if (Ember.getWithDefault(this, 'collapseOnReinflate', true) === true) {
        this.collapseFilters();
      }

      // pass the event to the higher-level component to initiate the search
      if (Ember.getWithDefault(this, 'runSearchOnReinflate', true) === true && this.onSearch) {
        this.onSearch(this.searchQuery, false);
      }
    }),

    collapseFilters: function collapseFilters() {
      // set isCollapsed to true if there are no filters applied, else set it to false
      Ember.set(this, 'isCollapsed', (0, _genericSearch.removeRedundantFilters)(this.searchQuery, true, true) !== undefined);
    },


    noFilters: false,

    onCollapseToggleObserver: Ember.observer('isCollapsed', function () {
      if (this.onCollapseToggle) {
        this.onCollapseToggle(Ember.get(this, 'isCollapsed'));
      }
    }),

    // this gets called after changing the internal representation of the query, in order to construct
    // 1: the searchQuery JSON
    // 2: the filter values that get passed to the child components
    queryChanged: function queryChanged() {
      var _this3 = this;

      var bubbleEvent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      Ember.set(this, 'searchQuery', (0, _genericSearch.removeRedundantFilters)({
        'filterSet': {
          'booleanOperator': 'AND',
          'filters': Object.keys(this.query).map(function (key) {
            return {
              'filterSet': {
                'booleanOperator': 'OR',
                'filters': _this3.query[key]
              }
            };
          })
        }
      }, true, false));

      // special case when no filters are applied
      if (this.searchQuery === undefined) {
        Ember.set(this, 'searchQuery', {
          'filterSet': {
            'booleanOperator': 'AND',
            'filters': []
          }
        });
      }

      if (Object.keys(this.query).filter(function (key) {
        return _this3.query[key].length > 0;
      }).length > 0) {
        Ember.set(this, 'noFilters', false);
      } else {
        Ember.set(this, 'noFilters', true);
      }

      // optionally pass the event to the higher-level component
      if (bubbleEvent && this.onChange) {
        this.onChange(this.searchQuery);
      }
    },


    actions: {
      addValue: function addValue(filterKey) {
        if (this.query[filterKey]) {
          this.query[filterKey].pushObject({});
        }
        this.queryChanged();
      },
      setValue: function setValue(filterKey, index, value) {
        Ember.set(this, 'query.' + filterKey + '.' + index, value);
        this.queryChanged();
      },
      removeValue: function removeValue(filterKey, index) {
        if (this.query[filterKey].length === 1) {
          // if there is only one value, call removeAllValues to remove the entire filter
          this.actions.removeAllValues.call(this, filterKey, index);
        } else {
          // else remove the value at the specified index
          this.query[filterKey].removeAt(index);
        }
        this.queryChanged();
      },
      removeAllValues: function removeAllValues(filterKey) {
        this.query[filterKey].clear();
        this.queryChanged();
      },
      resetSearch: function resetSearch() {
        var _this4 = this;

        Object.keys(this.query).forEach(function (filterKey) {
          _this4.query[filterKey].clear();
        });
        this.queryChanged();
        this.send('performSearch');
      },
      performSearch: function performSearch() {
        this.onSearch(this.searchQuery, true);
        this.collapseFilters();
      }
    }
  });
});