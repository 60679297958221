define('client/components/dynamic-form/view/text-area/text-field/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-view-text'],
    classNameBindings: ['formElement.suppressLabelInView:c-view-text--hide-label', 'isAddress:c-view-text--no-emphasis']
  });
});