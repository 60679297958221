define('client/components/actions-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      $ = Ember.$;
  exports.default = Ember.Component.extend({
    classNames: ['c-actions-bar', 'h-hide-for-print'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      $('input, textarea, select').on('focus', get(this, 'formFieldTabbingCorrection'));
    },
    formFieldTabbingCorrection: function formFieldTabbingCorrection() {
      // Check their position relative to the window's scroll
      var fieldBottom = $(this).offset().top + $(this).height();
      var windowScroll = $(window).scrollTop();
      var windowBottom = windowScroll + $(window).height();
      var barHeightBuffer = $('.fixed-actions-bar').height() + 50;
      if (fieldBottom + barHeightBuffer > windowBottom) {
        $(window).scrollTop(windowScroll + barHeightBuffer);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      $('input, textarea, select').off('focus', get(this, 'formFieldTabbingCorrection'));
    }
  });
});