define('client/components/dynamic-form/edit/location/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement'], function (exports, _dynamicFormElement, _complexFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var computed = Ember.computed,
      get = Ember.get,
      copy = Ember.copy;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    classNames: ['c-form-location-field'],
    easting: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'easting';
      });
    }),
    northing: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'northing';
      });
    }),
    location: computed('stateValue.easting.0.val', 'stateValue.northing.0.val', function () {
      var easting = get(this, 'stateValue.easting.0.val');
      var northing = get(this, 'stateValue.northing.0.val');
      return [easting, northing];
    }),
    actions: {
      /**
       * Handle a location selected by map.
       *
       * Updates the easting and northing in the children form elements.
       * Simulates a call to updateStateKey as the child form elements would.
       */
      setLocation: function setLocation(_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            easting = _ref2[0],
            northing = _ref2[1];

        var state = copy(get(this, 'formElementState'), true);
        var name = get(this, 'formElement.name');

        // Update the state
        state[0]['val']['easting'][0]['val'] = easting.toFixed(3);
        state[0]['val']['northing'][0]['val'] = northing.toFixed(3);

        // Now update with the new state
        get(this, 'updateStateKey')(name, state);
      }
    }
  });
});