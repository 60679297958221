define('client/helpers/strip-tags', ['exports', 'npm:lodash/unescape'], function (exports, _unescape) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _toArray(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    if (typeof value === 'string') {
      value = (0, _unescape.default)(value.replace(/(<([^>]+)>)|&nbsp;/ig, ''));
    }
    return value;
  });
});