define('client/components/pagination-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    curPage: 0,
    pageSize: 10,
    maxPageLinksShown: 5,
    lastPage: computed('total', 'pageSize', function () {
      var lastPage = Math.ceil(get(this, 'total') / get(this, 'pageSize'));
      return lastPage && lastPage > 0 ? lastPage : 1;
    }),
    pageRange: computed('lastPage', 'curPage', function () {
      var pages = [];

      var curPage = get(this, 'curPage');
      var numPages = get(this, 'lastPage');
      var maxPageLinks = numPages > get(this, 'maxPageLinksShown') ? get(this, 'maxPageLinksShown') : numPages;
      var numLinksEachSideOfActive = Math.ceil((maxPageLinks - 1) / 2);

      // Default range
      var i = 1;
      var lastPageLink = maxPageLinks;

      // Alter range so that current page link in in center once possible:
      if (curPage > numLinksEachSideOfActive) {
        // If at end of list, current page link can't be in center:
        if (curPage + numLinksEachSideOfActive > numPages) {
          i = numPages - (maxPageLinks - 1);
          lastPageLink = numPages;
          // Otherwise, set range to 2 links either side of current page:
        } else {
          i = curPage - numLinksEachSideOfActive;
          lastPageLink = curPage + numLinksEachSideOfActive;
        }
      }

      for (i; i <= lastPageLink; i++) {
        pages.push(i);
      }
      return pages;
    })
  });
});