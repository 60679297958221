define('client/components/generic-search/filter/input/date-specific/component', ['exports', 'moment', 'client/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // The format used to display the currently set date
    dateFormat: _environment.default.dateFormat,

    // Use a custom function to parse dates entered by the user as text
    parseDate: function parseDate(dateString, format) {
      var dateFormats = [format, // The same format as used for display
      'D/M/YYYY', // Standard NZ date formatting
      'YYYY-M-D' // ISO standard date formatting
      ];
      return (0, _moment.default)(dateString, dateFormats).toDate();
    },


    dateValue: Ember.computed.alias('filter.filterValue'),

    actions: {
      setDate: function setDate(date) {
        if (!date) {
          this.setValue({});
        } else {
          this.setValue({
            'filterKey': this.template.filterKey,
            'filterOperator': this.template.filterOperator || '=',
            'filterValue': (0, _moment.default)(date).format('YYYY-MM-DD') // pikaday returns "any valid JavaScript date including Date object."
          });
        }
      }
    }

  });
});