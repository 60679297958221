define('client/test-fixtures/location-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    formElements: [{
      name: 'testGridRef',
      label: 'Test location',
      type: 'location',
      repeatable: true,
      numAutoRepeats: 1,
      formElements: [{
        name: 'easting',
        label: 'Easting',
        type: 'text'
      }, {
        name: 'northing',
        label: 'Northing',
        type: 'text'
      }]
    }],
    state: {
      testGridRef: [{
        val: {
          easting: [{
            val: '1234'
          }],
          northing: [{
            val: '5678'
          }]
        }
      }]
    }
  };
});