define('client/routes/home-section/issued-consents-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.UnprotectedRoute.extend({
    actions: {
      onSearchQueryChange: function onSearchQueryChange() {
        var _controller;

        (_controller = this.controller).onSearchQueryChange.apply(_controller, arguments);
      }
    }
  });
});