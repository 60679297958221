define('client/helpers/format-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _toArray(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    if (value == null || value.trim() === '') return '-';
    // Trim 0s from the rhs of the number.  Interesting hack of converting to a number and then back to string
    return Number(value).toString() || '-';
  });
});