define('client/test-fixtures/section-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'formTemplate': {
      // Ignore for now
      id: 177
    },
    'formElements': [{
      'name': 'applicationWrapper',
      'type': 'section',
      'repeatable': true,
      'numAutoRepeats': 1,
      'formElements': [{
        'name': 'applications',
        'type': 'section',
        'repeatable': true,
        'numAutoRepeats': 1,
        'minRepeats': 1,
        'maxRepeats': 4,
        formElements: [{
          'name': 'applicantTypeApplicant',
          'label': 'Type',
          'type': 'radio',
          'default': { val: 1 },
          'options': [{ 'value': 1, 'label': 'radio 1' }, { 'value': 2, 'label': 'radio 2' }, { 'value': 3, 'label': 'radio 3' }]
        }, {
          'name': 'someTextArea',
          'label': 'Some text area',
          'type': 'text'
        }]
      }, {
        'type': 'select',
        'name': 'Selectbox',
        'options': [{ 'value': 1, 'label': 'option 1' }, { 'value': 2, 'label': 'option 2' }, { 'value': 3, 'label': 'option 3' }]
      }]
    }],
    'state': {
      applicationWrapper: [{
        val: {
          applications: [{
            val: {
              applicantTypeApplicant: [{ val: null }],
              someTextArea: [{ val: null }]
            }
          }],
          Selectbox: [{ val: null }]
        }
      }]
    }
  };
});