define('client/test-fixtures/applicant-details-version-2', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'formTemplate': {
      'name': 'State Test Form',
      'version': 1,
      'templateKey': 'state_test_form'
    },
    'formElements': [{
      'comment': 'This outer element exists to spawn an application row. Could me moved into form template? would need duplication',
      'name': 'application',
      'initialiseStorageTableRow': 'application',
      'type': 'section',
      'repeatable': false,

      'formElements': [{
        'name': 'targetOrg',
        'label': 'Application To',
        'help': 'Select the target regional or district council which is appropriate',
        'type': 'select',
        'underlyingType': 'integer',
        'storageTable': 'application',
        'storageColumn': 'target_org',
        'validationConditions': [{
          'function': 'f_validate_not_null'
        }],
        'options': [{
          'value': 1,
          'label': 'Marlborough District Council'
        }, {
          'value': 4,
          'label': 'Otago Regional Council'
        }, {
          'value': 5,
          'label': 'Queenstown Lakes District Council'
        }],
        'repeatable': false
      }, {
        'name': 'agentOrApplicant',
        'label': 'Are you an...',
        'type': 'radio',
        'default': {
          'val': 1
        },
        'underlyingType': 'integer',
        'options': [{
          'value': 1,
          'label': 'Applicant'
        }, {
          'value': 2,
          'label': 'Agent'
        }],
        'repeatable': true
      }, {
        'name': 'councilRefAllocated',
        'label': 'Has a council reference been allocated?',
        'type': 'radio',
        'underlyingType': 'bool',
        'options': [{
          'value': true,
          'label': 'Yes'
        }, {
          'value': false,
          'label': 'No'
        }],
        'repeatable': false
      }, {
        'name': 'refNumber',
        'label': 'Reference number',
        'type': 'text',
        'repeatable': false,
        'displayConditions': { 'and': [{
            'name': 'councilRefAllocated',
            'value': true
          }] }
      }, {
        'name': 'affectedParties',
        'label': 'Affected Parties',
        'type': 'text',
        'repeatable': true
      }, {
        'name': 'affectedAreas',
        'label': 'Which area is affected?',
        'type': 'radio',
        'default': {
          'val': 1
        },
        'underlyingType': 'integer',
        'options': [{
          'value': 1,
          'label': 'Coastal'
        }, {
          'value': 2,
          'label': 'Forest'
        }, {
          'value': 3,
          'label': 'Lakes'
        }, {
          'value': 4,
          'label': 'Plains'
        }],
        'repeatable': true
      }, {
        'name': 'applications',
        'type': 'section',
        'repeatable': true,
        'formElements': [{
          'name': 'applicantType',
          'label': 'Type',
          'type': 'radio',
          'default': {
            'val': 1
          },
          'underlyingType': 'integer',
          'options': [{
            'value': 1,
            'label': 'Individual'
          }, {
            'value': 2,
            'label': 'Company'
          }, {
            'value': 3,
            'label': 'Trust'
          }]
        }, {
          'name': 'individual',
          'type': 'section',
          'initialiseStorageTableRow': 'applicant_details',
          'initialiseStorageTableBindColumn': 'application_id',
          'initialiseStorageTableBindKey': 'application',
          'displayConditions': { 'and': [{
              'name': 'applicantType',
              'value': 1
            }] },
          'formElements': [{
            'storageTable': 'applicant_details',
            'storageColumn': 'first_name',
            'name': 'firstName',
            'label': 'First name(s)',
            'help': 'Some help text',
            'type': 'text'
          }, {
            'storageTable': 'applicant_details',
            'storageColumn': 'last_name',
            'name': 'lastName',
            'label': 'Last Name',
            'type': 'text'
          }]
        }, {
          'name': 'company',
          'type': 'section',
          'displayConditions': { 'and': [{
              'name': 'applicantType',
              'value': 2
            }] },
          'formElements': [{
            'name': 'companyName',
            'label': 'Company Name',
            'help': 'Some help text',
            'type': 'text'
          }, {
            'name': 'companyNumber',
            'label': 'Registered Company Number',
            'type': 'text'
          }]
        }, {
          'name': 'trust',
          'type': 'section',
          'displayConditions': { 'and': [{
              'name': 'applicantType',
              'value': 3
            }] },
          'formElements': [{
            'name': 'trustName',
            'label': 'Trust Name',
            'help': 'Some help text',
            'type': 'text'
          }, {
            'name': 'trustNumber',
            'label': 'Registered Trust Number',
            'type': 'text'
          }]
        }]
      }]
    }],
    'state': {}
  };
});