define('client/components/dynamic-form/edit/validation-message/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/libs/validation-error-lib'], function (exports, _dynamicFormElement, _validationErrorLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    /**
     * Little bit of hacky code to remove duplicate errors from the same formElement.  Particularly useful where
     * child element errors have been promoted to their parent
     */
    processedErrors: computed('formElementState', 'state', 'index', 'formElement.name', function () {
      var errors = get(this, 'state.' + get(this, 'formElement.name') + '.' + get(this, 'index') + '.errors') || [];
      return (0, _validationErrorLib.deDupErrors)(errors);
    })
  });
});