define('client/components/dynamic-form/view/inline-help/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',
    showInView: Ember.computed.alias('formElement.extendedAttributes.showInView'),
    statusPill: Ember.computed.alias('formElement.extendedAttributes.statusPill')
  });
});