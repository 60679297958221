define('client/components/modal-dialog/confirm-public-submission/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    remoteMethods: inject.service('resource-consent-remote-methods'),
    dynamicFormsMethods: inject.service('dynamic-forms-methods'),
    isDisableSubmit: null,
    loading: false,
    hideCloseButton: false,
    modalHeading: '',
    modalText: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var formInstanceId = get(this, 'formInstanceId');
      var isShowModal = get(this, 'showPublicSubmissionConfirmModal');
      if (isShowModal) {
        set(this, 'loading', true);
        // Save the form instance first
        if (formInstanceId) {
          // Check if the current form instance has errors
          this.dynamicFormsMethods.fetchPublicSubmissionFormState(get(this, 'formInstanceId')).then(function (_ref) {
            var isWithinExtendedCloseDate = _ref.isWithinExtendedCloseDate,
                isDecisionFinalised = _ref.isDecisionFinalised,
                isWithinStandardCloseDate = _ref.isWithinStandardCloseDate,
                isReleasedForNotification = _ref.isReleasedForNotification,
                hasErrors = _ref.hasErrors,
                isAllowLateSubmission = _ref.isAllowLateSubmission;

            var isDisableSubmit = void 0,
                modalHeading = void 0,
                modalText = void 0;
            if (hasErrors) {
              modalHeading = 'Incomplete items';
              modalText = 'The form has incomplete items. Please fill out all the required fields and save the form.';
              isDisableSubmit = true;
            } else if (isDecisionFinalised || !isWithinExtendedCloseDate && !isWithinStandardCloseDate) {
              modalHeading = 'Public submissions have closed';
              modalText = '<p>Public submissions have closed. Any submission you wish to make must now be mailed to Council, by downloading and printing this submission and sending to Council at <a href="mailto:RCInbox@marlborough.govt.nz">RCInbox@marlborough.govt.nz</a>; once received the processing officer will make an assessment as to the validity of the submission.</p>';
              isDisableSubmit = !isAllowLateSubmission;
            } else if (!isReleasedForNotification || !isDecisionFinalised && isWithinExtendedCloseDate && !isWithinStandardCloseDate) {
              modalHeading = 'Public submissions have closed';
              modalText = 'While public submissions have closed, you are still able to send your submission to Council. However, your submission might not be accepted as it will be received after the submission closing date.';
              isDisableSubmit = false;
            } else {
              modalHeading = 'Submit application';
              modalText = 'Once you make your submission to council you will be unable to edit your submission';
              isDisableSubmit = false;
            }
            set(_this, 'modalText', modalText);
            set(_this, 'modalHeading', modalHeading);
            set(_this, 'isDisableSubmit', isDisableSubmit);
            set(_this, 'loading', false);
          });
        } else {
          set(this, 'loading', false);
          get(this, 'setShowPublicSubmissionConfirmModal')(false);
        }
      }
    },


    actions: {
      confirmPublicSubmission: function confirmPublicSubmission() {
        var _this2 = this;

        // Show the spinner and hide the close button because the user can't cancel this action once we've sent the request.
        set(this, 'loading', true);
        set(this, 'hideCloseButton', true);
        get(this, 'remoteMethods').confirmPublicSubmission(get(this, 'formInstanceId')).then(function (response) {
          if (response.error) {
            get(_this2, 'flashMessages.danger')('Submission failed' + (response.message ? ' - ' + response.message : ''));
          } else {
            get(_this2, 'flashMessages.success')('Application has been submitted');
            get(_this2, 'setShowPublicSubmissionConfirmModal')(false);
            _this2.refreshModel();
          }
        }).finally(function () {
          set(_this2, 'loading', false);
        });
      }
    }
  });
});