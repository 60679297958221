define('client/components/dynamic-form/edit/application-version-selector/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject,
      copy = Ember.copy,
      getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    remoteMethods: inject.service('resource-consent-lookups'),
    uniqueId: Ember.inject.service(),
    inputId: Ember.computed(function () {
      return this.uniqueId.next();
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var applicationId = getWithDefault(this, 'parentId', null);

      get(this, 'remoteMethods').getApplicationVersionsList(applicationId, false).then(function (results) {
        set(_this, 'versionList', results);
      }).then(function () {
        var applicationVersionId = getWithDefault(_this, 'values.0.val.applicationVersionId.0.val', null);
        var versionList = getWithDefault(_this, 'versionList', []);
        if (applicationVersionId !== null) {
          var version = versionList.find(function (version) {
            return version.application_id === parseInt(applicationVersionId);
          });
          if (version) set(_this, 'selectedVersion', version['application_id']);
        } else {
          var latest = versionList.find(function (version) {
            return version.is_latest;
          });
          if (latest) set(_this, 'selectedVersion', latest['application_id']);
        }
      });
    },


    actions: {
      setSelected: function setSelected(selected) {
        set(this, 'selectedVersion', selected);

        var applicationVersionId = getWithDefault(this, 'selectedVersion', null);

        var state = copy(get(this, 'formElementState'), true);
        state[0]['val']['applicationVersionId'][0]['val'] = applicationVersionId ? parseInt(applicationVersionId) : null;

        // Simulate a call to updateStateKey as the child form elements would
        var name = get(this, 'formElement.name');
        get(this, 'updateStateKey')(name, state);
      }
    }
  });
});