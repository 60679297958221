define('client/routes/route-not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      get = Ember.get;
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      set(this, 'queryParams', transition.queryParams);
    },
    model: function model(params) {
      return {
        pigify: get(this, 'queryParams.pigify')
      };
    }
  });
});