define('client/controllers/form/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    filesService: inject.service('files'),
    disableSave: computed.alias('filesService.uploadInProgress')
  });
});