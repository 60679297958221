define('client/initializers/add-string-includes-polyfill', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  // Source: https://github.com/ember-learn/ember-api-docs/pull/325
  function initialize() {
    // Source: https://developer.mozilla.org/cs/docs/Web/JavaScript/Reference/Global_Objects/String/includes#Polyfill
    if (!String.prototype.includes) {
      // eslint-disable-next-line no-extend-native
      String.prototype.includes = function (search, start) {
        'use strict';

        if (typeof start !== 'number') {
          start = 0;
        }
        if (start + search.length > this.length) {
          return false;
        } else {
          return this.indexOf(search, start) !== -1;
        }
      };
    }
  }

  exports.default = {
    name: 'add-string-includes-polyfill',
    initialize: initialize
  };
});