define('client/components/light-table-components/expandable-row/cell-value/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    cellValue: computed('row', 'column', function () {
      var cellVal = get(get(this, 'row'), get(this, 'column.valuePath'));
      if (get(this, 'column.format')) {
        return get(this, 'column.format')(cellVal);
      } else {
        return cellVal;
      }
    })
  });
});