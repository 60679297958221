define('client/components/recent-best-practice-guides/component', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  var HOST = _constants.BEST_PRACTICE_BLOG_URL.HOST,
      API_PATH = _constants.BEST_PRACTICE_BLOG_URL.API_PATH,
      LIMIT_POSTS_QUERY_STRING = _constants.BEST_PRACTICE_BLOG_URL.LIMIT_POSTS_QUERY_STRING;
  exports.default = Ember.Component.extend({
    tagName: null,
    isEnabled: _constants.BEST_PRACTICE_BLOG_ENABLED,
    posts: Ember.inject.service('wordpress-posts'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      /**
       * Load recent posts from Best Practice Guides Wordpress site:
       */
      get(this, 'posts').getPosts(HOST, API_PATH, '?' + LIMIT_POSTS_QUERY_STRING + get(this, 'numberOfPosts')).then(function (posts) {
        set(_this, 'isLoadingPosts', false);
        set(_this, 'posts', posts.posts);
      }).catch(function () {
        set(_this, 'hasFailedToLoadPosts', true);
      });
    },

    isLoadingPosts: true,
    hasFailedToLoadPosts: false,
    numberOfPosts: 6,
    bestPracticeSiteLink: HOST
  });
});