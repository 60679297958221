define('client/services/generic-search', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var filterSets = {};
  var previouslyDisplayedPage = {};
  var defaultFilterSet = {
    booleanOperator: 'AND',
    filters: []
  };

  exports.default = Ember.Service.extend({
    saveFilterSet: function saveFilterSet(type, filterSet) {
      filterSets[type] = (0, _emberCopy.copy)(filterSet, true);
    },
    getFilterSet: function getFilterSet(type) {
      return filterSets[type] || defaultFilterSet;
    },


    // Set the Previously displayed page for the search type
    // and view, so that when the search is navigated back to,
    // it can be returned to the original state. If the viewKey
    // is not specified, then all views for that searchType are'
    // set.
    setPreviouslyDisplayedPage: function setPreviouslyDisplayedPage(searchTypeKey) {
      var viewKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

      if (previouslyDisplayedPage[searchTypeKey] === undefined) {
        previouslyDisplayedPage[searchTypeKey] = {};
      }
      if (viewKey == null) {
        for (var view in previouslyDisplayedPage[searchTypeKey]) {
          if (previouslyDisplayedPage[searchTypeKey].hasOwnProperty(view)) {
            previouslyDisplayedPage[searchTypeKey][view] = 1;
          }
        }
      } else {
        previouslyDisplayedPage[searchTypeKey][viewKey] = page;
      }
    },
    getPreviouslyDisplayedPage: function getPreviouslyDisplayedPage(searchTypeKey, viewKey) {
      if (previouslyDisplayedPage[searchTypeKey] === undefined || previouslyDisplayedPage[searchTypeKey][viewKey] === undefined) {
        this.resetPreviouslyDisplayedPage(searchTypeKey, viewKey);
      }
      return previouslyDisplayedPage[searchTypeKey][viewKey];
    },
    resetPreviouslyDisplayedPage: function resetPreviouslyDisplayedPage(searchTypeKey) {
      var viewKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      this.setPreviouslyDisplayedPage(searchTypeKey, viewKey, 1);
    }
  });
});