define('client/routes/form/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Route.extend({
    dynamicFormsMethods: inject.service(),
    model: function model(_ref) {
      var instance_id = _ref.instance_id;

      return get(this, 'dynamicFormsMethods').fetchInstance(instance_id).then(function (form) {
        return { form: form, menu: {}, id: form.formInstance.id };
      });
    }
  });
});