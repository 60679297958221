define('client/helpers/starts-with', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _toArray(_ref),
        haystack = _ref2[0],
        needle = _ref2[1],
        rest = _ref2.slice(2);

    return haystack.indexOf(needle) === 0;
  });
});