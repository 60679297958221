define('client/components/generic-search/table/cell/date/component', ['exports', 'moment', 'client/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    displayValue: Ember.computed(function () {
      return this.value ? (0, _moment.default)(this.value).format(_environment.default.dateFormat) : '-';
    })
  });
});