define('client/helpers/number-to-human-size', ['exports', 'ember-number-to-human-size/helpers/number-to-human-size'], function (exports, _numberToHumanSize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _numberToHumanSize.default;
    }
  });
  Object.defineProperty(exports, 'numberToHumanSize', {
    enumerable: true,
    get: function () {
      return _numberToHumanSize.numberToHumanSize;
    }
  });
});