define('client/mixins/routes/handle-form-navigation', ['exports', 'client/libs/dynamic-forms-methods-lib'], function (exports, _dynamicFormsMethodsLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    resourceConsentMethods: inject.service(),
    dynamicFormsMethods: inject.service(),

    afterModel: function afterModel(model) {
      if ((0, _dynamicFormsMethodsLib.isNonNavigable)(model)) {
        // The user should not be able to land on this form, so navigate to the first child
        var childInstanceId = (0, _dynamicFormsMethodsLib.getChildInstanceId)(model);
        if (childInstanceId) {
          // If we have formRootRoute & formRootPath, honour those, otherwise
          // default to the application section.
          var formRootRoute = get(model, 'formRootRoute');
          var formRootPath = get(model, 'formRootPath');
          var mode = get(this, 'mode');
          if (formRootRoute && formRootPath) {
            var path = formRootPath + '/' + childInstanceId;
            this.transitionTo(formRootRoute, mode, path);
          } else {
            this.transitionTo('resource-consent-application.' + get(this, 'mode'), childInstanceId);
          }
        }
      }
    },


    actions: {
      // Move to the next form in the application
      next: function next() {
        var menu = get(this, 'controller.model.menu');
        var instanceId = get(this, 'controller.model.id');
        get(this, 'resourceConsentMethods').navigateToNextForm(menu, instanceId, get(this, 'mode'));
      },


      /**
       * Load a different snap shot for this application
       * @param {string} applicationInstanceId (either "12344" or "12345-43545" format accepted)
       */
      loadDifferentSnapshot: function loadDifferentSnapshot(applicationInstanceId) {
        var _this = this;

        // Need to fetch the menu for the target application so we can find out which specific form we should be loading
        get(this, 'dynamicFormsMethods').fetchMenu(applicationInstanceId).then(function (menuObj) {
          var mode = get(_this, 'mode');
          _this.transitionTo('resource-consent-application.' + mode, applicationInstanceId, menuObj.menu.forms[0].formInstanceId);
        });
      }
    }
  });
});