define('client/components/dynamic-form/edit/section/menu-section-element/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/show-delete-button', 'client/mixins/components/inline-form-table-view-action'], function (exports, _dynamicFormElement, _showDeleteButton, _inlineFormTableViewAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _showDeleteButton.default, _inlineFormTableViewAction.default, {
    tagName: 'tr',
    classNames: ['c-table__row']
  });
});