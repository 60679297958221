define('client/components/dynamic-form/edit/orderable-text-fields/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement'], function (exports, _dynamicFormElement, _complexFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      copy = Ember.copy,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    showConfirmationModal: false,

    /**
     * Keeps track of the numbering.
     *
     * @return {integer|null[]} An array of numbers, matches the index of the
     *                          orderable text fields, null means no number.
     *                          e.g. [null, 1, 2, null, 1, 2, 3]
     */
    numbers: computed('values', 'types', function () {
      var values = get(this, 'values');

      var counter = 0;
      var numbers = values.map(function (value) {
        var orderableTextFieldIsHeadingVal = get(value, 'val.orderableTextFieldIsHeading.0.val');
        var isHeading = orderableTextFieldIsHeadingVal && orderableTextFieldIsHeadingVal.toString() === 'true';

        // Deleted items and sub-headings don't get numbered.
        if (value.deleted || isHeading) return null;

        counter += 1;
        return counter;
      });

      return numbers;
    }),

    establishmentConditionChecks: computed('values', function () {
      var values = get(this, 'values');
      return values.map(function (value) {
        var isEstablishmentCondition = get(value, 'val.isEstablishmentCondition.0.val');
        return ['true', 'false'].includes(isEstablishmentCondition) ? isEstablishmentCondition : null;
      });
    }),

    /**
     * Marks a form element as deleted.
     * @param formElement
     * @param index
     */
    markDeleted: function markDeleted(formElement, index) {
      var state = copy(get(this, 'state'), true);
      state[formElement.name][index] = Object.assign({}, state[formElement.name][index], { deleted: true });
      get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'));
    },


    /**
     * For moving up and down we need to only work with visible (non-deleted)
     * items in the list. This filters deleted items out and returns an array
     * of their original indexes e.g. [0, 3, 4, 6] would mean that indexes of
     * 1, 2, or 5 in the list are deleted.
     *
     * @return {integer[]}
     */
    visibleIndexes: computed('values', function () {
      var values = get(this, 'values');
      var nonDeletedItems = [];
      values.forEach(function (value, index) {
        // Is it deleted?
        if (!value.deleted) nonDeletedItems.push(index);
      });
      return nonDeletedItems;
    }),

    actions: {
      toggleEstablishmentCondition: function toggleEstablishmentCondition(index) {
        var elementName = get(this, 'formElement.name');
        var visibleIndexes = get(this, 'visibleIndexes');
        var position = visibleIndexes.findIndex(function (i) {
          return i === index;
        });
        var existingState = get(this, 'state');
        var condition = existingState[elementName][position];
        var currentValue = condition['val']['isEstablishmentCondition'][0]['val'];
        var newValue = currentValue === 'false' ? 'true' : 'false';
        set(this, 'state.' + elementName + '.' + position + '.val.isEstablishmentCondition.0.val', newValue);
      },
      delete: function _delete(index, formElement) {
        var _this = this;

        set(this, 'confirmSuccessFunction', function () {
          _this.markDeleted(formElement, index);
          set(_this, 'showConfirmationModal', false);
        });
        set(this, 'showConfirmationModal', true);
      },
      add: function add(isHeading) {
        var _this2 = this;

        var elementName = get(this, 'formElement.name');
        var existingCount = get(this, 'state.' + elementName + '.length');
        get(this, 'populateFunctions').buildState({}, get(this, 'formElement.formElements'), existingCount + 1).then(function (newState) {
          newState['orderableTextFieldIsHeading'][0].val = isHeading ? 'true' : 'false';
          var existingState = copy(get(_this2, 'state'), true);
          existingState[elementName].push({ id: 'new', val: newState });
          _this2.updateStateKey(elementName, existingState[elementName]);
        });
      },
      moveUp: function moveUp(index) {
        var elementName = get(this, 'formElement.name');
        var visibleIndexes = get(this, 'visibleIndexes');
        var visiblePosition = visibleIndexes.findIndex(function (i) {
          return i === index;
        });

        if (visiblePosition < 1) {
          // Nothing to do, as item is not found or already first
          return;
        }

        var indexOfItemToSwitchWith = visibleIndexes[visiblePosition - 1];
        var existingState = copy(get(this, 'state'), true);
        var items = existingState[elementName];

        var item = items.splice(index, 1)[0]; // Remove item
        items.splice(indexOfItemToSwitchWith, 0, item); // Insert into new position
        this.updateStateKey(elementName, items);
        // As this is a re-ordering change, and not detected by the
        // dynamic-formElement mixin, force a reset of the "values" variable.
        this.forceSetValues();
      },
      moveDown: function moveDown(index) {
        var elementName = get(this, 'formElement.name');
        var visibleIndexes = get(this, 'visibleIndexes');
        var visiblePosition = visibleIndexes.findIndex(function (i) {
          return i === index;
        });

        if (visiblePosition === -1 || visiblePosition === visibleIndexes.length - 1) {
          // Nothing to do, as item is not found or this is the last item
          return;
        }

        var indexOfItemToSwitchWith = visibleIndexes[visiblePosition + 1];
        var existingState = copy(get(this, 'state'), true);
        var items = existingState[elementName];

        var item = items.splice(index, 1)[0]; // Remove item
        items.splice(indexOfItemToSwitchWith, 0, item); // Insert into new position
        this.updateStateKey(elementName, items);
        // As this is a re-ordering change, and not detected by the
        // dynamic-formElement mixin, force a reset of the "values" variable.
        this.forceSetValues();
      }
    }
  });
});