define('client/components/dynamic-form/dialogues/declaration-confirmation/component', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({

    // SWC 15.02.21  If everything from the menu is marked as 'COMPLETE', we are good to submit
    incomplete: computed('errors', function () {
      var errors = get(this, 'errors');
      return errors.forms.some(function (form) {
        return form.status !== _constants.FORM_STATES.COMPLETE;
      });
    }),

    /**
     * Count of all the forms that are Not Started
     */
    // 12.11.2018 Added skipValidation to form template extendedAttributes. Leaving the `application` and
    //            `consent` form checks here for compatibility with existing forms.
    notStartedCount: computed('errors.forms', function () {
      var forms = get(this, 'errors.forms');
      if (!forms) return 0;
      var filteredForms = forms.filter(function (form) {
        return ['application', 'consent'].indexOf(form.templateKey) === -1 && !form.skipValidation && form.status === _constants.FORM_STATES.NOT_STARTED;
      });
      return filteredForms.length;
    })
  });
});