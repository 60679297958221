define('client/test-fixtures/applicant-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'formTemplate': {
      // Ignore for now
      id: 177
    },
    'formElements': [{
      'type': 'page-heading',
      'markup': 'Application for resource consent'
    }, {
      'type': 'section-heading',
      'markup': 'Applicant details'
    }, {
      'name': 'agentOrApplicant',
      'label': 'Are you an:',
      'type': 'radio',
      'options': [{ 'value': '1', 'label': 'Applicant applying for Resource Consent' }, { 'value': '2', 'label': 'Agent applying on behalf of an applicant for Resource Consent' }],
      'repeatable': false
    }, {
      'name': 'applications',
      'label': 'Applicant Details',
      'type': 'section',
      'repeatable': true,
      'numAutoRepeats': 1,
      'minRepeats': 1,
      'maxRepeats': 4,
      formElements: [{
        'name': 'applicantTypeApplicant',
        'label': 'Type',
        'type': 'toggle-buttons',
        'default': { val: '1' },
        'options': [{ 'value': '1', 'label': 'Individual' }, { 'value': '2', 'label': 'Company' }, { 'value': '3', 'label': 'Trust' }]
      }, {
        'name': 'individual',
        'label': 'Person\'s Details',
        'type': 'section',
        'repeatable': true,
        'numAutoRepeats': 1,
        'displayConditions': { and: [{ 'name': 'applicantTypeApplicant', 'value': '1' }] },
        'formElements': [{
          'name': 'firstName',
          'label': 'First name(s)',
          'help': 'Some help text',
          'type': 'text',
          'default': {
            func: 'getFirstName',
            execute: 'first-only'
          }
        }, {
          'name': 'lastName',
          'label': 'Last Name',
          'type': 'text',
          'default': {
            func: 'getLastName',
            execute: 'first-only'
          }
        }]
      }, {
        'name': 'company',
        'type': 'section',
        'displayConditions': { and: [{ 'name': 'applicantTypeApplicant', 'value': '2' }] },
        'formElements': [{
          'name': 'companyName',
          'label': 'Company Name',
          'help': 'Some help text',
          'type': 'text'
        }, {
          'name': 'companyNumber',
          'label': 'Registered Company Number',
          'type': 'text'
        }]
      }, {
        'name': 'trust',
        'type': 'section',
        'displayConditions': { and: [{ 'name': 'applicantTypeApplicant', 'value': '3' }] },
        'formElements': [{
          'name': 'trustName',
          'label': 'Trust Name',
          'help': 'Some help text',
          'type': 'text'
        }, {
          'name': 'trustNumber',
          'label': 'Registered Trust Number',
          'type': 'text'
        }]
      }]
    }, {
      'name': 'agentWrapper',
      'type': 'section',
      'displayConditions': { and: [{ 'name': 'agentOrApplicant', 'value': '2' }] },
      'formElements': [{
        'name': 'agents',
        'type': 'section',
        'repeatable': true,
        'numAutoRepeats': 1,
        'formElements': [{
          'name': 'applicantTypeAgent',
          'label': 'Type',
          'type': 'toggle-buttons',
          'default': { val: '1' },
          'options': [{ 'value': '1', 'label': 'Individual' }, { 'value': '2', 'label': 'Company' }]
        }, {
          'name': 'individual',
          'type': 'section',
          'displayConditions': { and: [{ 'name': 'applicantTypeAgent', 'value': '1' }] },
          'formElements': [{
            'name': 'firstName',
            'label': 'First name(s)',
            'help': 'Some help text',
            'type': 'text'
          }, {
            'name': 'lastName',
            'label': 'Last Name',
            'type': 'text'
          }]
        }, {
          'name': 'company',
          'type': 'section',
          'displayConditions': { and: [{ 'name': 'applicantTypeAgent', 'value': '2' }] },
          'formElements': [{
            'name': 'companyName',
            'label': 'Company Name',
            'help': 'Some help text',
            'type': 'text'
          }, {
            'name': 'companyNumber',
            'label': 'Registered Company Number',
            'type': 'text'
          }]
        }]
      }]
    }],

    'state': {}
  };
});