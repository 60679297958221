define('client/components/dynamic-form/edit/select/component', ['exports', 'client/mixins/components/dynamic-formElement', 'ember-data', 'client/constants'], function (exports, _dynamicFormElement, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      set = Ember.set,
      inject = Ember.inject,
      observer = Ember.observer;


  // Add opt to optionArray, with de-duplication based on the value (usually an id number)
  // Suffix is optional, for displaying " (inactive)" on deleted options
  function addOption() {
    var optionArray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var opt = arguments[1];
    var suffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

    if (opt === null || opt === undefined || opt.value === null || opt.value === undefined) {
      return optionArray;
    } else if (optionArray.some(function (o) {
      return o.value === opt.value;
    })) {
      // option already exists in the array
      return optionArray;
    } else {
      // the option doesn't exist in the array
      // therefore it has been deleted
      // add it to the array with an optional suffix
      var returnArray = optionArray.copy();
      returnArray.push({
        value: opt.value,
        label: opt.label + suffix
      });
      return returnArray;
    }
  }

  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    dynamicFormsMethods: inject.service(),
    dependentKeyValue: null,

    init: function init() {
      this._super.apply(this, arguments);

      // Set the initial value for the dependent key
      var filterKey = get(this, 'formElement.filterTriggerField');
      if (!filterKey) return;
      var currentDependentKeyValue = get(this, 'state.' + filterKey + '.0.val');
      set(this, 'dependentKeyValue', currentDependentKeyValue);
    },


    initialSelectedOption: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.initialSelectedOption === null) {
        var initialSelectedOption = getWithDefault(this, 'state.' + this.formElement.name + '.0.selectedOption', null);
        if (initialSelectedOption !== null && get(this, 'formElement.filterTriggerField')) {
          // save the value of the form element we're dynamically filtering by so it can be reverted later if requested
          initialSelectedOption.filterKey = get(this, 'formElement.filterTriggerField');
          initialSelectedOption.filterState = get(this, 'state.' + initialSelectedOption.filterKey);
        }
        set(this, 'initialSelectedOption', initialSelectedOption);
      }
    },


    options: computed('formElement.options', 'formElement.selectedOption', 'formElement.filterTriggerField', 'formInstanceId', 'state', function () {
      var _this = this;

      // This will trigger on any state change; that's unavoidable as we can't target listening to the bit of state
      // we need
      // We trust our cache to return the options very quickly when this computed property is called needlessly
      var filterKey = get(this, 'formElement.filterTriggerField');
      if (!filterKey) {
        return addOption(this.formElement.options, this.deletedOption, this.suffix);
      }

      var filterVal = get(this, 'state.' + filterKey + '.0.val');
      var formInstanceId = get(this, 'formInstanceId');
      var promise = void 0;

      if (filterKey && filterVal != null) {
        promise = get(this, 'dynamicFormsMethods').fetchFilteredList(formInstanceId, get(this, 'formElement.name'), filterVal).then(function (optionArray) {
          return addOption(optionArray, _this.deletedOption, _this.suffix);
        });
      } else {
        promise = Ember.RSVP.resolve([]);
      }

      return _emberData.default.PromiseObject.create({ promise: promise });
    }),

    deletedOption: computed('state', function () {
      if (this.initialSelectedOption === null) {
        return null;
      } else if (this.initialSelectedOption.filterKey === undefined) {
        return this.initialSelectedOption;
      } else {
        // only return the selectedOption if the value of the form element we're dynamically filtering by hasn't changed
        if (this.state[this.initialSelectedOption.filterKey][0].val === this.initialSelectedOption.filterState[0].val) {
          return this.initialSelectedOption;
        } else {
          return null;
        }
      }
      // return getWithDefault(this, `state.${this.formElement.name}.0.selectedOption`, null)
    }),

    showRevertAction: computed('initialSelectedOption.value', 'stateValue', function () {
      // If the underlying value has been marked as deleted, and the user is changing it to something else, display a warning message with an undo button.
      return getWithDefault(this, 'initialSelectedOption.isDeleted', false) === true && getWithDefault(this, 'initialSelectedOption.value', NaN) !== getWithDefault(this, 'stateValue', NaN);
    }),

    // Suffix for deleted options
    suffix: computed('formElement.extendedAttributes', function () {
      return getWithDefault(this, 'formElement.extendedAttributes.deletedSuffix', _constants.DELETED_ITEM_DEFAULT_SUFFIX);
    }),
    // Note: Acknowledgement that observers are not considered best practice, but since we're actively seeking side-effects
    // on the state object, this is currently considered the best way
    updateState: observer('formElement.filterTriggerField', 'state', function () {
      var _this2 = this;

      // This function monitors the state of the field on which the options are filtered upon.  If that value changes, we
      // need to clear whatever value has been stored in the state for this element
      // e.g.
      //  - Set consent_type to 'Subdivision', and subcategory_type to 'Allotment Creation'
      //  - Change the consent_type to 'Coastal'
      //  - We want the subcategory_type state value to clear
      var filterKey = get(this, 'formElement.filterTriggerField');
      if (!filterKey) return;
      var currentDependentKeyValue = get(this, 'state.' + filterKey + '.0.val');
      var dependentKeyValue = get(this, 'dependentKeyValue');

      if (dependentKeyValue && currentDependentKeyValue && currentDependentKeyValue !== dependentKeyValue) {
        // We have to stick this change after the full render of this page, otherwise we're changing the state of the
        // page as it`s drawing, and Ember quite rightly throws up it's hands and asks us to think carefully about our
        // actions.  Therefore, we tell it modify the value after the render.
        // This results in an immediate re-render of the page, but it's quick, silent and painless
        Ember.run.scheduleOnce('afterRender', this, function () {
          set(_this2, 'dependentKeyValue', currentDependentKeyValue);
          _this2.clearValue();
        });
      }

      set(this, 'dependentKeyValue', currentDependentKeyValue);
    }),

    displayValue: computed('stateValue', 'options', function () {
      var stateValue = get(this, 'stateValue');
      if (!stateValue) return _emberData.default.PromiseObject.create({ promise: Ember.RSVP.resolve('-') });

      var optionsOrPromise = get(this, 'options');
      var promise = void 0;
      if (optionsOrPromise.then) {
        promise = optionsOrPromise.then(function (options) {
          var option = options.find(function (option) {
            return get(option, 'value') === stateValue;
          });
          return option && option.label || '-';
        });
      } else {
        var option = optionsOrPromise.find(function (option) {
          return get(option, 'value') === stateValue;
        });
        promise = Ember.RSVP.resolve(option && option.label || '-');
      }

      return _emberData.default.PromiseObject.create({ promise: promise });
    }),

    actions: {
      revertToInitialOption: function revertToInitialOption() {
        var _this3 = this;

        // Is this form element dynamically filtered?
        if (this.initialSelectedOption.filterKey !== undefined) {
          // Revert the value of the form element we're filtering by
          this.updateStateKey(this.initialSelectedOption.filterKey, this.initialSelectedOption.filterState, this.index);

          // Revert the value of this form element
          // We need to schedule this twice so that it sets the value after the one in the 'updateState' observer, which will clear the value after the update above.
          Ember.run.scheduleOnce('afterRender', this, function () {
            Ember.run.scheduleOnce('afterRender', _this3, function () {
              _this3.send('setValue', _this3.initialSelectedOption.value);
            });
          });
        } else {
          // Revert the value of this form element
          this.send('setValue', this.initialSelectedOption.value);
        }
      }
    },

    // Hack to prevent bug in Firefox where the "Please select..." option gets erroniously selected
    didRender: function didRender() {
      if (this.stateValue !== null) {
        var elem = document.getElementById('select-null-option-' + this.elementId);
        if (elem !== null) {
          elem.selected = false;
        }
      }
    }
  });
});