define('client/libs/workflows', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.workflowTypes = undefined;
  var get = Ember.get,
      getWithDefault = Ember.getWithDefault;


  /**
   * Define different workflow types. This is used by the generic workflows route
   * to handle the different types.
   *
   * Currently supports:
   *  - form             Typical dynamic form workflow.
   *  - applicationForm  Extends 'form' but adds some extras specific to a Resource Consents Application.
   *  - component        A non-dynamic form workflow e.g. Documents.
   *
   * Workflows need to implement the following interface:
   *  - getFormInstance  Returns a promise that resolves to an object with a form instance ID e.g. {formInstanceId: 12345}.
   *  - getMenu          Return the menu object.
   *  - getModel         Returns the model for the workflow.
   *  - afterModel       Optional: After model hook for this workflow.
   *
   * NOTE: These are called using 'call' so can reference 'this' as if it's the route.
   *       e.g. workflowType.getFormInstance.call(this, {wf, parentId})
   */

  // Recursively tests if a form instance ID is within workflow forms.
  var isFormInstanceIdInWorkflow = function isFormInstanceIdInWorkflow(form, id) {
    if (form.formInstanceId === parseInt(id, 10)) {
      return true;
    }
    if (Array.isArray(form.forms)) {
      return form.forms.some(function (childForm) {
        return isFormInstanceIdInWorkflow(childForm, id);
      });
    }
    return false;
  };

  // Different types of supported workflows.
  var workflowTypes = {};

  // Dynamic form workflow.
  workflowTypes['form'] = {
    getFormInstance: function getFormInstance(_ref) {
      var wf = _ref.wf,
          parentId = _ref.parentId;

      var dynamicFormsMethods = get(this, 'dynamicFormsMethods');
      return dynamicFormsMethods.retrieveFormInstance({ parentId: parentId, formKey: wf.key });
    },
    getMenu: function getMenu(_ref2) {
      var parentFormInstance = _ref2.parentFormInstance;

      var dynamicFormsMethods = get(this, 'dynamicFormsMethods');
      var parentFormInstanceId = parentFormInstance.formInstanceId;

      if (parentFormInstanceId) {
        return dynamicFormsMethods.fetchMenu(parentFormInstanceId).then(function (result) {
          return result.menu;
        });
      }

      // Return an empty menu if the form instance ID isn't set.
      return Ember.RSVP.resolve({ menu: [] });
    },
    getModel: function getModel(_ref3) {
      var _this = this;

      var activeWorkflow = _ref3.activeWorkflow,
          parentId = _ref3.parentId,
          path = _ref3.path,
          transition = _ref3.transition,
          pageInfo = _ref3.pageInfo;

      var dynamicFormsMethods = get(this, 'dynamicFormsMethods');

      var topLevelFormInstanceId = activeWorkflow.menu.formInstanceId;

      // If the last part of the path is a number, it's the form instance ID.
      var formInstanceId = null;
      var fullWorkflow = false;
      var parts = path.split('/');
      var lastSegment = parts[parts.length - 1];
      if (/^\d+$/.test(lastSegment)) {
        formInstanceId = parts.pop();
      } else if (lastSegment === _constants.FULL_WORKFLOW_TEXT) {
        formInstanceId = topLevelFormInstanceId;
        fullWorkflow = true;
        parts.pop();
      }
      var tidiedPath = parts.join('/');
      if (!tidiedPath) {
        tidiedPath = activeWorkflow.config.path;
      }

      // Validate that the form instance ID is present in the menu, if not transition.
      if (formInstanceId && !isFormInstanceIdInWorkflow(activeWorkflow.menu, formInstanceId)) {
        console.log('Form instance ID "' + formInstanceId + '" not found in workflow', activeWorkflow.menu.forms);

        // The line below has been commented out, because - in the case of tables with hideStateFromClient
        // set in config), the menu will have child form instances suppressed (i.e. ommitted from the state
        // by the server), so this test would incorrectly fail.

        // return this.transitionTo(get(transition, 'targetName'), parentId, MODE.VIEW, tidiedPath)
      }

      // Only support fullWorkflow in print view
      if (fullWorkflow && get(this, 'mode') !== _constants.MODE.PRINT_VIEW) {
        console.log('Full workflow only supported in print view, redirecting to default form');
        return this.transitionTo(get(transition, 'targetName'), parentId, _constants.MODE.VIEW, tidiedPath);
      }

      // If no form instance ID has been extracted from the path, use the first
      // form instance ID from the menu.
      var id = null;
      if (formInstanceId) {
        id = formInstanceId;
      } else if (activeWorkflow.menu.forms) {
        id = activeWorkflow.menu.forms[0].formInstanceId;
      }

      var formInstance = null;

      return Ember.RSVP.resolve().then(function () {
        if (id) {
          return dynamicFormsMethods.fetchInstance(id, fullWorkflow).then(function (x) {
            formInstance = x;
          });
        }
      }).then(function () {
        // Determine the template to use for rendering this form
        var status = activeWorkflow.parentFormInstance.status;
        var templates = {
          'ERROR': 'workflow/error',
          'NOT_STARTED': 'workflow/start',
          'CREATED': 'workflow/form',
          'IN_PROGRESS': 'workflow/form'
        };
        var templateName = templates[status] || templates['ERROR'];

        /**
         * Returns a function that performs a depth-first search of an array of formElements which may contain arrays of formElements.
         * Useful for returning the first element on the page that passes a given test.
         * @param test A function that takes a form element and returns true if it matches, eg. (element) => (element.type === 'page-heading')
         * @returns A function that takes an array of form elements and returns the first one in the tree that matches the test.
         */
        var getFirstFormElementMatching = function getFirstFormElementMatching(test) {
          var getFirstFormElementMatchingTest = function getFirstFormElementMatchingTest(formElements) {
            for (var i = 0; i < formElements.length; i++) {
              var element = formElements[i];
              if (element === undefined) {
                continue;
              }
              var isMatch = test(element);
              if (isMatch === true) {
                return element;
              }
              if (Array.isArray(element.formElements)) {
                var childMatch = getFirstFormElementMatchingTest(element.formElements);
                if (childMatch !== undefined) {
                  return childMatch;
                }
              }
            }
          };
          return getFirstFormElementMatchingTest;
        };

        // Get the page heading used in print view
        var pageHeading = void 0;
        if (get(_this, 'mode') === _constants.MODE.PRINT_VIEW) {
          pageHeading = activeWorkflow.config.printViewPageHeading;

          if (pageHeading && pageInfo && pageInfo.consentNumber && activeWorkflow.config.includeApplicationNumber) {
            pageHeading = pageHeading + ' - ' + pageInfo.consentNumber;
          }

          if (pageHeading === undefined) {
            // The page heading wasn't specified in the workflow config so use the first page heading element in the form
            // or if that doesn't exist use the title of the workflow.
            var getFirstPageHeadingElement = getFirstFormElementMatching(function (element) {
              return element.type === 'page-heading';
            });
            var firstPageHeadingElement = formInstance !== null ? getFirstPageHeadingElement(formInstance.formElements) : undefined;

            if (firstPageHeadingElement !== undefined) {
              firstPageHeadingElement.suppressInView = true;
            }
            pageHeading = firstPageHeadingElement !== undefined ? firstPageHeadingElement.markup : activeWorkflow.title;
          }
        }

        return {
          pageHeading: pageHeading,
          parentFormInstanceId: activeWorkflow.parentFormInstanceId,
          id: id,
          topLevelFormInstanceId: topLevelFormInstanceId,
          form: formInstance,
          menu: activeWorkflow.menu,
          formRootPath: tidiedPath,
          templateName: templateName
        };
      });
    },
    afterModel: function afterModel(model, transition) {
      // readOnly forms can't be edited so redirect them to view mode
      var isReadOnly = getWithDefault(model, 'form.formInstance.readOnly', false) === true;
      if (model.mode === _constants.MODE.EDIT && isReadOnly) {
        this.transitionTo(model.formRootRoute, _constants.MODE.VIEW, model.path);
      }
    }
  };

  // An extension of the normal form workflow type specific to a resource consent
  // application. Adds some extra data, primarily to drive the actions menu.
  workflowTypes['applicationForm'] = Object.assign({}, workflowTypes.form, {
    getModel: function getModel(_ref4) {
      var _workflowTypes$form$g,
          _this2 = this;

      var parentId = _ref4.parentId;

      return (_workflowTypes$form$g = workflowTypes['form'].getModel).call.apply(_workflowTypes$form$g, [this].concat(Array.prototype.slice.call(arguments))).then(function (model) {
        return Ember.RSVP.hash(Object.assign(model, {
          templateName: 'workflow/application-form',
          versionList: model.topLevelFormInstanceId ? get(_this2, 'resourceConsentLookups').getApplicationVersionsList(model.topLevelFormInstanceId) : [],
          isSnapshot: false,
          isResubmission: get(_this2, 'resourceConsentRemoteMethods').isResubmission(model.topLevelFormInstanceId),
          isSubmitted: get(_this2, 'resourceConsentRemoteMethods').isSubmitted(model.topLevelFormInstanceId),
          amendApplicationConditions: get(_this2, 'resourceConsentRemoteMethods').getAmendApplicationConditions(model.topLevelFormInstanceId)
        }));
      });
    }
  });

  // A non-dynamic form workflow.
  workflowTypes['component'] = {
    getFormInstance: function getFormInstance() {
      return Ember.RSVP.resolve({ formInstanceId: null });
    },
    getMenu: function getMenu(_ref5) {
      var wf = _ref5.wf,
          path = _ref5.path,
          active = _ref5.active;

      return Ember.RSVP.resolve(wf.getMenu.call(this, { path: path, active: active }));
    },
    getModel: function getModel(_ref6) {
      var activeWorkflow = _ref6.activeWorkflow,
          parentId = _ref6.parentId,
          path = _ref6.path;

      return Ember.RSVP.hash({
        menu: activeWorkflow.menu,
        component: activeWorkflow.config.component,
        parentId: parentId,
        id: -1, // required to get the menu to display
        formRootPath: path,
        templateName: 'workflow/component'
      });
    }
  };

  exports.workflowTypes = workflowTypes;
});