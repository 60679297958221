define('client/libs/map-lib', ['exports', 'client/config/environment', 'client/libs/map-lib-mdc', 'client/libs/map-lib-linz', 'client/libs/map-lib-esri', 'client/libs/map-lib-osm', 'client/libs/map-lib-mapbox'], function (exports, _environment, _mapLibMdc, _mapLibLinz, _mapLibEsri, _mapLibOsm, _mapLibMapbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BASE_MAP_PROVIDER = _environment.default.ENV_VARS.BASE_MAP_PROVIDER;

  var baseMaps = {
    'MDC': _mapLibMdc.default,
    'LINZ': _mapLibLinz.default,
    'ESRI': _mapLibEsri.default,
    'OSM': _mapLibOsm.default,
    'MapBox': _mapLibMapbox.default
  };

  exports.default = baseMaps[BASE_MAP_PROVIDER];
});