define('client/components/page-header/login-or-register/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['c-page-header pad-bottom'],
    session: inject.service(),
    councilInfo: computed.alias('session.councilInfo'),

    actions: {
      login: function login() {
        set(this, 'session.returnTo', get(this, 'returnTo'));
        get(this, 'session').login();
      }
    }
  });
});