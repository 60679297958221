define('client/routes/style-guide', ['exports', 'client/test-fixtures/style-guide'], function (exports, _styleGuide) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'style-guide',
    model: function model() {
      return {
        crumbs: [{ name: 'Style Guide' }],
        tabs: [{
          path: 'style-guide.index',
          title: 'Info'
        }, {
          path: 'style-guide.page-layout',
          title: 'Page Layout'
        }, {
          path: 'style-guide.actions',
          title: 'Actions'
        }, {
          path: 'style-guide.user-feedback',
          title: 'User Feedback'
        }, {
          path: 'style-guide.forms',
          title: 'Forms'
        }, {
          path: 'style-guide.icons-and-images',
          title: 'Icons'
        }, {
          path: 'style-guide.navigation',
          title: 'Navigation'
        }, {
          path: 'style-guide.structure',
          title: 'Structural Components'
        }, {
          path: 'style-guide.tables-and-lists',
          title: 'Tables and Lists'
        }, {
          path: 'style-guide.typography',
          title: 'Typography'
        }],
        exampleTextInput: _styleGuide.default.textInput,
        exampleNumberInput: _styleGuide.default.numberInput,
        exampleSelect: _styleGuide.default.selectInput,
        exampleCheckbox: _styleGuide.default.checkboxInput,
        exampleCheckboxGroup: _styleGuide.default.checkboxGroup,
        exampleRadioGroup: _styleGuide.default.radioGroup,
        exampleLocationInput: _styleGuide.default.locationInput
      };
    }
  });
});