define('client/libs/dynamic-forms-methods-lib', ['exports', 'npm:lodash/findIndex', 'npm:lodash/find', 'client/constants'], function (exports, _findIndex, _find, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.treeSearch = exports.getChildInstanceId = exports.isNonNavigable = exports.findNextFormPeerFirstThenParent = exports.tidyData = exports.findNextFormEdit = exports.findNextFormView = exports.findFormViaInstance = undefined;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Logger = Ember.Logger,
      get = Ember.get;


  function treeSearch(obj, key) {
    for (var iKey in obj) {
      if (obj.hasOwnProperty(iKey)) {
        if (iKey === key) {
          return obj[iKey];
        } else if (_typeof(obj[iKey]) === 'object' && obj[iKey] !== null) {
          var search = treeSearch(obj[iKey], key);
          if (search !== undefined) {
            return search;
          }
        }
      }
    }
  }

  /**
   * Used by View mode
   * Gets the next form for the provided instance, moving down to a child form as a preference
   * Rules are:
   *  - 'Next' by default should take you to the first child
   *    - If first child is non-navigable, then repeat these rules for that child
   *  - If no children, take you to the next peer
   *    - If peer is non-navigable, repeat these rules for that peer
   *  - If currently last peer, take you to the parent's peer
   *    - If parent's peer is non-navigable, repeat these rules for parent's peer
   *  - Return LAST if all no further navigation is possible
   * @param {string|number} instanceId
   * @param {Object[]} forms
   * @param {Object[]} originalForms
   * @returns {string|null}
   */

  function findNextFormChildFirst(instanceId, forms, originalForms) {
    // Get the current form instance
    var formIndex = (0, _findIndex.default)(forms, function (form) {
      return String(form.formInstanceId) === String(instanceId);
    });

    // Check to see if the form was found on this level of the hierarchy.
    if (formIndex > -1) {
      // it was
      // First attempt to navigate to any children
      if (forms[formIndex].forms) {
        // If the first child is non-navigable, then search for the next form for the child
        // else return the formInstanceId for the first child
        return forms[formIndex].forms[0].nonNavigable ? findNextFormChildFirst(forms[formIndex].forms[0].formInstanceId, forms[formIndex].forms, originalForms) : forms[formIndex].forms[0].formInstanceId;
      } else {
        // No children, so try to navigate to a peer
        return findNextFormPeerFirstThenChild(instanceId, originalForms, originalForms);
      }
    } else {
      // instance Id was not found on this level of the hierarchy, so look for any element on this level of the hierarchy
      // that has children and recurse down that tree to find it.
      var matchedInstances = forms.reduce(function (acc, form) {
        if (!form.forms) return acc;
        var nextInstanceId = findNextFormChildFirst(instanceId, form.forms, originalForms);
        if (nextInstanceId) acc.push({ parentForm: form, nextInstanceId: nextInstanceId });
        return acc;
      }, []);

      // The form wasn't matched in this section of the tree.  That's okay.
      if (!matchedInstances.length) return null;

      if (matchedInstances.length > 1) Logger.error('Found multiple matched instances for id ' + instanceId);
      return matchedInstances[0].nextInstanceId;
    }
  }

  /**
   * Internal function, only used by `findNextFormChildFirst`
   * In cases where a form has no children, then we need to return the next peer, unless:
   *  - The next peer is non-navigable, in which case pass that into `findNextFormChildFirst` to look for a valid next
   *    form
   *  - The peer is the last form in it's peer group, then return it's parent
   * @param {string|number} instanceId
   * @param {Object[]} forms
   * @param {Object[]} originalForms
   * @returns {string|null}
   */
  function findNextFormPeerFirstThenChild(instanceId, forms, originalForms) {
    var formIndex = (0, _findIndex.default)(forms, function (form) {
      return String(form.formInstanceId) === String(instanceId);
    });

    if (formIndex > -1) {
      if (formIndex === forms.length - 1) return _constants.LAST;
      if (!forms[formIndex + 1].nonNavigable) return forms[formIndex + 1].formInstanceId;

      // The next form is non-navigable, so look for it's next View form
      return findNextFormChildFirst(forms[formIndex + 1].formInstanceId, forms, originalForms);
    } else {
      //  Form index was not found on this level of the hierarchy, so need to dig deeper
      var matchedInstances = forms.reduce(function (acc, form) {
        if (!form.forms) return acc;
        var nextInstanceId = findNextFormPeerFirstThenChild(instanceId, form.forms, originalForms);
        if (nextInstanceId) acc.push({ parentForm: form, nextInstanceId: nextInstanceId });
        return acc;
      }, []);

      if (matchedInstances.length > 1) Logger.error('Found multiple matched instances for id ' + instanceId);
      if (!matchedInstances.length) return null;
      if (matchedInstances[0].nextInstanceId === _constants.LAST) {
        // The child was the last of it's peer group, so it's parent's peer is next
        return findNextFormPeerFirstThenChild(matchedInstances[0].parentForm.formInstanceId, originalForms, originalForms);
      }
      return matchedInstances[0].nextInstanceId;
    }
  }

  /**
   * Used by Edit mode
   * Gets the next form for the provided instance, moving to a peer as a preference
   * Rules are:
   *  - 'Next' by default should take you to the next peer
   *    - If peer is non-navigable, repeat these rules for that peer
   *  - If no next peer, navigate to the parent
   *    - If parent is non-navigable, repeat these rules for that parent
   *  - Return LAST if no further navigation is possible
   * @param {string|number} instanceId
   * @param {Object[]} forms
   * @param {Object[]} originalForms
   * @returns {string|null}
   */
  function findNextFormPeerFirstThenParent(instanceId, forms, originalForms) {
    var formIndex = (0, _findIndex.default)(forms, function (form) {
      return String(form.formInstanceId) === String(instanceId);
    });

    // Form is on this level of the hierarchy
    if (formIndex > -1) {
      if (formIndex === forms.length - 1) return _constants.LAST;
      if (forms[formIndex + 1].nonNavigable) {
        // The next form is non-navigable, so run these rules again from the context of that next form
        return findNextFormPeerFirstThenParent(forms[formIndex + 1].formInstanceId, forms, originalForms);
      }
      return forms[formIndex + 1].formInstanceId;
    } else {
      //  Form index was not found on this level of the hierarchy, so need to dig deeper
      var matchedInstances = forms.reduce(function (acc, form) {
        if (!form.forms) return acc;
        var nextInstanceId = findNextFormPeerFirstThenParent(instanceId, form.forms, originalForms);
        if (nextInstanceId) acc.push({ parentForm: form, nextInstanceId: nextInstanceId });
        return acc;
      }, []);

      if (matchedInstances.length > 1) Logger.error('Found multiple matched instances for id ' + instanceId);
      if (!matchedInstances.length) return null;
      if (matchedInstances[0].nextInstanceId === _constants.LAST) {
        // The next instance is the last in it's peer group, so need to return the first navigable parent
        return findValidParent(matchedInstances[0].parentForm.formInstanceId, originalForms, originalForms);
      }
      return matchedInstances[0].nextInstanceId;
    }
  }

  /**
   * Internal function, only used by `findNextFormPeerFirstThenParent`
   * Returns the supplied form if valid, or the next valid parent if not
   * Rules are:
   *  - If identified form is navigable, return that
   *  - If not-navigable, then return the next valid form based off rules in `findNextFormPeerFirstThenParent` for that
   *    identified form
   * @param {string|number} parentInstanceId
   * @param {Object[]} forms
   * @param {Object[]} originalForms
   * @returns {string|null}
   */
  function findValidParent(parentInstanceId, forms, originalForms) {
    var formIndex = (0, _findIndex.default)(forms, function (form) {
      return String(form.formInstanceId) === String(parentInstanceId);
    });
    if (formIndex > -1) {
      // Parent is on the top level
      if (forms[formIndex].nonNavigable) {
        // Top level form is non-navigable, so find the next valid peer
        return findNextFormPeerFirstThenParent(parentInstanceId, forms, forms);
      }
      return forms[formIndex].formInstanceId;
    }

    // Parent not on the top level, so look into all child forms for this level
    var matchedInstances = forms.reduce(function (acc, form) {
      if (!form.forms) return acc;
      var nextInstanceId = findValidParent(parentInstanceId, form.forms, originalForms);
      if (nextInstanceId) acc.push({ parentForm: form, nextInstanceId: nextInstanceId });
      return acc;
    }, []);

    if (matchedInstances.length > 1) Logger.error('Found multiple matched instances for id ' + parentInstanceId);
    if (!matchedInstances.length) return null;
    if (matchedInstances[0].nonNavigable) {
      return findValidParent(matchedInstances[0].nextInstanceId, originalForms, originalForms);
    }
    return matchedInstances[0].nextInstanceId;
  }

  /**
   * Recurses through the menu.forms json to find a specific form based on the instanceId
   * @param {Array} forms - list of forms
   * @param {number} instanceId - form instance that's being searched for
   * @returns {object|null}
   */
  function findFormViaInstance(forms, instanceId) {
    // if forms array is null then return immediately
    if (!forms) return false;

    var foundForm = (0, _find.default)(forms, function (form) {
      return String(form.formInstanceId) === String(instanceId);
    });
    if (foundForm) return foundForm;

    var matchedForms = forms.reduce(function (acc, form) {
      if (!form.forms) return acc;
      var foundForm = findFormViaInstance(form.forms, instanceId);
      if (foundForm) acc.push(foundForm);
      return acc;
    }, []);

    if (matchedForms.length > 1) Ember.Logger.error('Found multiple matched forms for id ' + instanceId);
    if (!matchedForms.length) return null;
    return matchedForms[0];
  }

  /**
   * Entry point for code to find the next form in the menu for Edit mode
   * @param {string|number} instanceId
   * @param {Object[]} forms
   * @returns {string}
   */
  function findNextFormEdit(instanceId, forms) {
    return findNextFormPeerFirstThenParent(instanceId, forms, forms);
  }

  /**
   * Entry point for code to find the next form in the menu for View mode
   * @param {string|number} instanceId
   * @param {Object[]} forms
   * @returns {string}
   */
  function findNextFormView(instanceId, forms) {
    return findNextFormChildFirst(instanceId, forms, forms);
  }

  /**
   * Removes all {id: "new"}, {id: "new", val: null}, {id: "new", hidden: true} elements from the state to save on pointless bandwidth and to
   * make the dbs life a little easier
   * @param data
   */
  function tidyData(state) {
    var keys = Object.keys(state);
    return keys.reduce(function (newState, key) {
      var reducedKeyState = state[key].reduce(function (keyState, stateItem) {
        if (stateItem.id === 'new' && (stateItem.hidden || stateItem.deleted)) {
          // no-op, we don't add to the keyState here
        } else {
          if (stateItem.val == null) {
            keyState.push(Object.assign({}, stateItem, { val: null }));
            return keyState;
          }

          // if this is state for a complex element type, then recurse the next level down
          var newKeyStateVal = _typeof(stateItem.val) === 'object' ? tidyData(stateItem.val) : stateItem.val;
          if ((typeof newKeyStateVal === 'undefined' ? 'undefined' : _typeof(newKeyStateVal)) !== 'object' || Object.keys(newKeyStateVal).length) {
            keyState.push(Object.assign({}, stateItem, { val: newKeyStateVal }));
          }
          return keyState;
        }
        return keyState;
      }, []);
      if (reducedKeyState.length) newState[key] = reducedKeyState;

      return newState;
    }, {});
  }

  /**
   * Tests if this form model is non-navigable.
   * @param model
   * @returns {bool}
   */
  function isNonNavigable(model) {
    return get(model, 'form.formTemplate.extendedAttributes.nonNavigable');
  }

  /**
   * Given a set of formElements and their associated state, return the first value in the tree (depth-first ordering.)
   * @param elements Array of form elements
   * @param state The state associated with those form elements
   * @returns {string}
   */
  function getFirstValue(elements, state) {
    try {
      var firstElement = elements[0];
      var firstState = state[firstElement.name][0].val;
      return getFirstValue(firstElement.formElements, firstState);
    } catch (e) {
      // if one of the above lookups doesn't resolve, we must be at the bottom of the tree
      return state;
    }
  }

  /**
   * Gets the first child instance ID from this form model.
   * @param model
   * @returns {string}
   */
  function getChildInstanceId(model) {
    return getFirstValue(model.form.formElements, model.form.state);
  }

  exports.findFormViaInstance = findFormViaInstance;
  exports.findNextFormView = findNextFormView;
  exports.findNextFormEdit = findNextFormEdit;
  exports.tidyData = tidyData;
  exports.findNextFormPeerFirstThenParent = findNextFormPeerFirstThenParent;
  exports.isNonNavigable = isNonNavigable;
  exports.getChildInstanceId = getChildInstanceId;
  exports.treeSearch = treeSearch;
});