define('client/components/maps/small-map/component', ['exports', 'client/mixins/components/common-map-mixin', 'client/libs/map-lib'], function (exports, _commonMapMixin, _mapLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get;
  exports.default = Ember.Component.extend(_commonMapMixin.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initMap();
    },


    /**
     * Initialise the map.
     */
    initMap: function initMap() {
      this.createMapAndBaseLayers('.js-map-container');
      var map = get(this, 'map');
      var markerClusterGroup = new L.markerClusterGroup(); // eslint-disable-line new-cap
      map.addLayer(markerClusterGroup);

      // offset - moves the popup above the marker icon
      // closeButton - hide the X in the top corner
      var popup = L.popup({
        offset: new L.Point(0, 0),
        closeButton: false
      });

      var markers = get(this, 'markers');
      var markersForMap = [];
      if (markers) {
        markers.forEach(function (marker) {
          if (marker.position) {
            var _marker$position$map = marker.position.map(function (x) {
              return Number(x);
            }),
                _marker$position$map2 = _slicedToArray(_marker$position$map, 2),
                easting = _marker$position$map2[0],
                northing = _marker$position$map2[1];

            if (_mapLib.default.validateNztm(easting, northing)) {
              var latlng = _mapLib.default.nztmToLatLng([easting, northing]);

              var markerForMap = void 0;
              if (marker.icon) {
                markerForMap = new L.Marker(latlng, { icon: marker.icon });
              } else {
                markerForMap = new L.Marker(latlng, { icon: L.icon({
                    iconUrl: '/assets/markers/marker-generic.svg',
                    iconSize: [35, 44],
                    iconAnchor: [18, 44]
                  }) });
              }
              if (marker.popupContent) {
                markerForMap.on('mouseover', function (e) {
                  popup.setContent(marker.popupContent);
                  popup.setLatLng(this.getLatLng());
                  map.openPopup(popup);
                });
                markerForMap.on('mouseout', function (e) {
                  map.closePopup(popup);
                });
              }

              markersForMap.push(markerForMap);
            }
          }
        });
        markerClusterGroup.addLayers(markersForMap);
        var bounds = markerClusterGroup.getBounds();
        if (bounds) {
          map.fitBounds(bounds);
        }
      }
    }
  });
});