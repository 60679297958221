define('client/routes/processing-section/hearing', ['exports', 'client/mixins/routes/generic-workflows', 'client/libs/documents-component'], function (exports, _genericWorkflows, _documentsComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    workflows: [{
      key: 'hearings_section',
      path: 'hearings',
      title: 'Hearings'
    }, {
      component: 'documents',
      type: 'component',
      path: 'documents',
      title: 'Documents',
      getMenu: _documentsComponent.default.getMenu
    }]
  });
});