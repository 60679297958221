define('client/services/dashboard', ['exports', 'client/services/remote-method', 'client/mixins/cache'], function (exports, _remoteMethod, _cache) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _remoteMethod.default.extend(_cache.default, {
    // Cache table requests for 1min.
    cacheLengthSec: 60,

    /**
      *
      * Gets the list of records returned by the publicly notified applications table
      *
      * @param {object} - options for passing to the server to process the search
      * @return {Array} - An array of application records (promise)
      *
      */

    getPubliclyNotifiedApplications: function getPubliclyNotifiedApplications(options) {
      var page = options.page || 1;
      var filters = options.filters || { status: [] };
      var sortDirection = options.sortDirection;
      var sortField = options.sortField;
      var pageSize = options.pageSize || 10;

      var activeStatusFilters = [];
      Object.keys(filters.status).forEach(function (key) {
        if (filters.status[key]) activeStatusFilters.push(key);
      });

      // Build the ordering param.
      var ordering = '';
      if (sortField) {
        // A '-' in front of the field will reverse the direction (descending).
        ordering = '' + (sortDirection === 'desc' ? '-' : '') + sortField;
      }

      var requestData = { page: page, ordering: ordering, pageSize: pageSize };

      var cache = get(this, 'cache');
      var cacheKey = 'getPubliclyNotifiedApplications_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = this.request('/dashboard/publiclyNotifiedApplications/', { data: requestData });
      cache.save(cacheKey, request);
      return request;
    },
    resetDashboardTable: function resetDashboardTable(tableType) {
      return this.post('/dashboard/reset-dashboard-table/' + tableType);
    },
    setDashboardTable: function setDashboardTable(state) {
      return this.post('/dashboard/set-dashboard-table', { data: state, contentType: 'application/json' });
    },
    getDashboardTable: function getDashboardTable(tableType) {
      return this.request('/dashboard/get-dashboard-table/' + tableType);
    },


    /**
      *
      * Gets the list of records returned by the basic dashboard page (not search)
      *
      * @param {object} - options for passing to the server to process the search
      * @return {Array} - An array of application records (promise)
      *
      */

    getApplications: function getApplications(options) {
      var page = options.page || 1;
      var filters = options.filters || { status: [] };
      var sortDirection = options.sortDirection;
      var sortField = options.sortField;
      var pageSize = options.pageSize || 10;

      var activeStatusFilters = [];
      Object.keys(filters.status).forEach(function (key) {
        if (filters.status[key]) activeStatusFilters.push(key);
      });

      // Build the ordering param.
      var ordering = '';
      if (sortField) {
        // A '-' in front of the field will reverse the direction (descending).
        ordering = '' + (sortDirection === 'desc' ? '-' : '') + sortField;
      }

      var requestData = { page: page, ordering: ordering, pageSize: pageSize };
      requestData.latest = 'LATEST';

      // This to serve the following filters:
      // 'assigned to me', 'unassigned' and 'not sent to granted consents'
      var processingApplicationFilters = ['ASSIGNED_TO_ME', 'UNASSIGNED', 'NOT_SENT_TO_CONSENTS_AND_PERMITS'];

      processingApplicationFilters.forEach(function (element) {
        if (activeStatusFilters.includes(element)) {
          requestData.processingApplicationFilter = element;
        }
      });

      // This to serve the 'Draft' and 'Submitted' filters
      if (!('processingApplicationFilter' in requestData)) {
        requestData.status = activeStatusFilters.join(',');
      }

      var cache = get(this, 'cache');
      var cacheKey = 'getApplications_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = '';

      if (requestData.processingApplicationFilter) {
        request = this.request('/dashboard/filter/', { data: requestData });
      } else {
        request = this.request('/dashboard/', { data: requestData });
      }

      cache.save(cacheKey, request);
      return request;
    },


    /**
      *
      * Gets the list of records returned by the basic dashboard page (not search)
      *
      * @param {object} - options for passing to the server to process the search
      * @return {Array} - An array of submission records (promise)
      *
      */
    getSubmissions: function getSubmissions(options) {
      var page = options.page || 1;
      var filters = options.filters || { status: [] };
      var sortDirection = options.sortDirection;
      var sortField = options.sortField;
      var pageSize = options.pageSize || 10;

      var activeStatusFilters = [];
      Object.keys(filters.status).forEach(function (key) {
        if (filters.status[key]) activeStatusFilters.push(key);
      });

      // Build the ordering param.
      var ordering = '';
      if (sortField) {
        // A '-' in front of the field will reverse the direction (descending).
        ordering = '' + (sortDirection === 'desc' ? '-' : '') + sortField;
      }

      var requestData = { page: page, ordering: ordering, pageSize: pageSize };
      requestData.latest = 'LATEST';

      var submissionFilters = ['DRAFT', 'COMPLETED'];
      submissionFilters.forEach(function (element) {
        if (activeStatusFilters.includes(element)) {
          requestData.submissionFilter = element;
        }
      });

      var cache = get(this, 'cache');
      var cacheKey = 'getSubmissions_' + this.cacheKeyFromRequestData(requestData);
      var cachedResult = cache.fetch(cacheKey);
      if (cachedResult) return cachedResult;

      var request = '';
      request = this.request('/dashboard/submissions/', { data: requestData });

      cache.save(cacheKey, request);
      return request;
    }
  });
});