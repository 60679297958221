define('client/components/dynamic-form/submit-application/component', ['exports', 'client/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      run = Ember.run;
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['h-hide-for-print'],
    showSummaryErrors: false,
    showSubmitConfirmation: false,

    // SWC 15.02.21  If everything from the menu is marked as 'COMPLETE', we are good to submit
    incomplete: computed('errors', function () {
      var errors = get(this, 'errors');
      return errors.forms.some(function (form) {
        return form.status !== _constants.FORM_STATES.COMPLETE;
      });
    }),

    actions: {
      confirmSubmission: function confirmSubmission() {
        set(this, 'showSubmitConfirmation', false);
        get(this, 'submit')();
      },

      // Jiggles modal link to show remaining items if user is unable to submit:
      jiggleItemsRemainingLink: function jiggleItemsRemainingLink() {
        var remainingItemsMsg = this.$().find('.jiggle-animation-target');
        if (remainingItemsMsg.length) {
          remainingItemsMsg.addClass('h-jiggle');
          run.later(function () {
            remainingItemsMsg.removeClass('h-jiggle');
          }, 700);
        }
      }
    }
  });
});