define('client/components/dynamic-form/view/question-with-assessment/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/complex-formElement'], function (exports, _dynamicFormElement, _complexFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _complexFormElement.default, {
    classNames: ['c-view-text'],
    savedValue: '',
    actions: {
      /**
       * When the checkbox is toggled, if on sets the value of this element to be 'Provision not relevant', else
       * sets it to whatever was the last text entered into the textarea
       */
      toggleCheckbox: function toggleCheckbox(checked) {
        var newStateValue = 'Provision not relevant';
        if (!checked) {
          newStateValue = get(this, 'savedValue');
        }
        this.updateState(0, newStateValue);
      },


      /**
       * Updates the value of this element with newly entered text
       * Saves both to the state tree, but also to a local 'savedState' var which is used to remember the last entered
       * value so checking and unchecking the checkbox doesn't require the user to re-enter the same text again
       */
      updateTextarea: function updateTextarea(value) {
        set(this, 'savedValue', value);
        this.updateState(0, value);
      }
    }
  });
});