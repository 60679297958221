define('client/libs/validation-error-lib', ['exports', 'npm:lodash/isObject'], function (exports, _isObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.deDupErrors = exports.countErrors = undefined;


  function countErrors(state) {
    if (!state || !(0, _isObject.default)(state)) return 0;

    return Object.keys(state).reduce(function (currentCount, name) {
      currentCount += state[name].reduce(function (internalCount, instanceState) {
        // If this element has errors then add the number to the count
        if (instanceState.errors) internalCount += instanceState.errors.length;

        // If this element is a container, then dig down to the next level to grab any further errors down the hierarchy
        if ((0, _isObject.default)(instanceState.val)) internalCount += countErrors(instanceState.val);
        return internalCount;
      }, 0);
      return currentCount;
    }, 0);
  }

  /**
   * Removes duplicates from an array of Error objects
   * @param {Object[]} errors
   * @returns {string[]}
   */
  function deDupErrors(errors) {
    var errorsObj = errors.reduce(function (acc, error) {
      acc[error.description] = true;
      return acc;
    }, {});
    return Object.keys(errorsObj);
  }

  exports.countErrors = countErrors;
  exports.deDupErrors = deDupErrors;
});