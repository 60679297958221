define('client/components/dynamic-form/view/form-link-button/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/subform-creation', 'client/constants', 'ember-copy'], function (exports, _dynamicFormElement, _subformCreation, _constants, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _subformCreation.default, {
    dynamicFormsMethods: inject.service(),

    parentId: computed(function () {
      var state = (0, _emberCopy.copy)(get(this, 'formElementState'), true);
      return state[0]['val']['parentId'][0]['val'];
    }),
    childFormId: computed(function () {
      var state = (0, _emberCopy.copy)(get(this, 'formElementState'), true);
      return state[0]['val']['childFormId'][0]['val'];
    }),

    emberRoute: computed.alias('formElement.extendedAttributes.emberRoute'),
    routeFragment: computed.alias('formElement.extendedAttributes.routeFragment'),

    navigate: function navigate() {
      var route = getWithDefault(this, 'emberRoute', 'processing-section.submitted-application');
      var routeFragment = getWithDefault(this, 'routeFragment', 'application');
      var id = getWithDefault(this, 'childFormId', undefined);
      var parentId = getWithDefault(this, 'parentId', undefined);

      if (!parentId) {
        get(this, 'flashMessages.danger')('Unable to navigate to form');
      }

      var transitionArgs = {
        route: route,
        mode: _constants.MODE.VIEW,
        parentId: parentId,
        formRootPath: routeFragment,
        id: id
      };

      get(this, 'dynamicFormsMethods').transitionToForm(transitionArgs);
    },


    actions: {
      navigate: function navigate() {
        this.navigate();
      }
    }
  });
});