define('client/components/dynamic-form/view/radio/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',
    displayValue: computed('stateValue', 'formElement.options.@each.label', function () {
      var stateValue = get(this, 'stateValue');
      if (!stateValue) return null;

      var option = null;
      try {
        option = get(this, 'formElement.options').find(function (option) {
          return get(option, 'value') === stateValue;
        });
      } catch (e) {
        console.log('Probable configuration error: Options failed to load for ' + get(this, 'formElement.name'));
      }

      return option && option.label || '-';
    })
  });
});