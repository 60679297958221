define('client/libs/generic-search', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  // Simplifies a filter object by collapsing/removing children
  exports.removeRedundantFilters = function () {
    var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var removeEmpty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var removeSingleton = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    // a filter has two types: a single filter (filterKey), or a set of filters (filterSet)
    if (filter.filterSet) {
      var filteredFilters = filter.filterSet.filters.map(function (f) {
        return exports.removeRedundantFilters(f, removeEmpty, removeSingleton);
      }).filter(function (f) {
        return f !== undefined;
      });

      if (removeEmpty && filteredFilters.length === 0) {
        // remove filterSets with no filters
        return undefined;
      } else if (removeSingleton && filteredFilters.length === 1) {
        // collapse filterSets with a single filter
        return filteredFilters[0];
      } else {
        // base case for filterSet - return filterSet with removeRedundantFilters(filters)
        return Object.assign({}, filter, {
          'filterSet': {
            'booleanOperator': filter.filterSet.booleanOperator,
            'filters': filteredFilters
          }
        });
      }
    } else if (filter.filterKey) {
      return filter;
    }
  };

  // List of valid date ranges and functions to calculate them. Used by the date picker input and pill components
  exports.dateRangeFilterOperatorList = [{
    'value': '',
    'label': 'Custom'
  }, {
    'value': 'last-12-calendar-months',
    'label': 'Last 12 calendar months',
    'help': 'The current incomplete month does not form part of this range.',
    'getMomentUpper': function getMomentUpper() {
      return (0, _moment.default)().subtract(1, 'months').endOf('month');
    },
    'getMomentLower': function getMomentLower() {
      return (0, _moment.default)().subtract(12, 'months').startOf('month');
    },
    'getPillText': function getPillText() {
      return [{ operator: 'in the' }, { value: 'last 12 calendar months' }];
    }
  }, {
    'value': 'last-6-calendar-months',
    'label': 'Last 6 calendar months',
    'help': 'The current incomplete month does not form part of this range.',
    'getMomentUpper': function getMomentUpper() {
      return (0, _moment.default)().subtract(1, 'months').endOf('month');
    },
    'getMomentLower': function getMomentLower() {
      return (0, _moment.default)().subtract(6, 'months').startOf('month');
    },
    'getPillText': function getPillText() {
      return [{ operator: 'in the' }, { value: 'last 6 months' }];
    }
  }, {
    'value': 'last-calendar-month',
    'label': 'Last calendar month',
    'help': 'The current incomplete month does not form part of this range.',
    'getMomentUpper': function getMomentUpper() {
      return (0, _moment.default)().subtract(1, 'months').endOf('month');
    },
    'getMomentLower': function getMomentLower() {
      return (0, _moment.default)().subtract(1, 'months').startOf('month');
    },
    'getPillText': function getPillText() {
      return [{ operator: 'in the' }, { value: 'last month' }];
    }
  }, {
    'value': 'this-calendar-month',
    'label': 'This calendar month',
    'getMomentUpper': function getMomentUpper() {
      return (0, _moment.default)().endOf('month');
    },
    'getMomentLower': function getMomentLower() {
      return (0, _moment.default)().startOf('month');
    },
    'getPillText': function getPillText() {
      return [{ operator: 'is' }, { value: 'this month' }];
    }
  }, {
    'value': 'last-28-days',
    'label': 'Last 28 days',
    'getMomentUpper': _moment.default,
    'getMomentLower': function getMomentLower() {
      return (0, _moment.default)().subtract(27, 'days');
    }, // -1 because the range displayed includes the start and end dates
    'getPillText': function getPillText() {
      return [{ operator: 'in the' }, { value: 'last 28 days' }];
    }
  }, {
    'value': 'last-14-days',
    'label': 'Last 14 days',
    'getMomentUpper': _moment.default,
    'getMomentLower': function getMomentLower() {
      return (0, _moment.default)().subtract(13, 'days');
    }, // -1 because the range displayed includes the start and end dates
    'getPillText': function getPillText() {
      return [{ operator: 'in the' }, { value: 'last 14 days' }];
    }
  }, {
    'value': 'last-7-days',
    'label': 'Last 7 days',
    'getMomentUpper': _moment.default,
    'getMomentLower': function getMomentLower() {
      return (0, _moment.default)().subtract(6, 'days');
    }, // -1 because the range displayed includes the start and end dates
    'getPillText': function getPillText() {
      return [{ operator: 'in the' }, { value: 'last 7 days' }];
    }
  }, {
    'value': 'yesterday',
    'label': 'Yesterday',
    'getMomentUpper': function getMomentUpper() {
      return (0, _moment.default)().subtract(1, 'days');
    },
    'getMomentLower': function getMomentLower() {
      return (0, _moment.default)().subtract(1, 'days');
    },
    'getPillText': function getPillText() {
      return [{ operator: 'is' }, { value: 'yesterday' }];
    }
  }, {
    'value': 'today',
    'label': 'Today',
    'getMomentUpper': _moment.default,
    'getMomentLower': _moment.default,
    'getPillText': function getPillText() {
      return [{ operator: 'is' }, { value: 'today' }];
    }
  }];
});