define('client/routes/processing-section/notification', ['exports', 'client/mixins/routes/generic-workflows', 'client/libs/documents-component'], function (exports, _genericWorkflows, _documentsComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    workflows: [{
      key: 'notification_package',
      path: 'notification-package',
      title: 'Notification package'
    }, {
      key: 'notification_submissions',
      // NOTE: If this is changed, need to update the dashboard route as well
      path: 'notification-submissions',
      title: 'Notification submissions',
      hideContinueToNextWorkflow: function hideContinueToNextWorkflow() {
        return true;
      },
      actions: [{
        name: 'Delete submission',
        icon: 'trash',
        method: 'deletePublicSubmission',
        permissionRequired: 'global.view_application_decision'
      }]
    }, {
      component: 'documents',
      type: 'component',
      path: 'documents',
      title: 'Documents',
      getMenu: _documentsComponent.default.getMenu
    }]
  });
});