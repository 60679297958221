define('client/components/dynamic-form/view/numbered-text-fields/section-element/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNameBindings: ['formElement.repeatable:c-view-section__inner'],

    number: computed('formElement.formElements', function () {
      var formElements = get(this, 'formElement.formElements');
      var formElement = formElements.find(function (item) {
        return item.type === 'text';
      });
      var val = get(this, 'stateValue.' + formElement.name + '.0.val');
      // Very occasionally migrated data has a trailing '.', strip it so there
      // aren't two when displaying.
      return val ? val.replace(/\.+$/, '') : '';
    }),

    text: computed('formElement.formElements', function () {
      var formElement = get(this, 'textFormElement');
      var val = get(this, 'stateValue.' + formElement.name + '.0.val');
      return val || '-';
    }),

    /**
     * Get the child text form element.
     */
    textFormElement: computed('formElement.formElements', function () {
      var formElements = get(this, 'formElement.formElements');
      var formElement = formElements.find(function (item) {
        return item.type === 'text-area';
      });
      return formElement;
    })
  });
});