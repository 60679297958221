define('client/components/dynamic-form/view/location/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/libs/map-lib'], function (exports, _dynamicFormElement, _mapLib) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-view-location'],
    hideMapInView: computed.bool('formElement.extendedAttributes.hideMapInView'),
    easting: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'easting';
      });
    }),
    eastingVal: computed('stateValue', function () {
      return get(this, 'stateValue.easting')[0].val;
    }),
    northing: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'northing';
      });
    }),
    northingVal: computed('stateValue', function () {
      return get(this, 'stateValue.northing')[0].val;
    }),
    // Optional - needed to customise the icon based on consent type.
    consentType: computed('formElement.formElements.[]', function () {
      return get(this, 'formElement.formElements').find(function (obj) {
        return obj.name === 'pinTypeConsent';
      });
    }),
    // Optional - needed to customise the icon based on consent type.
    consentTypeVal: computed('stateValue.pinTypeConsent.0.val', function () {
      return get(this, 'stateValue.pinTypeConsent.0.val');
    }),
    markers: computed('eastingVal', 'northingVal', function () {
      var easting = get(this, 'eastingVal');
      var northing = get(this, 'northingVal');

      if (!_mapLib.default.validateNztm(easting, northing)) return [];

      var icon = null;

      // Consents
      // If there is a consent type child - use it to set an icon.
      var consentType = get(this, 'consentType');
      var consentTypeVal = get(this, 'consentTypeVal');
      if (consentTypeVal && consentType && consentType.options) {
        var consentTypeOption = consentType.options.find(function (o) {
          return o.value === consentTypeVal;
        });
        if (consentTypeOption) {
          icon = _mapLib.default.consentTypeIcons[consentTypeOption.label];
        }
      }

      return [{
        position: [easting, northing],
        icon: icon
      }];
    })
  });
});