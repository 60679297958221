define('client/components/dynamic-form/view/form-link/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/mixins/components/form-label'], function (exports, _dynamicFormElement, _formLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, _formLabel.default, {
    init: function init() {
      // This section generates the id that can be navigated to from the top menu.
      // The top menu is currently not used.
      // todo: code candidate for removing once feature complete

      this._super.apply(this, arguments);

      var instanceId = get(this, 'formElementState.0.formInstanceId');
      if (instanceId) {
        this.set('elementId', instanceId + get(this, 'formElement.name'));
      }
    }
  });
});