define('client/services/default-functions', ['exports', 'moment', 'client/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Service.extend({
    session: inject.service(),
    gateKeeper: inject.service(),

    getFirstName: function getFirstName() {
      return get(this, 'session.currentUser.firstName') || null;
    },
    getLastName: function getLastName() {
      return get(this, 'session.currentUser.lastName') || null;
    },
    getEmail: function getEmail() {
      return get(this, 'session.currentUser.email') || null;
    },
    getCurrentDate: function getCurrentDate() {
      return (0, _moment.default)().format(_environment.default.dateFormat);
    },
    getCurrentUserId: function getCurrentUserId(permissionRequired) {
      if (!(permissionRequired instanceof String) || this.gateKeeper.can(permissionRequired)) {
        return '' + get(this, 'session.currentUser.id');
      }
      return null;
    }
  });
});