define('client/components/dynamic-form/view/read-only-remote-method-form/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    resourceConsentRemoteMethods: inject.service(),
    tagName: '',
    result: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      // Calls a method on resourceConsentRemoteMethods with the value and displays the response.
      var resourceConsentRemoteMethods = get(this, 'resourceConsentRemoteMethods');
      var methodName = get(this, 'formElement.extendedAttributes.methodName');
      if (methodName && typeof resourceConsentRemoteMethods[methodName] === 'function') {
        resourceConsentRemoteMethods[methodName](get(this, 'stateValue')).then(function (result) {
          return set(_this, 'result', result);
        });
      }
    },


    actions: {
      noop: function noop() {
        // pass
      }
    }
  });
});