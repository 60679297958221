define('client/mixins/components/complex-formElement', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Mixin.create({
    populateFunctions: inject.service(),

    isFormContainer: computed('form.formElements', function () {
      // Sections that contain only forms need to behave differently for rendering, adding additional items for repeating
      // sections, and (for section-elements) deleting sections
      // At the moment, and this may change, we determine if this section is a formContainer by:
      // 1. Does this section only contain one child element?
      // 2. Is that child element of type 'form-link'
      // BRITTLE
      var childElements = get(this, 'formElement.formElements');
      return childElements.length === 1 && get(childElements, '0.type') === 'form-link';
    }),

    // If we need to fetch a form link tables data from the server, it's flagged
    // in the extendedAttributes.
    fetchFormLinkTableData: computed.bool('formElement.formElements.0.extendedAttributes.fetchFormLinkTableData'),

    showAddButton: computed('totalNumberDisplayed', 'formElement.maxRepeats', 'formElement.extendedAttributes.hideAddButtonClientSide', function () {
      var isHideAddButton = get(this, 'formElement.extendedAttributes.hideAddButtonClientSide');
      if (isHideAddButton) return false;
      var maxRepeats = get(this, 'formElement.maxRepeats');
      if (!maxRepeats) return true;
      return get(this, 'totalNumberDisplayed') < maxRepeats;
    }),

    actions: {
      updateStateKey: function updateStateKey(sectionIndex, key, childState) {
        var state = (0, _emberCopy.copy)(get(this, 'state'), true);
        var elementName = get(this, 'formElement.name');
        state[elementName][sectionIndex].val[key] = childState;

        return get(this, 'updateStateKey')(elementName, state[elementName]);
      }
    }
  });
});