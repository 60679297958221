define('client/components/dynamic-form/edit/tool-tip/component', ['exports', 'client/mixins/components/dynamic-formElement', 'client/constants'], function (exports, _dynamicFormElement, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    classNames: ['c-tool-tip'],
    classNameBindings: ['isToolTipEmpty:c-tool-tip--empty'],
    isToolTipEmpty: computed('toolTipText', function () {
      return !get(this, 'toolTipText');
    }),
    toolTipMaxLength: _constants.TOOL_TIP_MAX_CHAR_LENGTH,
    iconType: 'info',
    iconWidth: '12px',
    iconHeight: '12px'
  });
});