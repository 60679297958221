define('client/components/dynamic-form/view/table-view/component', ['exports', 'client/mixins/components/dynamic-formElement', 'ember-light-table'], function (exports, _dynamicFormElement, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;

  var whiteList = ['address', 'checkbox', 'checkbox-group', 'file-upload', 'location', 'number', 'person-name', 'question-with-assessment', 'radio', 'restricted-rich-text', 'select', 'text', 'text-area', 'date'];

  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    table: null,

    /**
     * Table columns in the format that Ember Light Table uses.
     */
    columns: computed('state', 'formElement.formElements', function () {
      var formElements = get(this, 'formElement.formElements.0.formElements');

      // Loop through the form elements, remove any non-whiteList form element types and convert them into Ember Light Table
      // column objects.
      var columns = formElements.filter(function (fe) {
        return whiteList.includes(fe.type);
      }).map(function (fe) {
        // Defaults
        var label = fe.label;
        var cellComponent = 'dynamic-form/view/table-view/field';
        var valuePath = 'val.' + fe.name + '.0.val';
        var classNames = 'c-table__cell c-table__cell--heading c-table__cell--view-heading';
        var cellClassNames = 'c-table__cell c-table__cell--view c-table__cell--wrap c-table__cell_' + fe.type;
        var format = void 0;

        // Form Element type specifics
        if (fe.type === 'date') {
          cellComponent = 'dynamic-form/view/table-view/date-field';
        }
        if (fe.type === 'select') {
          // Use a format function to display the correct text for the select option.
          format = function format(value) {
            var option = fe.options.find(function (option) {
              return get(option, 'value') === value;
            });
            return option && option.label ? option.label : '';
          };
        }
        if (fe.type === 'file-upload') {
          cellComponent = 'dynamic-form/view/table-view/file-field';
        }

        // Extended attributes
        if (get(fe, 'extendedAttributes.tableColumnLabel')) {
          label = get(fe, 'extendedAttributes.tableColumnLabel');
        }

        return {
          label: label,
          valuePath: valuePath,
          cellComponent: cellComponent,
          format: format,
          classNames: classNames,
          cellClassNames: cellClassNames,
          sortable: false,
          align: 'inherit'
        };
      });

      return columns;
    }),

    /**
     * Rows for the table extracted from the state.
     */
    rows: computed('state', 'formElement.formElements', function () {
      var state = get(this, 'state');
      var name = get(this, 'formElement.name');
      var sectionName = get(this, 'formElement.formElements.0.name');
      return get(state, name + '.0.val.' + sectionName) || [];
    }),

    errors: computed('rows.@each.errors', function () {
      // Assumption made that the child of a table-view type is a repeatable section.  Errors will have been
      // bubbled up to that section, but can't be displayed there as each section is a row, with no space to display
      // errors
      var errors = get(this, 'rows').reduce(function (errors, row) {
        if (row.errors) {
          // Add each unique error to the errors object
          row.errors.forEach(function (error) {
            errors[error.description] = error.description;
          });
        }
        return errors;
      }, {});

      // Each key is a description, so just return those
      return Object.keys(errors);
    }),

    noContentMessage: computed('formElement.extendedAttributes.noContentMessage', function () {
      return get(this, 'formElement.extendedAttributes.noContentMessage');
    }),

    /**
     * Instantiate the Ember Light Table.
     */
    init: function init() {
      this._super.apply(this, arguments);

      var table = new _emberLightTable.default(get(this, 'columns'), get(this, 'rows'));
      this.set('table', table);
    }
  });
});