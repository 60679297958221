define('client/components/app-nav/user-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      get = Ember.get,
      inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    session: inject.service(),
    router: inject.service(),
    classNames: ['c-user-menu'],
    classNameBindings: ['isOpen:c-user-menu--open'],
    isOpen: false,
    usernameAndRole: computed('session.username', 'session.roleDisplayName', function () {
      var username = get(this, 'session.username');
      var role = get(this, 'session.roleDisplayName');
      return role ? username + ' | ' + role : username;
    }),
    actions: {
      login: function login() {
        get(this, 'router').transitionTo('home-section', { queryParams: { loginModal: true } });
      },
      logout: function logout() {
        get(this, 'session').logout();
      },
      changePassword: function changePassword() {
        get(this, 'session').changePassword();
      },
      toggleUserMenu: function toggleUserMenu() {
        this.toggleProperty('isOpen');
      },
      closeUserMenu: function closeUserMenu() {
        set(this, 'isOpen', false);
      }
    }
  });
});