define('client/routes/processing-section/checklist', ['exports', 'client/libs/documents-component', 'client/mixins/routes/generic-workflows'], function (exports, _documentsComponent, _genericWorkflows) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_genericWorkflows.default, {
    workflows: [{
      key: 'pre_checks',
      path: 'pre-checks',
      title: 'Assignee & pre-checks'
    }, {
      key: 'assignee',
      path: 'assignee',
      title: 'Assignee',
      actions: [{
        name: 'Send notification to processing officer',
        icon: 'bell',
        method: 'sendAssignedProcessingOfficerNotification',
        permissionRequired: 'global.send_assigned_processing_officer_notification'
      }]
    }, {
      key: 'processing_properties',
      path: 'properties',
      title: 'Properties',
      actions: [{
        name: 'Create Content Manager folders',
        icon: 'add', // TODO review icon
        method: 'createTrimFolders',
        permissionRequired: 'global.create_trim_folders'
      }]
    }, {
      key: 'affected_person_approvals_workflow',
      path: 'affected-person-approvals',
      title: 'Affected person approvals'
    }, {
      type: 'form',
      key: 'section_88_checks',
      path: 'section-88-checks',
      title: 'Section 88 check',
      includeApplicationNumber: true
    }, {
      component: 'documents',
      type: 'component',
      path: 'documents',
      title: 'Documents',
      getMenu: _documentsComponent.default.getMenu
    }]
  });
});