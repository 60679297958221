define('client/components/dynamic-form/edit/number/component', ['exports', 'client/mixins/components/dynamic-formElement'], function (exports, _dynamicFormElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_dynamicFormElement.default, {
    tagName: '',

    attr: computed.alias('formElement.extendedAttributes'),
    min: computed.alias('attr.min'),
    max: computed.alias('attr.max'),
    maxlength: 9,
    step: computed('attr.{step,decimalPlaces}', function () {
      // if step is defined in the exendedAttributes, use that
      var step = get(this, 'attr.step');
      if (step !== undefined) {
        return step;
      }

      // else if decimalPlaces is defined, convert that to a step, eg. if decimalPlaces=2 then step=0.01
      var decimalPlaces = get(this, 'attr.decimalPlaces');
      if (decimalPlaces !== undefined) {
        return Math.pow(10, -decimalPlaces);
      }

      // else set to step="any"
      // The default step is 1 (allowing only integers, unless the min attribute has a non-integer value). See https://www.w3.org/TR/2010/WD-html5-20101019/number-state.html#number-state
      return 'any';
    }),

    // TODO: this is "$" if the field is used for money
    prefix: computed.alias('attr.prefix'),

    uniqueId: Ember.inject.service(),
    inputId: Ember.computed(function () {
      return this.uniqueId.next();
    }),

    actions: {
      setValue: function setValue(value) {
        if (value.toString().length > this.maxlength) {
          // this will prevent pasting a large number
          var v = value.toString().substr(0, this.maxlength);
          this.setValue(v);
        } else if (value.trim() === '') {
          this.clearValue();
        } else {
          this.setValue(value);
        }
      },
      preventLargeNumber: function preventLargeNumber(e) {
        // Acceptable keys: delete, backspace, right arrow, left arrow, tab, shift
        var acceptableKeys = [8, 46, 39, 37, 9, 16];
        var value = e.target.value.toString();
        if (value.length === this.maxlength && value !== '' && acceptableKeys.indexOf(e.keyCode) === -1) {
          e.preventDefault();
        }
        // prevent letter 'e', to prevent a number with notation
        if (e.keyCode === 69) {
          e.preventDefault();
        }
      }
    }
  });
});