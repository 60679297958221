define('client/components/generic-search/filter/input/date-range/component', ['exports', 'moment', 'client/config/environment', 'client/libs/generic-search'], function (exports, _moment, _environment, _genericSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    dateValueLower: null,
    dateValueUpper: null,

    filterOperator: '',
    filterOperatorList: _genericSearch.dateRangeFilterOperatorList,

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.filter && this.filter.filterOperator) {
        this.actions.setFilterOperator.call(this, this.filter.filterOperator);
      } else {
        Ember.set(this, 'dateValueLower', this.getFilterValue('>=') || null);
        Ember.set(this, 'dateValueUpper', this.getFilterValue('<=') || null);
      }
    },


    // The format used to display the currently set date
    dateFormat: _environment.default.dateFormat,

    // Use a custom function to parse dates entered by the user as text
    parseDate: function parseDate(dateString, format) {
      var dateFormats = [format, // The same format as used for display
      'D/M/YYYY', // Standard NZ date formatting
      'YYYY-M-D' // ISO standard date formatting
      ];
      return (0, _moment.default)(dateString, dateFormats).toDate();
    },


    filters: Ember.computed.alias('filter.filterSet.filters'),

    getFilterValue: function getFilterValue(filterOperator) {
      var filter = (this.filters || []).find(function (f) {
        return f.filterOperator === filterOperator;
      });
      return filter ? filter.filterValue : undefined;
    },


    // Javascript date objects for passing to the date picker component's min/max date properties
    dateLower: Ember.computed('dateValueLower', function () {
      return (0, _moment.default)(this.dateValueLower).toDate();
    }),
    dateUpper: Ember.computed('dateValueUpper', function () {
      return (0, _moment.default)(this.dateValueUpper).toDate();
    }),

    dateInputClasses: Ember.computed('isMapMode', function () {
      var classes = ['c-form-date-field__input'];
      if (this.isMapMode) {
        classes.push('c-form-date-field__input--small');
      }
      return classes.join(' ');
    }),

    actions: {
      setFilterOperator: function setFilterOperator(filterOperator) {
        Ember.set(this, 'filterOperator', filterOperator);
        this.actions.setDate.call(this);
      },
      setDate: function setDate(bound, date) {
        var _this = this;

        if (this.filterOperator === '') {
          // custom date range
          var dateValue = date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null;

          if (bound === 'lower') {
            Ember.set(this, 'dateValueLower', dateValue);
          } else if (bound === 'upper') {
            Ember.set(this, 'dateValueUpper', dateValue);
          }

          // recompute the filter and send it up to the parent component
          this.setValue({
            'filterSet': {
              'booleanOperator': 'AND',
              'filters': [{
                'filterKey': this.template.filterKey,
                'filterOperator': '>=',
                'filterValue': bound === 'lower' ? dateValue : this.dateValueLower
              }, {
                'filterKey': this.template.filterKey,
                'filterOperator': '<=',
                'filterValue': bound === 'upper' ? dateValue : this.dateValueUpper
              }].filter(function (_ref) {
                var filterValue = _ref.filterValue;
                return filterValue;
              })
            }
          });
        } else {
          // set local values
          var _filterOperatorList$f = this.filterOperatorList.find(function (f) {
            return f.value === _this.filterOperator;
          }),
              getMomentLower = _filterOperatorList$f.getMomentLower,
              getMomentUpper = _filterOperatorList$f.getMomentUpper;

          Ember.set(this, 'dateValueLower', getMomentLower ? getMomentLower().format('YYYY-MM-DD') : null);
          Ember.set(this, 'dateValueUpper', getMomentUpper ? getMomentUpper().format('YYYY-MM-DD') : null);

          // recompute the filter and send it up to the parent component
          this.setValue({
            'filterKey': this.template.filterKey,
            'filterOperator': this.filterOperator
          });
        }
      }
    }
  });
});